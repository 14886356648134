import React, { useMemo } from "react";
import { DRAWER, useModalV2 } from "../../../../components/modal";
import { TAG_PROPS } from "./props";
import ChannelsTagAutomationManager from "./ChannelTagAutomationManager";
import style from "./style.module.scss";
import { TagList } from "../../../../components/tagList";
import { TagButton } from "../../../../components/tagButton";
import { useTagsRepository } from "../../../../repository";

function TagAutomationTagging({
  channelType,
  objectType,
  setTempModalState,
  tags,
}) {
  const [TagManager, openTagManager] = useModalV2(
    ChannelsTagAutomationManager,
    DRAWER
  );

  const tagManagerTitle = useMemo(() => `Auto Tag ${objectType}`, [objectType]);

  const objectTypeLabel = useMemo(() => `${objectType}`, [objectType]);

  const repository = useTagsRepository();

  return (
    <>
      <TagManager
        objectType={objectType}
        initialSelectedTags={[]}
        setTempModalState={setTempModalState}
        tags={tags}
        title={tagManagerTitle}
        repository={repository}
      />
      <div className={style.tagAutomationCard__object}>
        <div className={style.tagAutomationCard__tagTypeTitle}>
          {objectTypeLabel}
        </div>
        {tags.length > 0 && (
          <div className={style.tags}>
            <TagList
              onClick={openTagManager}
              size={0.5}
              tags={tags}
              maxCharPerRow={TAG_PROPS.MAX_CHAR_PER_ROW}
              maxRows={TAG_PROPS.MAX_NUM_ROWS}
            />
          </div>
        )}
        {tags.length < 1 && (
          <div className={style.tags}>
            <TagButton
              onClick={openTagManager}
              name={`/channels/${channelType}/add_channel/auto_tag_${objectType}`}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default TagAutomationTagging;
