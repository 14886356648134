import { buildRequest, fetchWithErrorHandling } from "../utils/fetchV2";
import { API } from "../props";

async function saveTeamTags(teamIds, tagIds) {
  return await fetchWithErrorHandling(
    buildRequest(API.tags.tagTeams, {
      body: JSON.stringify(
        {
          tagIds,
          teamIds,
        },
        null,
        4
      ),
      method: "PUT",
    })
  );
}

export function useTeamTagsRepository() {
  return {
    saveTeamTags,
  };
}
