import React, { useEffect, useState } from "react";

import {
  Stack,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import Dialog from "../../componentsV2/dialogs/Dialog";
import SecondaryButton from "../../componentsV2/buttons/SecondaryButton";
import PrimaryButton from "../../componentsV2/buttons/PrimaryButton";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Account } from "../../pages/settings/accounts";

export function ContactsSyncAccountsModal({
  open,
  accounts,
  onSubmit,
  onClose,
}: {
  open: boolean;
  accounts: Account[];
  onSubmit: (selected: Account[]) => void;
  onClose: () => void;
}) {
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);

  const accountEnabled = (account: Account) =>
    account.authEnabled && account.authValid;

  useEffect(() => {
    setSelectedAccounts([]);
  }, [open]);

  if (!accounts) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Synchronize Contacts"
      actions={
        <>
          <PrimaryButton
            disabled={selectedAccounts.length < 1}
            onClick={() => {
              onSubmit(selectedAccounts);
            }}
          >
            Synchronize
          </PrimaryButton>

          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
    >
      <Stack direction="column" spacing={2}>
        <Typography>
          Please select your connected user accounts to synchronize contacts
          from:
        </Typography>
        {accounts
          .sort(
            (a, b) => (accountEnabled(b) ? 1 : 0) - (accountEnabled(a) ? 1 : 0)
          )
          .map((account) => (
            <FormGroup key={account.userId}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    disabled={!accountEnabled(account)}
                    checked={selectedAccounts.some(
                      (a) => a.userId === account.userId
                    )}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedAccounts([account, ...selectedAccounts]);
                      } else {
                        setSelectedAccounts([
                          ...selectedAccounts.filter(
                            (selectedAccount) =>
                              selectedAccount.userId !== account.userId
                          ),
                        ]);
                      }
                    }}
                  />
                }
                label={
                  accountEnabled(account)
                    ? account.email
                    : `${account.email} (disabled)`
                }
              />
            </FormGroup>
          ))}
      </Stack>
    </Dialog>
  );
}
