import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useReportingService } from "../services";
import { buildQueryString } from "../utils";
import { errorHandler } from "src/hooks/errorHandler";

export function useOrgReporting(limit: number, offset: number, qry?: string) {
  const service = useReportingService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const query = buildQueryString({ limit, offset, qry });
  const url = `/api/reporting/orgs?${query}`;

  const { data, error } = useSWR(url, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as {
      reports: {
        accountName: string;
        acceptRate: number;
        macsUsedPerDay: number;
        expiredUsers: number;
        meetingTypePerformances: {
          high: {
            name: string;
            acceptRate: number;
          };
          low: {
            name: string;
            acceptRate: number;
          };
        };
        totals: {
          users: number;
          macsConsumed: number;
          instancesProvisioned: number;
          importChannels: number;
          updateChannels: number;
          instancesInFinalState: number;
          meetingsOnCalendar: number;
          periodData: {
            rolling: number;
            current: number;
            previous: number;
            meetingsOnCalendarRolling: number;
            meetingsOnCalendarCurrent: number;
          };
        };
      }[];
      currentStaticDayRange: {
        start: string;
        end: string;
      };
      previousStaticDayRange: {
        start: string;
        end: string;
      };
      orgCount: number;
    },
    error,
    loading: !error && !data,
  };
}
