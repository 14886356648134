import React, { useCallback, useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { MediumSpacing } from "../../../components/spacing";
import { P1 } from "../../../components/typography";
import { GUEST_STATUS_TO_LABEL, toGuestStatusColor } from "../../props";
import { useHasOrgAdminPermissions } from "../../../auth";
import { useGuestsRepository } from "../../../repository";
import GuestStatusSelect from "../../guestStatusSelect";
import { useMeeting } from "../state";
import useOverrideStatusRules from "../../guestStatusSelect/useOverrideStatusRules";
import style from "./guestHubGuestListItemStatus.module.scss";
import { confirm } from "../../../components/confirmModal/ConfirmModal";

const GuestHubGuestListItemStatus = ({ guest = null, status }) => {
  const [meeting] = useMeeting();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const { patchGuestStatus } = useGuestsRepository();
  const { overridable } = useOverrideStatusRules();

  const statusColor = useMemo(
    () => ({
      backgroundColor: `${toGuestStatusColor(status)}`,
    }),
    [status]
  );

  const canEditGuestStatus = useMemo(
    () =>
      hasOrgAdminPermissions &&
      guest !== null &&
      overridable({
        fromStatus: status,
      }),
    [guest, hasOrgAdminPermissions, overridable, status]
  );

  const setStatus = useCallback(
    async (s) => {
      try {
        // ask user if they want to notify the guest
        let notifyGuest = true;
        try {
          await confirm({
            cancelLabel: "No",
            okLabel: "Yes",
            title: (
              <div>
                Would you like guests to receive notifications of this status
                update?
                <dvi>(Defaults to "Yes")</dvi>
                <MediumSpacing />
                <div>
                  *Note: selecting "No" is not recommended unless the intention
                  is data cleanup, in which case we avoid any calendar
                  operations
                </div>
              </div>
            ),
          });
        } catch {
          notifyGuest = false;
        }
        // TODO: This is not a good way to update guest status. Should be done through the controller
        patchGuestStatus({
          guestRefId: guest.id,
          guestType: guest.type,
          meetingId: meeting.id,
          notifyGuest,
          status: s,
        });
      } catch (error) {
        throw error;
      }
    },
    [guest, patchGuestStatus, meeting]
  );

  return (
    <Flexbox>
      {({ Row }) => (
        <Row className={style.guestStatus} style={statusColor}>
          {!canEditGuestStatus && (
            <P1 className={style.guestStatus__text}>
              {GUEST_STATUS_TO_LABEL[status]}
            </P1>
          )}

          {canEditGuestStatus && (
            <GuestStatusSelect status={status} setStatus={setStatus} />
          )}
        </Row>
      )}
    </Flexbox>
  );
};

export default GuestHubGuestListItemStatus;
