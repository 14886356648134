import React, { useCallback } from "react";
import { TagManager } from "../../../../components/tagManager";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import { useTagsRepository } from "../../../../repository";
import { CHANNEL_TAG_AUTOMATION_OBJECT_TYPES } from "./props";

const isSavable = (selectedTags) => selectedTags !== null;

const determineTagsToKeep = (removeTagIds, currentTags) => {
  const keep = [];

  currentTags.forEach((ct) => {
    for (let i = 0; i < removeTagIds.length; i += 1) {
      if (removeTagIds[i] === ct.id) {
        break;
      }

      keep.push(ct);
    }
  });

  return keep;
};

function ChannelTagAutomationManager({
  close,
  objectType,
  setTempModalState,
  tags,
  title,
}) {
  const repository = useTagsRepository();
  const { addError } = useGeneralNotifications();
  // lots of set temp modal state

  const editChannelAutomationTags = useCallback(
    async (tagId, update) => {
      try {
        // server update
        await repository.updateTag(tagId, update);

        // local state update
        setTempModalState((p) => ({
          ...p,
          tagAutomation: {
            tagContacts: p.tagAutomation.tagContacts.map((ct) => {
              if (ct.id === update.id) {
                // inject the updated tag
                return update;
              }
              return ct;
            }),
            tagMeetings: p.tagAutomation.tagMeetings.map((ct) => {
              if (ct.id === update.id) {
                // inject the updated tag
                return update;
              }
              return ct;
            }),
          },
        }));
      } catch (e) {
        addError("Unable to update tag");
      }
    },
    [addError, repository, setTempModalState]
  );

  const saveChannelAutomationTags = useCallback(
    (newTags) => {
      // update current tempModalState, no server calls here
      switch (objectType) {
        case CHANNEL_TAG_AUTOMATION_OBJECT_TYPES.CONTACT:
          // udpate tagContacts
          setTempModalState((p) => ({
            ...p,
            tagAutomation: {
              ...p.tagAutomation,
              tagContacts: newTags,
            },
          }));
          break;

        case CHANNEL_TAG_AUTOMATION_OBJECT_TYPES.INSTANCE:
          // update tagMeetings
          setTempModalState((p) => ({
            ...p,
            tagAutomation: {
              ...p.tagAutomation,
              tagMeetings: newTags,
            },
          }));

          break;

        default:
          throw Error(`Unkown tag automation object type: ${objectType}`);
      }
    },
    [objectType, setTempModalState]
  );

  const removeChannelAutomationTags = useCallback(
    (removeTagIds) => {
      if (!removeTagIds || removeTagIds.length < 1) {
        return;
      }
      // remove from  current tempModalState, no server calls here
      // the TagManager component handles actual tag deletion from list
      setTempModalState((p) => ({
        ...p,
        tagAutomation: {
          tagContacts: determineTagsToKeep(
            removeTagIds,
            p.tagAutomation.tagContacts
          ),
          tagMeetings: determineTagsToKeep(
            removeTagIds,
            p.tagAutomation.tagMeetings
          ),
        },
      }));
    },
    [setTempModalState]
  );

  return (
    <TagManager
      close={close}
      initialSelectedTags={tags}
      isSavable={isSavable}
      onEdit={editChannelAutomationTags}
      onRemove={removeChannelAutomationTags}
      onSave={saveChannelAutomationTags}
      repository={repository}
      title={title}
    />
  );
}

export default ChannelTagAutomationManager;
