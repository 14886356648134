export function buildQueryString(params: {
  [index: string]: number | string | number[] | string[] | undefined | null;
}): string {
  return Object.entries(params)
    .filter(([k, v]) => v !== undefined)
    .map(([k, v]) =>
      Array.isArray(v) && v.length > 0
        ? v.map((elem) => [k, elem].join("=")).join("&")
        : [k, v].join("=")
    )
    .join("&");
}
