import {
  BadRequestError,
  ConflictError,
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
  UnknownError,
  toErrorLogInfo,
} from "../error";
import { getUserToken } from "./jwtToken";

export const buildRequest = (url, init) => {
  const token = getUserToken();
  const actingAs = JSON.parse(sessionStorage.getItem("ACTING_AS"));

  const newHeaders = new Headers({
    ...init?.headers,
    "JWT-TOKEN": token ? token.replace(/"/g, "") : token,
    ...(actingAs // When acting as another user, authorize with their JWT token
      ? {
          override: JSON.stringify({
            email: actingAs.email,
            id: actingAs.id,
            org: actingAs.org,
            role: actingAs.role,
          }),
        }
      : {}),
  });

  return new Request(url, {
    method: "GET",
    ...init,
    headers: newHeaders,
  });
};

export async function fetchWithErrorHandling(request, options) {
  const response = await fetch(request, options);
  const { status } = response;

  if (status === 400) {
    throw new BadRequestError(
      await response.text(),
      toErrorLogInfo("", request, response)
    );
  }

  if (status === 401) {
    throw new UnauthorizedError(
      await response.text(),
      toErrorLogInfo("", request, response)
    );
  }

  if (status === 403) {
    throw new ForbiddenError(
      await response.text(),
      toErrorLogInfo("", request, response)
    );
  }

  if (status === 409) {
    throw new ConflictError(
      await response.text(),
      toErrorLogInfo("", request, response)
    );
  }

  if (status === 404) {
    throw new NotFoundError(
      await response.text(),
      toErrorLogInfo("", request, response)
    );
  }

  if (!response.ok) {
    const { headers } = response;
    const contentLength = parseInt(headers.get("Content-Length"));

    if (contentLength > 0) {
      throw new UnknownError(
        await response.text(),
        toErrorLogInfo("", request, response)
      );
    }

    throw new UnknownError(
      response.statusText,
      toErrorLogInfo("", request, response)
    );
  }

  return response;
}

export function buildUrl(uri, search) {
  const qs = new URLSearchParams(search).toString();
  const { length } = qs;

  if (length > 0) {
    return `${uri}?${qs}`;
  }

  return uri;
}
