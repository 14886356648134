import React, { useCallback, useMemo, useState } from "react";
import moment from "moment-timezone";
import { DayPickerSingleDateController } from "react-dates";
import Input from "../../../componentsV2/inputs/Input";
import { Timezone, useTimezones } from "../../../components/timezone";
import { DEFAULT_HOUR_VALUE } from "../meetingTypeDetailsHeader/MeetingTypeDetailsCalendar";
import style from "./style.module.scss";

function SelectReminderTime({
  reminderTime = null,
  setReminderTime = async () => {},
  meetingStartTime = null,
}) {
  const [focusedInput, setFocusedInput] = useState(false);
  const { getBrowserTimezoneInUTC } = useTimezones();
  const [userSelectedTimezone, setUserSelectedTimezone] = useState(
    getBrowserTimezoneInUTC
  );

  const selectedReminderTime = useMemo(() => {
    const t = moment(reminderTime);
    if (t.isValid()) {
      return t;
    }

    return moment(new Date());
  }, [reminderTime]);

  const handleNewTime = useCallback(
    (event) => {
      let [hour, minute] = event.target.value.split(":");
      // Input element of type time behaves differently in firefox and chrome.
      // In firefox, when a 0 is entered in the hour place it becomes 12 whereas in
      // chrome the whole time gets cleared out and becomes undefined.
      // Below if condition will deal with the case when either hour or minute becomes
      // undefined.
      if (typeof hour === "undefined" || typeof minute === "undefined") {
        hour = DEFAULT_HOUR_VALUE;
        minute = selectedReminderTime.minute();
      }
      const tempDateTime = moment({
        day: selectedReminderTime.date(),
        hour: parseInt(hour),
        minute: parseInt(minute),
        month: selectedReminderTime.month(),
        year: selectedReminderTime.year(),
      }).tz(userSelectedTimezone, true);

      setReminderTime(tempDateTime);
    },
    [selectedReminderTime, setReminderTime, userSelectedTimezone]
  );

  const handleTimezoneChange = ({ value: timezone }) => {
    setUserSelectedTimezone(timezone);
    setReminderTime(() => moment(reminderTime).tz(timezone, true));
  };

  const isOutsideDayRange = (day) => {
    return (
      day.isBefore(moment().startOf("day")) ||
      day.isAfter(moment(meetingStartTime).endOf("day"))
    );
  };

  return (
    <div className={style.select_reminder_time}>
      <DayPickerSingleDateController
        isOutsideRange={isOutsideDayRange}
        focused={focusedInput}
        onDateChange={(value) => {
          setReminderTime(
            moment({
              day: value.date(),
              hour: selectedReminderTime.hour(),
              minute: selectedReminderTime.minute(),
              month: value.month(),
              year: value.year(),
            })
          );
        }}
        onFocusChange={() => setFocusedInput(true)}
        numberOfMonths={1}
        daySize={31}
        hideKeyboardShortcutsPanel
        renderMonthElement={({ month }) => moment(month).format("MMM")}
      />
      <div className={style.timeInputs}>
        <Input
          className={style.dateInput}
          type="text"
          value={selectedReminderTime.format("MM/DD/YYYY")}
          readOnly
        />
        <Input
          type="time"
          onChange={handleNewTime}
          value={selectedReminderTime.format("HH:mm")}
          required
        />
        <Timezone
          onChange={handleTimezoneChange}
          value={userSelectedTimezone}
        />
      </div>
    </div>
  );
}

export default SelectReminderTime;
