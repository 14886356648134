import React, { useEffect, useMemo, useRef } from "react";
import moment from "moment";
import DOMPurify from "dompurify";
import { Chip } from "@mui/material";
import { useMeeting } from "../state";
import { GUEST_STATUS_TO_LABEL } from "../../props";
import Flexbox from "../../../components/flexbox";
import InitialsIcon from "../../../components/initials";
import { P1 } from "../../../components/typography";
import { cleanMessageAsHTML, getInitials } from "../util/helpers";
import style from "./messageTypes.module.scss";

const sanitizer = DOMPurify.sanitize;

function UserMessage({
  status,
  occurrence,
  message,
  emailStatus,
  // openCount,
}) {
  const hostMessageRef = useRef(null);
  const [meeting] = useMeeting();
  const meetingId = meeting?.id || null;
  const meetingHost = meeting?.host || null;
  const hostEmail = useMemo(() => {
    if (meetingId === null || meetingHost === null) {
      return "";
    }
    return meetingHost.email;
  }, [meetingHost, meetingId]);

  const hostInitials = useMemo(() => {
    if (meetingId === null || meetingHost === null) {
      return "";
    }
    return getInitials(meetingHost);
  }, [meetingHost, meetingId]);

  const guestStatus = useMemo(() => {
    return `${GUEST_STATUS_TO_LABEL[status]} - ${moment(occurrence).format(
      "ddd M/DD h:mma"
    )}`;
  }, [status, occurrence]);

  const displayEmailStatus = useMemo(() => {
    if (!emailStatus || emailStatus.length < 1) {
      return null;
    }

    if (emailStatus === "opened") {
      // TEMP: commenting out for now until we can more accurately count the opens
      // if (openCount && openCount > 0) {
      //   return (
      //     <div className={style.status_row}>
      //       <Chip
      //         label={`Opened ${openCount} time(s)`}
      //         color="success"
      //         variant="outlined"
      //         size="small"
      //       />
      //       <div className={style.beta}>Beta</div>
      //     </div>
      //   );
      // }

      return (
        <div className={style.status_row}>
          <Chip
            label="Opened"
            color="success"
            variant="outlined"
            size="small"
          />
          <div className={style.beta}>Beta</div>
        </div>
      );
    }

    return (
      <div className={style.status_row}>
        <Chip label="Sent" color="warning" variant="outlined" />
        <div className={style.beta}>Beta</div>
      </div>
    );
  }, [emailStatus /* , openCount */]);

  useEffect(() => {
    if (hostMessageRef.current !== null) {
      hostMessageRef.current.innerHTML = sanitizer(cleanMessageAsHTML(message));
    }
  }, [message]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Column height="fit-content" alignItems="flex-end">
          <Column
            className={style.userMessage}
            width="fit-content"
            alignItems="flex-end"
          >
            <P1 className={style.userMessage__email}>{hostEmail}</P1>
            <Column
              className={style.userMessage__body}
              ref={hostMessageRef}
              alignItems="flex-start"
            />
            {displayEmailStatus && (
              <Column alignItems="flex-start" className={style.emailStatus}>
                {displayEmailStatus}
              </Column>
            )}
          </Column>

          <Row
            className={style.userMessage__icon_row}
            justifyContent="flex-end"
          >
            <P1 className={style.userMessage__status}>{guestStatus}</P1>
            <InitialsIcon
              className={style.userMessage_icon_row__icon}
              initials={hostInitials}
            />
          </Row>
        </Column>
      )}
    </Flexbox>
  );
}

export default UserMessage;
