import React, { useCallback, useMemo } from "react";
import Flexbox from "../../../components/flexbox";
import { P1 } from "../../../components/typography";
import {
  GUEST_STATUS_TO_LABEL,
  STAGING_STATUS,
  toGuestStatusColor,
} from "../../props";
import { useHasOrgAdminPermissions } from "../../../auth";
import { useTableRowMeeting } from "../../context";
import { useGuestsRepository } from "../../../repository";
import GuestStatusSelect from "../../guestStatusSelect";
import { confirm } from "../../../components/confirmModal/ConfirmModal";
import { MediumSpacing } from "../../../components/spacing";
import useOverrideStatusRules from "../../guestStatusSelect/useOverrideStatusRules";

function GuestStatus({ guest }) {
  const meeting = useTableRowMeeting();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const { patchGuestStatus } = useGuestsRepository();
  const { overridable } = useOverrideStatusRules();

  const guestStatus = useMemo(() => {
    if (guest === null) {
      return STAGING_STATUS;
    }
    return guest.status;
  }, [guest]);

  const statusColor = useMemo(
    () => ({
      backgroundColor: `${toGuestStatusColor(guestStatus)}`,
    }),
    [guestStatus]
  );

  const textColor = useMemo(() => {
    // If guest is null, status defaults to 'staging'
    if (guest === null || guestStatus === STAGING_STATUS) {
      return "#000";
    }
    return "#FFF";
  }, [guest, guestStatus]);

  const canEditGuestStatus = useMemo(
    () =>
      hasOrgAdminPermissions &&
      overridable({
        fromStatus: guestStatus,
      }),
    [hasOrgAdminPermissions, overridable, guestStatus]
  );

  const setStatus = useCallback(
    async (status) => {
      // ask user if they want to notify the guest
      let notifyGuest = true;
      try {
        await confirm({
          cancelLabel: "No",
          okLabel: "Yes",
          title: (
            <div>
              Would you like guests to receive notifications of this status
              update?
              <div>(Defaults to "Yes")</div>
              <MediumSpacing />
              <div>
                *Note: selecting "No" is not recommended unless the intention is
                data cleanup, in which case we avoid any calendar operations
              </div>
            </div>
          ),
        });
      } catch {
        notifyGuest = false;
      }

      try {
        patchGuestStatus({
          guestRefId: guest.id,
          guestType: guest.type,
          meetingId: meeting.id,
          notifyGuest,
          status,
        });
      } catch (error) {
        throw error;
      }
    },
    [patchGuestStatus, meeting, guest]
  );

  return (
    <Flexbox>
      {({ Row }) => (
        <Row width="42%" style={statusColor}>
          {!canEditGuestStatus && (
            <P1 style={{ color: textColor }}>
              {GUEST_STATUS_TO_LABEL[guestStatus]}
            </P1>
          )}

          {canEditGuestStatus && (
            <GuestStatusSelect status={guestStatus} setStatus={setStatus} />
          )}
        </Row>
      )}
    </Flexbox>
  );
}

export default GuestStatus;
