import { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";

const useGuestStatusSelectTheme = () => {
  return useMemo(
    () => ({
      container: (provided, { selectProps: { statusColor } }) => ({
        ...provided,
        backgroundColor: statusColor,
        border: "none",
        width: "100%",
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        border: "none",
        height: "100%",
      }),
      indicatorSeparator: () => null,
      menu: (provided) => ({ ...provided, margin: 0, top: 38 }),
      menuList: (provided, { selectProps: { statusColor } }) => ({
        ...provided,
        backgroundColor: "hsl(0, 0%, 7%)",
        border: `1px solid ${statusColor}`,
        padding: 0,
      }),
      option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        backgroundColor: isFocused || isSelected ? "#F1FAFF" : WHITE,
        color: BLACK,
      }),
      placeholder: (provided) => ({
        ...provided,
        border: "none",
        color: "hsl(0, 0%, 90%)",
        position: "absolute",
      }),
      singleValue: (provided, { selectProps: { value } }) => ({
        ...provided,
        color: value.value === "staging" ? BLACK : WHITE,
      }),
      valueContainer: (provided) => ({
        ...provided,
        alignItems: "center",
        border: "none",
        display: "flex",
        fontSize: "15px",
        justifyContent: "center",
        overflow: "unset",
      }),
    }),
    []
  );
};

export default useGuestStatusSelectTheme;
