import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Breadcrumbs as Bread, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export interface Breadcrumb {
  name: string;
  route: string;
}

export function Breadcrumbs({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  const history = useHistory();

  return (
    <Box sx={{ mt: "1em", mb: "1em" }}>
      <Bread
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((b, i) =>
          i !== breadcrumbs.length - 1 ? (
            <Typography key={b.name}>
              <Link
                underline="hover"
                key="1"
                color="primary"
                onClick={() => history.push(b.route)}
              >
                {b.name}
              </Link>
            </Typography>
          ) : (
            <Typography key={b.name}>{b.name}</Typography>
          )
        )}
      </Bread>
    </Box>
  );
}
