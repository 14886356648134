import React, { useCallback, useMemo, useRef, useState } from "react";
import { mdiLoading, mdiTag } from "@mdi/js";
import { Icon } from "@mdi/react";

import { useHasOrgAdminPermissions } from "../../auth";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { ButtonContainer } from "../button";
import {
  DrawerContainer,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerSpacing,
} from "../drawer";
import { EmptyView } from "../genericView/GenericView";
import { useSize } from "../size";
import { Checkbox, TableHeader, Table } from "../tableV4";
import { Pagination } from "../pagination/Pagination";
import { Container } from "./TagManagerContainer";
import { DeleteAndSearchMenuRow } from "./TagManagerDeleteAndSearchMenuRow";
import { TagManagerDrawer } from "./TagManagerDrawer";
import { useTagFilter } from "./TagManagerFilter";
import { Overlay } from "./TagManagerOverlay";
import { CreateMenuRow } from "./TagManagerCreateMenuRow";
import { TagManagerMenu } from "./TagManagerMenu";
import { TagManagerRow } from "./TagManagerRow";
import { MenuSpacing } from "./TagManagerMenuSpacing";
import { useCreateNewTag } from "./useCreateNewTag";
import { useDeleteSelectedTags } from "./useDeletedSelectedTags";
import { useEditTag } from "./useEditTag";
import { useOffset } from "./useOffset";
import { useRemoveTags } from "./useRemoveTags";
import { useSelectedCountMessage } from "./useSelectedCountMessage";
import { useSelectedTags } from "./useSelectedTags";
import { useSlide } from "./useSlide";
import { useTableHeaderStyle } from "./useTableHeaderStyle";
import { useTags } from "./useTags";
import { useUnshift } from "./useUnshift";
import style from "./style.module.scss";

const TAG_PAGE_SIZE = 100;

export function TagManager({
  close,
  initialSelectedTags,
  isSavable = null,
  onModify,
  onEdit,
  onRemove,
  onSave,
  repository,
  title,
}) {
  const { addInternalError } = useGeneralNotifications();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const [createdTags, setCreatedTags] = useState([]);
  const { className: slideClassName, slideOut } = useSlide(close);
  const {
    createTag,
    deleteTags,
    useSearchQuery: useSearchTagsQuery,
  } = repository;
  const [offset, setOffset] = useOffset();
  const { filter, searchBar } = useTagFilter(setOffset);
  const unshift = useUnshift(initialSelectedTags, createdTags);
  const {
    edit: editTagInCache,
    isInitialized: areTagsInitialized,
    isLoading,
    isManuallyRefreshing,
    refresh,
    remove: removeTagsInCache,
    tags: searchedTags,
    total: totalSearchedTags,
  } = useSearchTagsQuery({ filter, offset, unshift }, setOffset);
  const areTagsLoading = useMemo(() => {
    return isLoading || isManuallyRefreshing;
  }, [isLoading, isManuallyRefreshing]);
  const {
    editSelectedTag,
    isEveryTagSelected,
    isTagSelected,
    removeSelectedTagIds,
    selectedTagCount,
    selectedTagIds,
    selectedTags,
    toggleTag,
    toggleTags,
  } = useSelectedTags(initialSelectedTags, searchedTags);
  const selectedCountMessage = useSelectedCountMessage(selectedTagCount);
  // TODO: Consolidate the editing better? Maybe all in the repository
  const editTag = useEditTag(editTagInCache, editSelectedTag, onEdit, onModify);
  const {
    createNewMeetingTagForm,
    isNewMeetingTagFormShowing,
    removeNewlyCreatedTags,
    showNewMeetingForm,
  } = useCreateNewTag(createTag, toggleTag, setCreatedTags, onModify);
  const menuRef = useRef(null);
  const { isDeletingTags, removeTags } = useRemoveTags(
    deleteTags,
    removeTagsInCache,
    removeSelectedTagIds,
    removeNewlyCreatedTags,
    refresh,
    onRemove,
    onModify
  );
  const deleteSelectedTags = useDeleteSelectedTags(
    deleteTags,
    removeTags,
    selectedTagIds
  );
  const { height: menuHeight } = useSize(menuRef);
  const tableHeaderStyle = useTableHeaderStyle(menuHeight);
  const tags = useTags(searchedTags, createdTags, filter, offset);
  const saveAndSlideOut = useCallback(async () => {
    try {
      await onSave(selectedTags);
    } catch (error) {
      addInternalError(error.message);
    }
    slideOut();
  }, [addInternalError, selectedTags, onSave, slideOut]);

  const isSaveDisabled = useMemo(() => {
    if (isSavable === null) {
      return false;
    }
    return !isSavable(selectedTags);
  }, [isSavable, selectedTags]);
  return (
    <Container>
      <Overlay slideOut={slideOut} />
      <TagManagerDrawer slideClassName={slideClassName}>
        <DrawerContainer>
          <DrawerHeader>{title}</DrawerHeader>
          <DrawerContent>
            <TagManagerMenu menuRef={menuRef}>
              <DrawerSpacing />
              <CreateMenuRow onShowNewMeetingForm={showNewMeetingForm} />
              <MenuSpacing pixels={13} />
              <DeleteAndSearchMenuRow
                deleteSelectedTags={deleteSelectedTags}
                searchBar={searchBar}
                selectedCountMessage={selectedCountMessage}
                selectedTagCount={selectedTagCount}
              />
              <MenuSpacing pixels={8} />
            </TagManagerMenu>
            <Table>
              <thead>
                <tr>
                  <TableHeader
                    className={style.tagManager__headerCell}
                    style={tableHeaderStyle}
                  >
                    <div className={style.tagManager__cell}>
                      <Checkbox
                        checked={isEveryTagSelected}
                        name="/tag_manager/select_all"
                        onChange={toggleTags}
                      />
                    </div>
                  </TableHeader>
                  <TableHeader
                    className={style.tagManager__headerCell}
                    style={tableHeaderStyle}
                  >
                    <div className={style.tagManager__cell}>Tag</div>
                  </TableHeader>
                  {(hasOrgAdminPermissions || isNewMeetingTagFormShowing) && (
                    <TableHeader
                      className={style.tagManager__headerCell}
                      style={tableHeaderStyle}
                    >
                      <div className={style.tagManager__cell}>Action</div>
                    </TableHeader>
                  )}
                </tr>
              </thead>
              <tbody>
                {isNewMeetingTagFormShowing && createNewMeetingTagForm}
                {tags !== null &&
                  tags.map((tag) => (
                    <TagManagerRow
                      key={tag.id}
                      tag={tag}
                      isChecked={isTagSelected(tag.id)}
                      toggleTag={toggleTag}
                      editTag={editTag}
                      removeTagsByIds={removeTags}
                    />
                  ))}
              </tbody>
            </Table>
            {areTagsInitialized &&
              !areTagsLoading &&
              tags?.length === 0 &&
              !isDeletingTags && <EmptyView icon={mdiTag} view="tags" />}
            {(!areTagsInitialized ||
              (isDeletingTags && tags?.length === 0) ||
              (areTagsLoading && tags?.length === 0)) && (
              <div className={style.tagManager__loading}>
                <Icon path={mdiLoading} spin={1} size={8} />
              </div>
            )}
          </DrawerContent>
          <DrawerFooter>
            <ButtonContainer
              name="/tag_manager/close"
              secondary
              large
              onClick={slideOut}
            >
              Close
            </ButtonContainer>
            <div style={{ marginLeft: "1em" }} />
            <ButtonContainer
              name="/tag_manager/save"
              disabled={isSaveDisabled}
              primary
              large
              onClick={saveAndSlideOut}
            >
              Save
            </ButtonContainer>
            <div className={style.tagManager__pagination}>
              <Pagination
                offset={offset}
                setOffset={setOffset}
                pageDelta={2}
                pageSize={TAG_PAGE_SIZE}
                total={totalSearchedTags}
              />
            </div>
          </DrawerFooter>
        </DrawerContainer>
      </TagManagerDrawer>
    </Container>
  );
}
