import React, { useState } from "react";
import {
  Autocomplete,
  Checkbox,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TokenStatus, TokenStatusOptions, tokenStatusList } from "src/types";

export function CalendarTokenStatusFilter({
  value,
  onChange,
}: {
  value: TokenStatus[];
  onChange: (value: TokenStatus[]) => void;
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(_, value) => {
        onChange(value.map((v) => v.value));
      }}
      value={TokenStatusOptions.filter((option) =>
        value.includes(option.value)
      )}
      options={TokenStatusOptions}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{option.label}</Typography>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Calendar Token Status"
        />
      )}
    />
  );
}

export default CalendarTokenStatusFilter;
