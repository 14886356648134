import { buildRequest, fetchWithErrorHandling } from "../utils/fetchV2";
import { API } from "../props";

async function saveMeetingTypeTags(meetingTypeIds, tagIds) {
  try {
    return await fetchWithErrorHandling(
      buildRequest(API.tags.tagMeetingTypes, {
        body: JSON.stringify(
          {
            meetingTypeIds,
            tagIds,
          },
          null,
          4
        ),
        method: "PUT",
      })
    );
  } catch (error) {
    throw error;
  }
}

export function useMeetingTypeTagsRepository() {
  return {
    saveMeetingTypeTags,
  };
}
