import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useActingAs, useIsDefaultUser } from "src/auth";
import { ROLE_LEVELS } from "src/auth/roles";
import { useDebounce } from "src/hooks";
import { isLinkFirst } from "src/meetingTypes/invite/props";
import { useTeams } from "src/queries";
import { RoutingLogic } from "src/queries/useRoutingLogic";
import { MeetingDefinition } from "src/types";
import { getUserDetails } from "src/utils/jwtToken";
import { TemplateEditSelectableCard } from "./TemplateEditSelectableCard";

type TemplateEditDistributionProps = {
  hideMeetingHosts: boolean;
  currentTeam?: { id: number; name: string };
  routingLogic?: RoutingLogic[];
  createdByDefaultUser?: boolean;
  onTeamChange: () => void;
  onUpdateRoutingLogicUser: (routingLogic: RoutingLogic) => Promise<void>;
};

export const TemplateEditDistribution = (
  props: TemplateEditDistributionProps
) => {
  const { control, register, getValues } = useFormContext<MeetingDefinition>();

  const [routingJustMeSelected, setRoutingJustMeSelected] = useState(
    getValues("routingJustMe")
  );

  const isDefaultUser = useIsDefaultUser();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Meeting Distribution
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Set hosts and calendar distribution for this template.`
            : `Set hosts for this template.`}
        </Typography>
      </Box>

      <Stack
        sx={{
          gap: 2,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Typography variant="h5" fontWeight="bold">
            Meeting Hosts
          </Typography>

          {(isDefaultUser || props.createdByDefaultUser) && (
            <Stack sx={{ mb: 7 }}>
              <DefaultUserRouting />
            </Stack>
          )}

          {!(isDefaultUser || props.createdByDefaultUser) && (
            <TeamRouting
              onTeamChange={props.onTeamChange}
              routingJustMeSelected={routingJustMeSelected}
              onRoutingJustMeSelected={setRoutingJustMeSelected}
            />
          )}

          <Box sx={{ mb: 7 }}>
            <Typography variant="h5" fontWeight="bold">
              BCC
            </Typography>
            <Typography sx={{ mb: 3 }}>
              Enter email address of the recipient you wish to be BCC'd on
              meeting invites.
            </Typography>
            <TextField
              {...register("properties.bcc")}
              size="small"
              label="BCC"
              sx={{ width: "50%", mb: 2 }}
            />
          </Box>

          {!isLinkFirst(getValues("inviteStyle")) && (
            <Box sx={{ mb: 7 }}>
              <Typography variant="h5" fontWeight="bold">
                Calendar Distribution
              </Typography>
              <Typography sx={{ mb: 3 }}>
                Select how meetings should be scheduled on Hosts calendar.
              </Typography>
              <Controller
                control={control}
                name="distribution"
                render={({ field }) => (
                  <Stack sx={{ flexDirection: "row", gap: 5 }}>
                    <Box sx={{ flex: "1 1 0", maxWidth: "260px" }}>
                      <TemplateEditSelectableCard
                        selected={field.value === "sequential"}
                        onClick={() => field.onChange("sequential")}
                      >
                        <Stack
                          sx={{
                            mb: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            color: "primary.dark",
                            gap: 2,
                          }}
                        >
                          <Typography variant="h5" fontWeight="bold">
                            Front Load
                          </Typography>
                        </Stack>
                        <Typography>
                          The meeting time proposed will be the first
                          availability of the guest within day range.
                        </Typography>
                      </TemplateEditSelectableCard>
                    </Box>

                    <Box sx={{ flex: "1 1 0", maxWidth: "260px" }}>
                      <TemplateEditSelectableCard
                        selected={field.value === "random_high_volume"}
                        onClick={() => field.onChange("random_high_volume")}
                      >
                        <Stack
                          sx={{
                            mb: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            color: "primary.dark",
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="primary.dark"
                          >
                            High Density
                          </Typography>
                        </Stack>
                        <Typography>
                          The meetings are distributed randomly and are stacked.
                          This type of calendar distribution can stack up to 14
                          meetings at the same time slot.
                        </Typography>
                        <br />
                        <br />
                        <Typography>
                          In case of a double booking a user may have to move
                          the meetings.
                        </Typography>
                      </TemplateEditSelectableCard>
                    </Box>
                  </Stack>
                )}
              />
            </Box>
          )}
        </Stack>

        {!(isDefaultUser || props.createdByDefaultUser) &&
          !props.hideMeetingHosts && (
            <Stack
              sx={{
                flexShrink: 0,
                backgroundColor: "#F8FBFF",
                padding: 2.5,
                maxHeight: "800px",
                overflow: "auto",
              }}
            >
              <Box>
                <Typography>Meeting Hosts:</Typography>
                <Typography
                  sx={{ fontWeight: "bold", fontSize: 22, marginBottom: 4 }}
                >
                  {props.currentTeam?.name}
                </Typography>
              </Box>
              <Stack sx={{ gap: 4 }}>
                {props.routingLogic?.map((routingLogicItem) => (
                  <Stack
                    key={routingLogicItem.email}
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Switch
                        disabled={routingJustMeSelected}
                        checked={routingLogicItem.enabled}
                        onChange={(event) => {
                          props.onUpdateRoutingLogicUser({
                            ...routingLogicItem,
                            enabled: event.target.checked,
                          });
                        }}
                      />
                      <Typography
                        title={routingLogicItem.email}
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whitespace: "nowrap",
                          width: "260px",
                        }}
                      >
                        {routingLogicItem.email}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
      </Stack>
    </Box>
  );
};

type TeamRoutingProps = {
  onTeamChange: () => void;
  routingJustMeSelected: boolean;
  onRoutingJustMeSelected: (selected: boolean) => void;
};
function TeamRouting({
  onTeamChange,
  routingJustMeSelected,
  onRoutingJustMeSelected,
}: TeamRoutingProps) {
  const { control, getValues, setValue, watch } =
    useFormContext<MeetingDefinition>();

  const [teamSearchQuery, setTeamSearchQuery] = useState("");
  const debouncedTeamSearch = useDebounce(
    encodeURIComponent(teamSearchQuery),
    1000
  );
  const { data: teams } = useTeams(100, 0, { qry: debouncedTeamSearch });

  useEffect(() => {
    setTeamSearchQuery(getValues("team.name"));
  }, [getValues]);
  return (
    <Stack>
      <Typography variant="h6" fontWeight="bold">
        Just Me
      </Typography>
      <Controller
        control={control}
        name="routingJustMe"
        render={({ field }) => (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <Typography
              title={"Just Me"}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whitespace: "nowrap",
                width: "320px",
              }}
            >
              Route to the template owner only
            </Typography>
            <Switch
              sx={{
                mt: -1,
                mb: 1,
              }}
              checked={getValues("routingJustMe")}
              onChange={(event) => {
                setValue("routingJustMe", event.target.checked);
                onRoutingJustMeSelected(event.target.checked);
              }}
            />
          </Stack>
        )}
      />

      <Typography variant="h6" fontWeight="bold">
        Teams
      </Typography>

      <Typography sx={{ mb: 0 }}>
        {!isLinkFirst(getValues("inviteStyle"))
          ? `Select which team will handle this meeting and how it will be routed among the hosts on the team.`
          : `Select which team will handle this meeting.`}
      </Typography>

      <Stack
        sx={{
          flexDirection: { xs: "column", lg: "row" },
          gap: 4,
          mb: 7,
          mt: 1,
        }}
      >
        <FormControl>
          <Controller
            control={control}
            name="team"
            rules={{
              required: "Please select a Team",
              validate: (newValue) =>
                (Number.isInteger(newValue?.id) &&
                  !!teams?.data?.find((team) => team.id === newValue.id)) ||
                "Please select a Team",
            }}
            render={({ field }) => (
              <Autocomplete
                disabled={routingJustMeSelected}
                value={field.value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, newValue) => {
                  if (!newValue) {
                    return;
                  }
                  field.onChange({
                    id: newValue?.id,
                    name: newValue?.name,
                  });
                  onTeamChange();
                }}
                inputValue={teamSearchQuery}
                onInputChange={(_, newInputValue) => {
                  setTeamSearchQuery(newInputValue);
                }}
                id="team-select"
                options={teams?.data ?? []}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => (
                  <TextField {...params} label="Select A Team" />
                )}
              />
            )}
          />
        </FormControl>
        {!isLinkFirst(getValues("inviteStyle")) && (
          <Stack sx={{ flexDirection: { lg: "row", xs: "column" }, gap: 4 }}>
            <FormControl>
              <InputLabel id="select-routing-label">Select Routing</InputLabel>
              <Controller
                control={control}
                name="routing"
                render={({ field }) => (
                  <Select
                    disabled={routingJustMeSelected}
                    {...field}
                    sx={{ width: 300 }}
                    labelId="select-routing-label"
                    id="select-routing"
                    label="Select Routing"
                    onChange={(event) => {
                      field.onChange(event);
                      if (event.target.value === "email") {
                        setValue(
                          "routing_logic.metadata.subtypeDetails.field",
                          "routing_field"
                        );
                      }
                    }}
                  >
                    <MenuItem value="sequential">Sequential</MenuItem>
                    <MenuItem value="email">Email Based</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {watch("routing") === "email" && (
              <FormControl>
                <InputLabel id="select-logic-field-label">
                  Host Email Field
                </InputLabel>
                <Controller
                  control={control}
                  name="routing_logic.metadata.subtypeDetails.field"
                  render={({ field }) => (
                    <Select
                      {...field}
                      sx={{ width: 300 }}
                      labelId="select-logic-field-label"
                      id="select-logic-field"
                      label="Host Email Field"
                    >
                      <MenuItem value="routing_field">Routing Field</MenuItem>
                      <MenuItem value="logic_field">Logic Field</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

function DefaultUserRouting() {
  const { getValues } = useFormContext<MeetingDefinition>();
  const loggedInUser = getUserDetails();
  const creationData = getValues("creationData");
  const [actingAs] = useActingAs();

  if (!loggedInUser) {
    return <Typography>No Host Info Available</Typography>;
  }

  if (!creationData) {
    return (
      <Typography>
        Host: {loggedInUser.firstName} {loggedInUser.lastName} --{" "}
        {loggedInUser.email} (or other connected accounts)
      </Typography>
    );
  }

  if (creationData.creatorData.userRole === ROLE_LEVELS.DEFAULT) {
    return (
      <Typography>
        Host: {creationData.creatorData.userFirstName}{" "}
        {creationData.creatorData.userLastName} --{" "}
        {creationData.creatorData.userEmail}
      </Typography>
    );
  }

  if (actingAs !== null && actingAs.role === ROLE_LEVELS.DEFAULT) {
    return (
      <Typography>
        Host: {actingAs.firstName} {actingAs.lastName} -- {actingAs.email}
      </Typography>
    );
  }

  return (
    <Typography>
      Host: {loggedInUser.firstName} {loggedInUser.lastName} --{" "}
      {loggedInUser.email} (or other connected accounts)
    </Typography>
  );
}
