import { Button, Divider, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Tag, User } from "src/types";
import { SkinnyMeetingDefinition, Team } from "../queries";
import CreatorFilter from "./filters/CreatorFilter";
import { DateRangeFilterForReporting } from "./filters/DateRangeFilterForReporting";
import TagFilter from "./filters/TagFilter";
import TeamFilter from "./filters/TeamFilter";
import TemplateFilter from "./filters/TemplateFilter";

type ReportingFilterBarProps = {
  filters: ReportingQuery;
  templateFilter: number[];
  onFilterUpdate: (filters: ReportingQuery) => void;
  onTemplateFilterUpdate: (templates: SkinnyMeetingDefinition[]) => void;
  onReset: () => void;
};

export const ReportingFilterBar = (props: ReportingFilterBarProps) => {
  const [query, setQuery] = useState<ReportingQuery>(props.filters);

  useEffect(() => {
    setQuery(props.filters);
  }, [props.filters]);

  const [updateTimeout, setUpdateTimeout] = useState<any>();

  // We have to maintain an extra state because meetingIdFilter accepts a number array.
  const [meetingIdString, setMeetingIdString] = useState("");

  const updateFilters = (newFilters: Partial<ReportingQuery>) => {
    clearTimeout(updateTimeout);

    setQuery((oldFilters) => {
      const filtersToSet = { ...oldFilters, ...newFilters };
      const newTimeout = setTimeout(
        () => props.onFilterUpdate(filtersToSet),
        750
      );

      setUpdateTimeout(newTimeout);

      return filtersToSet;
    });
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TemplateFilter
        search={query.templateFilterSearch}
        onSearchChange={(value) =>
          updateFilters({
            templateFilterSearch: value,
          })
        }
        value={props.templateFilter}
        onChange={(templates) => props.onTemplateFilterUpdate(templates)}
      />
      <CreatorFilter
        search={query.hostFilterSearch}
        onSearchChange={(value) => {
          updateFilters({
            hostFilterSearch: value,
          });
        }}
        value={query.host}
        onChange={(value) => {
          updateFilters({ host: value });
        }}
      />
      <TeamFilter
        search={query.teamFilterSearch}
        onSearchChange={(value) => {
          updateFilters({ teamFilterSearch: value });
        }}
        value={query.team}
        onChange={(value) => {
          updateFilters({ team: value });
        }}
      />
      <TagFilter
        label={"Template Tags"}
        search={query.templateTagFilterSearch}
        onSearchChange={(value) =>
          updateFilters({
            templateTagFilterSearch: value,
          })
        }
        value={query.templateTags}
        onChange={(templateTags) => updateFilters({ templateTags })}
      />
      <TagFilter
        label={"Meeting Tags"}
        search={query.meetingTagFilterSearch}
        onSearchChange={(value) =>
          updateFilters({
            meetingTagFilterSearch: value,
          })
        }
        value={query.meetingTags}
        onChange={(meetingTags) => updateFilters({ meetingTags })}
      />
      <DateRangeFilterForReporting
        value={[query.date.range[0], query.date.range[1]]}
        onChange={(range) =>
          updateFilters({
            date: { ...query.date, range },
          })
        }
      />
      <Divider orientation="vertical" flexItem />
      <Button
        onClick={() => {
          props.onReset();
          setMeetingIdString("");
        }}
      >
        Reset
      </Button>
    </Stack>
  );
};

export interface ReportingQuery {
  template: SkinnyMeetingDefinition[];
  host: User[];
  hostFilterSearch: string;
  templateIds?: number[];
  meetingTags: Tag[];
  templateTags: Tag[];
  team: Team[];
  teamFilterSearch: string;
  date: {
    field: "startTime" | "lastActivity";
    range: [Dayjs | null, Dayjs | null];
  };
  templateTagFilterSearch: string;
  meetingTagFilterSearch: string;
  templateFilterSearch: string;
}
