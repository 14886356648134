import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { mdiLoading, mdiSend } from "@mdi/js";
import Icon from "@mdi/react";
import Flexbox from "../../../components/flexbox";
import AddDM from "../addDM";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import useMeetingHistoryRepository from "../../../repository/meetingHistory";
import { SCHEDULING_STAGE } from "../../props";
import { ADD_MESSAGE, useMeeting, useMessengerState } from "../state";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import { cleanMessageAsHTML } from "../util/helpers";
import style from "./messengerInput.module.scss";

const MessengerInput = forwardRef((props, ref) => {
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { postMeetingHistoryMessage } = useMeetingHistoryRepository();
  const [meeting] = useMeeting();
  const { messengerDispatch } = useMessengerState();
  const { addError } = useGeneralNotifications();

  const textAreaDisabled = useMemo(
    () => !meeting.enabled || meeting.status !== SCHEDULING_STAGE,
    [meeting]
  );

  const handleMessageOnSubmit = useCallback(async () => {
    try {
      setIsSendingMessage(true);

      const cleanedMessage = cleanMessageAsHTML(message);

      await postMeetingHistoryMessage({
        id: meeting.id,
        message: cleanedMessage,
      });

      messengerDispatch({
        payload: {
          message: {
            note: cleanedMessage,
            note_type: "touch_sent",
            occurrence: new Date(),
            status: meeting.status,
          },
        },
        type: ADD_MESSAGE,
      });

      setMessage("");
    } catch (error) {
      addError("There was an issue sending the message");
    } finally {
      setIsSendingMessage(false);
    }
  }, [
    postMeetingHistoryMessage,
    meeting,
    message,
    addError,
    messengerDispatch,
  ]);

  const handleMessageOnChange = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column ref={ref} className={style.messengerInput} height="fit-content">
          <AddDM />

          {!isSendingMessage && (
            <ButtonContainer
              className={style.messengerInput__send}
              disabled={textAreaDisabled}
              name="/instanceDetails/send_button"
              onClick={handleMessageOnSubmit}
            >
              <ButtonIcon
                className={style.messengerInput__send_icon}
                path={mdiSend}
              />
            </ButtonContainer>
          )}

          {isSendingMessage && (
            <Icon
              className={style.messengerInput__send}
              path={mdiLoading}
              size={1}
              spin={1}
            />
          )}

          <textarea
            className={style.messengerInput__text_area}
            name="/instanceDetails/message_box"
            placeholder="Type your message..."
            onChange={handleMessageOnChange}
            disabled={textAreaDisabled}
            value={message}
            rows={5}
          />
        </Column>
      )}
    </Flexbox>
  );
});

export default MessengerInput;
