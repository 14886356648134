import { INTEGRATION_AUTH_STATUS } from "./props";
import { Account } from "../pages/settings/accounts";
import { UserIntegration } from "src/types/UserIntegration";

export const determineAuthStatus = (account: Account | null): string => {
  if (!account?.authEnabled) {
    return INTEGRATION_AUTH_STATUS.DISABLED;
  }

  return account.authValid
    ? INTEGRATION_AUTH_STATUS.CURRENT
    : INTEGRATION_AUTH_STATUS.EXPIRED;
};

export const determineIntegrationStatus = (
  integration: UserIntegration | null
): string => {
  if (integration === null || integration.tokenInfo.tokenType.length < 1) {
    return INTEGRATION_AUTH_STATUS.DISABLED;
  }

  return integration.authValid
    ? INTEGRATION_AUTH_STATUS.CURRENT
    : INTEGRATION_AUTH_STATUS.EXPIRED;
};
