import React, { useEffect, useMemo, useRef } from "react";
import moment from "moment";
import DOMPurify from "dompurify";

import Flexbox from "../../../components/flexbox";
import InitialsIcon from "../../../components/initials";
import { P1 } from "../../../components/typography";
import { useMeeting } from "../state";
import { GUEST_STATUS_TO_LABEL } from "../../props";
import { cleanMessageAsHTML, getInitials } from "../util/helpers";

import style from "./messageTypes.module.scss";

const sanitizer = DOMPurify.sanitize;

const UNKNOWN_GUEST_EMAIL = "Unknown Guest";

const GuestMessage = ({ status, occurrence, message }) => {
  const guestMessageRef = useRef(null);
  const [meeting] = useMeeting();
  const { guests = null } = meeting || {};
  const guest = useMemo(() => {
    if (Array.isArray(guests) && guests.length > 0) {
      return guests[0];
    }
    return {
      email: UNKNOWN_GUEST_EMAIL,
    };
  }, [guests]);
  const guestEmail = guest.email;
  const guestInitials = getInitials(guest);
  const guestStatus = `${GUEST_STATUS_TO_LABEL[status]} - ${moment(
    occurrence
  ).format("ddd M/DD h:mma")}`;

  useEffect(() => {
    if (guestMessageRef.current !== null) {
      guestMessageRef.current.innerHTML = sanitizer(
        cleanMessageAsHTML(message)
      );
    }
  }, [message]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Column height="fit-content" alignItems="flex-start">
          <Column
            className={style.guestMessage}
            width="fit-content"
            alignItems="flex-start"
          >
            <P1 className={style.guestMessage__email}>{guestEmail}</P1>

            <Column
              className={style.guestMessage__body}
              ref={guestMessageRef}
              alignItems="flex-start"
            />
          </Column>

          <Row
            className={style.guestMessage__icon_row}
            justifyContent="flex-start"
          >
            <InitialsIcon initials={guestInitials} />
            <P1 className={style.guestMessage__status}>{guestStatus}</P1>
          </Row>
        </Column>
      )}
    </Flexbox>
  );
};

export default GuestMessage;
