import React, { useMemo } from "react";
import { WHITE, BLACK } from "../../components/colors";

function useHostTheme(hasHost, textColor = null) {
  return useMemo(
    () => ({
      container: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? WHITE : "inherit",
        borderRadius: "3px",
        color: isFocused ? WHITE : BLACK,
        height: "100%",
        justifyContent: "flex-start",
        width: !hasHost && isFocused ? "calc(41% - 60px)" : "100%",
      }),
      control: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        border: 0,
        boxShadow: 0,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row-reverse",
        fontSize: "10px",
        height: "100%",
        justifyContent: "flex-start",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: "0.5px solid #A8B1BD",
        borderRadius: 0,
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? "#F1FAFF" : WHITE,
        border: "0.5px solid #A8B1BD",
        color: BLACK,
        cursor: "pointer",
        paddingBottom: "7px",
        paddingTop: "7px",
      }),
      singleValue: (base) => {
        if (textColor !== null) {
          return {
            ...base,
            color: textColor,
          };
        }
        return BLACK;
      },
      valueContainer: (base) => {
        if (textColor !== null) {
          return {
            ...base,
            color: textColor,
            fontSize: "13px",
            fontWeight: "500",
            height: "100%",
            padding: "0px",
          };
        }
        return {
          ...base,
          color: BLACK,
          fontSize: "13px",
          fontWeight: "500",
          height: "100%",
          padding: "0px",
        };
      },
    }),
    [hasHost, textColor]
  );
}

export default useHostTheme;
