import { useCallback, useState, useRef, useEffect, useMemo } from "react";
import { mdiAlertCircle } from "@mdi/js";

import { API } from "../props";
import { buildRequest, buildUrl } from "../utils/fetchV2";
import { useCustomFetchFn } from "../components/fetch";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import { buildUpdateUserSettingsRequest } from "./appSettings";
import { BadRequestError } from "../error";
import { useActingAs, useIsDefaultUser } from "../auth";
import { ROLE_LEVELS } from "../auth/roles";
import { downloadBlob } from "../utils/helpers";
import { HTTP_METHODS } from "../components/fetch/props";
import { getUserDetails } from "src/utils/jwtToken";

const buildGetTeamsRequest = ({ qryFields }) =>
  buildRequest(buildUrl(API.teams.default(), qryFields));

const buildUpdateUserRequest = ({ id, body }) =>
  buildRequest(API.users.default(id), {
    body,
    method: "PATCH",
  });

const buildAddTeamRequest = ({ body }) =>
  buildRequest(API.teams.default(), {
    body,
    method: "POST",
  });

const buildUpdateTeamRequest = ({ body, id }) =>
  buildRequest(API.teams.default(id), {
    body,
    method: "PATCH",
  });

const buildDeleteTeamRequest = ({ qryFields }) =>
  buildRequest(buildUrl(API.teams.default(), qryFields), {
    method: "DELETE",
  });

const buildGetUsersRequest = ({ qryFields }) =>
  buildRequest(buildUrl(API.users.default(), qryFields));

const buildGetUsersForMeetingTransferRequest = ({ qryFields }) =>
  buildRequest(buildUrl(API.users.usersForMeetingTransfer, qryFields));

const buildDeleteUsersRequest = ({ urlParams }) =>
  buildRequest(buildUrl(API.users.default(), urlParams), {
    method: "DELETE",
  });

const buildDeleteOpenUsersRequest = ({ urlParams }) =>
  buildRequest(buildUrl(API.users.open, urlParams), {
    method: "DELETE",
  });

const buildAddUsersToTeamsRequest = ({ body }) =>
  buildRequest(API.users.teams, {
    body,
    method: "POST",
  });

const buildCreateTagRequest = ({ id, body }) =>
  buildRequest(API.tags.default(id), {
    body,
    method: "PUT",
  });

const buildDeleteTagRequest = ({ urlParams }) =>
  buildRequest(buildUrl(API.tags.default(), urlParams), {
    method: "DELETE",
  });

const buildSaveTagsToUser = ({ body }) =>
  buildRequest(API.tags.tagUsers, {
    body,
    method: "PUT",
  });

const buildGetDeletingUserMeetingDefinitionInfoRequest = ({ userIds }) =>
  buildRequest(
    buildUrl(API.users.meetingDefinitionInfo, {
      ids: userIds.join(","),
    })
  );

const buildExportAllUsersRequest = () => buildRequest(API.users.exportAll);

const buildGetUserAccountsRequest = () => buildRequest(API.users.accounts);

const buildGetAddUserAccountURLRequest = ({ client, email }) =>
  buildRequest(
    buildUrl(API.users.accountsURL, {
      client,
      email,
    })
  );

const buildDisconnectUserAccountRequest = ({ body }) =>
  buildRequest(API.users.accounts, {
    body: JSON.stringify(body),
    method: HTTP_METHODS.DELETE,
  });

function useAllTeamMembers(teamId = null, creationData = {}) {
  // use a temp ID here to keep team members in sync with `teamId` so that when `teamId` changes,
  // we do not return an old set of users
  const [tempId, setTempId] = useState(teamId);
  const { fetch: doFetch } = useCustomFetchFn();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const isDefaultUser = useIsDefaultUser();
  const userInfo = getUserDetails();
  const [actingAs] = useActingAs();

  const getAllUsers = useCallback(
    async (accumulation = [], offset = 0, currentTotal = 0) => {
      try {
        const request = buildRequest(
          buildUrl(API.users.default(), [
            ["limit", 100],
            ["offset", offset],
            ["team", teamId],
          ])
        );
        const response = await doFetch(request);
        const payload = await response.json();
        const { total = 0, data: newData } = payload;
        const newAccumulation = [...accumulation, ...newData];
        if (total > currentTotal + newData.length) {
          return await getAllUsers(
            newAccumulation,
            offset + 1,
            currentTotal + newData.length
          );
        }
        setData(newAccumulation);
        setError(null);
        return newAccumulation;
      } catch (error) {
        setData(null);
        setError(error);
        return null;
      }
    },
    [doFetch, teamId]
  );
  useEffect(() => {
    setTempId(teamId);
  }, [teamId]);
  useEffect(() => {
    // default user -- team of one
    if (isDefaultUser) {
      // default user -- team of one
      setData([userInfo]);
      setLoading(false);
      setError(null);
      return;
    }

    if (
      actingAs?.role === ROLE_LEVELS.DEFAULT ||
      creationData?.userRole === ROLE_LEVELS.DEFAULT
    ) {
      setData([
        {
          email: creationData.userEmail,
          firstName: creationData.userFirstName,
          lastName: creationData.userLastName,
        },
      ]);
      setLoading(false);
      setError(null);
      return;
    }

    async function run() {
      if (teamId !== null && teamId > 0) {
        setLoading(true);
        await getAllUsers([], 0, 0);
        setLoading(false);
      } else {
        setData(null);
        setError(new BadRequestError("No Team ID specified."));
        setLoading(false);
      }
    }
    // all other roles get full team members
    run();
  }, [
    actingAs,
    creationData.userRole,
    creationData.userEmail,
    creationData.userFirstName,
    creationData.userLastName,
    getAllUsers,
    isDefaultUser,
    teamId,
    userInfo,
  ]);
  return useMemo(() => {
    if (tempId !== teamId) {
      return {
        data: null,
        error: null,
        loading: true,
      };
    }
    return {
      data,
      error,
      loading,
    };
  }, [data, error, loading, teamId, tempId]);
}

function useUpdateUserSettings() {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    async (body, userId) =>
      fetch(
        buildUpdateUserSettingsRequest({
          body: JSON.stringify(body),
          id: userId,
        })
      ),
    [fetch]
  );
}

function useGetUsers() {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    async ({ limit, offset, sortBy, qry, org, statusFilter }) => {
      const search = {};
      if (limit) {
        Object.assign(search, { limit });
      }
      if (offset) {
        Object.assign(search, { offset });
      }
      if (sortBy) {
        Object.assign(search, { sortBy });
      }
      if (qry) {
        Object.assign(search, { qry });
      }
      if (org) {
        Object.assign(search, { org });
      }
      if (statusFilter) {
        Object.assign(search, { statusFilter });
      }
      return fetch(buildRequest(buildUrl(API.users.all, search)));
    },
    [fetch]
  );
}

function useDeleteUser() {
  const { fetch } = useCustomFetchFn();
  return useCallback(
    (userId, { isVerifiedUser = false } = {}) => {
      if (isVerifiedUser) {
        return fetch(
          buildDeleteOpenUsersRequest({
            urlParams: { ids: [userId] },
          })
        );
      }
      return fetch(
        buildDeleteUsersRequest({
          urlParams: { ids: [userId] },
        })
      );
    },
    [fetch]
  );
}

function useUsers({
  limit = 100,
  offset = 0,
  sortBy,
  qry = "",
  org,
  statusFilter,
}) {
  const { addGeneralNotification } = useGeneralNotifications();
  const getUsers = useGetUsers();
  const deleteUser = useDeleteUser();
  const updateUser = useUpdateUserSettings();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleDeleteUser = useCallback(
    async (userId, { isVerifiedUser = false } = {}) => {
      try {
        setLoading(true);
        await deleteUser(userId, { isVerifiedUser });
        setData(({ data: prevData, total }) => {
          return {
            data: prevData.filter((u) => u.id !== userId),
            total: total - ``,
          };
        });
      } catch (error) {
        addGeneralNotification("Unable to delete user", mdiAlertCircle);
      } finally {
        setLoading(false);
      }
    },
    [addGeneralNotification, deleteUser]
  );
  const update = useCallback(
    async (userUpdate, userId) => {
      try {
        setLoading(true);
        setData(({ data: users, total }) => {
          return {
            data: users.map((user) => {
              if (user.id === userId) {
                return {
                  ...user,
                  ...userUpdate,
                };
              }
              return user;
            }),
            total,
          };
        });
        await updateUser(userUpdate, userId);
      } catch (error) {
        addGeneralNotification("Unable to update user", mdiAlertCircle);
      } finally {
        setLoading(false);
      }
    },
    [addGeneralNotification, updateUser]
  );
  useEffect(() => {
    async function run() {
      setLoading(true);
      try {
        const response = await getUsers({
          limit,
          offset,
          org,
          qry,
          sortBy,
          statusFilter,
        });
        const users = await response.json();
        setData(users);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    run();
  }, [getUsers, setError, limit, offset, sortBy, qry, org, statusFilter]);
  return useMemo(() => {
    return {
      delete: handleDeleteUser,
      error,
      loading,
      update,
      users: data,
    };
  }, [data, error, handleDeleteUser, loading, update]);
}

export default function useUsersRepository() {
  const { fetch } = useCustomFetchFn();

  const fetchJson = useCallback(
    async (request) => {
      try {
        const response = await fetch(request);
        const { status } = response;

        if (status === 204) {
          return {
            data: null,
            error: null,
          };
        }

        return {
          data: await response.json(),
          error: null,
        };
      } catch (error) {
        return {
          data: null,
          error,
        };
      }
    },
    [fetch]
  );

  const getTeams = useCallback(
    async (qryFields) => fetchJson(buildGetTeamsRequest({ qryFields })),
    [fetchJson]
  );

  const addTeam = useCallback(
    async (body) =>
      fetchJson(buildAddTeamRequest({ body: JSON.stringify(body) })),
    [fetchJson]
  );

  const updateTeam = useCallback(
    async (body, teamId) =>
      fetchJson(
        buildUpdateTeamRequest({
          body: JSON.stringify(body),
          id: teamId,
        })
      ),
    [fetchJson]
  );

  const deleteTeam = useCallback(
    async (qryFields) => fetchJson(buildDeleteTeamRequest({ qryFields })),
    [fetchJson]
  );

  const getUsers = useCallback(
    async (qryFields) => fetchJson(buildGetUsersRequest({ qryFields })),
    [fetchJson]
  );

  const getUsersForMeetingTransfer = useCallback(
    async (qryFields) =>
      fetchJson(buildGetUsersForMeetingTransferRequest({ qryFields })),
    [fetchJson]
  );

  const deleteUsers = useCallback(
    async (urlParams) => fetchJson(buildDeleteUsersRequest({ urlParams })),
    [fetchJson]
  );

  const addUsersToTeams = useCallback(
    async (body) =>
      fetchJson(buildAddUsersToTeamsRequest({ body: JSON.stringify(body) })),
    [fetchJson]
  );

  const updateUserSettings = useUpdateUserSettings();

  const createTag = useCallback(
    async (body, tagId) =>
      fetchJson(
        buildCreateTagRequest({
          body: JSON.stringify(body),
          id: tagId,
        })
      ),
    [fetchJson]
  );

  const deleteTag = useCallback(
    async (urlParams) =>
      fetchJson(
        buildDeleteTagRequest({
          urlParams,
        })
      ),
    [fetchJson]
  );

  const saveTagsToUser = useCallback(
    async (body) =>
      fetchJson(buildSaveTagsToUser({ body: JSON.stringify(body) })),
    [fetchJson]
  );

  const updateUser = useCallback(
    async (body, userId) =>
      fetchJson(
        buildUpdateUserRequest({
          body: JSON.stringify(body),
          id: userId,
        })
      ),
    [fetchJson]
  );

  const getDeletingUserMeetingDefinitionInfo = useCallback(
    async (userIds) =>
      fetch(buildGetDeletingUserMeetingDefinitionInfoRequest({ userIds })).then(
        (r) => r.blob()
      ),
    [fetch]
  );

  const exportAllUsers = useCallback(async () => {
    const res = await fetch(buildExportAllUsersRequest());
    const blob = await res.blob();

    downloadBlob(blob, "admin_users");
  }, [fetch]);

  const getUserAccounts = useCallback(
    async () => fetch(buildGetUserAccountsRequest()).then((r) => r.json()),
    [fetch]
  );

  const getAddUserAccountURL = useCallback(
    async ({ client, email }) =>
      fetch(buildGetAddUserAccountURLRequest({ client, email })).then((r) =>
        r.json()
      ),
    [fetch]
  );

  const disconnectUserAccount = useCallback(
    async (body) =>
      fetch(buildDisconnectUserAccountRequest({ body })).then((r) => r.json()),
    [fetch]
  );

  const { current } = useRef({
    addTeam,
    addUsersToTeams,
    createTag,
    deleteTag,
    deleteTeam,
    deleteUsers,
    disconnectUserAccount,
    exportAllUsers,
    getAddUserAccountURL,
    getDeletingUserMeetingDefinitionInfo,
    getTeams,
    getUserAccounts,
    getUsers,
    getUsersForMeetingTransfer,
    saveTagsToUser,
    updateTeam,
    updateUser,
    updateUserSettings,
    useAllTeamMembers,
    useUsers,
  });

  return current;
}
