import React, { useMemo } from "react";
import cx from "classnames";

import { toGuestStatusColor } from "../../meetings/props";
import Flexbox from "../flexbox";
import style from "../../meetings/icons/icons.module.scss";
import { P1 } from "../typography";

const WIDTH = "30px";
const HEIGHT = "30px";

export default function InitialsIcon({
  className,
  initials,
  width = WIDTH,
  height = HEIGHT,
  size = { small: true },
  showStatus = false,
  status = "staging",
  borderStyle,
  textStyle,
  color,
  ...props
}) {
  const statusColor = useMemo(
    () => ({
      backgroundColor: `${toGuestStatusColor(status)}`,
    }),
    [status]
  );
  return (
    <Flexbox>
      {({ Column }) => (
        <Column
          className={cx(style.initialsIcon__icon, className)}
          width={width}
          height={height}
          style={{ ...borderStyle, borderColor: color }}
          {...props}
        >
          <P1 style={{ ...textStyle, color }} small={size.small}>
            {initials}
          </P1>

          {showStatus && (
            <div
              className={style.initialsIcon__status}
              style={{ ...statusColor, borderColor: color }}
            />
          )}
        </Column>
      )}
    </Flexbox>
  );
}
