export type MeetingStatus =
  | "cancelled"
  | "completed"
  | "init_fault"
  | "initialized"
  | "initializing"
  | "no_quorum"
  | "queued"
  | "scheduled"
  | "scheduling";

export interface MeetingStatusOption {
  label: string;
  value: MeetingStatus;
}

export const MeetingStatusOptions: MeetingStatusOption[] = [
  { label: "Initializing", value: "initializing" },
  { label: "Initializing Fault", value: "init_fault" },
  { label: "Initialized", value: "initialized" },
  { label: "Scheduling", value: "scheduling" },
  { label: "Queued", value: "queued" },
  { label: "Scheduled", value: "scheduled" },
  { label: "Completed", value: "completed" },
  { label: "No Quorum", value: "no_quorum" },
  { label: "Cancelled", value: "cancelled" },
];

export function meetingStatusOptionFromValue(
  status: string
): MeetingStatusOption | null {
  return MeetingStatusOptions.find((o) => o.value === status) || null;
}

export function isValidMeetingStatus(status: string): boolean {
  return MeetingStatusOptions.find((o) => o.value === status) !== undefined;
}
