import React, { useMemo, useState } from "react";
import Flexbox from "../../../components/flexbox";
import HostSelect from "../../hostSelect";
import { getInitials } from "../../instanceDetails/util/helpers";
import { P1 } from "../../../components/typography";
import { useIsInitializingPhase, useTableRowMeeting } from "../../context";
import {
  INITIALIZING_READY_TO_ACTIVATE_STAGE,
  INITIALIZING_STAGE,
  SCHEDULED_STAGE,
} from "../../props";
import InitialsIcon from "../../../components/initials";
import style from "./hostRow.module.scss";
import { ROLE_LEVELS } from "../../../auth/roles";
import { userCanChangeMeetingOwner } from "../../../auth/permissions";
import { useChangeMeetingOwner } from "../../../features/useChangeMeetingOwner";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { TransferHostDialog } from "../../../componentsV2/dialogs";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useTransferHost } from "../../../mutations";
import { getUserDetails } from "src/utils/jwtToken";

function HostRow({ disabled, host = null, setHost, meetingStatus }) {
  const isInitializingPhase = useIsInitializingPhase();
  const meeting = useTableRowMeeting();
  const user = getUserDetails();
  const changeMeetingOwnerEnabled = useChangeMeetingOwner();
  const [showTransferMeetingHostDialog, setShowTransferMeetingHostDialog] =
    useState(false);

  const hostInitials = useMemo(() => getInitials(host), [host]);

  const hostEmail = useMemo(() => {
    if (host === null) {
      return "";
    }
    return host.email;
  }, [host]);

  const color = useMemo(() => {
    if (
      meetingStatus === INITIALIZING_STAGE ||
      meetingStatus === INITIALIZING_READY_TO_ACTIVATE_STAGE
    ) {
      return "#000";
    }
    return "#FFF";
  }, [meetingStatus]);

  const canTransferMeeting = useMemo(() => {
    if (!user) {
      return false;
    }
    const { role = ROLE_LEVELS.DEFAULT } = user;
    return (
      changeMeetingOwnerEnabled &&
      meeting.status === SCHEDULED_STAGE &&
      (role > ROLE_LEVELS.DEFAULT || userCanChangeMeetingOwner(user))
    );
  }, [changeMeetingOwnerEnabled, meeting, user]);

  const { addError, addGeneralNotification } = useGeneralNotifications();
  const transferHost = useTransferHost();
  return (
    <>
      {showTransferMeetingHostDialog && (
        <TransferHostDialog
          open
          meetingIds={[meeting.id]}
          onClose={() => setShowTransferMeetingHostDialog(false)}
          onConfirm={({ host, meetingLinkOption, customMeetingLink }) => {
            transferHost(
              meeting.id,
              host.id,
              meetingLinkOption === "current",
              customMeetingLink
            )
              .then(() => {
                addGeneralNotification(
                  `Successfully transferred meeting to ${host.firstName} ${host.lastName}`
                );
                setShowTransferMeetingHostDialog(false);
              })
              .catch(() => {
                addError("There was a problem transferring this meeting");
              });
          }}
        />
      )}
      <Flexbox>
        {({ Row }) => (
          <Row className={style.hostRow} justifyContent="flex-start">
            <Row width="fit-content">
              {host !== null && (
                <InitialsIcon
                  initials={hostInitials}
                  width="35px"
                  height="35px"
                  size={{ small: true }}
                  borderStyle={{ borderColor: color }}
                  color={color}
                />
              )}
            </Row>
            {isInitializingPhase && (
              <>
                <HostSelect
                  disabled={disabled}
                  host={host}
                  setHost={setHost}
                  textColor={color}
                />
              </>
            )}

            {!isInitializingPhase && (
              <>
                {hostEmail && <P1 style={{ color }}>{hostEmail}</P1>}

                {!hostEmail && <P1 style={{ color }}>No Host Detected</P1>}
              </>
            )}

            {canTransferMeeting && (
              <div className={style.transferButton}>
                <Tooltip title="Transfer to another host" arrow>
                  <IconButton
                    onClick={() => setShowTransferMeetingHostDialog(true)}
                  >
                    <KeyboardDoubleArrowRight />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Row>
        )}
      </Flexbox>
    </>
  );
}

export default HostRow;
