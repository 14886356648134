import React, { useState, useCallback, useMemo } from "react";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment-timezone";
import { Input } from "../../../components/input";
import Flexbox from "../../../components/flexbox";
import { ButtonContainer } from "../../../components/button";
import { P1 } from "../../../components/typography";
import { Timezone, useTimezones } from "../../../components/timezone";
import { useMeetingRepository } from "../../../repository/meetings";
import { useMeeting } from "../state";
import style from "./meetingTypeDetailsHeader.module.scss";

export const DEFAULT_HOUR_VALUE = "00";

function MeetingTypeDetailsCalendar({ startTime, handleCloseCalendar }) {
  const { overrideStartTime } = useMeetingRepository();
  const [meeting] = useMeeting();
  const [newDateTime, setNewDateTime] = useState(moment(startTime));
  const [focusedInput, setFocusedInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { getBrowserTimezoneInUTC } = useTimezones();
  const [userSelectedTimezone, setUserSelectedTimezone] = useState(
    getBrowserTimezoneInUTC
  );

  const handleCancel = () => {
    handleCloseCalendar();
  };

  const isSameTime = useMemo(() => {
    const tempDateTime = moment({
      day: newDateTime.date(),
      hour: newDateTime.hour(),
      minute: newDateTime.minute(),
      month: newDateTime.month(),
      year: newDateTime.year(),
    }).tz(userSelectedTimezone, true);

    return tempDateTime.isSame(startTime);
  }, [newDateTime, startTime, userSelectedTimezone]);

  const handleNewTime = useCallback(
    (event) => {
      let [hour, minute] = event.target.value.split(":");
      // Input element of type time behaves differently in firefox and chrome.
      // In firefox, when a 0 is entered in the hour place it becomes 12 whereas in
      // chrome the whole time gets cleared out and becomes undefined.
      // Below if condition will deal with the case when either hour or minute becomes
      // undefined.
      if (typeof hour === "undefined" || typeof minute === "undefined") {
        hour = DEFAULT_HOUR_VALUE;
        minute = newDateTime.minute();
      }
      const tempDateTime = moment({
        day: newDateTime.date(),
        hour: parseInt(hour),
        minute: parseInt(minute),
        month: newDateTime.month(),
        year: newDateTime.year(),
      }).tz(userSelectedTimezone, true);

      setNewDateTime(tempDateTime);
    },
    [newDateTime, userSelectedTimezone]
  );

  const handleSave = useCallback(async () => {
    const response = await overrideStartTime({
      id: meeting.id,
      startTime: newDateTime.toISOString(),
    });
    if (!response) {
      setErrorMessage("There was a problem updating the start time.");
    } else {
      handleCloseCalendar();
      setErrorMessage(null);
    }
  }, [handleCloseCalendar, meeting.id, newDateTime, overrideStartTime]);

  const handleTimezoneChange = ({ value: timezone }) => {
    setUserSelectedTimezone(timezone);
    setNewDateTime(() => moment(newDateTime).tz(timezone, true));
  };

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <>
          <div className={style.triangle} />
          <Row height="300px" width="600px" className={style.calendarWindow}>
            <Column>
              <DayPickerSingleDateController
                isOutsideRange={(day) => day.isBefore(moment())}
                focused={focusedInput}
                onDateChange={(value) => {
                  setNewDateTime(
                    moment({
                      day: value.date(),
                      hour: newDateTime.hour(),
                      minute: newDateTime.minute(),
                      month: value.month(),
                      year: value.year(),
                    })
                  );
                }}
                onFocusChange={() => setFocusedInput(true)}
                numberOfMonths={1}
                daySize={31}
                hideKeyboardShortcutsPanel
              />
            </Column>

            <Column alignItems="flex-start">
              <Row justifyContent="flex-start" height="35%">
                <P1 className={style.cardHeader}>Date</P1>
              </Row>
              <Row
                className={style.headerSpace}
                justifyContent="flex-start"
                height="5%"
              >
                <Input
                  className={style.dateInput}
                  type="text"
                  value={newDateTime.format("MM/DD/YYYY")}
                  readOnly
                />
              </Row>

              <Row height="25%">
                <Column alignItems="flex-start" width="45%">
                  <P1 className={style.cardHeader}>Time</P1>
                </Column>
                <Column alignItems="flex-start" width="55%">
                  <P1 className={style.cardHeader}>Timezone</P1>
                </Column>
              </Row>

              <Row height="20%">
                <Column alignItems="flex-start" width="45%">
                  <Input
                    className={style.timeInput}
                    type="time"
                    onChange={handleNewTime}
                    value={newDateTime.format("HH:mm")}
                    required
                  />
                </Column>
                <Column alignItems="flex-start" width="55%">
                  <Timezone
                    onChange={handleTimezoneChange}
                    timezoneAbbr
                    value={userSelectedTimezone}
                  />
                </Column>
              </Row>

              <Row>
                <Column width="50%">
                  <ButtonContainer
                    className={style.calendarCancelButton}
                    onClick={handleCancel}
                  >
                    CANCEL
                  </ButtonContainer>
                </Column>
                <Column width="50%">
                  <ButtonContainer
                    className={style.calendarSaveButton}
                    onClick={handleSave}
                    disabled={isSameTime}
                  >
                    SAVE
                  </ButtonContainer>
                </Column>
              </Row>
              {errorMessage && (
                <Row className={style.warning}>{errorMessage}</Row>
              )}
            </Column>
          </Row>
        </>
      )}
    </Flexbox>
  );
}

export default MeetingTypeDetailsCalendar;
