import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDefaultSelectTheme } from "../../../components/select";
import Select from "../../../inputs/Select";
import { INTEGRATION_TYPES } from "../../../integrations/props";

import style from "./style.module.scss";

function FieldMappingCard({
  fieldMappings,
  fieldObjectType,
  fields,
  selectedIntegration,
  setTempModalState,
}) {
  const [components, theme] = useDefaultSelectTheme();
  const [dropdownFields, setDropdownFields] = useState(null);
  const dropdownFieldOptions = useMemo(() => {
    if (dropdownFields === null) {
      return [];
    }
    return dropdownFields.map((dropdownField) => ({
      label: dropdownField.label,
      value: dropdownField,
    }));
  }, [dropdownFields]);
  const handleSelectLogicField = useCallback(
    (selection) => {
      setTempModalState((p) => ({
        ...p,
        fieldMappings: {
          ...p.fieldMappings,
          logicField: {
            label: selection?.label || "",
            name: selection?.name || "",
          },
        },
      }));
    },
    [setTempModalState]
  );

  const handleSelectRoutingField = useCallback(
    (selection) => {
      setTempModalState((p) => ({
        ...p,
        fieldMappings: {
          ...p.fieldMappings,
          routingField: {
            label: selection?.label || "",
            name: selection?.name || "",
          },
        },
      }));
    },
    [setTempModalState]
  );

  const routingFieldPlaceholder = useMemo(() => {
    if (
      selectedIntegration.name === INTEGRATION_TYPES.HUBSPOT ||
      selectedIntegration.name === INTEGRATION_TYPES.DYNAMICS
    ) {
      return <div> No Mapping (Default) </div>;
    }
    return <div> Owner Email (Default) </div>;
  }, [selectedIntegration.name]);

  useEffect(() => {
    if (fieldObjectType?.type && fields.has(selectedIntegration.name)) {
      setDropdownFields(
        fields.get(selectedIntegration.name)[fieldObjectType.type]
      );
    }
  }, [fieldObjectType, fields, selectedIntegration]);

  return (
    <div className={style.fieldMappingCard}>
      <div className={style.fieldMappingCard__mappings}>
        <div className={style.fieldMappingCard__mappings__content}>
          <div>
            <span
              className={style.fieldMappingCard__mappings__content__boldedWords}
            >
              {`Routing Field `}
            </span>
            maps to...
          </div>
          <div>
            <span
              className={style.fieldMappingCard__mappings__content__boldedWords}
            >
              {`Logic Field `}
            </span>
            maps to...
          </div>
        </div>
        {!dropdownFields && <div>Loading</div>}
        {dropdownFields && (
          <div className={style.fieldMappingCard__mappings__content}>
            <Select
              components={components}
              customTheme={theme}
              isClearable
              name="/channels/import/add_channel/routing_field"
              onChange={(option) => {
                if (option === null) {
                  handleSelectRoutingField(null);
                  return;
                }
                handleSelectRoutingField(option.value);
              }}
              options={dropdownFieldOptions}
              placeholder={routingFieldPlaceholder}
              value={dropdownFieldOptions.find(
                (dfo) => dfo.label === fieldMappings.routingField.label
              )}
            />
            <Select
              components={components}
              customTheme={theme}
              isClearable
              name="/channels/import/add_channel/logic_field"
              onChange={(option) => {
                if (option === null) {
                  handleSelectLogicField(null);
                  return;
                }
                handleSelectLogicField(option.value);
              }}
              options={dropdownFieldOptions}
              placeholder={<div>No Mapping (Default)</div>}
              value={dropdownFieldOptions.find(
                (dfo) => dfo.label === fieldMappings.logicField.label
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default FieldMappingCard;
