import React, { useCallback, useRef, useState } from "react";
import { Button } from "../../../components/button";
import SendLater from "./SendLater";
import { useModalV2 } from "../../../components/modal";
import { ActivateButton } from "../MenuButtons";
import useEventListener, {
  LISTENER_TYPES,
} from "../../../hooks/useEventListener";
import style from "./style.module.scss";

function BulkActivateMenu({
  bulkActivate,
  bulkActivateMeetingsSendLater,
  disabled,
  selectedMeetingCount,
}) {
  const menuNode = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [SendLaterModal, openSendLaterModal] = useModalV2(SendLater);

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeMenu = useCallback(
    (e) => {
      if (menuNode.current && menuNode.current.contains(e.target)) {
        return;
      }
      setIsOpen(false);
    },
    [setIsOpen]
  );

  // this allows user to click "off" the menu and have menu close
  useEventListener(LISTENER_TYPES.MOUSEDOWN, closeMenu);

  const bulkActivateNow = useCallback(() => {
    bulkActivate();
    setIsOpen(false);
  }, [bulkActivate]);

  const bulkActivateLater = useCallback(
    (executeAt) => {
      bulkActivateMeetingsSendLater(executeAt);
      setIsOpen(false);
    },
    [bulkActivateMeetingsSendLater]
  );

  return (
    <>
      <SendLaterModal
        bulkActivateLater={bulkActivateLater}
        selectedMeetingCount={selectedMeetingCount}
      />
      <div className={style.instanceMenu__overflowMenuContainer} ref={menuNode}>
        <ActivateButton onClick={openMenu} disabled={disabled} />

        {isOpen && (
          <div className={style.instanceMenu__overflowMenu}>
            <Button
              className={style.instanceMenu__overflowMenuItem}
              name="/instances/action_bar/activate_selected/send_now"
              onClick={bulkActivateNow}
            >
              Send Now
            </Button>
            <Button
              className={style.instanceMenu__overflowMenuItem}
              name="/instances/action_bar/activate_selected/send_later"
              onClick={openSendLaterModal}
            >
              Send Later
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default BulkActivateMenu;
