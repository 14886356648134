import {
  AccessTime,
  Delete,
  Email,
  Label,
  PermContactCalendarOutlined,
  Person,
} from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import dayjs from "dayjs";
import advanced from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import GuestStatusDropdown from "src/componentsV2/GuestStatusDropdown";
import Loading from "src/componentsV2/Loading";
import RichText from "src/componentsV2/RichText";
import ProposeNewTimeDialog from "src/componentsV2/dialogs/ProposeNewTimeDialog";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import UpdateReminderDialog from "src/componentsV2/dialogs/UpdateReminder";
import { useSnackbar } from "src/hooks";
import {
  INITIALIZING_FAULT_STAGE,
  isFinalMeetingStage,
  isMeetingCancelledStage,
  isMeetingInitFaultStage,
} from "src/meetings/props";
import {
  useDeleteMeetings,
  useUpdateMeetingTags,
  useUpdateMeetings,
} from "src/mutations";
import useGuestStatusOverride from "src/mutations/useGuestStatusOverride";
import useUpdateMeetingReminder from "src/mutations/useUpdateMeetingReminder";
import useUpdateMeetingStartTime from "src/mutations/useUpdateMeetingStartTime";
import ErrorPage from "src/pages/ErrorPage";
import { useMeetingDetails } from "src/queries/useMeetingDetails";
import { meetingStatusOptionFromValue } from "src/types";
import {
  isCalendarFirst,
  isEmailFirst,
  isLinkFirst,
} from "../../../../meetingTypes/invite/props";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default function KMeMeeting() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const theme = useTheme();

  const [showUpdateReminderDilaog, setShowUpdateReminderDialog] =
    useState(false);
  const [showProposeNewTimeDialog, setShowProposeNewTimeDialog] =
    useState(false);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const { data, loading, error } = useMeetingDetails(Number.parseInt(id));

  const updateMeetings = useUpdateMeetings();
  const updateMeetingTags = useUpdateMeetingTags();
  const [openSnackbar] = useSnackbar();
  const deleteMeetings = useDeleteMeetings();
  const updateReminder = useUpdateMeetingReminder();
  const proposeNewTime = useUpdateMeetingStartTime();
  const guestStatusOverride = useGuestStatusOverride();

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showUpdateReminderDilaog && (
        <UpdateReminderDialog
          open
          currentTime={
            data.reminder ? dayjs(data.reminder.executionTime) : null
          }
          schedulingRange={[dayjs(), dayjs(data.meetingTime)]}
          onClose={() => setShowUpdateReminderDialog(false)}
          onSubmit={async (time) => {
            try {
              await updateReminder(data.id, time.toDate());
              openSnackbar("Reminder updated");
              setShowUpdateReminderDialog(false);
            } catch {
              openSnackbar("Failed to update reminder");
            }
          }}
        />
      )}
      {showProposeNewTimeDialog && (
        <ProposeNewTimeDialog
          open
          currentTime={data.meetingTime ? dayjs(data.meetingTime) : null}
          onClose={() => setShowProposeNewTimeDialog(false)}
          onSubmit={async (time) => {
            try {
              await proposeNewTime(data.id, time.toDate());
              openSnackbar("Meeting start time successfully updated");
              setShowProposeNewTimeDialog(false);
            } catch {
              openSnackbar("Failed to update meeting start time");
            }
          }}
        />
      )}
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Update Meeting Tags"
          open
          initialTags={data?.tags}
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await updateMeetingTags(
                [data.id],
                tags.map((tag) => tag.id)
              );
              openSnackbar("Tags successfully updated");

              setShowTagManagementDialog(false);
            } catch {
              openSnackbar("Failed to update meeting tags ");
            }
          }}
        />
      )}
      <Container maxWidth="xl" disableGutters sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ pb: 1 }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link component={RouterLink} to="/meetings">
              Meetings
            </Link>
            <Typography>{id}</Typography>
          </Breadcrumbs>

          <Stack direction="row" spacing={1}>
            <Switch
              disabled={
                !data.guest ||
                !data.template?.enabled ||
                data.status === INITIALIZING_FAULT_STAGE ||
                isFinalMeetingStage(data.status) ||
                isMeetingCancelledStage(data.status) ||
                (isMeetingInitFaultStage(data.status) && !data.enabled)
              }
              checked={data.enabled || false}
              onClick={async () => {
                try {
                  const { failures } = await updateMeetings([
                    { id: data.id, enabled: !data.enabled },
                  ]);
                  if (failures?.length > 0) {
                    openSnackbar("Failed to update meeting");
                  }
                } catch {
                  openSnackbar("Meeting updated");
                }
              }}
            />
            <IconButton
              onClick={async () => {
                try {
                  await deleteMeetings([data.id]);
                  history.push("/meetings");
                  openSnackbar("Meeting deleted");
                } catch {
                  openSnackbar("Failed to delete meeting");
                }
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignContent="flex-start"
          >
            <Stack spacing={1}>
              <Typography
                variant="h4"
                sx={{ color: "primary.dark", fontWeight: "bold" }}
              >
                {data.template?.name || "No Name :("}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Stack direction="row" spacing={1}>
                  <Person />
                  <Typography>
                    <Typography fontWeight="bold" component="span">
                      Host
                    </Typography>
                    {data.host ? (
                      <Stack>
                        <Typography>
                          {data.host.firstName} {data.host.lastName}
                        </Typography>
                        <Typography color="primary">
                          {data.host.email}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography>No Host</Typography>
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <PermContactCalendarOutlined />
                  <Typography>
                    <Typography fontWeight="bold" component="span">
                      Guest
                    </Typography>
                    {data.guest ? (
                      <Stack>
                        <Typography>
                          {data.guest.firstName} {data.guest.lastName}
                        </Typography>
                        <Typography color="primary">
                          {data.guest.email}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography>No Guest</Typography>
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <AccessTime />
                  <Typography>
                    <Typography fontWeight="bold" component="span">
                      Meeting Time
                    </Typography>
                    {data.meetingTime ? (
                      <Stack>
                        <Typography>
                          {dayjs(data.meetingTime).format("M/D/YYYY")}
                        </Typography>
                        <Typography>
                          {dayjs(data.meetingTime).format("h:mm A z")}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography>Not yet scheduled</Typography>
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Box
                sx={{
                  p: 2,
                  color: theme.palette.primary.contrastText,
                  backgroundColor: "primary.dark",
                  borderRadius: "5px",
                  width: "fit-content",
                  height: "54px",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography>
                  {meetingStatusOptionFromValue(data.status)?.label ||
                    "Unknown"}
                </Typography>
              </Box>
              <GuestStatusDropdown
                status={data.guest?.status || ""}
                meetingStage={data.status}
                onChange={async (status) => {
                  if (data.guest?.id) {
                    try {
                      await guestStatusOverride(
                        data.id,
                        data.guest?.id,
                        status,
                        true
                      );

                      openSnackbar("Guest status updated");
                    } catch {
                      openSnackbar("Failed to override guest status");
                    }
                  }
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              sx={{
                backgroundColor: "#E1E6EB",
                textTransform: "none",
                py: 1,
                px: 2,
                color: "text.primary",
              }}
              startIcon={<Email />}
              onClick={() => {
                setShowUpdateReminderDialog(true);
              }}
            >
              Update Reminder Time
            </Button>
            <Button
              sx={{
                backgroundColor: "#E1E6EB",
                textTransform: "none",
                py: 1,
                px: 2,
                color: "text.primary",
              }}
              startIcon={<AccessTime />}
              onClick={() => {
                setShowProposeNewTimeDialog(true);
              }}
            >
              Propose New Time
            </Button>
            <Button
              sx={{
                backgroundColor: "#E1E6EB",
                textTransform: "none",
                py: 1,
                px: 2,
                color: "text.primary",
              }}
              startIcon={<Label />}
              onClick={() => {
                setShowTagManagementDialog(true);
              }}
            >
              Add Tag
            </Button>
          </Stack>
          <Grid container>
            <Grid item xs={9}>
              <Stack
                sx={{
                  borderStyle: "solid",
                  borderRadius: "5px",
                  borderColor: theme.palette.divider,
                  borderWidth: "1px",
                  width: "100%",
                  minHeight: "600px",
                  p: 2,
                  gap: 2,
                }}
              >
                {data.history.length < 1 && (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="h5">No Email History</Typography>
                  </Stack>
                )}
                {data.history
                  .sort((a, b) => (dayjs(a.date).isBefore(b.date) ? 1 : -1))
                  .map((item, index) => {
                    if (item.type !== "email") {
                      return (
                        <Box sx={{ width: "100%" }}>
                          <Divider>
                            <Chip
                              label={`${item.note} @ ${dayjs(item.date).format(
                                "h:mm A z M/D/YYYY"
                              )}`}
                              variant="filled"
                              sx={{ borderRadius: "5px" }}
                            />
                          </Divider>
                        </Box>
                      );
                    }

                    const isGuest = data?.guest?.email === item.sender.email;

                    let senderInitials = "";
                    if (
                      item.sender.firstName?.length &&
                      item.sender.lastName?.length
                    ) {
                      senderInitials = `${item.sender.firstName?.[0]}${item.sender.lastName?.[0]}`;
                    } else if (item.sender.email?.length) {
                      senderInitials = item.sender.email[0];
                    }

                    return (
                      <>
                        <Stack sx={{ marginBottom: 5, marginTop: 5 }}>
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: 4,
                            }}
                          >
                            <Stack
                              sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <Avatar
                                sx={{
                                  backgroundColor: isGuest
                                    ? theme.palette.text.primary
                                    : theme.palette.primary.main,
                                  color: "white",
                                }}
                              >
                                {senderInitials}
                              </Avatar>
                              <Stack>
                                <Typography>
                                  {item.sender.firstName} {item.sender.lastName}
                                </Typography>
                                <Typography variant="caption">
                                  {item.sender.email}
                                </Typography>
                              </Stack>
                              {item.sender.ai && (
                                <Chip
                                  sx={{ marginLeft: 2 }}
                                  label="AI Response"
                                />
                              )}
                            </Stack>
                            <Stack>
                              <Typography variant="caption" align="right">
                                {dayjs(item.date).format("ddd MMM D, YYYY")}
                              </Typography>
                              <Typography variant="caption" align="right">
                                {" "}
                                {dayjs(item.date).format("h:mm A z")}
                              </Typography>
                            </Stack>
                          </Stack>
                          <RichText content={item.body} />
                        </Stack>
                        {data.history?.[index + 1]?.type === "email" && (
                          <Divider />
                        )}
                      </>
                    );
                  })}
              </Stack>
            </Grid>
            <Grid item xs={2} sx={{ ml: 2 }}>
              <Stack sx={{ width: "100%", maxWidth: "350px" }} spacing={1}>
                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Template Type:
                  </Typography>{" "}
                  {isCalendarFirst(data.template?.type)
                    ? "Kronologic Invite"
                    : isEmailFirst(data.template?.type)
                    ? "Kronologic Email"
                    : isLinkFirst(data.template?.type)
                    ? "Kronologic Link"
                    : "Unknown"}
                </Typography>

                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Meeting Location:
                  </Typography>{" "}
                  {data.template?.invite?.location ? (
                    <a href={data.template?.invite?.location}>
                      Link to meeting
                    </a>
                  ) : (
                    "None"
                  )}
                </Typography>

                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Sequence:
                  </Typography>{" "}
                  {data.attempts.current} of {data.attempts.total}
                </Typography>
                <Typography>
                  <Typography fontWeight="bold" component="span">
                    Reminder:
                  </Typography>{" "}
                  {!data.reminder.enabled
                    ? "None"
                    : data.reminder.executionTime
                    ? dayjs(data.reminder.executionTime).format(
                        "h:mm A z M/D/YYYY"
                      )
                    : "Not yet scheduled"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
