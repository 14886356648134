import React, { useRef, useState } from "react";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../Loading";
import FilterButton from "./FilterButton";

export function FilterAutocomplete<T>(props: {
  label?: string;
  loading?: boolean;
  options?: T[];
  value?: T[];
  total?: number;
  search?: string;
  onSearchChange?: (value: string) => void;
  getOptionId?: (option: T) => string | number;
  getOptionLabel?: (option: T) => string | React.ReactNode;
  getOptionDisabled?: (option: T) => boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  onChange?: (value: T[]) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);

  const compareFn =
    props.isOptionEqualToValue ||
    function (option, value) {
      return props.getOptionId
        ? props.getOptionId(option) === props.getOptionId(value)
        : option === value;
    };

  const options = props.options || [];
  const selected = props.value || [];
  const remainder = options.filter(
    (o) => !selected.some((v) => compareFn(o, v))
  );

  return (
    <div>
      <FilterButton
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        active={selected.length > 0}
        dropdown
      >
        {props.label}
        {selected.length > 0 ? ` (${selected.length})` : ""}
      </FilterButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper>
          <Box sx={{ p: 1 }}>
            <TextField
              autoFocus
              inputRef={inputRef}
              size="small"
              fullWidth
              placeholder="Search"
              value={props.search}
              onChange={(event) => {
                if (props.onSearchChange) {
                  props.onSearchChange(event.target.value);
                }
              }}
            />
          </Box>

          {selected.length > 0 && (
            <List sx={{ width: "300px", pt: 0 }}>
              {selected.map((option) => {
                return (
                  <ListItemButton
                    key={props.getOptionId?.(option)}
                    onClick={() => {
                      if (props.onChange) {
                        props.onChange(
                          selected.filter((o) => !compareFn(o, option))
                        );
                      }
                    }}
                    sx={{ p: 0.5 }}
                  >
                    <Checkbox
                      size="small"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={true}
                    />
                    <Typography>
                      {props.getOptionLabel?.(option) ?? option}
                    </Typography>
                  </ListItemButton>
                );
              })}
            </List>
          )}
          <Divider />

          <List sx={{ width: "300px" }}>
            {props.loading ? (
              <Loading />
            ) : (
              <>
                {remainder.map((option) => (
                  <ListItemButton
                    key={props.getOptionId?.(option)}
                    onClick={() => {
                      if (props.onChange) {
                        props.onChange(selected.concat([option]));
                      }
                    }}
                    sx={{ p: 0.5 }}
                  >
                    <Checkbox
                      size="small"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={false}
                    />
                    <Typography>
                      {props.getOptionLabel?.(option) ?? option}
                    </Typography>
                  </ListItemButton>
                ))}
                {remainder?.length === 0 && <ListItem>None</ListItem>}
              </>
            )}
          </List>
          {props.total !== undefined && (
            <>
              <Divider />
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ p: 1 }}
              >
                <Typography variant="caption">
                  {selected.length} selected
                </Typography>
                <Typography variant="caption">
                  {options.length} of {props.total}
                </Typography>
              </Stack>
            </>
          )}
        </Paper>
      </Popover>
    </div>
  );
}

export default FilterAutocomplete;
