import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { useTokenRefreshHandler } from "src/hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { MeetingReportQuery } from "src/types";

export interface MeetingCounts {
  total: number;
  hostMissingTotal: number;
  readyToSendTotal: number;
  sentTotal: number;
  scheduledTotal: number;
  noQuorumTotal: number;
  completedTotal: number;
}

export function useMeetingsCount(query: MeetingReportQuery) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(
    [`/api/meetings/instances/counts`, query],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as MeetingCounts | undefined,
    error,
    loading: !error && !data,
  };
}
