import useSWR from "swr";
import { useUserService } from "../services";
import { useTokenRefreshHandler } from "../hooks";
import { useActingAsOverrideHeader } from "../auth";
import { getUserToken } from "../utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

type ReportsCampaignRequest = {
  dateFrom?: string;
  dateTo?: string;
  meetingDefinitionIds?: number[];
  team?: number[];
  meetingTags?: number[];
  templateTags?: number[];
  user?: number[];
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: "asc" | "desc" | null;
};

export type AggregateData = {
  meetingsCount: number;
  responseCount: number;
  automatedResponseCount: number;
  softAcceptCount: number;
  hardAcceptCount: number;
  tentativeAcceptCount: number;
  acceptCount: number;
  softDeclineCount: number;
  hardDeclineCount: number;
  softUnsubscribeCount: number;
  hardUnsubscribeCount: number;
  unsubscribeCount: number;
  declineCount: number;
  noResponseCount: number;
  respondedUnknownIntentCount: number;
  undeliverableCount: number;
  cancelledManuallyCount: number;
  cancelledAutomaticallyCount: number;
  cancelledCount: number;
  responseRate: number;
  automatedResponseRate: number;
  acceptRate: number;
  softAcceptRate: number;
  hardAcceptRate: number;
  tentativeAcceptRate: number;
  declineRate: number;
  softDeclineRate: number;
  hardDeclineRate: number;
  unsubscribeRate: number;
  softUnsubscribeRate: number;
  hardUnsubscribeRate: number;
  noResponseRate: number;
  respondedUnknownIntentRate: number;
  cancelRate: number;
  avgResponseTime: number;
  avgAcceptTime: number;
  avgDeclineTime: number;
  avgTouches: number;
  inboundCount: number;
  inboundRate: number;
  outboundCount: number;
  outboundRate: number;
};

export type ReportsCampaignResponse = {
  total: number;
  data: ReportsCampaign[];
};

export type ReportsCampaign = {
  meetingDefinitionId: number;
  campaign: string;
  meetingDefinitionEnabled: boolean;
  teamId: number;
  team: string | null;
  inviteStyle: "kronologicInvite" | "kronologicEmail" | "kronologicLink";
  aggregateData: AggregateData;
};

export function useReportingCampaign(query: ReportsCampaignRequest) {
  const accessToken = getUserToken();
  const service = useUserService();
  const override = useActingAsOverrideHeader();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(
    ["/api/reports/campaign", query],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as ReportsCampaignResponse | undefined,
    error,
    loading: !error && !data,
  };
}
