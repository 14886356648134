import { Chip, Paper, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";

import { Download } from "@mui/icons-material";
import { useCustomFetchFn } from "../components/fetch";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import useImportExportRepository from "../repository/importExport";
import {
  colorFromStatus,
  DataExport,
  DownloadStatus,
  ObjectType,
  objectTypeToLabel,
} from "../types";
import { downloadBlob } from "../utils/helpers";
import PrimaryButton from "./buttons/PrimaryButton";
import Table from "./tables/Table";
import { capitalize } from "src/globalLogs/builders/util";

const TIME_FORMAT = "MM/DD/YYYY h:mma";

export function RecentExports({
  exports,
  loading,
}: {
  exports?: DataExport[];
  loading?: boolean;
}) {
  const { fetch } = useCustomFetchFn();
  const { requestCsvDownload } = useImportExportRepository();
  const { addError } = useGeneralNotifications();

  const getStatus = (item: DataExport) => {
    if (
      item.type == "contact_csv_import" &&
      item.status == "working" &&
      item.progress !== undefined
    ) {
      return `working: ${item.progress}%`;
    }
    return item.status;
  };

  const colorFromStatusString = (statusString: string) => {
    const status: DownloadStatus = statusString.startsWith("working:")
      ? "working"
      : (statusString as DownloadStatus);
    return colorFromStatus(status);
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Recent Imports and Exports</Typography>
      </Stack>
      <Paper elevation={0}>
        <Table
          loading={loading}
          minRows={5}
          data={
            exports?.map((item, idx) => ({
              date: dayjs(item.createdAt).format(TIME_FORMAT),
              id: idx + 1,
              objectType: objectTypeToLabel(item.type as ObjectType),
              refId: item.id,
              status: getStatus(item),
              storageRef: item.storageRef,
              userEmail: item.userEmail,
              userFirstName: item.userFirstName,
              userLastName: item.userLastName,
            })) || []
          }
          columns={[
            {
              id: "refId",
              label: "Id",
              component: (row) => {
                return (
                  <Tooltip title={row.storageRef}>
                    <Typography>{row.refId}</Typography>
                  </Tooltip>
                );
              },
            },
            {
              id: "created",
              label: "Date Created",
              component: (row) => <>{row.date}</>,
            },
            {
              id: "createdBy",
              label: "Created By",
              component: (row) => (
                <>
                  {capitalize(row.userFirstName)} {capitalize(row.userLastName)}{" "}
                  ({row.userEmail})
                </>
              ),
            },
            {
              id: "status",
              label: "Status",
              component: (row) => {
                return (
                  <Chip
                    label={row.status}
                    color={colorFromStatusString(row.status)}
                    variant="outlined"
                  />
                );
              },
            },
            {
              id: "type",
              label: "Type",
              component: (row) => <>{row.objectType}</>,
            },
            {
              id: "link",
              label: "Download Link",
              component: (row) => {
                return !row.objectType?.includes("Import") ? (
                  <PrimaryButton
                    disabled={row.status !== "completed"}
                    onClick={() => {
                      requestCsvDownload(row.refId)
                        .then(({ message }) => fetch(message))
                        .then((res) => res?.blob())
                        .then((blob) => downloadBlob(blob, row.objectType))
                        .catch(() => {
                          addError(
                            "Unable to download CSV. Please try again later"
                          );
                        });
                    }}
                    icon={<Download />}
                  >
                    Download
                  </PrimaryButton>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </Paper>
    </Stack>
  );
}
