import HelpIcon from "@mui/icons-material/Help";
import MenuIcon from "@mui/icons-material/Menu";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import {
  Assessment,
  Assistant,
  Business,
  CalendarMonth,
  CalendarToday,
  Group,
  Logout,
  PermContactCalendar,
  Settings,
  Storage,
} from "@mui/icons-material";
import React from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useActingAs } from "src/auth";
import { ROLE_LEVELS, supersedes } from "src/auth/roles";
import { Page } from "src/componentsV2/GenericNavbar";
import UserMenu from "src/componentsV2/UserMenu";
import {
  useMeetingTypesForReps,
  useNewMeetingsPage,
  useQuickLaunch,
} from "src/features";
import { KMeIcon } from "src/kronologic-me/icons/KronologicMeIcon";
import { MANUAL_LOGOUT_KEY } from "src/logout/Logout";
import { SuperAdminRORole, SuperAdminRole } from "src/types";
import { getUserDetails } from "src/utils/jwtToken";
import KMeQuickLaunch from "./QuickLaunch";

export function KMeNavbar() {
  const meetingTypesForReps = useMeetingTypesForReps();
  const newMeetingsPage = useNewMeetingsPage();
  const theme = useTheme();

  const quickLaunch = useQuickLaunch();

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();

  const history = useHistory();

  const pages = [];

  const user = actingAs || loggedInUser;

  const [anchorElHelpNav, setAnchorElHelpNav] =
    React.useState<null | HTMLElement>(null);

  const handleOpenHelpNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHelpNav(event.currentTarget);
  };

  const handleCloseHelpNavMenu = () => {
    setAnchorElHelpNav(null);
  };

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <PermContactCalendar />,
      name: "Contacts",
      route: "/contacts",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    }) ||
    meetingTypesForReps
  ) {
    pages.push({
      icon: <CalendarToday />,
      name: "Templates",
      route: "/meeting-templates",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.DEFAULT,
    })
  ) {
    pages.push({
      icon: <CalendarMonth />,
      name: "Meetings",
      route: newMeetingsPage ? "/meetings" : "/instances",
    });
  }

  const options = [];

  options.push({
    icon: <Assessment />,
    name: "Reports",
    onClick: () => {
      history.push("/reports");
    },
  });

  options.push({
    icon: <Settings />,
    name: "Settings",
    onClick: () => {
      history.push("/settings/app");
    },
  });

  options.push({
    icon: <Logout />,
    name: "Logout",
    onClick: () => {
      localStorage.setItem(MANUAL_LOGOUT_KEY, "true");
      history.push("/logout");
    },
  });

  const additionalOptions = [
    {
      icon: <Group />,
      name: "Switch Users",
      onClick: () => {
        history.push("/admin/users");
      },
    },
    {
      icon: <Storage />,
      name: "Provisioning",
      onClick: () => {
        history.push("/admin/provisioning");
      },
    },
    {
      icon: <Business />,
      name: "Org Settings",
      onClick: () => {
        history.push("/admin/orgSettings");
      },
    },
    {
      icon: <Assistant />,
      name: "Merlin Chat",
      onClick: () => {
        history.push("/admin/merlin");
      },
    },
  ];

  const additionalOptionsToShow = [SuperAdminRole, SuperAdminRORole].includes(
    user?.role || 0
  )
    ? additionalOptions
    : [];

  return (
    <AppBar
      position="sticky"
      sx={{ background: theme.palette.background.paper, zIndex: 10 }}
      elevation={1}
    >
      <Toolbar variant="dense">
        <Box sx={{ display: { md: "flex", xs: "none" }, mr: 3 }}>
          <Link component={RouterLink} to="/">
            <KMeIcon />
          </Link>
        </Box>
        <Box
          sx={{
            display: { md: "none", xs: "flex" },
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <CollapsedMenu pages={pages} />
          <Link component={RouterLink} to="/">
            <KMeIcon />
          </Link>
        </Box>
        <Box
          sx={{
            display: { md: "none", xs: "flex" },
            flexGrow: 1,
            mr: 1,
          }}
        ></Box>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            flexGrow: 1,
          }}
        >
          <ExpandedMenu pages={pages} />
        </Box>

        {quickLaunch && <KMeQuickLaunch />}

        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            ml: 2,
            mr: 2,
            flexGrow: 0,
          }}
        >
          <Button
            size="small"
            startIcon={<HelpIcon />}
            onClick={handleOpenHelpNavMenu}
            sx={{
              color: theme.palette.text.primary,
              textTransform: "none",
            }}
          >
            Help
          </Button>

          <Menu
            id="help-menu"
            anchorEl={anchorElHelpNav}
            open={Boolean(anchorElHelpNav)}
            onClose={handleCloseHelpNavMenu}
          >
            <MenuItem
              onClick={handleCloseHelpNavMenu}
              href="https://www.kronologic.com/how-to-guides"
              target="_blank"
              component="a"
            >
              <ListItemIcon>
                <OndemandVideoIcon />
              </ListItemIcon>
              <ListItemText>How To Videos</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleCloseHelpNavMenu}
              href="mailto:support@kronologic.me"
              target="_blank"
              component="a"
            >
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText>Support</ListItemText>
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <UserMenu
            user={user}
            options={options}
            additionalOptions={additionalOptionsToShow}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

function CollapsedMenu({ pages }: { pages: Page[] }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const history = useHistory();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { md: "none", xs: "block" },
        }}
      >
        {pages.map((page) => (
          <MenuItem
            key={page.name}
            onClick={() => {
              history.push(page.route);
              handleCloseNavMenu();
            }}
            sx={{
              color: location.pathname.startsWith(page.route)
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          >
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
        {pages.length === 0 && (
          <MenuItem disabled>
            <Typography textAlign="center">None</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function ExpandedMenu({ pages }: { pages: Page[] }) {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  return (
    <>
      {pages.map((page) => (
        <Button
          size="small"
          startIcon={page.icon}
          key={page.name}
          onClick={() => history.push(page.route)}
          sx={{
            color: location.pathname.startsWith(page.route)
              ? theme.palette.primary.main
              : theme.palette.text.primary,

            mr: 1,
            my: 0,
            textTransform: "none",
          }}
        >
          {page.name}
        </Button>
      ))}
    </>
  );
}
