import React, { useMemo } from "react";
import cx from "classnames";
import Flexbox from "../../../components/flexbox/FlexboxV2";
import { H6, P1 } from "../../../components/typography";
import { useMeeting, useMeetingTypeState } from "../state";
import { formatCurrency } from "../../../utils/format";
import {
  COMPLETED_STAGE,
  INSTANCE_STAGE_TO_LABEL,
  SCHEDULED_STAGE,
  toInstanceStatusColor,
} from "../../props";
import { MediumSpacing } from "../../../components/spacing";
import style from "./statusBanner.module.scss";

const SHOW_VALUE_STATUSES = [SCHEDULED_STAGE, COMPLETED_STAGE];

const StatusBanner = () => {
  const [meeting] = useMeeting();
  const { meetingType } = useMeetingTypeState();

  const statusColor = useMemo(
    () => ({
      backgroundColor: `${toInstanceStatusColor(meeting.status)}`,
    }),
    [meeting]
  );

  const meetingId = useMemo(() => {
    if (meeting.id !== null) {
      return meeting.id;
    }
    return "---";
  }, [meeting]);

  const meetingStatusLabel = useMemo(
    () => INSTANCE_STAGE_TO_LABEL[meeting.status],
    [meeting]
  );

  const showMeetingValue = useMemo(
    () => SHOW_VALUE_STATUSES.includes(meeting.status),
    [meeting]
  );

  const meetingValue = useMemo(() => {
    if (showMeetingValue) {
      return formatCurrency(meetingType.cost);
    }
    return <span>&mdash;</span>;
  }, [showMeetingValue, meetingType]);

  return (
    <div className={style.statusHeader} style={statusColor} height="5%">
      <Flexbox.Row className={style.statusHeader__instance_id}>
        <P1 bold className={style.statusHeader_instance_id__title} extraSmall>
          Meeting ID:
        </P1>
        <MediumSpacing />
        <P1 bold className={style.statusHeader_instance_id__value} extraSmall>
          {meetingId}
        </P1>
      </Flexbox.Row>

      <H6 className={style.statusHeader__status} medium bold>
        {meetingStatusLabel}
      </H6>

      <P1
        bold
        className={cx(style.statusHeader_value__text, {
          [style.greyed_out]: !showMeetingValue,
        })}
      >
        {meetingValue}
      </P1>
    </div>
  );
};

export default StatusBanner;
