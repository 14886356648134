import { useCallback, useMemo } from "react";

import { useCustomFetchFn } from "../components/fetch";
import { API } from "../props";
import { buildRequest, buildUrl } from "../utils/fetchV2";
import useDoFetch from "./useDoFetch";
import useMultiQuery from "./useMultiQuery";

function buildPublicCalendarsRequest() {
  return buildRequest(API.calendar.publicCalendars);
}

function buildPreviewCalendarRequest(calendarId, search) {
  return buildRequest(
    buildUrl(API.calendar.previewCalendar(calendarId), search),
    {
      method: "PUT",
    }
  );
}

function buildShareCalendarWithUsersRequest(calendarIds, userIds) {
  return buildRequest(API.calendar.shareCalendarsWithUsers, {
    body: JSON.stringify(
      {
        calendarIds,
        userIds,
      },
      null,
      4
    ),
    method: "PUT",
  });
}

function buildShareCalendarWithTeamsRequest(calendarIds, teamIds) {
  return buildRequest(API.calendar.shareCalendarsWithTeams, {
    body: JSON.stringify(
      {
        calendarIds,
        teamIds,
      },
      null,
      4
    ),
    method: "PUT",
  });
}

function buildListCalendarEventsRequest(userId, start, end) {
  return buildRequest(
    buildUrl(API.calendar.listEvents, {
      end,
      start,
      userId,
    })
  );
}

function buildGetCalendarEventRequest(id) {
  return buildRequest(API.calendar.getEvent(id));
}

function usePublicCalendars() {
  const publicCalendarRequests = useMemo(() => {
    return [buildPublicCalendarsRequest()];
  }, []);
  const {
    errors: [publicCalendarsError],
    isLoading: arePublicCalendarsLoading,
    payloads: [publicCalendarsPayload = null],
  } = useDoFetch(publicCalendarRequests);
  const publicCalendars = useMemo(() => {
    if (publicCalendarsPayload === null) {
      return [];
    }
    return publicCalendarsPayload;
  }, [publicCalendarsPayload]);
  return {
    arePublicCalendarsLoading,
    publicCalendars,
    publicCalendarsError,
  };
}

function usePreviewCalendars(allDayOnly, calendarIds) {
  const previewCalendarRequests = useMemo(() => {
    return calendarIds.map((calendarId) => {
      return buildPreviewCalendarRequest(calendarId, { allDayOnly });
    });
  }, [allDayOnly, calendarIds]);
  const {
    errors: calendarPreviewErrors,
    isLoading: isCalendarPreviewLoading,
    payloads: calendarPreviewPayloads,
  } = useMultiQuery(previewCalendarRequests);
  const calendarPreviews = useMemo(() => {
    return calendarIds.reduce((acc, calendarId, index) => {
      acc[calendarId] = {
        data: calendarPreviewPayloads[index]?.data || null,
        error: calendarPreviewErrors[index] || null,
        isLoading: isCalendarPreviewLoading[index] || false,
      };
      return acc;
    }, {});
  }, [
    calendarIds,
    calendarPreviewPayloads,
    calendarPreviewErrors,
    isCalendarPreviewLoading,
  ]);
  return {
    calendarPreviews,
  };
}

export function useCalendarsRepository() {
  const { fetch } = useCustomFetchFn();
  const shareCalendarWithUsers = useCallback(
    (calendarIds, userIds) => {
      return fetch(buildShareCalendarWithUsersRequest(calendarIds, userIds));
    },
    [fetch]
  );
  const shareCalendarWithTeams = useCallback(
    (calendarIds, userIds) => {
      return fetch(buildShareCalendarWithTeamsRequest(calendarIds, userIds));
    },
    [fetch]
  );

  const listCalendarEvents = useCallback(
    ({ userId = 0, start = null, end = null }) =>
      fetch(buildListCalendarEventsRequest(userId, start, end)).then((r) =>
        r.json()
      ),
    [fetch]
  );

  const getCalendarEvent = useCallback(
    ({ id }) => fetch(buildGetCalendarEventRequest(id)).then((r) => r.json()),
    [fetch]
  );

  return {
    getCalendarEvent,
    listCalendarEvents,
    shareCalendarWithTeams,
    shareCalendarWithUsers,
    usePreviewCalendars,
    usePublicCalendars,
  };
}
