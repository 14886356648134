import React, { useCallback, useState, useRef, useMemo } from "react";
import cx from "classnames";
import { Icon } from "@mdi/react";
import { mdiMagnify, mdiClose } from "@mdi/js";
import { InputAdornment } from "@mui/material";
import { FilterButton } from "./FilterButton";
import Flexbox from "../flexbox";
import Input from "../../componentsV2/inputs/Input";
import style from "./style.module.scss";

function getSearchHistory() {
  let history = [];
  if (localStorage.searchHistory) {
    try {
      history = JSON.parse(localStorage.searchHistory);
    } catch {
      history = [];
    }

    if (!Array.isArray(history)) {
      history = [];
    }
  }

  return history;
}

function saveSearchHistory(history) {
  let h = Array.isArray(history) ? history : [];

  // Drop any empty values.
  h = h.filter((e) => e.length && e.length > 0);

  // Remove duplicates.
  h = h.filter((value, index, self) => self.indexOf(value) === index);

  // Keep only 5 items.
  if (h.length > 5) {
    h = h.slice(0, 5);
  }

  localStorage.searchHistory = JSON.stringify(h);
}

function EmptyList() {
  return (
    <ul className={style.searchBar__recentSearchesContainerList}>
      <li className={style.searchBar__recentSearchesEmptyListItem}>None</li>
    </ul>
  );
}

function List({ items, onClick }) {
  if (items.length < 1) {
    return <EmptyList />;
  }

  return (
    <ul className={style.searchBar__recentSearchesContainerList}>
      {items.map((s) => (
        <li key={s} className={style.searchBar__recentSearchesListItem}>
          <button
            type="button"
            className={style.searchBar__recentSearchesListItemButton}
            onClick={() => {
              onClick(s);
            }}
          >
            {s}
          </button>
        </li>
      ))}
    </ul>
  );
}

export function SearchBar({
  value,
  filters,
  onChange,
  onFilterChange,
  width,
  customFormClass,
  customInputClass,
}) {
  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), []);

  const handleSearchSubmit = useCallback((e) => e.preventDefault(), []);

  const recent = getSearchHistory();
  const timer = useRef();

  const filterList = useMemo(() => {
    const finalList = [];
    if (filters) {
      Object.keys(filters).forEach((k) => {
        if (filters[k]) {
          finalList.push(k);
        }
      });
    }
    return finalList.join(", ");
  }, [filters]);

  const setSearchHistory = useCallback((v) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      saveSearchHistory(v);
    }, 750);
  }, []);

  if (open) {
    window.addEventListener("click", close);
  } else {
    window.removeEventListener("click", close);
  }

  const filterCount = Object.values(filters).reduce(
    (a, b) => (b ? a + 1 : a),
    0
  );

  const onSearchTextChangeHandler = useCallback(
    (text) => {
      setSearchHistory([text].concat(recent));
      onChange(text);
    },
    [onChange, recent, setSearchHistory]
  );

  const onInputChangeHandler = (event) => {
    onSearchTextChangeHandler(event.target.value);
  };

  const onClickHandler = useCallback(
    (event) => {
      setOpen(true);
      event.stopPropagation();
    },
    [setOpen]
  );

  const onFocusHandler = useCallback(() => setOpen(true), [setOpen]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        setOpen(false);
      }
    },
    [setOpen]
  );

  return (
    <Flexbox>
      {({ Row }) => (
        <Row width={width}>
          <div className={cx(style.searchBar, customFormClass)}>
            <form
              onSubmit={handleSearchSubmit}
              className={style.searchBar__form}
            >
              <label htmlFor="qrySearch" className={style.searchBar__formLabel}>
                <Input
                  name="/instances/action_bar/search"
                  className={cx(
                    style.searchBar__formLabelInput,
                    customInputClass
                  )}
                  value={value}
                  placeholder={`Search by ${filterList}`}
                  onChange={onInputChangeHandler}
                  onClick={onClickHandler}
                  onFocus={onFocusHandler}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {filterCount > 0 && (
                          <p className={style.searchBar__formLabelP}>
                            {filterCount}
                            {" filter"}
                            {filterCount > 1 ? "s" : ""}
                          </p>
                        )}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          path={mdiMagnify}
                          size={0.7}
                          className={style.searchBar__formLabelIcon}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </label>
            </form>

            {open ? (
              <div
                className={style.searchBar__dropdown}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div className={style.searchBar__section}>
                  <button
                    type="button"
                    className={style.searchBar__closeButton}
                    onClick={() => setOpen(false)}
                  >
                    <Icon
                      name="/instances/action_bar/search/close_tab"
                      path={mdiClose}
                      size={0.5}
                      className={style.searchBar__closeButtonIcon}
                    />
                  </button>
                  <p className={style.searchBar__category}>SEARCH BY</p>
                  <div className={style.searchBar__filtersContainer}>
                    {Object.keys(filters).map((f) => (
                      <FilterButton
                        key={f}
                        value={f}
                        onClick={() => {
                          const temp = { ...filters };
                          temp[f] = !filters[f];
                          onFilterChange(temp);
                        }}
                        selected={filters[f]}
                      />
                    ))}
                  </div>
                </div>
                <hr />
                <div className={style.searchBar__section}>
                  <p className={style.searchBar__category}>RECENT SEARCHES</p>
                  <div className={style.searchBar__recentSearchesContainer}>
                    <List items={recent} onClick={onSearchTextChangeHandler} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Row>
      )}
    </Flexbox>
  );
}
