import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import "src/componentsV2/TipTap/styles.scss";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { useOpenAiIntegration } from "../../../features";
import { Input } from "../../inputs/Input";
import { OpenAiTextInput } from "./OpenAiTextInput";
import { State } from "./State";
import { EmailEditor, cleanRichTextHTML } from "src/componentsV2/inputs";

type EditMessageProps = {
  state: State;
  setState: (state: State) => void;
  next: () => void;
  previous: () => void;
};

function EditMessage({ state, setState, next, previous }: EditMessageProps) {
  const [tab, setTab] = useState(0);
  const openAiEnabled = useOpenAiIntegration();

  const onEmailBodyChange = (v: string) => {
    setState({
      ...state,
      overrides: {
        ...state.overrides,
        emailBodyTemplate: v || "",
      },
    });
  };

  return (
    <>
      <DialogContent>
        <Stack
          sx={{
            p: 2,
            height: "600px",
          }}
        >
          <Tabs
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
            centered
          >
            <Tab label="Email" />
            <Tab label="Calendar Invite" />
          </Tabs>
          <Divider flexItem />
          <Stack spacing={2} sx={{ py: 2 }}>
            {tab === 0 && (
              <>
                <Input
                  label="Subject"
                  value={state.overrides.emailSubjectTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        emailSubjectTemplate: e.target.value,
                      },
                    });
                  }}
                />
                <Box sx={{ px: "1px" }}>
                  <EmailEditor
                    label="Email body"
                    value={state.overrides.emailBodyTemplate || ""}
                    onChange={onEmailBodyChange}
                    withMergeFields
                  />
                </Box>
                {openAiEnabled && (
                  <OpenAiTextInput
                    onAiResponse={(value: string) => {
                      const cleaned = cleanRichTextHTML(value);
                      onEmailBodyChange(cleaned);
                    }}
                  />
                )}
              </>
            )}
            {tab === 1 && (
              <>
                <Input
                  label="Title"
                  value={state.overrides.inviteTitleTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        inviteTitleTemplate: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  label="Description"
                  multiline
                  minRows={14}
                  value={state.overrides.inviteBodyTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        inviteBodyTemplate: e.target.value,
                      },
                    });
                  }}
                />
                {openAiEnabled && (
                  <OpenAiTextInput
                    onAiResponse={(value: string) => {
                      setState({
                        ...state,
                        overrides: {
                          ...state.overrides,
                          inviteBodyTemplate: value.trimStart(),
                        },
                      });
                    }}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          sx={{ pt: 2, width: "100%" }}
        >
          <PrimaryButton
            disabled={
              state.overrides.emailSubjectTemplate === "" ||
              state.overrides.emailBodyTemplate === "" ||
              state.overrides.inviteTitleTemplate === "" ||
              state.overrides.inviteBodyTemplate === ""
            }
            onClick={() => next()}
          >
            Next
          </PrimaryButton>
          <PrimaryButton onClick={() => previous()}>Prev</PrimaryButton>
        </Stack>
      </DialogActions>
    </>
  );
}

export default EditMessage;
