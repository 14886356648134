import React, { useCallback } from "react";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import shortid from "shortid";
import Button from "../button/Button";
import style from "./pagination.module.scss";
import { usePageNumbers } from "./usePageNumbers";
import { usePagination } from "./usePagination";

export function Pagination({
  isDisabled = false,
  pageDelta,
  pageSize,
  offset,
  setOffset,
  total,
}) {
  const {
    goToNextPage,
    goToPage,
    goToPreviousPage,
    hasNextPage,
    hasPreviousPage,
    pageIndex,
    pageOptions,
  } = usePagination(total, offset, setOffset, pageSize);

  const clickPage = useCallback(
    (pageNumber) => {
      goToPage(pageNumber);
    },
    [goToPage]
  );

  const pageNumbers = usePageNumbers(
    pageIndex + 1,
    pageOptions.length,
    pageDelta
  );

  return (
    <div className={style.pagination}>
      <div className={style.controls}>
        <Button
          name="/pagination/previous_page"
          type="secondary"
          onClick={goToPreviousPage}
          disabled={!hasPreviousPage || isDisabled}
        >
          <Icon path={mdiChevronLeft} size={1} />
        </Button>
        <div className={style.pages}>
          {pageNumbers.map((i) => {
            if (i !== "...") {
              return (
                <Button
                  name="/pagination/page_number"
                  key={`page-${i}`}
                  disabled={isDisabled}
                  type="tertiary"
                  onClick={() => clickPage(i - 1)}
                  active={pageIndex === i - 1}
                >
                  {i}
                </Button>
              );
            }

            return (
              <span
                className={style.elipsis}
                key={`page-${i}-${shortid.generate()}`}
              >
                {i}
              </span>
            );
          })}
        </div>
        <Button
          name="/pagination/next_page"
          type="secondary"
          onClick={goToNextPage}
          disabled={!hasNextPage || isDisabled}
        >
          <Icon path={mdiChevronLeft} rotate={180} size={1} />
        </Button>
      </div>
    </div>
  );
}
