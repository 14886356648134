import React, { useReducer } from "react";
import {
  meetingTypeReducer,
  DEFAULT_MEETING_TYPE_STATE,
  MeetingTypeStateContext,
} from "./meetingTypeState";
import {
  messengerReducer,
  DEFAULT_MESSENGER,
  MessengerStateContext,
} from "./messengerState";

const StateManager = ({ children }) => {
  const [meetingType, meetingTypeDispatch] = useReducer(
    meetingTypeReducer,
    DEFAULT_MEETING_TYPE_STATE
  );
  const [messenger, messengerDispatch] = useReducer(
    messengerReducer,
    DEFAULT_MESSENGER
  );

  return (
    <MeetingTypeStateContext.Provider
      value={{
        meetingType,
        meetingTypeDispatch,
      }}
    >
      <MessengerStateContext.Provider value={{ messenger, messengerDispatch }}>
        {children}
      </MessengerStateContext.Provider>
    </MeetingTypeStateContext.Provider>
  );
};

export default StateManager;
