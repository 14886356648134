import { useCallback, useRef, useState } from "react";
import { useCustomFetchFn } from "../components/fetch";
import { buildRequest } from "../utils/fetchV2";
import { API } from "../props";

const buildGetLogsByMeetingIdRequest = (id) =>
  buildRequest(API.customerLogs.meeting(id));

const useLogsRepository = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { fetch } = useCustomFetchFn();

  const doFetch = useCallback(
    async (request) => {
      try {
        setIsLoading(true);

        const response = await fetch(request);
        const { status } = response;

        if (status === 204) {
          return {
            data: null,
            error: null,
          };
        }

        return {
          data: await response.json(),
          error: null,
        };
      } catch (error) {
        return {
          data: null,
          error,
        };
      } finally {
        setIsLoading(false);
      }
    },
    [fetch]
  );

  const { current } = useRef({
    getLogsByMeetingId: (id) => doFetch(buildGetLogsByMeetingIdRequest(id)),
  });

  return { ...current, isLoading };
};

export default useLogsRepository;
