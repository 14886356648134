import React, { createContext, useCallback } from "react";
import shortid from "shortid";

export const NotificationCenterControllersContext = createContext();

export const NotificationCenterControllers = ({
  setNotifications = () => {},
  children,
}) => {
  const removeNotification = useCallback(
    (id) => {
      setNotifications((prev) => {
        const newState = new Map(prev);
        newState.delete(id);

        return newState;
      });
    },
    [setNotifications]
  );

  const dispatchNotification = useCallback(
    (wrapper, body, userDismiss, wait, unique) => {
      const id = unique ? JSON.stringify(body) : shortid();

      setNotifications(
        (prev) =>
          new Map(
            prev.set(id, {
              Wrapper: wrapper,
              body,
              props: {
                id,
                userDismiss,
                wait,
              },
            })
          )
      );

      return id;
    },
    [setNotifications]
  );

  return (
    <>
      <NotificationCenterControllersContext.Provider
        value={{ dispatchNotification, removeNotification }}
      >
        {children}
      </NotificationCenterControllersContext.Provider>
    </>
  );
};
