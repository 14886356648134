import React, { useCallback, useMemo, useState } from "react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import style from "./style.module.scss";
import { Input } from "../../../components/input";
import { ButtonContainer } from "../../../components/button";

function SendLater({ bulkActivateLater, close, selectedMeetingCount }) {
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const onSingleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  const onFocusChange = useCallback(({ focused }) => {
    setIsFocused(focused);
  }, []);

  const onChangeTime = useCallback((e) => {
    setSelectedTime(e.target.value);
  }, []);

  const activateWithTime = useCallback(() => {
    bulkActivateLater(selectedDate.format(`YYYY-MM-DDT${selectedTime}:ssZ`));
    close();
  }, [bulkActivateLater, close, selectedDate, selectedTime]);

  const confirmDisabled = useMemo(
    () => selectedTime.length < 5 || !selectedDate,
    [selectedDate, selectedTime]
  );

  const selectedMeetings = useMemo(
    () =>
      `${selectedMeetingCount} selected meeting${
        selectedMeetingCount === 1 ? `` : `s`
      }`,
    [selectedMeetingCount]
  );

  return (
    <div className={style.sendLaterModal}>
      <div className={style.sendLater}>
        <div className={style.topBar}>
          <div className={style.title}>Send Later</div>
          <div className={style.closeIcon}>
            <Icon
              path={mdiClose}
              size={1}
              className={style.close}
              onClick={close}
            />
          </div>
        </div>
        <div className={style.pleaseChooseATime}>
          Please choose a date and time to execute the selected meetings
        </div>
        <div className={style.dateTimeArea}>
          <div className={style.dateArea}>
            <SingleDatePicker
              regular
              date={selectedDate}
              focused={isFocused}
              onFocusChange={onFocusChange}
              onDateChange={onSingleDateChange}
              id="sendLater/singleDatePicker"
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
            />
          </div>
          <div className={style.timeArea}>
            <Input
              medium
              type="time"
              value={selectedTime}
              onChange={onChangeTime}
            />
          </div>
        </div>
        <div className={style.selectedMeetings}>{selectedMeetings}</div>
        <div className={style.belowArea}>
          <ButtonContainer
            primary
            large
            onClick={activateWithTime}
            disabled={confirmDisabled}
          >
            Confirm
          </ButtonContainer>
        </div>
      </div>
    </div>
  );
}

export default SendLater;
