import React, { forwardRef, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { ButtonContainer, ButtonIcon } from "../button";
import style from "./list.module.scss";
import Flexbox from "../flexbox";

const List = forwardRef(
  (
    {
      children,
      wrapperClassName,
      bodyClassName,
      collapsable = false,
      scrollable = false,
      wrapperWidth = "100%",
      wrapperHeight = "100%",
      bodyWidth = "100%",
      bodyHeight = "100%",
      list = [],
      style: s,
      ...props
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState(false);

    const icon = useMemo(
      () => (expanded ? mdiChevronUp : mdiChevronDown),
      [expanded]
    );

    const handleExpandingOnClick = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);

    return (
      <Flexbox>
        {({ Column }) => (
          <Column
            className={cx(style.list, wrapperClassName)}
            width={wrapperWidth}
            height={wrapperHeight}
            style={s}
            ref={ref}
          >
            <Column
              className={cx(style.list__body, bodyClassName, {
                [style["list__body--collapsed"]]: collapsable && !expanded,
                [style["list__body--expanded"]]: collapsable && expanded,
                [style["list__body--scrollable"]]: scrollable,
              })}
              width={bodyWidth}
              height={bodyHeight}
              {...props}
            >
              {list.map((li, i) => children(li, i))}
            </Column>

            {collapsable && (
              <ButtonContainer
                className={cx(style.list__action, {
                  [style["list__action--expanded"]]: expanded,
                })}
                onClick={handleExpandingOnClick}
              >
                <ButtonIcon
                  className={style.list_action__icon}
                  path={icon}
                  size={2}
                />
              </ButtonContainer>
            )}
          </Column>
        )}
      </Flexbox>
    );
  }
);

export default List;
