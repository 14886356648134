import { useEffect, useState } from "react";
import { IconButton, Paper, Stack } from "@mui/material";
import { SwapHoriz } from "@mui/icons-material";

import { Input } from "src/componentsV2/inputs/Input";
import { Organization, roleToString } from "src/types";
import { OrganizationSelectInput } from "src/componentsV2/inputs";
import { PageLayout } from "src/componentsV2/PageLayout";
import { Pagination } from "src/componentsV2/Pagination";
import { Table } from "src/componentsV2/Table";
import { useActingAs } from "src/auth";
import { useDebounce } from "src/hooks";
import { useUsersAll } from "src/queries";
import ErrorPage from "src/pages/ErrorPage";
import { TableSort } from "src/componentsV2/tables/Table";
import { useHistory } from "react-router-dom";

function SwitchUsers() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [org, setOrg] = useState<Organization | null>(null);
  const [sort, setSort] = useState<TableSort>({
    columnId: "role",
    order: "asc",
  });

  // Reset to first page if the organization changes.
  useEffect(() => {
    setPage(0);
  }, [org]);

  const [, setActingAs] = useActingAs();

  const PAGE_SIZE = 10;

  const s = useDebounce(search, 750);

  const { data, loading, error } = useUsersAll(
    PAGE_SIZE,
    page,
    org?.schemaName || null,
    {
      query: s,
      sort: {
        field: sort.columnId as "firstName" | "lastName" | "email" | "role",
        order: sort.order,
      },
    }
  );

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  const users = data?.data || [];
  const count = data?.total || 0;

  return (
    <PageLayout title="Switch Users">
      <Stack spacing={2}>
        <OrganizationSelectInput value={org} onChange={setOrg} />
        {org && (
          <>
            <Input
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Paper>
              <Table
                loading={loading}
                columns={[
                  {
                    id: "id",
                    label: "Id",
                    component: (row) => <>{row.id}</>,
                  },
                  {
                    id: "firstName",
                    label: "First",
                    component: (row) => <>{row.firstName}</>,
                    sortable: true,
                  },
                  {
                    id: "lastName",
                    label: "Last",
                    component: (row) => <>{row.lastName}</>,
                    sortable: true,
                  },
                  {
                    id: "email",
                    label: "Email",
                    component: (row) => <>{row.email}</>,
                    sortable: true,
                  },
                  {
                    id: "role",
                    label: "Role",
                    component: (row) => <>{roleToString(row.role)}</>,
                    sortable: true,
                  },
                  {
                    id: "actAs",
                    label: "Act As",
                    component: (u) => (
                      <IconButton
                        onClick={() => {
                          setActingAs({
                            id: u.id,
                            firstName: u.firstName,
                            lastName: u.lastName,
                            email: u.email,
                            org: org.schemaName,
                            role: u.role,
                          });
                          history.push("/instances");
                        }}
                      >
                        <SwapHoriz />
                      </IconButton>
                    ),
                  },
                ]}
                data={users}
                minRows={PAGE_SIZE}
                sort={sort}
                onSortChange={setSort}
              />
              <Pagination
                pages={Math.ceil(count / PAGE_SIZE)}
                currentPage={page + 1}
                onPageChange={(p) => setPage(p - 1)}
              />
            </Paper>
          </>
        )}
      </Stack>
    </PageLayout>
  );
}

export default SwitchUsers;
