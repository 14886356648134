import React, { useCallback, useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import { useInstanceStageOptions } from "../props";
import { Select } from "../../inputs";
import { useSearch, useSearchUpdater } from "../Search";
import { useFilterMenuContext } from "../context";
import { useDropdownTheme } from "./useDropdownTheme";
import style from "./style.module.scss";

function noInstanceStageOptions() {
  return "No Meeting Stage options left.";
}

export function InstanceStageFilter({ menuPortalTarget }) {
  const [components, theme] = useDropdownTheme();
  const instanceStageOptions = useInstanceStageOptions();
  const { setFilterMenuClosed, setFilterMenuOpen } = useFilterMenuContext();
  const {
    filter: { meetingStatusFilter },
  } = useSearch();
  const searchUpdater = useSearchUpdater();

  const onInstanceStageFilterChange = useCallback(
    (values) => {
      if (values === null) {
        searchUpdater.setMeetingStatusFilter([]);
        return;
      }
      const newMeetingStatusFilter = values.map((v) => v.value);
      searchUpdater.setMeetingStatusFilter([...newMeetingStatusFilter]);
    },
    [searchUpdater]
  );

  const instanceStageValues = useMemo(() => {
    if (meetingStatusFilter.length === 0) {
      return null;
    }
    return instanceStageOptions.filter((option) => {
      return meetingStatusFilter.includes(option.value);
    });
  }, [meetingStatusFilter, instanceStageOptions]);

  const placeholder = useMemo(() => {
    return (
      <div className={style.meetingType__filterPlaceHolder}>
        <div className={style.meetingType__filterPlaceHolderText}>
          Meeting Stages
        </div>
        <Icon
          className={style.meetingType__filterPlaceHolderIcon}
          path={mdiChevronDown}
          size={0.5}
        />
      </div>
    );
  }, []);

  return useMemo(() => {
    return (
      <Select
        closeMenuOnScroll
        components={components}
        customTheme={theme}
        isMulti
        label="Meeting Stages"
        menuPortalTarget={menuPortalTarget}
        name="/instances/action_bar/instance_stages_dropdown"
        noOptionsMessage={noInstanceStageOptions}
        onChange={onInstanceStageFilterChange}
        onMenuOpen={setFilterMenuOpen}
        onMenuClose={setFilterMenuClosed}
        options={instanceStageOptions}
        placeholder={placeholder}
        value={instanceStageValues}
      />
    );
  }, [
    components,
    instanceStageOptions,
    instanceStageValues,
    menuPortalTarget,
    onInstanceStageFilterChange,
    placeholder,
    setFilterMenuClosed,
    setFilterMenuOpen,
    theme,
  ]);
}
