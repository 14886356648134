import React, { useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";

type Units = "hours" | "minutes";

export function MinimumSchedulingNoticeInput({
  value = 180,
  onChange,
}: {
  value?: number | null;
  onChange?: (value: number | null) => void;
}) {
  const [units, setUnits] = useState<Units>("hours");

  const input = value
    ? units === "hours"
      ? Math.round(value / 60)
      : value
    : 0;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!onChange) {
      return;
    }

    if (e.target.value === "") {
      onChange(null);
      return;
    }

    if (units === "hours") {
      onChange(Number.parseInt(e.target.value) * 60);
    } else {
      onChange(Number.parseInt(e.target.value));
    }
  };

  const handleUnitChange = (u: Units) => {
    if (!onChange) {
      return;
    }

    if (u === "hours") {
      onChange(Math.round(input / 60) * 60);
    } else {
      onChange(value);
    }

    setUnits(u);
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <RadioGroup
        defaultValue="minutes"
        value={units}
        onChange={(_, v) => {
          handleUnitChange(v as Units);
        }}
      >
        <FormControlLabel
          value="hours"
          control={<Radio size="small" />}
          label="Hours"
        />
        <FormControlLabel
          value="minutes"
          control={<Radio size="small" />}
          label="Minutes"
        />
      </RadioGroup>
      <TextField
        size="small"
        label="Time"
        value={value === null ? "" : input}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        sx={{ maxWidth: "6rem" }}
        onChange={handleInputChange}
      />
    </Stack>
  );
}
