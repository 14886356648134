type UserPermission = number;
type UserPermissions = number;

const USER_PERMISSIONS = {
  CHANGE_MEETING_OWNER: 1 as UserPermission,
};

function userHasPermission(
  user: { permissions: UserPermissions },
  p: UserPermission
): boolean {
  // Using bitwise & here to check user permission
  // since BE sends all user permission as a bit-map.
  return ((user.permissions || 0) & p) > 0;
}

function setUserPermission(
  user: { permissions: UserPermissions },
  p: UserPermission
): UserPermissions {
  return (user.permissions || 0) | p;
}

function unsetUserPermission(
  user: { permissions: UserPermissions },
  p: UserPermission
): UserPermissions {
  return (user.permissions || 0) & ~p;
}

export function userCanChangeMeetingOwner(user: {
  permissions: UserPermissions;
}): boolean {
  return userHasPermission(user, USER_PERMISSIONS.CHANGE_MEETING_OWNER);
}

export function setUserCanChangeMeetingOwner(user: {
  permissions: UserPermissions;
}): UserPermissions {
  return setUserPermission(user, USER_PERMISSIONS.CHANGE_MEETING_OWNER);
}

export function unsetUserCanChangeMeetingOwner(user: {
  permissions: UserPermissions;
}): UserPermissions {
  return unsetUserPermission(user, USER_PERMISSIONS.CHANGE_MEETING_OWNER);
}
