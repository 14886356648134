import React, { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";

function DropdownIndicator() {
  return <span />;
}

export function useDropdownTheme() {
  return useMemo(
    () => [
      { DropdownIndicator },
      {
        container: (base) => ({
          ...base,
          backgroundColor: "#e6e6e6",
          height: "100%",
          paddingLeft: "0px",
          paddingRight: "0px",
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: "inherit",
          border: 0,
          borderRadius: 0,
          boxShadow: 0,
          fontSize: "12px",
          height: "100%",
          minHeight: "27px",
          padding: "0px",
          width: "100%",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: "inherit",
          width: 0,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          padding: "0px",
        }),
        input: (base) => ({
          ...base,
          color: BLACK,
          fontSize: "12px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: WHITE,
          boxShadow: "none",
          marginTop: 2,
        }),
        menuList: (base) => ({
          ...base,
          border: "0.5px solid #A8B1BD",
          borderRadius: 0,
          padding: "0px",
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: WHITE,
          borderRadius: "2px",
          color: BLACK,
          margin: "2px",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? "#F1FAFF" : WHITE,
          border: "0.5px solid #A8B1BD",
          color: BLACK,
          cursor: "pointer",
          padding: "7px",
        }),
        singleValue: (base) => ({
          ...base,
          color: BLACK,
        }),
        valueContainer: (base) => ({
          ...base,
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          height: "100%",
          justifyContent: "center",
          padding: "0px",
        }),
      },
    ],
    []
  );
}
