import { useCallback, useRef } from "react";
import { buildRequest } from "../utils/fetchV2";
import { API } from "../props";
import { PATCH, POST, useCustomFetchFn } from "../components/fetch";
import { useQuery } from "../components/query/useQuery";

const buildPatchMeetings = (meetings) =>
  buildRequest(API.meetings.instance, {
    body: JSON.stringify(meetings),
    method: PATCH,
  });

const buildOverrideStartTimeRequest = (payload) =>
  buildRequest(API.meetings.overrideStartTime, {
    body: JSON.stringify(payload),
    method: POST,
  });

const buildScheduleAttemptNowRequest = (meetingId) =>
  buildRequest(API.meetings.queueNow, {
    body: JSON.stringify([meetingId]),
    method: PATCH,
  });

const buildRouteMeetingsRequest = (meetingIds) =>
  buildRequest(API.meetings.routing, {
    body: JSON.stringify(meetingIds),
    method: POST,
  });

function buildOneClickMeetingUrl(payload) {
  return buildRequest(API.meetings.scheduling.url, {
    body: JSON.stringify(payload),
    method: POST,
  });
}

const buildHostAvailabilityRequest = (reqBody) =>
  buildRequest(API.meetings.hostAvailability, {
    body: JSON.stringify(reqBody),
    method: POST,
  });

function useActivateOneClick(payload) {
  return useQuery(API.meetings.scheduling.default, {
    body: payload,
    method: POST,
  });
}

const buildChangeHostRequest = ({ body }) =>
  buildRequest(API.meetings.changeHost.default, {
    body: JSON.stringify(body),
    method: POST,
  });

const buildIsHostAvailableRequest = ({ body }) =>
  buildRequest(API.meetings.hostAvailable, {
    body: JSON.stringify(body),
    method: POST,
  });

export function useMeetingRepository() {
  const { fetch, fetchJson } = useCustomFetchFn();

  const patchMeetings = useCallback(
    function patchMeetings(meetings) {
      return fetch(buildPatchMeetings(meetings));
    },
    [fetch]
  );

  const overrideStartTime = useCallback(
    function overrideStartTime(payload) {
      return fetch(buildOverrideStartTimeRequest(payload));
    },
    [fetch]
  );

  const scheduleAttemptNow = useCallback(
    function scheduleAttemptNow(meetingId) {
      return fetch(buildScheduleAttemptNowRequest(meetingId));
    },
    [fetch]
  );

  const routeMeetings = useCallback(
    function routeMeetings(meetingIds) {
      return fetchJson(buildRouteMeetingsRequest(meetingIds));
    },
    [fetchJson]
  );

  /** Generate URL to use for one-click meeting scheduling */
  const generateOneClickUrl = useCallback(
    function generateOneClickUrl(payload) {
      return fetchJson(buildOneClickMeetingUrl(payload));
    },
    [fetchJson]
  );

  const getHostAvailability = useCallback(
    function getHostAvailability(payload) {
      return fetchJson(buildHostAvailabilityRequest(payload));
    },
    [fetchJson]
  );

  const changeHost = useCallback(
    async (body) =>
      fetch(buildChangeHostRequest({ body })).then((r) => r.json()),
    [fetch]
  );

  const isHostAvailable = useCallback(
    async (body) =>
      fetch(buildIsHostAvailableRequest({ body })).then((r) => r.json()),
    [fetch]
  );

  const { current } = useRef({
    changeHost,
    generateOneClickUrl,
    getHostAvailability,
    isHostAvailable,
    overrideStartTime,
    patchMeetings,
    routeMeetings,
    scheduleAttemptNow,
    useActivateOneClick,
  });

  return current;
}
