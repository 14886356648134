import {
  Delete,
  Download,
  LocalOffer,
  PersonAdd,
  Upload,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { PageLayout } from "src/componentsV2/PageLayout";
import { TagList } from "src/componentsV2/TagList";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import ContactStatusFilter from "src/componentsV2/filters/ContactStatusFilter";
import FilterSearchString from "src/componentsV2/filters/FilterSearchString";
import FilterSelect from "src/componentsV2/filters/FilterSelect";
import TagFilter from "src/componentsV2/filters/TagFilter";
import { SelectTable } from "src/componentsV2/tables/SelectTable";
import { Pagination } from "../../componentsV2/Pagination";
import { AddContactModal } from "../../componentsV2/dialogs";
import { TableSort } from "../../componentsV2/tables/Table";
import { useDeleteConfirmationDialog, useSnackbar } from "../../hooks";
import { useDebounce } from "../../hooks/useDebounce";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import {
  useCreateContact,
  useDeleteContacts,
  useTagContacts,
} from "../../mutations";
import { useContactsV2 } from "../../queries";
import { ContactStatus, ContactWithDetails, Tag } from "../../types";
import ErrorPage from "../ErrorPage";

export default function Page() {
  // State variables for contacts table.
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<TableSort>({
    columnId: "lastName",
    order: "asc",
  });
  const history = useHistory();
  const [selected, setSelected] = useState<number[]>([]);

  // Used to cache data during loads.
  const [contacts, setContacts] = useState<ContactWithDetails[]>([]);
  const [total, setTotal] = useState(0);

  // State variables for
  const { watch, setValue, reset, getValues } = useForm({
    defaultValues: {
      account: "",
      email: "",
      firstName: "",
      lastName: "",
      logicField: "",
      routingField: "",
      tags: [] as Tag[],
      contactStatus: "active" as ContactStatus | null,
      tagFilterSearch: "",
    },
  });

  const filters = getValues();
  useEffect(() => {
    setPage(1);
  }, [
    filters.firstName,
    filters.lastName,
    filters.email,
    filters.account,
    filters.logicField,
    filters.routingField,
    filters.tags,
  ]);

  const [showAddNewContactModal, setShowAddNewContactModal] = useState(false);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const showDeleteConfirmationDialog = useDeleteConfirmationDialog();
  const createContact = useCreateContact();
  const deleteContacts = useDeleteContacts();
  const tagContacts = useTagContacts();
  const [openSnackbar] = useSnackbar();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  // Configure the page size.
  const pageSize = 15;

  // Construct the query used to search for contacts.
  const search = useMemo(
    () => ({
      filter: {
        account: filters.account.length > 0 ? filters.account : undefined,
        email: filters.email.length > 0 ? filters.email : undefined,
        firstName: filters.firstName.length > 0 ? filters.firstName : undefined,
        lastName: filters.lastName.length > 0 ? filters.lastName : undefined,
        logicField:
          filters.logicField.length > 0 ? filters.logicField : undefined,
        routingField:
          filters.routingField.length > 0 ? filters.routingField : undefined,
        tags: filters.tags.map((t) => t.id),
        unsubscribed: filters.contactStatus === "unsubscribed",
      },
      sort: { field: sort.columnId, order: sort.order },
    }),
    [
      filters.firstName,
      filters.lastName,
      filters.email,
      filters.account,
      filters.logicField,
      filters.routingField,
      filters.tags,
      filters.contactStatus,
      sort,
    ]
  );

  // Debounce the search value so that we dont issue a search on every character
  // change to a filter param.
  const searchQuery = useDebounce(search, 750);

  // Fetch a list of contacts.
  const { data, loading, error } = useContactsV2(
    pageSize,
    (page - 1) * pageSize,
    searchQuery
  );

  useEffect(() => {
    if (data) {
      setTotal(data.total);
      setContacts(data.data);
    }
  }, [data]);

  // Calculate the number of pages based on the configured page size.
  const pages = data ? Math.ceil(total / pageSize) : 0;

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  return (
    <>
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Bulk Add Tags"
          open
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await tagContacts(
                selected,
                tags.map((tag: Tag) => tag.id)
              );

              openSnackbar(
                `Successfully added ${tags.length} tag(s) to ${selected.length} contact(s)`
              );
              setShowTagManagementDialog(false);
            } catch {
              openSnackbar("Failed to apply tags to contacts");
            }
          }}
        />
      )}
      <PageLayout
        title="Contacts"
        actions={
          <>
            <Tooltip title="Add Contact" arrow>
              <IconButton
                onClick={() => {
                  setShowAddNewContactModal(true);
                }}
              >
                <PersonAdd />
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage Tags" arrow>
              <IconButton
                disabled={selected.length < 2}
                onClick={() => {
                  setShowTagManagementDialog(true);
                }}
              >
                <LocalOffer />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export Contacts" arrow>
              <IconButton
                onClick={() =>
                  history.push(
                    `/reports/export/contacts?firstName=${watch(
                      "firstName"
                    )}&lastName=${watch("lastName")}&email=${watch(
                      "email"
                    )}&account=${watch("account")}&tags=${JSON.stringify(
                      watch("tags")
                    )}&logicField=${watch("logicField")}&routingField=${watch(
                      "routingField"
                    )}&view=${
                      watch("contactStatus") === "unsubscribed"
                        ? "Unsubscribed"
                        : "All"
                    }`
                  )
                }
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Contacts" arrow>
              <IconButton
                onClick={() => {
                  showDeleteConfirmationDialog(
                    `Are you sure you want to delete ${selected.length} contacts?`,
                    () => {
                      deleteContacts(selected)
                        .then(() => {
                          addGeneralNotification(
                            "Successfully deleted contact(s)"
                          );
                          setSelected([]);
                        })
                        .catch(() => addError("Failed to delete contact(s)"));
                    }
                  );
                }}
                disabled={
                  selected.length < 1 ||
                  watch("contactStatus") === "unsubscribed"
                }
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload Contacts" arrow>
              <IconButton
                onClick={() => {
                  history.push("/reports/import/contacts");
                }}
              >
                <Upload />
              </IconButton>
            </Tooltip>
          </>
        }
      >
        <Stack spacing={2}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Stack direction="row" spacing={2}>
              <FilterSelect<TableSort>
                label="Sort By"
                value={sort}
                isOptionEqualToValue={(option, value) =>
                  option.columnId === value?.columnId &&
                  option.order === value.order
                }
                onChange={(value) =>
                  setSort(
                    value || {
                      columnId: "lastName",
                      order: "asc",
                    }
                  )
                }
                options={[
                  {
                    label: "First Name (A-Z)",
                    value: { columnId: "firstName", order: "asc" },
                  },
                  {
                    label: "First Name (Z-A)",
                    value: { columnId: "firstName", order: "desc" },
                  },
                  {
                    label: "Last Name (A-Z)",
                    value: { columnId: "lastName", order: "asc" },
                  },
                  {
                    label: "Last Name (Z-A)",
                    value: { columnId: "lastName", order: "desc" },
                  },
                  {
                    label: "Email (A-Z)",
                    value: { columnId: "email", order: "asc" },
                  },
                  {
                    label: "Email (Z-A)",
                    value: { columnId: "email", order: "desc" },
                  },
                ]}
              />
              <FilterSearchString
                label="First Name"
                value={watch("firstName")}
                onChange={(value) => {
                  setValue("firstName", value);
                }}
              />
              <FilterSearchString
                label="Last Name"
                value={watch("lastName")}
                onChange={(value) => {
                  setValue("lastName", value);
                }}
              />
              <FilterSearchString
                label="Email"
                value={watch("email")}
                onChange={(value) => {
                  setValue("email", value);
                }}
              />
              <TagFilter
                search={watch("tagFilterSearch")}
                onSearchChange={(value) => {
                  setValue("tagFilterSearch", value);
                }}
                value={watch("tags")}
                onChange={(value) => {
                  setValue("tags", value);
                }}
              />
              <FilterSearchString
                label="Account"
                value={watch("account")}
                onChange={(value) => {
                  setValue("account", value);
                }}
              />
              <FilterSearchString
                label="Logic Field"
                value={watch("logicField")}
                onChange={(value) => {
                  setValue("logicField", value);
                }}
              />
              <FilterSearchString
                label="Routing Field"
                value={watch("routingField")}
                onChange={(value) => {
                  setValue("routingField", value);
                }}
              />
              <ContactStatusFilter
                value={watch("contactStatus")}
                onChange={(value) => {
                  setValue("contactStatus", value);
                }}
              />
              <Divider orientation="vertical" flexItem />
              <Button
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            </Stack>
          </Paper>
          <Paper elevation={0} sx={{ width: "100%" }}>
            <SelectTable
              getKey={(contact) => contact.id}
              loading={loading}
              minRows={pageSize}
              columns={[
                {
                  align: "left",
                  component: (c) => (
                    <Link component={RouterLink} to={`/contacts/${c.id}`}>
                      {c.id}
                    </Link>
                  ),
                  id: "id",
                  label: "Id",
                  width: 50,
                },
                {
                  component: (c) => (
                    <Stack>
                      <Typography>
                        {c.firstName} {c.lastName}
                      </Typography>
                      <Typography variant="caption">{c.email}</Typography>
                    </Stack>
                  ),
                  id: "name",
                  label: "Name",
                },
                {
                  component: (c) => <TagList tags={c.tags} />,
                  id: "tags",
                  label: "Tags",
                  visible: lg,
                },
              ]}
              data={contacts}
              sort={sort}
              selected={selected}
              onSelect={(checked, cs) => {
                if (!checked) {
                  setSelected(selected.concat(cs.map((c) => c.id)));
                } else {
                  setSelected(
                    selected.filter((s) => !cs.map((c) => c.id).includes(s))
                  );
                }
              }}
            />
            <Pagination
              pages={pages}
              currentPage={page}
              onPageChange={setPage}
            />
          </Paper>
        </Stack>

        {showAddNewContactModal && (
          <AddContactModal
            open
            contact={undefined}
            onClose={() => {
              setShowAddNewContactModal(false);
            }}
            onSubmit={(contact) => {
              createContact(contact)
                .then(() => {
                  addGeneralNotification("Successfully created contact");
                  setShowAddNewContactModal(false);
                })
                .catch(() => {
                  addError("Failed to create contact");
                });
            }}
          />
        )}
      </PageLayout>
    </>
  );
}
