import { getUserToken } from "src/utils/jwtToken";
import useSWR from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export type RoutingLogic = {
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  order: number;
  enabled: boolean;
  mapping_logic: MappingLogic[] | null;
};

export type MappingLogic = {
  field: string;
  operator: "is";
  value: string;
};

export function useRoutingLogic(id?: number | string) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error, mutate } = useSWR(
    `/api/meetings/definition/${id}/routing_logic`,
    (url: string) =>
      service
        .get(url)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as RoutingLogic[] | undefined,
    error,
    loading: !error && !data,
    mutate,
  };
}
