import { useCallback } from "react";

export function useEditTag(editTagInCache, editSelectedTag, onEdit, onModify) {
  return useCallback(
    async (tagId, update) => {
      // Edit ids in the urlSearch (i.e local data source)
      editTagInCache(tagId, update);
      // Edit selected id
      editSelectedTag(tagId, update);
      await onEdit(tagId, update);
      if (onModify) {
        onModify();
      }
    },
    [editTagInCache, editSelectedTag, onEdit, onModify]
  );
}
