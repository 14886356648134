import React, { useCallback, useState } from "react";
import cx from "classnames";
import { mdiCalendarMultiselect } from "@mdi/js";

import MeetingTableHeader from "./meetingTableHeader";
import LoadingBar from "./loadingBar";
import { TableBody } from "../../components/tableV5/GridTable";
import { Pagination } from "../pagination/Pagination";
import { ButtonContainer } from "../../components/button";
import { useHasSearchFilters, useSearchUpdater } from "../Search";
import MeetingTableRow from "./meetingTableRow";
import NewMeetingTableRow from "./newMeetingTableRow";
import {
  EmptyViewContainer,
  EmptyViewRow,
  EmptyViewIcon,
} from "../../components/emptyView";
import { IsFilterMenuIsOpenContext } from "../context";
import style from "./style.module.scss";
import { useUpdateMeetings } from "../../mutations";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { useDefaultUserDisableMeetingsCancel } from "../../features";
import { getUserDetails } from "../../utils/jwtToken";
import { ROLE_LEVELS } from "../../auth/roles";

function NoResults({ hasFilters, onClearFilters = () => {} }) {
  return (
    <EmptyViewContainer className={style.meetingTable__emptyViewContainer}>
      <EmptyViewRow>
        <EmptyViewIcon
          className={style.meetingTable__emptyViewContainer_icon}
          icon={mdiCalendarMultiselect}
        />
      </EmptyViewRow>

      <EmptyViewRow>No Meetings found.</EmptyViewRow>

      {hasFilters && (
        <EmptyViewRow>
          <ButtonContainer
            className={style.emptyView_button}
            small
            primary
            key="clear-filters-button"
            name="/instances/status_chart/clear_filters"
            onClick={onClearFilters}
          >
            Clear Filters
          </ButtonContainer>
        </EmptyViewRow>
      )}
    </EmptyViewContainer>
  );
}

export function MeetingTable({
  loading,
  total,
  pageSize,
  page,
  meetings,
  selected,
  onNewMeetingCreated,
  showCreateNewMeetingRow,
  onCreateNewMeetingRowClose,
  onSelectedToggle,
  onSelectAllToggle,
  onPageChange,
  onPageSizeChange,
}) {
  const searchUpdater = useSearchUpdater();
  const hasFilters = useHasSearchFilters();
  const isFilterMenuOpenState = useState(false);

  const updateMeetings = useUpdateMeetings();

  const { addError } = useGeneralNotifications();

  const handleMeetingSelectedToggle = useCallback(
    (meeting) => onSelectedToggle(meeting.id),
    [onSelectedToggle]
  );

  const handleToggleAll = useCallback(
    (state) => {
      onSelectAllToggle(
        state,
        meetings.map((m) => m.id)
      );
    },
    [onSelectAllToggle, meetings]
  );

  const handleActivation = useCallback(
    (meeting) => {
      updateMeetings([
        {
          enabled: !meeting.enabled,
          id: meeting.id,
        },
      ]).catch(() => {
        addError(`Failed to update meeting ${meeting.id}`);
      });
    },
    [updateMeetings, addError]
  );

  const handleMeetingTypeChange = useCallback(
    (meeting, type) => {
      updateMeetings([
        {
          id: meeting.id,
          meetingType: type.id,
        },
      ]).catch(() => {
        addError(`Failed to update meeting ${meeting.id}`);
      });
    },
    [updateMeetings, addError]
  );

  const handleGuestChange = useCallback(
    (meeting, guest) => {
      updateMeetings([
        {
          contact: guest.id,
          id: meeting.id,
        },
      ]).catch(() => {
        addError(`Failed to update meeting ${meeting.id}`);
      });
    },
    [updateMeetings, addError]
  );

  const handleHostChange = useCallback(
    (meeting, host) => {
      updateMeetings([
        {
          id: meeting.id,
          user: host.id,
        },
      ]).catch(() => {
        addError(`Failed to update meeting ${meeting.id}`);
      });
    },
    [updateMeetings, addError]
  );

  const user = getUserDetails();
  const userRole = user?.role || ROLE_LEVELS.DEFAULT;
  const meetingCancellationDisabledForDefaultUser =
    useDefaultUserDisableMeetingsCancel();
  const activateSwitchDisabled =
    userRole === ROLE_LEVELS.DEFAULT &&
    meetingCancellationDisabledForDefaultUser;

  return (
    <IsFilterMenuIsOpenContext.Provider value={isFilterMenuOpenState}>
      <MeetingTableHeader
        allSelected={
          meetings &&
          meetings.every((m) => selected.includes(m.id)) &&
          selected.length > 0
        }
        onToggleAll={handleToggleAll}
      />

      <LoadingBar loading={loading} />

      {showCreateNewMeetingRow && (
        <NewMeetingTableRow
          onClose={onCreateNewMeetingRowClose}
          onCreate={onNewMeetingCreated}
        />
      )}

      {meetings && meetings.length > 0 && (
        <TableBody className={style.meetingTable__grid}>
          {meetings.map((meeting, i) => {
            const checked = selected.includes(meeting.id);
            return (
              <MeetingTableRow
                selected={checked}
                key={meeting.id}
                meeting={meeting}
                index={i}
                activateSwitchDisabled={activateSwitchDisabled}
                onSelectedToggle={() => handleMeetingSelectedToggle(meeting)}
                onActivationToggle={() => {
                  handleActivation(meeting);
                }}
                onMeetingTypeChange={(type) => {
                  handleMeetingTypeChange(meeting, type);
                }}
                onGuestChange={(g) => {
                  handleGuestChange(meeting, g);
                }}
                onHostChange={(h) => {
                  handleHostChange(meeting, h);
                }}
              />
            );
          })}
        </TableBody>
      )}

      <div
        className={cx(style.meetingTable__pagination, "kl-shadow--xs-reverse")}
      >
        <div className={style.meetingTable__paginationContainer}>
          <Pagination
            isDisabled={meetings === undefined}
            offset={page || 0}
            pageSize={pageSize}
            pageSizeOptions={[{ value: 10 }, { value: 25 }, { value: 100 }]}
            setPageSize={onPageSizeChange}
            setOffset={onPageChange}
            total={total || 0}
          />
        </div>
      </div>

      {!loading && meetings && meetings.length === 0 && (
        <NoResults
          hasFilters={hasFilters}
          onClearFilters={searchUpdater.clearFilters}
        />
      )}
    </IsFilterMenuIsOpenContext.Provider>
  );
}
