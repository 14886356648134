import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import Icon from "@mdi/react";
import style from "./button.module.scss";

const ButtonGroup = ({ children }) => (
  <div className={style.group}>{children}</div>
);

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.any]).isRequired,
};

const Button = ({
  active,
  className,
  dataCy,
  dataGtm,
  disabled,
  icon,
  name,
  pulse,
  size,
  type,
  children,
  ...rest
}) => {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  return (
    <button
      aria-label={name}
      type="button"
      disabled={disabled}
      className={cx(
        style.btn,
        { [style.pulseSuccess]: pulse },
        style[type],
        { [style.disabled]: disabled },
        { [style.isActive]: active },
        className
      )}
      data-size={size}
      name={name}
      {...rest}
      {...dataProps}
    >
      {icon && (
        <>
          <Icon color={null} path={icon} size={1} className={style.icon} />
          {children}
        </>
      )}
      {!icon && children}
    </button>
  );
};

Button.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.any,
  ]),
  dataCy: PropTypes.string,
  dataGtm: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string,
  pulse: PropTypes.bool,
  size: PropTypes.oneOf(["default", "small", "large"]),
  type: PropTypes.oneOf([
    "primary",
    "rounded",
    "secondary",
    "tertiary",
    "text",
  ]),
};

Button.defaultProps = {
  active: false,
  children: null,
  dataCy: null,
  dataGtm: null,
  disabled: false,
  icon: null,
  name: null,
  pulse: false,
  size: "default",
  type: "primary",
};

export { Button as default, ButtonGroup };
