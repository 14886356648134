import React, { useCallback, useContext } from "react";
import Icon from "@mdi/react";
import { mdiAlert } from "@mdi/js";
import { NotificationCenterControllersContext } from "../notifications/NotificationCenterControllers";
import {
  GeneralNotification,
  ErrorNotification,
} from "../notifications/NotificationTypes";
import style from "../notifications/style.module.scss";

const useGeneralNotifications = () => {
  const { dispatchNotification } = useContext(
    NotificationCenterControllersContext
  );

  const addGeneralNotification = useCallback(
    (content, icon = null, userDismiss = false) =>
      dispatchNotification(
        GeneralNotification,
        <>
          {icon !== null && (
            <Icon path={icon} size={1} className={style.notification__icon} />
          )}

          <span className={style.notification__content}>{content}</span>
        </>,
        userDismiss,
        3000,
        false
      ),
    [dispatchNotification]
  );

  const addInternalError = useCallback(
    (message) =>
      dispatchNotification(
        ErrorNotification,
        <>
          <Icon path={mdiAlert} size={1} className={style.notification__icon} />

          <span className={style.notification__content}>
            {`Error: ${message}`}
          </span>
        </>,
        true,
        3000,
        false
      ),
    [dispatchNotification]
  );

  const addError = useCallback(
    (content) =>
      dispatchNotification(
        ErrorNotification,
        <>
          <Icon path={mdiAlert} size={1} className={style.notification__icon} />

          <span className={style.notification__content}>{content}</span>
        </>,
        true,
        3000,
        true
      ),
    [dispatchNotification]
  );

  return {
    addError,
    addGeneralNotification,
    addInternalError,
  };
};

export default useGeneralNotifications;
