import React, { useCallback, useMemo } from "react";
import Chip from "@mui/material/Chip";
import { ConnectionStatus } from "../ConnectionStatus";
import {
  ComingSoon,
  DeleteButton,
  ReauthorizeButton,
  ConnectButton,
  DisconnectButton,
} from "../buttons";
import {
  IntegrationCard,
  IntegrationCardButtonArea,
  IntegrationCardTitle,
} from "../IntegrationCard";
import { INTEGRATION_AUTH_STATUS } from "../props";
import { determineIntegrationStatus } from "../util";
import { Logo } from "./Logo";
import style from "./style.module.scss";

export function OutreachIntegrationCard({
  enabled,
  onConnect,
  onDisconnect,
  integration = null,
  isLoading,
}) {
  const connected = integration !== null;
  const authStatus = determineIntegrationStatus(integration);

  const connectionStatus = useMemo(
    () => `Auth Status: ${authStatus}`,
    [authStatus]
  );

  const reauthorize = useCallback(() => {
    onConnect(integration.id);
  }, [integration, onConnect]);

  const connect = useCallback(() => {
    onConnect(0);
  }, [onConnect]);

  return (
    <IntegrationCard enabled={enabled}>
      {connected && <ConnectionStatus>{connectionStatus}</ConnectionStatus>}
      <div className={style.betaChip}>
        <Chip label="Beta" />
      </div>

      <Logo />
      <IntegrationCardTitle>Outreach</IntegrationCardTitle>

      <IntegrationCardButtonArea>
        {isLoading && <span>Loading Integrations...</span>}
        {enabled &&
          !connected &&
          authStatus === INTEGRATION_AUTH_STATUS.DISABLED && (
            <>
              <ConnectButton
                name="/integrations/outreach/connect"
                onClick={connect}
              />
            </>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.EXPIRED && (
            <div className={style.reauth_container}>
              <ReauthorizeButton
                name="/integrations/outreach/reauthorize"
                onClick={reauthorize}
              />
              <DeleteButton
                name="/integrations/outreach/delete"
                onClick={onDisconnect}
              />
            </div>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.CURRENT && (
            <DisconnectButton
              name="/integrations/outreach/disconnect"
              onClick={onDisconnect}
            />
          )}
        {!enabled && <ComingSoon name="/integrations/outreach/coming_soon" />}
      </IntegrationCardButtonArea>
    </IntegrationCard>
  );
}
