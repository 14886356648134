import { useCallback, useRef } from "react";
import { useCustomFetchFn } from "../components/fetch";
import { HTTP_METHODS } from "../components/fetch/props";
import { INTEGRATION_TYPES } from "../integrations/props";
import { API } from "../props";
import { buildRequest, buildUrl } from "../utils/fetchV2";

const buildUpdateIntegrationConfigRequest = (id) => {
  return buildRequest(API.integrations.config(id));
};

const buildGetIntegrationsRequest = () =>
  buildRequest(API.integrations.default());

const buildconnectOrgIntegrationSandboxRequest = (integrationType) =>
  buildRequest(
    buildUrl(API.integrations[integrationType].authURL(0), [
      ["is_test", "true"],
    ])
  );

const buildConnectOrgIntegrationRequest = (
  integrationType,
  integrationId,
  url
) =>
  buildRequest(
    buildUrl(API.integrations[integrationType].authURL(integrationId), { url })
  );

const buildConnectUserIntegrationRequest = (
  integrationType,
  integrationId,
  userId,
  url
) =>
  buildRequest(
    buildUrl(API.integrations[integrationType].authURL(integrationId, userId), {
      url,
    })
  );

const buildDisconnectOrgIntegrationRequest = (integrationId) =>
  buildRequest(API.integrations.org(integrationId), {
    method: HTTP_METHODS.DELETE,
  });

const buildDisconnectUserIntegrationRequest = ({ body }) =>
  buildRequest(API.integrations.user(), {
    body: JSON.stringify(body),
    method: HTTP_METHODS.DELETE,
  });

const buildGetUserIntegrationsRequest = (userId) =>
  buildRequest(API.integrations.user(userId), {
    method: HTTP_METHODS.GET,
  });

export default function useIntegrationsRepository() {
  const { fetch } = useCustomFetchFn();

  const updateIntegrationConfig = useCallback(
    async (id, config) => {
      return fetch(buildUpdateIntegrationConfigRequest(id), {
        body: JSON.stringify(config),
        method: "PATCH",
      }).then((r) => r.json());
    },
    [fetch]
  );

  const getIntegrations = useCallback(async () => {
    const integrations = await fetch(buildGetIntegrationsRequest()).then((r) =>
      r.json()
    );

    return integrations.map((i) => {
      // normalize the `salesforce-test` integrations to just be `salesforce`
      if (i.name === INTEGRATION_TYPES.SALESFORCE_TEST) {
        return { ...i, name: INTEGRATION_TYPES.SALESFORCE };
      }

      return i;
    });
  }, [fetch]);

  const connectOrgIntegrationSandbox = useCallback(
    async (integrationType) =>
      fetch(buildconnectOrgIntegrationSandboxRequest(integrationType)).then(
        (r) => r.json()
      ),
    [fetch]
  );

  const connectOrgIntegration = useCallback(
    async (integrationType, integrationId, url) =>
      fetch(
        buildConnectOrgIntegrationRequest(integrationType, integrationId, url)
      ).then((r) => r.json()),
    [fetch]
  );

  const connectUserIntegration = useCallback(
    async (integrationType, integrationId, userId, url) =>
      fetch(
        buildConnectUserIntegrationRequest(
          integrationType,
          integrationId,
          userId,
          url
        )
      ).then((r) => r.json()),
    [fetch]
  );

  const disconnectOrgIntegration = useCallback(
    async (integrationId) =>
      fetch(buildDisconnectOrgIntegrationRequest(integrationId)).then((r) =>
        r.json()
      ),
    [fetch]
  );

  const disconnectUserIntegration = useCallback(
    async (body) =>
      fetch(buildDisconnectUserIntegrationRequest({ body })).then((r) =>
        r.json()
      ),
    [fetch]
  );

  const getUserIntegrations = useCallback(
    async (userId) =>
      fetch(buildGetUserIntegrationsRequest(userId)).then((r) => r.json()),
    [fetch]
  );

  const { current } = useRef({
    connectOrgIntegration,
    connectOrgIntegrationSandbox,
    connectUserIntegration,
    disconnectOrgIntegration,
    disconnectUserIntegration,
    getIntegrations,
    getUserIntegrations,
    updateIntegrationConfig,
  });

  return current;
}
