import React, { useCallback } from "react";
import { TagManager } from "../../components/tagManager";
import { useTagsRepository } from "../../repository";
import { buildRequest } from "../../utils/fetchV2";
import { API } from "../../props";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";

const isSavable = (selectedTags) => selectedTags !== null;

const ChannelsTagsManager = ({
  title,
  tags = [],
  channelIds,
  channelType,
  close,
  dispatch,
}) => {
  const repository = useTagsRepository();
  const { addError } = useGeneralNotifications();

  const saveChannelsTags = useCallback(
    async (selectedTags) => {
      const tagIds = selectedTags.map((t) => t.id);

      const response = await fetch(
        buildRequest(API.tags.channels, {
          body: JSON.stringify({
            channelIds,
            tagIds,
          }),
          method: "PUT",
        })
      );

      if (response.ok) {
        dispatch({
          payload: {
            channelIds,
            channelType,
            tags: selectedTags,
          },
          type: "CHANNEL_TAGS_SAVED",
        });
      }
    },
    [channelIds, channelType, dispatch]
  );

  const editChannelsTags = useCallback(
    async (tagId, update) => {
      try {
        await repository.updateTag(tagId, update);

        dispatch({
          payload: {
            channelType,
            updatedTag: update,
          },
          type: "CHANNEL_TAGS_UPDATED",
        });
      } catch (e) {
        addError("Unable to update tag");
      }
    },
    [repository, channelType, addError, dispatch]
  );

  const removeChannelsTags = useCallback(
    (tagIds) => {
      dispatch({
        payload: {
          channelType,
          tagIds,
        },
        type: "CHANNEL_TAGS_REMOVED",
      });
    },
    [channelType, dispatch]
  );

  return (
    <TagManager
      title={title}
      initialSelectedTags={tags}
      isSavable={isSavable}
      close={close}
      repository={repository}
      onSave={saveChannelsTags}
      onEdit={editChannelsTags}
      onRemove={removeChannelsTags}
    />
  );
};

export default ChannelsTagsManager;
