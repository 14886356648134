import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { PersonalAccessToken } from "../../../../../types/PersonalAccessToken";
import SecondaryButton from "../../../../../componentsV2/buttons/SecondaryButton";
import PrimaryButton from "../../../../../componentsV2/buttons/PrimaryButton";
import { useDeletePAT } from "../../../../../mutations/useDeletePAT";
import useGeneralNotifications from "../../../../../hooks/useGeneralNotifications";

// DeletePATDialog warns users about deleting a personal access token
function DeletePATDialog({
  token,
  onClose,
}: {
  token: PersonalAccessToken;
  onClose: () => void;
}) {
  const deletePAT = useDeletePAT();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          Delete{" "}
        </Typography>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          {token.description}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="span">
          You are about to delete the following Personal Access Token:
        </Typography>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {` ${token.description}. `}
        </Typography>
        <Typography component="span">
          Any applications using this token will no longer be able to access the
          Kronologic API. This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={async () => {
            try {
              await deletePAT(token.id);
              addGeneralNotification(`${token.description} deleted`);
              onClose();
            } catch {
              addError(
                `There was a problem deleting ${token.description}. Please try again later.`
              );
            }
          }}
        >
          Confirm
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeletePATDialog;
