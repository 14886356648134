import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Organization } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export function useOrganizationsSearch(
  limit: number,
  offset: number,
  query?: {
    search: string;
    sort: { field: "enabled" | "name"; order: "asc" | "desc" };
  }
) {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const url = `/api/org/search`;

  const body = {
    limit,
    offset,
    qry: query?.search,
    sort: query?.sort,
  };

  const { data, error } = useSWR([url, body], (u) =>
    service
      .post(u)
      .set(headers)
      .send(body)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { data: Organization[]; total: number },
    error,
    loading: !error && !data,
  };
}
