import { useCallback, useMemo, useState } from "react";
import { mdiCloseCircle } from "@mdi/js";

import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useSetTotal } from "../../context";
import {
  INSTANCES_ADD_NEW,
  useCreationMeta,
} from "../../../components/meetingCreationMethods";
import DisplayFailedInstances from "../../notifications/DisplayFailedInstances";
import { useCreateMeetings } from "../../../mutations";

function useCreateNewMeeting({ onClose, onCreate = null }) {
  const { addError } = useGeneralNotifications();

  const createMeetings = useCreateMeetings();

  const setTotal = useSetTotal();
  const [isSaving, setIsSaving] = useState(false);
  const meta = useCreationMeta(INSTANCES_ADD_NEW);
  const createNewMeeting = useCallback(
    async ({ contact, meetingType, user }) => {
      if (isSaving) {
        return;
      }
      setIsSaving(true);
      try {
        const newMeeting = {
          enabled: false,
          meta,
        };

        if (contact) {
          Object.assign(newMeeting, { contact: contact.id });
        }

        if (meetingType) {
          Object.assign(newMeeting, {
            meetingType: meetingType.id,
          });
        }

        if (user) {
          Object.assign(newMeeting, { user: user.id });
        }

        const { instances, failures } = await createMeetings([
          newMeeting,
        ]).catch(() => {
          addError("Failed to create meeting");
        });

        // report failures
        if (failures && failures.length > 0) {
          addError(<DisplayFailedInstances failures={failures} />);
        }

        // handle successes
        if (instances && instances.length > 0) {
          instances[0].meetingType = meetingType;
          instances[0].user = user;
          instances[0].contact = contact;

          setTotal((prev) => prev + 1);
          createMeetings(instances);
          if (onCreate !== null) {
            onCreate(instances[0]);
          }
          setIsSaving(false);
        }
      } catch (error) {
        setIsSaving(false);
        addError(
          "An error occurred creating the meeting. Please try again later.",
          mdiCloseCircle
        );
      } finally {
        onClose();
      }
    },
    [addError, createMeetings, onCreate, onClose, isSaving, meta, setTotal]
  );

  return useMemo(() => {
    return [isSaving, createNewMeeting];
  }, [isSaving, createNewMeeting]);
}

export default useCreateNewMeeting;
