import { ResponseError } from "superagent";
import useSWRImmutable from "swr/immutable";
import { useUserService } from "../services";
import { API } from "../props";

export function useValidateGuestDeclineAction(
  orgUUID: string,
  meetingUUID: string,
  contactUUID: string
) {
  const service = useUserService();
  const body = {
    a: "decline",
    c: contactUUID,
    m: meetingUUID,
    o: orgUUID,
  };

  const { data, error } = useSWRImmutable(
    [API.meetings.guestAction.decline.validate, body],
    (url: string) =>
      service
        .post(url)
        .send(body)
        .then((res: Response) => res.body)
  );

  return {
    error: error as ResponseError,
    loading: !error && !data,
  };
}
