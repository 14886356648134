import React from "react";
import cx from "classnames";

import style from "./typography.module.scss";

export function H1({ children }) {
  return <h1 className={style["heading-1--semiBold"]}>{children}</h1>;
}

export function H2({ className, children }) {
  return (
    <h2 className={cx(style["heading-2--semiBold"], className)}>{children}</h2>
  );
}

export function H3({ className, children }) {
  return (
    <h3 className={cx(style["heading-3--semiBold"], className)}>{children}</h3>
  );
}

export function H4({ className, children }) {
  return (
    <h4 className={cx(style["heading-4--semiBold"], className)}>{children}</h4>
  );
}

export function H5({ className, children }) {
  return (
    <h5 className={cx(style["heading-5--semiBold"], className)}>{children}</h5>
  );
}

export function H6({ className, children, whiteSpace }) {
  const custom = {};
  if (whiteSpace) {
    custom.whiteSpace = whiteSpace;
  }
  return (
    <h6 className={cx(style["heading-6--semiBold"], className)} style={custom}>
      {children}
    </h6>
  );
}

export function P1({
  children,
  className,
  bold: isBold = false,
  italic: isItalic,
  style: inlineStyles = null,
  whiteSpace = null,
}) {
  const custom = {};
  if (inlineStyles !== null) {
    Object.assign(custom, { ...inlineStyles });
  }
  if (whiteSpace) {
    custom.whiteSpace = whiteSpace;
  }
  if (isItalic) {
    custom.fontStyle = "italic";
  }
  return (
    <span
      className={cx(
        {
          [style["paragraph-1"]]: !isBold,
          [style["paragraph-1--semiBold"]]: isBold,
        },
        className
      )}
      style={custom}
    >
      {children}
    </span>
  );
}

export function Caption({ className, children }) {
  return <span className={cx(className, style.caption)}>{children}</span>;
}

export function Bold({ children }) {
  return <span className={style.kl_bold}>{children}</span>;
}
