import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { TokenStatus, User, UserStatus } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export function useUsersV2(
  limit: number,
  offset: number,
  options: {
    filters: {
      firstName?: string;
      lastName?: string;
      email?: string;
      team?: number[];
      status?: UserStatus[];
      calendarTokenStatus?: TokenStatus[];
      meetingLinkTokenStatus?: TokenStatus[];
      tag?: number[];
      role?: number[];
    };
    sort: {
      field?: "firstName" | "lastName" | "email" | "meetingLink" | "role";
      order?: "asc" | "desc";
    };
  }
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const body = {
    limit,
    offset,
    filters: {
      firstName: options?.filters?.firstName || "",
      lastName: options?.filters?.lastName || "",
      email: options?.filters?.email || "",
      team: options?.filters?.team || [],
      tag: options?.filters?.tag || [],
      role: options?.filters?.role || [],
      status: options?.filters?.status || [],
      calendarTokenStatus: options?.filters?.calendarTokenStatus || [],
      meetingLinkTokenStatus: options?.filters?.meetingLinkTokenStatus || [],
    },
    sort: {
      field: options?.sort?.field || "lastName",
      order: options?.sort?.order || "asc",
    },
  };

  const { data, error } = useSWR(["/api/users/v2", body], (u) =>
    service
      .post(u)
      .set(headers)
      .send(body)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as
      | {
          total: number;
          data: User[];
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}

export default useUsersV2;
