import { createContext } from "react";

const handleChannelError = (errorMessage) => {
  switch (errorMessage) {
    case "fetchFields":
      return "fieldObjectError";
    default:
      return null;
  }
};

export const DefaultChannelState = Object.freeze({
  channelIsLoading: true,
  importChannels: new Map([]),
  importError: new Map([]),
  importFields: new Map([]),
  importIntegrations: new Map([]),
  importIsLoading: true,
  integrations: new Map([]),
  interceptChannels: new Map([]),
  interceptError: new Map([]),
  interceptFields: new Map([]),
  interceptIntegrations: new Map([]),
  interceptIsLoading: true,
  updateChannels: new Map([]),
  updateError: new Map([]),
  updateFields: new Map([]),
  updateIntegrations: new Map([]),
  updateIsLoading: true,
});

export const ChannelsStateReducer = (state, { type, payload }) => {
  let newintegrationMap;
  let newintegrationsMap;
  let newintegrationFieldMap;
  let newChannelsMap;
  let newErrorMap;

  switch (type) {
    case "SET_INTEGRATIONS":
      newintegrationsMap = new Map([]);

      payload.integrations.forEach((integration) => {
        newintegrationsMap.set(integration.name, integration);
      });

      return { ...state, integrations: newintegrationsMap };
    case "SET_INTEGRATION":
      newintegrationMap = new Map(state[`${payload.channelType}Integrations`]);
      newintegrationMap.set(payload.integration.name, payload.integration);

      return {
        ...state,
        [`${payload.channelType}Integrations`]: newintegrationMap,
      };
    case "SET_INTEGRATION_FIELD":
      newintegrationFieldMap = new Map(state[`${payload.channelType}Fields`]);

      newintegrationFieldMap.set(payload.integrationName, payload.fieldObject);

      return {
        ...state,
        [`${payload.channelType}Fields`]: newintegrationFieldMap,
        [`${payload.channelType}IsLoading`]: false,
      };
    case "SET_INTEGRATION_CHANNEL": {
      newChannelsMap = new Map(state[`${payload.channelType}Channels`]);

      payload.channels.forEach((channel) => {
        newChannelsMap.set(channel.id, {
          ...channel,
          isOpen: channel.isOpen || false,
          tagAutomation: {
            tagContacts: channel.tagAutomation.tagContacts || [],
            tagMeetings: channel.tagAutomation.tagMeetings || [],
          },
          tags: channel.tags || [],
        });
      });

      const sortedChannels = [...newChannelsMap].sort((c1, c2) => {
        const [, { name: n1 }] = c1;
        const [, { name: n2 }] = c2;
        return n1.localeCompare(n2);
      });

      return {
        ...state,
        [`${payload.channelType}Channels`]: new Map(sortedChannels),
      };
    }
    case "UPDATE_CHANNEL":
      newChannelsMap = new Map(state[`${payload.channelType}Channels`]);
      return {
        ...state,
        [`${payload.channelType}Channels`]: newChannelsMap.set(
          payload.id,
          payload.channel
        ),
      };
    case "DELETE_CHANNEL":
      newChannelsMap = new Map(state[`${payload.channelType}Channels`]);

      newChannelsMap.delete(payload.channelId);

      return {
        ...state,
        [`${payload.channelType}Channels`]: newChannelsMap,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        [`${payload.channelType}IsLoading`]: payload.value,
      };
    case "SET_CHANNEL_IS_LOADING":
      return { ...state, channelIsLoading: payload.value };
    case "SET_CLONED":
      return { ...state, cloned: false };
    case "SET_CHANNEL_ERROR":
      newErrorMap = new Map(state[`${payload.channelType}Error`]);

      return {
        ...state,
        [`${payload.channelType}Error`]: newErrorMap.set(
          payload.integrationType,
          handleChannelError(payload.errorLocation)
        ),
      };
    case "CHANNEL_TAGS_SAVED": {
      const channels = state[`${payload.channelType}Channels`];

      payload.channelIds.forEach((id) => {
        channels.set(id, {
          ...channels.get(id),
          tags: payload.tags,
        });
      });

      return {
        ...state,
        [`${payload.channelType}Channels`]: new Map(channels),
      };
    }
    case "CHANNEL_TAGS_UPDATED": {
      const channels = state[`${payload.channelType}Channels`];

      channels.forEach((channel, channelId) => {
        const { tags } = channel;

        if (tags.findIndex((tag) => tag.id === payload.updatedTag.id) > -1) {
          const newTags = tags.map((tag) => {
            if (tag.id === payload.updatedTag.id) {
              return {
                ...tag,
                ...payload.updatedTag,
              };
            }
            return tag;
          });

          channels.set(channelId, {
            ...channel,
            tags: newTags,
          });
        }
      });

      return {
        ...state,
        [`${payload.channelType}Channels`]: new Map(channels),
      };
    }
    case "CHANNEL_TAGS_REMOVED": {
      const channels = state[`${payload.channelType}Channels`];

      channels.forEach((channel, channelId) => {
        const { tags } = channel;

        const newTags = tags.filter(
          (tag) => !payload.tagIds.some((id) => tag.id === id)
        );

        channels.set(channelId, {
          ...channel,
          tags: newTags,
        });
      });

      return {
        ...state,
        [`${payload.channelType}Channels`]: new Map(channels),
      };
    }
    default:
      throw new Error(
        `Dispatched action of type ${type} does not exist in the ChannelsReducer`
      );
  }
};

export const DefaultModalState = {
  import: {
    add: {
      channelStatus: { value: null },
      checkAssociatedObjects: false,
      conditions: [
        [
          {
            condition: "",
            field: { label: "", name: "" },
            value: "",
          },
        ],
      ],
      enabled: false,
      fieldMappings: {
        logicField: { label: "", name: "" },
        routingField: { label: "", name: "" },
      },
      fieldObjectType: { type: null },
      fieldObjectsDropdownData: [],
      isOpen: false,
      name: "",
      selectedIntegration: { id: null },
      tagAutomation: {
        tagContacts: [],
        tagMeetings: [],
      },
    },
    selectedChannel: null,
    update: {
      isOpen: false,
    },
  },
  intercept: {
    add: {
      conditions: [
        [
          {
            condition: "",
            field: { label: "", name: "" },
            value: "",
          },
        ],
      ],
      enabled: false,
      fieldObjectType: { type: null },
      fieldObjectsDropdownData: [],
      isOpen: false,
      name: "",
      selectedIntegration: { id: null },
      tagAutomation: {
        tagContacts: [],
        tagMeetings: [],
      },
    },
    selectedChannel: null,
    update: {
      isOpen: false,
    },
  },
  update: {
    add: {
      conditions: [
        [
          {
            condition: "",
            field: { label: "", name: "" },
            value: "",
          },
        ],
      ],
      enabled: false,
      fieldObjectType: { type: null },
      fieldObjectsDropdownData: [],
      isOpen: false,
      meetingStatus: [],
      name: "",
      selectedIntegration: { id: null },
      tagAutomation: {
        tagContacts: [],
        tagMeetings: [],
      },
    },
    selectedChannel: null,
    update: {
      isOpen: false,
    },
  },
};

export const ModalStateReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_UPDATE_IS_OPEN":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          update: {
            ...state[payload.channelType].update,
            isOpen: payload.value,
          },
        },
      };
    case "SET_ADD_STATE":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            ...payload.value,
          },
        },
      };
    case "SET_ADD_IS_OPEN":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            isOpen: payload.value,
          },
        },
      };
    case "SET_ADD_SELECTED_INTEGRATION":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            selectedIntegration: payload.value,
          },
        },
      };
    case "SET_ADD_CHANNEL_STATUS":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            channelStatus: payload.value,
          },
        },
      };
    case "SET_ADD_FIELD_OBJECT_TYPE":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state.add,
            fieldObjectType: payload.value,
          },
        },
      };
    case "SET_ADD_FIELD_OBJECT_DROPDOWN_DATA":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            fieldObjectsDropdownData: payload.value,
          },
        },
      };
    case "SET_ADD_CONDITIONS":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            conditions: payload.value,
          },
        },
      };
    case "SET_ADD_NAME":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          add: {
            ...state[payload.channelType].add,
            name: payload.value,
          },
        },
      };
    case "SET_SELECTED_CHANNEL":
      return {
        ...state,
        [payload.channelType]: {
          ...state[payload.channelType],
          selectedChannel: payload.value,
        },
      };
    default:
      throw new Error(
        `Dispatched action of type ${type} does not exist in the ImportChannelModalReducer`
      );
  }
};

export const ChannelsStateContext = createContext();
export const ModalStateContext = createContext();
