import React, { useCallback, useState } from "react";
import {
  mdiPencilOutline,
  mdiTagOffOutline,
  mdiTrashCanOutline,
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { useHasOrgAdminPermissions } from "../../auth";
import { ButtonContainer } from "../button";
import { TableCell } from "../tableV4";
import { isConflictError } from "../../error";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { CheckBoxCell } from "./TagManagerCheckboxCell";
import { useTagNameInput } from "./TagManagerNameInput";
import style from "./style.module.scss";

export function TagManagerRow({
  editTag,
  tag,
  isChecked,
  removeTagsByIds,
  toggleTag,
}) {
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const { addGeneralNotification } = useGeneralNotifications();
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const { name: newName, isValid, input, reset } = useTagNameInput(tag);
  const startEditing = useCallback(() => {
    setIsBeingEdited(true);
    reset();
  }, [reset, setIsBeingEdited]);
  const stopEditing = useCallback(() => {
    setIsBeingEdited(false);
  }, [setIsBeingEdited]);
  const removeTag = useCallback(() => {
    removeTagsByIds([tag.id]);
  }, [removeTagsByIds, tag]);
  const save = useCallback(async () => {
    const { name: previousName } = tag;
    try {
      setIsBeingEdited(false);
      await editTag(
        tag.id,
        {
          ...tag,
          name: newName.trim(),
        },
        true
      );
    } catch (error) {
      // Set the tag back to the original name
      await editTag(
        tag.id,
        {
          ...tag,
          name: previousName,
        },
        false
      );
      if (isConflictError(error)) {
        addGeneralNotification(
          `tag ${newName} already exists`,
          mdiTagOffOutline
        );
      }
    }
  }, [addGeneralNotification, editTag, newName, setIsBeingEdited, tag]);
  return (
    <tr key={tag.id}>
      {!isBeingEdited && (
        <TableCell>
          <CheckBoxCell tag={tag} checked={isChecked} toggleTag={toggleTag} />
        </TableCell>
      )}
      {!isBeingEdited && (
        <TableCell>
          <div className={style.tagManager__text}>
            <span>{tag.name}</span>
          </div>
        </TableCell>
      )}
      {isBeingEdited && (
        <TableCell colSpan={2}>
          <div className={style.tagManager__input}>{input}</div>
        </TableCell>
      )}
      {hasOrgAdminPermissions && (
        <TableCell>
          {!isBeingEdited && (
            <div className={style.tagManagerRow__buttons}>
              <ButtonContainer
                name={`/tag_manager/delete/${tag.id}`}
                transparent
                onClick={removeTag}
              >
                <Icon
                  className={style.tagManager__menuCellIcon}
                  path={mdiTrashCanOutline}
                  size={0.9}
                />
              </ButtonContainer>
              <div style={{ marginLeft: "10px" }} />
              <ButtonContainer
                name={`/tag_manager/edit/${tag.id}`}
                transparent
                onClick={startEditing}
              >
                <Icon
                  className={style.tagManager__menuCellIcon}
                  path={mdiPencilOutline}
                  size={0.9}
                />
              </ButtonContainer>
            </div>
          )}
          {isBeingEdited && (
            <div className={style.tagManagerRow__buttons}>
              <ButtonContainer
                name={`/tag_manager/edit/${tag.id}/save`}
                disabled={!isValid}
                primary
                small
                onClick={save}
              >
                Save
              </ButtonContainer>
              <div style={{ marginLeft: "8px" }} />
              <ButtonContainer
                name="/tag_manager/cancel_edit_button"
                secondary
                small
                onClick={stopEditing}
              >
                Cancel
              </ButtonContainer>
            </div>
          )}
        </TableCell>
      )}
    </tr>
  );
}
