import React, { useCallback, useMemo, useState } from "react";

import { Logo } from "./Logo";
import { INTEGRATION_AUTH_STATUS } from "../props";
import { ConfigModal } from "./ConfigModal";
import {
  ComingSoon,
  DeleteButton,
  ReauthorizeButton,
  SandboxButton,
  ConfigurationButton,
  ConnectButton,
  DisconnectButton,
} from "../buttons";
import {
  IntegrationCard,
  IntegrationCardButtonArea,
  IntegrationCardTitle,
} from "../IntegrationCard";
import { useIntegrationsRepository } from "../../repository";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { ConnectionStatus } from "../ConnectionStatus";
import { determineIntegrationStatus } from "../util";
import style from "./style.module.scss";

export function SalesforceIntegrationCard({
  enabled,
  onConnect,
  onSandboxConnect,
  onDisconnect,
  integration = null,
  isLoading,
  onFilterChange,
}) {
  const { addGeneralNotification } = useGeneralNotifications();
  const { updateIntegrationConfig } = useIntegrationsRepository();

  const connected = integration !== null;
  const authStatus = determineIntegrationStatus(integration);

  const connectionStatus = useMemo(
    () => `Auth Status: ${authStatus}`,
    [authStatus]
  );

  // reauthorize existing integration
  const reauthorize = useCallback(() => {
    onConnect(integration.id);
  }, [integration, onConnect]);

  // connect new integration
  const connect = useCallback(() => {
    onConnect(0);
  }, [onConnect]);

  const [openConfig, setOpenConfig] = useState(false);

  const handleSave = (object, filter) => {
    const { filters } = integration.data;
    filters.import = filters.import || {};
    filters.import[object] = filter;

    const i = { ...integration };
    i.data.filters.import[object] = filter;

    onFilterChange(i);

    updateIntegrationConfig(integration.id, {
      ...filters,
    })
      .then(() => {
        addGeneralNotification(
          `Global filter for Salesforce ${object} imports successfully updated`
        );
      })
      .catch(() => {
        addGeneralNotification(
          `Failed to update global filter for Salesforce ${object} imports`
        );
      });
  };

  return (
    <IntegrationCard enabled={enabled}>
      {connected && <ConnectionStatus>{connectionStatus}</ConnectionStatus>}
      <Logo />

      <IntegrationCardTitle>Salesforce</IntegrationCardTitle>

      <IntegrationCardButtonArea>
        {isLoading && <span>Loading Integrations...</span>}
        {enabled &&
          !connected &&
          authStatus === INTEGRATION_AUTH_STATUS.DISABLED && (
            <>
              <ConnectButton
                name="/integrations/salesforce/connect"
                onClick={connect}
              />
              {onSandboxConnect !== null && (
                <SandboxButton
                  name="/integrations/salesforce/sandbox"
                  onClick={onSandboxConnect}
                />
              )}
            </>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.EXPIRED && (
            <div className={style.reauth_container}>
              <ReauthorizeButton
                name="/integrations/salesforce/reauthorize"
                onClick={reauthorize}
              />
              <DeleteButton
                name="/integrations/salesforce/delete"
                onClick={onDisconnect}
              />
            </div>
          )}

        {enabled &&
          connected &&
          authStatus === INTEGRATION_AUTH_STATUS.CURRENT && (
            <DisconnectButton
              name="/integrations/salesforce/disconnect"
              onClick={onDisconnect}
            />
          )}
        {!enabled && <ComingSoon />}
      </IntegrationCardButtonArea>
      {enabled &&
        connected &&
        authStatus === INTEGRATION_AUTH_STATUS.CURRENT && (
          <ConfigurationButton
            name="/integrations/salesforce/config"
            onClick={() => setOpenConfig(true)}
          />
        )}
      <ConfigModal
        integration={integration}
        open={openConfig}
        onClose={() => setOpenConfig(false)}
        onSave={handleSave}
      />
    </IntegrationCard>
  );
}
