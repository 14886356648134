import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export type Intent =
  | "altdatetime"
  | "accept"
  | "decline"
  | "unsubscribe"
  | "unknown";

export interface ProposedTimeRange {
  start: string;
  end: string;
}

export interface MerlinResponse {
  data: {
    request_id: string;
    intent: Intent;
    proposed_times: string[] | null;
    proposed_time_ranges: ProposedTimeRange[] | null;
  };
  success: boolean;
}

export function useMerlin() {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    guest: {
      email: string;
      first_name: string;
      last_name: string;
      timezone: string;
    },
    text: string,
    inviteTime: Date,
    replyTime: Date
  ): Promise<MerlinResponse> =>
    service
      .post(`/api/merlin/intent`)
      .set(headers)
      .send({
        guest,
        invite_time: inviteTime,
        reply_time: replyTime,
        text,
      })
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler);
}
