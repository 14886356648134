export type GuestStatus =
  | "accepted"
  | "accept_pending_quorum"
  | "negotiation_in_progress"
  | "waiting_for_first_response"
  | "away"
  | "cancelled"
  | "declined"
  | "host_intervened"
  | "no_response"
  | "paused"
  | "requires_host_intervention"
  | "responded_unknown_intent"
  | "staging"
  | "undeliverable";

export interface GuestStatusOption {
  label: string;
  value: GuestStatus;
}

export const GuestStatusOptions: GuestStatusOption[] = [
  { label: "Accepted", value: "accepted" },
  { label: "Accept Pending Quorum", value: "accept_pending_quorum" },
  { label: "AI Negotiation", value: "negotiation_in_progress" },
  { label: "Awaiting Response", value: "waiting_for_first_response" },
  { label: "Away", value: "away" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Declined", value: "declined" },
  { label: "Host Intervened", value: "host_intervened" },
  { label: "No Response", value: "no_response" },
  { label: "Paused", value: "paused" },
  {
    label: "Requires Host Intervention",
    value: "requires_host_intervention",
  },
  { label: "Responded Unknown Intent", value: "responded_unknown_intent" },
  { label: "Staging", value: "staging" },
  { label: "Undeliverable", value: "undeliverable" },
];

export function guestStatusOptionFromValue(
  status: string
): GuestStatusOption | null {
  return GuestStatusOptions.find((o) => o.value === status) || null;
}

export function isValidGuestStatus(status: string): boolean {
  return GuestStatusOptions.find((o) => o.value === status) !== undefined;
}
