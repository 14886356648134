import { useMemo } from "react";
import { useConfigurationValue } from "./useConfigurationValue";

// Returns a given configuration value or the supplied default value if the
// configuration parameter is not defined or if the value is not a parsable
// integer.
export function useConfigurationBooleanValue(
  key: string,
  defaultValue: boolean
): boolean {
  const value = useConfigurationValue(key, defaultValue ? "true" : "false")
    .toLowerCase()
    .trim();

  return useMemo(() => {
    return value === "true" || value === "1" || defaultValue;
  }, [value, defaultValue]);
}
