import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

import Flexbox from "../../components/flexbox/FlexboxV2";
import Card from "../../components/card/Card";
import Messenger from "./messenger";
import MeetingDetails from "./meetingDetails";
import Close from "./close";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import {
  SET_MEETING_TYPE,
  SET_MESSAGES,
  MeetingContext,
  useMeetingTypeState,
  useMessengerState,
} from "./state";
import {
  useMeetingHistoryRepository,
  useMeetingTypesRepository,
} from "../../repository";

import useNewMeeting from "../useNewMeeting";
import { INITIALIZING } from "../../globalLogs/builders/props";
import style from "./instanceDetails.module.scss";
import { buildUrl } from "../../utils/fetchV2";
import { useMeeting } from "../../queries";

function useMeetingId() {
  const params = useParams();
  const { id } = params;
  try {
    if (id === "new") {
      return "new";
    }
    const parsedId = parseInt(id);
    if (Number.isNaN(parsedId)) {
      throw new Error("Non-new id should be a number");
    }
    if (parsedId < 0) {
      throw new Error("Id cannot be less than or equal to zero");
    }
    return parsedId;
  } catch (error) {
    return null;
  }
}

// TODO: Add an error boundary or some other form of error handling to this component
function InstanceDetails({ close, initialized, meeting }) {
  const { getMeetingTypeById } = useMeetingTypesRepository();
  const { getMeetingHistory } = useMeetingHistoryRepository();
  const { addError } = useGeneralNotifications();
  const { meetingTypeDispatch } = useMeetingTypeState();
  const { messengerDispatch } = useMessengerState();
  const meetingTypeId = useMemo(() => {
    if (!meeting?.meetingType?.id) {
      return null;
    }
    return meeting.meetingType.id;
  }, [meeting]);
  useEffect(() => {
    const handleFetchMeetingType = async () => {
      try {
        if (meetingTypeId !== null) {
          const response = await getMeetingTypeById(meetingTypeId);
          meetingTypeDispatch({
            payload: { meetingType: response },
            type: SET_MEETING_TYPE,
          });
        }
      } catch (error) {
        addError("There was a problem fetching the Meeting Template");
      }
    };
    handleFetchMeetingType();
  }, [addError, getMeetingTypeById, meetingTypeId, meetingTypeDispatch]);

  const meetingId = meeting?.id || null;

  // Messages
  useEffect(() => {
    const handleFetchMessages = async () => {
      try {
        if (meetingId !== null) {
          const messages = await getMeetingHistory(meetingId);
          messengerDispatch({
            payload: { messages },
            type: SET_MESSAGES,
          });
        }
      } catch {
        addError("There was an issue fetching messages");
      }
    };

    handleFetchMessages();
  }, [meetingId, getMeetingHistory, addError, messengerDispatch]);

  return (
    <MeetingContext.Provider value={[meeting]}>
      <Flexbox.Column
        alignItems="center"
        height="100%"
        width="100%"
        justifyContent="center"
      >
        <Card
          className={style.instanceDetails}
          contentClassName={style.instanceDetails__content}
        >
          <Close close={close} />
          {initialized && (
            <Flexbox.Row height="100%">
              <Messenger />
              <MeetingDetails />
            </Flexbox.Row>
          )}
          {!initialized && (
            <Flexbox.Row
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Icon path={mdiLoading} size={5} spin={1} />
            </Flexbox.Row>
          )}
        </Card>
      </Flexbox.Column>
    </MeetingContext.Provider>
  );
}

function InstanceDetailsUsingInstanceTableData({
  close,
  initialized,
  meeting,
}) {
  return (
    <InstanceDetails
      close={close}
      initialized={initialized}
      meeting={meeting}
    />
  );
}

function InstanceDetailsUsingNonInstanceTableData({ close }) {
  const meetingId = useMeetingId();
  const { data: meeting, error, loading } = useMeeting(meetingId);
  useEffect(() => {
    if (!loading && (meeting === null || meeting === undefined)) {
      close();
    }
  }, [close, loading, meeting]);
  if (error) {
    return <div>Id Not Found</div>;
  }
  return (
    <InstanceDetails close={close} initialized={!loading} meeting={meeting} />
  );
}

function InstanceDetailsUsingNewMeeting({ close }) {
  const history = useHistory();
  const onNewMeetingCreate = useCallback(
    (newMeeting) => {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      history.push(buildUrl(`/instances/${newMeeting.id}`, params.entries()));
    },
    [history]
  );
  const { guests, host, meetingType, tags } = useNewMeeting({
    onCreate: onNewMeetingCreate,
  });
  const meeting = {
    guests,
    host,
    meetingType,
    status: INITIALIZING,
    tags,
  };

  return <InstanceDetails close={close} initialized meeting={meeting} />;
}

function InstanceDetailsStrategy({ close, initialized, meetings }) {
  const meetingId = useMeetingId();
  useEffect(() => {
    if (meetingId === null) {
      close();
    }
  }, [close, meetingId]);
  if (meetingId === "new") {
    return <InstanceDetailsUsingNewMeeting close={close} />;
  }
  if (meetingId === null) {
    return <div>Need a small view for invalid ids</div>;
  }
  if (initialized) {
    const meeting = meetings.find((m) => m.id === meetingId) || null;
    if (meeting !== null) {
      return (
        <InstanceDetailsUsingInstanceTableData
          close={close}
          initialized={initialized}
          meeting={meeting}
        />
      );
    }
  }
  return <InstanceDetailsUsingNonInstanceTableData close={close} />;
}

export default InstanceDetailsStrategy;
