import { useCallback, useRef } from "react";
import { buildRequest } from "../utils/fetchV2";
import { API } from "../props";
import { useCustomFetchFn } from "../components/fetch";

const buildGetUserSettingsByIdRequest = (id) =>
  buildRequest(API.appSettings.custom(id));

export const buildUpdateUserSettingsRequest = ({ id, body }) =>
  buildRequest(API.appSettings.custom(id), {
    body,
    method: "PATCH",
  });

const buildBulkUpdateSchedulersRequest = ({ body }) =>
  buildRequest(API.appSettings.bulkSchedulers, {
    body,
    method: "PATCH",
  });

const buildBulkUpdateTimezoneRequest = ({ body }) =>
  buildRequest(API.appSettings.bulkTimezone, {
    body,
    method: "PATCH",
  });

export default function useAppSettingsRepository() {
  const { fetch } = useCustomFetchFn();

  const getUserSettingsById = useCallback(
    async (userId) =>
      fetch(buildGetUserSettingsByIdRequest(userId)).then((res) => res.json()),
    [fetch]
  );

  const updateUserSettings = useCallback(
    async (body, userId) =>
      fetch(
        buildUpdateUserSettingsRequest({
          body: JSON.stringify(body),
          id: userId,
        })
      ),
    [fetch]
  );

  const bulkUpdateSchedulers = useCallback(
    async (body) =>
      fetch(
        buildBulkUpdateSchedulersRequest({
          body: JSON.stringify(body),
        })
      ),
    [fetch]
  );

  const bulkUpdateTimezone = useCallback(
    async (body) =>
      fetch(
        buildBulkUpdateTimezoneRequest({
          body: JSON.stringify(body),
        })
      ),
    [fetch]
  );

  const { current } = useRef({
    bulkUpdateSchedulers,
    bulkUpdateTimezone,
    getUserSettingsById,
    updateUserSettings,
  });

  return current;
}
