import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormLabel, Stack } from "@mui/material";

import { useExportContacts } from "../../../../mutations";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";

import TagFilter from "../../../../importExport/export/filters/TagFilter";
import { PageLayout } from "../../../../componentsV2/PageLayout";
import PrimaryButton from "../../../../componentsV2/buttons/PrimaryButton";
import Input from "../../../../componentsV2/inputs/Input";
import { Loading } from "../../../../componentsV2/Loading";
import UnsubscribedFilter from "../../../../importExport/export/filters/UnsubscribedFilter";
import { Tag } from "../../../../types";
import { useUrlQuery } from "../../../../hooks";

function ExportContacts() {
  const [loading, setLoading] = useState(false);

  const query = useUrlQuery();

  // BARF Todo: fix this ugly shit
  let initialTags;
  try {
    initialTags = JSON.parse(query.tags);
  } catch {
    // do nothing
  }

  const [unsubscribed, setUnsubscribed] = useState(
    query.view === "Unsubscribed"
  );
  const [account, setAccount] = useState(query.account || "");
  const [firstName, setFirstName] = useState(query.firstName || "");
  const [lastName, setLastName] = useState(query.lastName || "");
  const [email, setEmail] = useState(query.email || "");
  const [tags, setTags] = useState<Tag[]>(initialTags || []);
  const [routingField, setRoutingField] = useState(query.routingField || "");
  const [logicField, setLogicField] = useState(query.logicField || "");

  const history = useHistory();
  const exportContacts = useExportContacts();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  const startProcess = async () => {
    setLoading(true);
    exportContacts({
      filter: {
        account,
        email,
        firstName,
        lastName,
        logicField,
        routingField,
        tags: tags.map((i) => i.id),
        unsubscribed,
      },
    })
      .then(() => {
        addGeneralNotification("Request submitted successfully");
        history.push("/reports");
      })
      .catch(() => {
        addError(
          "We are unable to process this request. Please try again later"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout
      title="Export Contacts"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Export", route: "/reports/export" },
        { name: "Contacts", route: "/reports/export/contacts" },
      ]}
    >
      <Stack spacing={2}>
        <FormLabel>Search</FormLabel>
        <Input
          value={account}
          label="Account"
          onChange={(e) => setAccount(e.target.value)}
        />
        <Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          label="Logic Field 1"
          value={logicField}
          onChange={(e) => setLogicField(e.target.value)}
        />
        <Input
          label="Routing Field"
          value={routingField}
          onChange={(e) => setRoutingField(e.target.value)}
        />
        <FormLabel>Filter</FormLabel>
        <TagFilter value={tags} onChange={setTags} />
        <UnsubscribedFilter value={unsubscribed} onChange={setUnsubscribed} />
        {loading ? (
          <Loading />
        ) : (
          <PrimaryButton onClick={startProcess} disabled={loading}>
            Export
          </PrimaryButton>
        )}
      </Stack>
    </PageLayout>
  );
}

export default ExportContacts;
