import React from "react";

import { formatWordForPossession, capitalize } from "./util";
import style from "./globallogs.module.scss";

const buildLogLine = (actorEmail, userEmail, label, value) => {
  if (actorEmail && actorEmail !== userEmail) {
    return (
      <span>
        {`${capitalize(actorEmail)} `}
        changed
        {` ${formatWordForPossession(userEmail)} `}
        {` ${label} `}
        to
        <span className={style.logBuilder__userAttributeChange}>
          {` ${value || '""'} `}
        </span>
      </span>
    );
  }
  return (
    <span>
      {`${capitalize(userEmail)} `}
      changed their
      {` ${label} `}
      to
      <span className={style.logBuilder__userAttributeChange}>
        {` ${value || '""'} `}
      </span>
    </span>
  );
};

const buildAppSettingsUpdatedLog = function* (log) {
  const {
    structuredData: {
      actorUserEmail,
      userEmail,
      userAvailability,
      userTimezone,
    },
  } = log;

  if (userAvailability?.length > 0) {
    yield buildLogLine(
      actorUserEmail,
      userEmail,
      "availability",
      userAvailability
    );
  }

  if (userTimezone?.length > 0) {
    yield buildLogLine(actorUserEmail, userEmail, "timezone", userTimezone);
  }
};

export { buildAppSettingsUpdatedLog as default };
