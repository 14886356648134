import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";
import { WeekSchedule } from "src/types/Availability";

import {
  isCustomSchedulingMode,
  MeetingDefinitionTeamMember,
  SchedulingMode,
} from "src/types";
import useSWR from "swr/immutable";

export function useMeetingDefinitionBusyTeamMembers(
  templateId: number | undefined,
  templateSchedulingMode: SchedulingMode,
  templateWeekSchedule: WeekSchedule,
  templateTimezone: string,
  templateIsRoutingJustMe: boolean
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const body = {
    weekSchedule: templateWeekSchedule,
    timezone: templateTimezone,
    isRoutingJustMe: templateIsRoutingJustMe,
    schedulingMode: templateSchedulingMode,
  };

  const url = `/api/meetings/definition/${templateId}/busy_team_members`;
  const shouldExecuteQuery =
    templateId &&
    templateWeekSchedule &&
    templateTimezone &&
    !templateIsRoutingJustMe &&
    isCustomSchedulingMode(templateSchedulingMode);

  const { data, error } = useSWR(shouldExecuteQuery ? [url, body] : null, () =>
    service
      .post(url)
      .set(headers)
      .send(body)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as MeetingDefinitionTeamMember[] | undefined,
    error,
    loading: !error && !data,
  };
}
