import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@mui/material";

import { Organization } from "src/types";
import Table, { Column, TableSort } from "src/componentsV2/tables/Table";
import { useDisableOrg } from "src/kronologic-me/mutations/useDisableOrg";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";

export function KMeOrganizationTable({
  loading,
  minRows,
  organizations = [],
  sort,
  onSortChange,
  onUpdateStatus,
}: {
  loading: boolean;
  minRows: number;
  organizations: Organization[];
  sort: TableSort;
  onSortChange: (value: TableSort) => void;
  onAddDomain: (org: Organization) => void;
  onUpdateStatus: (org: Organization) => void;
}) {
  const [disableOrg, setDisableOrg] = useState<Organization | null>(null);
  const columns: Column<Organization>[] = [
    {
      component: (row: Organization) => <>{row.id}</>,
      id: "id",
      label: "Id",
      sortable: false,
    },
    {
      component: (row: Organization) => (
        // currently, only kronologic.me orgs may be disabled
        <Switch
          checked={row.enabled}
          onChange={() => {
            if (row.enabled) {
              // if org is currently disabled, bring up warning dialog
              setDisableOrg(row);
              return;
            }

            // otherwise, we are enabling an org. we do not need to warn
            onUpdateStatus(row);
          }}
        />
      ),
      id: "enabled",
      label: "Enabled",
      sortable: true,
    },
    {
      component: (row: Organization) => <>{row.name}</>,
      id: "name",
      label: "Name",
      sortable: true,
    },
    {
      component: (row: Organization) => <>{row.schemaName}</>,
      id: "schemaName",
      label: "Schema Name",
      sortable: false,
    },
  ];

  return (
    <>
      <DisableOrgDialog
        open={!!disableOrg}
        org={disableOrg}
        onClose={() => {
          setDisableOrg(null);
        }}
      />
      <Table
        loading={loading}
        getKey={(o) => o.id}
        columns={columns}
        data={organizations}
        minRows={minRows}
        sort={sort}
        onSortChange={onSortChange}
      />
    </>
  );
}

type DisableOrgDialogProps = {
  org?: Organization | null;
  open: boolean;
  onClose: () => void;
};
const DisableOrgDialog = ({ open, org, onClose }: DisableOrgDialogProps) => {
  const disableOrg = useDisableOrg();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  if (!org) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          Disable{" "}
        </Typography>
        <Typography
          component="span"
          sx={{ color: "primary.dark", fontWeight: "bold" }}
        >
          {org.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography component="span">
          You are about to disable the following Organization:
        </Typography>
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {` ${org.name}. `}
        </Typography>
        <Typography component="span">
          While {org.name} is disabled, users of this organization will not be
          able to log in. Additionally, all processing (meetings, scheduling,
          etc...) for this organization will stop. {org.name} may be re-enabled
          at any time.
        </Typography>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={async () => {
            try {
              await disableOrg(org.id);
              addGeneralNotification(`${org.name} has been disabled.`);
              onClose();
            } catch {
              addError(
                `There was a problem disabling ${org.name}. Please try again later.`
              );
            }
          }}
        >
          Confirm
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
