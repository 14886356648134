import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export interface TimeRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

function overlap(x: TimeRange, y: TimeRange, duration: number): boolean {
  const durationX = x.end.unix() - x.start.unix();
  const durationY = y.end.unix() - y.start.unix();

  const width =
    Math.max(x.end.unix(), y.end.unix()) -
    Math.min(x.start.unix(), y.start.unix());

  return durationX + durationY - width >= duration * 60;
}

function intersection(
  x: TimeRange,
  y: TimeRange,
  duration: number
): TimeRange | null {
  if (!overlap(x, y, duration)) {
    return null;
  }

  const start = Math.max(x.start.unix(), y.start.unix());
  const end = Math.min(x.end.unix(), y.end.unix());

  return {
    start: dayjs.unix(start).utc(),
    end: dayjs.unix(end).utc(),
  };
}

export function findSlot(
  availability: TimeRange[],
  proposedTimeRanges: TimeRange[],
  duration: number
): TimeRange | null {
  for (const r of proposedTimeRanges) {
    for (const a of availability) {
      if (overlap(r, a, duration)) {
        const i = intersection(r, a, duration);

        return i;
      }
    }
  }

  return null;
}
