import cx from "classnames";
import React, { createContext, useContext } from "react";

import style from "./gridTable.module.scss";

const TableHeaderRow = React.forwardRef(function TableHeaderRow(
  { containerClassName, className, children },
  ref
) {
  return (
    <div className={cx(style.tableHeaderRowContainer, containerClassName)}>
      <div className={cx(style.tableHeaderRow, className)} ref={ref}>
        {children}
      </div>
    </div>
  );
});

function TableHeader({
  className,
  children,
  disableSortBy = false,
  style: headerStyle,
  ...props
}) {
  return (
    <div
      {...props}
      className={cx(style.table__headerCell, className, {
        [style["table__headerCell--clickable"]]: !disableSortBy,
      })}
      style={{
        gridRow: 1,
        ...headerStyle,
      }}
    >
      {children}
    </div>
  );
}

const TableBody = React.forwardRef(function TableBody(
  { className, children, rowSize = "auto", ...props },
  ref
) {
  return (
    <div
      {...props}
      className={cx(style.tableBody, className)}
      ref={ref}
      style={{
        gridAutoRows: rowSize,
      }}
    >
      {children}
    </div>
  );
});

const GridRowContext = createContext();

const TableCell = React.forwardRef(function TableCell(
  { children, style: tableCellStyle, ...props },
  ref
) {
  const gridRow = useContext(GridRowContext);
  return (
    <div
      {...props}
      style={{
        gridRow,
        ...tableCellStyle,
      }}
      ref={ref}
    >
      {children}
    </div>
  );
});

export { GridRowContext, TableBody, TableCell, TableHeader, TableHeaderRow };
