import React from "react";
import cx from "classnames";

import style from "./input.module.scss";

export function InputGroup({
  autocomplete: isAutoCompleteOn = false,
  children,
  className,
  disabled: isDisabled,
  left: isLeft = false,
  large: isLarge = false,
  name,
  inputRef,
  ...props
}) {
  return (
    <span
      aria-label={name}
      autoComplete={isAutoCompleteOn ? "on" : "off"}
      className={cx(
        style.input__group,
        {
          [style["input__group--left"]]: isLeft,
          [style["input__group--large"]]: isLarge,
        },
        className
      )}
      disabled={isDisabled}
      name={name}
      ref={inputRef}
      {...props}
    >
      {children}
    </span>
  );
}
