import React, { createElement, useMemo } from "react";

function Flexbox(
  {
    alignItems = "normal",
    children,
    column: isColumn = false,
    element = "div",
    flex = "initial",
    height = "auto",
    overflow = "visible",
    justifyContent = "normal",
    row: isRow = false,
    style,
    minWidth = null,
    maxHeight = null,
    wrap = "nowrap",
    width = "100%",
    ...props
  },
  ref
) {
  const flexDirection = useMemo(() => {
    if (isColumn) {
      return "column";
    }
    if (isRow) {
      return "row";
    }
    return "row";
  }, [isColumn, isRow]);
  return createElement(
    element,
    {
      style: {
        ...style,
        alignItems,
        display: "flex",
        flex,
        flexDirection,
        flexWrap: wrap,
        height,
        justifyContent,
        maxHeight,
        minWidth,
        overflow,
        width,
      },
      ...props,
      ref,
    },
    children
  );
}

const FlexboxWithRef = React.forwardRef(Flexbox);

function Column({ children, ...props }, ref) {
  return (
    <FlexboxWithRef column {...props} ref={ref}>
      {children}
    </FlexboxWithRef>
  );
}

function Row({ children, ...props }, ref) {
  return (
    <FlexboxWithRef row {...props} ref={ref}>
      {children}
    </FlexboxWithRef>
  );
}

FlexboxWithRef.Column = React.forwardRef(Column);
FlexboxWithRef.Row = React.forwardRef(Row);

export default FlexboxWithRef;
