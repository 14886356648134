import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { TokenStatus, User, UserStatus } from "../types";
import { buildQueryString } from "../utils";
import { errorHandler } from "src/hooks/errorHandler";

export function useUsers(
  limit: number,
  offset: number,
  options: {
    query?: string;
    team?: number | string;
    status?: UserStatus;
    tokenStatus?: TokenStatus;
    tags?: number[];
    sortBy?: "firstName" | "lastName" | "email" | "signUpDate" | "meetingLink";
    order?: "asc" | "desc";
    forMeetingTransfer?: boolean;
  }
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const q = buildQueryString({
    limit,
    offset,
    qry: options?.query,
    team: options?.team,
    statusFilter: options?.status,
    calendarTokenStatusFilter: options?.tokenStatus,
    tag: options?.tags?.join(","),
    sortBy: options?.sortBy
      ? `${options.sortBy}+${options?.order || "asc"}`
      : undefined,
  });

  // TODO(vovan): this is not ideal, we probably need another query hook.
  //  Also backend /users/for_meeting_transfer API is broken and
  //  completely ignores search query, always returning full list of
  //  active org users.
  const url =
    (options?.forMeetingTransfer && `/api/users/for_meeting_transfer?${q}`) ||
    `/api/users?${q}`;

  const { data, error } = useSWR(url, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as
      | {
          total: number;
          data: User[];
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}
