import { Delete, Error } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useOpenAiIntegration } from "src/features";
import { isLinkFirst } from "src/meetingTypes/invite/props";
import { getDynamicVariableMappings } from "src/meetingTypes/useDynamicVariableMappings";
import { MeetingDefinition } from "src/types";
import { buildRange } from "src/utils/helpers";
import { TipTapPreview } from "./RichText";
import TemplateAIModal from "./TemplateAIModal";
import SecondaryButton from "./buttons/SecondaryButton";
import { EmailEditor, cleanRichTextHTML } from "./inputs";

export const TemplateEditInviteAndEmail = () => {
  const { control, register, setValue, getValues, watch, clearErrors } =
    useFormContext<MeetingDefinition>();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Invite & Email Content
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Enter the copy for your calendar invite and email. Set any additional attempts, decline messages, and unsubscribe settings.`
            : `Enter the copy for your calendar invite and email.`}
        </Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" fontWeight="bold">
          Calendar Invite
        </Typography>

        {getValues("inviteStyle") === "calendar_first" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Invite
            </Typography>
            , this will be sent at the same time as the calendar invite.
          </Typography>
        )}

        {getValues("inviteStyle") === "custom" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Email
            </Typography>{" "}
            this will only show on the guest's calendar once they have accepted
            via the first email.
          </Typography>
        )}

        <CalendarInvite />
        <Divider sx={{ my: 5 }} />
      </Box>

      <Typography variant="h5" fontWeight="bold">
        Email
      </Typography>

      {getValues("inviteStyle") === "calendar_first" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Invite
          </Typography>
          , this will be sent at the same time as the calendar invite.
        </Typography>
      )}

      {getValues("inviteStyle") === "custom" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Email
          </Typography>{" "}
          this will be sent to the customer first, the calendar invite will be
          sent once they have accepted the meeting request
        </Typography>
      )}

      <Box sx={{ mb: 5 }}>
        {!isLinkFirst(getValues("inviteStyle")) && <EmailTemplatesAccordion />}

        {isLinkFirst(getValues("inviteStyle")) && (
          <Box sx={{ mb: 5 }}>
            <EmailTemplateInvite emailIndex={0} />
          </Box>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={getValues("properties.cleanDeclineRule.enabled")}
              onChange={(event) => {
                clearErrors("properties.cleanDeclineRule");

                if (!event.target.checked) {
                  return setValue("properties.cleanDeclineRule.enabled", false);
                }

                setValue("properties.cleanDeclineRule.enabled", true);
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              Decline Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, the guest will receive an additional message after
            declining the meeting
          </Typography>

          {getValues("properties.cleanDeclineRule.enabled") && <DeclineEmail />}
        </Box>
      )}

      <Box sx={{ mb: 5 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <Switch
            checked={getValues("properties.meetingReminder.enabled")}
            onChange={(event) => {
              clearErrors("properties.meetingReminder");

              // do NOT null out the meeting reminder, we can keep what the user has saved
              if (!event.target.checked) {
                return setValue("properties.meetingReminder", {
                  ...getValues("properties.meetingReminder"),
                  enabled: false,
                });
              }

              setValue("properties.meetingReminder", {
                ...getValues("properties.meetingReminder"),
                enabled: true,
              });
            }}
          />
          <Typography variant="h5" fontWeight="bold">
            Meeting Reminder
          </Typography>
        </Stack>
        <Typography sx={{ mb: 2 }}>
          If enabled, the guest will receive a reminder email at the configured
          interval before the start of the meeting
        </Typography>

        {getValues("properties.meetingReminder.enabled") && (
          <>
            <ReminderOptions />
            <ReminderEmail />
          </>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="unsubscribeTemplate.enabled"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              Unsubscribe Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, all emails from this template will include an
            unsubscribe link
          </Typography>

          {watch("unsubscribeTemplate.enabled") && (
            <Stack sx={{ gap: 2, mt: 2 }}>
              <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  What should the body of the unsubscribe clause say
                </Typography>
                <Typography>
                  Use {"{{link}}"} to signify where you'd like to hyperlink text
                  to appear
                </Typography>
              </Box>
              <TextField
                {...register("unsubscribeTemplate.body")}
                size="small"
                placeholder={`If you do not wish to receive meeting requests from Kronologic, {{link}}`}
                sx={{ width: "100%", mb: 2 }}
              />
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                What should the hyperlink say?
              </Typography>
              <TextField
                {...register("unsubscribeTemplate.hyperlinkText")}
                size="small"
                placeholder="Click Here"
                sx={{ width: "100%", mb: 2 }}
              />
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

const CalendarInvite = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const openAIEnabled = useOpenAiIntegration();

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("inviteTemplates.0.title")
  );
  const previewLocation = getDynamicVariableMappings(
    getValues("inviteTemplates.0.location")
  );
  const previewNotes = getDynamicVariableMappings(
    getValues("inviteTemplates.0.body")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      {selectedTab === "draft" && (
        <>
          <TextField
            {...register(`inviteTemplates.0.title`, {
              required: {
                value: true,
                message: "Invite Title is required",
              },
              minLength: {
                value: 5,
                message: "Invite Title must be at least 5 characters.",
              },
            })}
            size="small"
            label="Invite Title"
            sx={{ width: "100%", mb: 2 }}
          />
          {errors.inviteTemplates?.[0]?.title && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.title.message}
            </Box>
          )}

          <TextField
            {...register(`inviteTemplates.0.location`, {
              required: {
                value: true,
                message: "Invite Location is required",
              },
            })}
            size="small"
            label="Invite Location"
            sx={{ width: "100%", mb: 2 }}
          />
          {errors.inviteTemplates?.[0]?.location && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.location.message}
            </Box>
          )}

          <Controller
            control={control}
            name="inviteTemplates.0.body"
            rules={{
              required: {
                value: true,
                message: "Invite Message is required",
              },
              validate: (value) =>
                value.trim().length > 0 || "Invite Message is required",
            }}
            render={({ field }) => (
              <>
                <TemplateAIModal
                  open={aiModalOpen}
                  onClose={() => {
                    setAIModalOpen(false);
                  }}
                  onAIResponse={(text: string) => {
                    field.onChange(text.trimStart());
                  }}
                />

                <TextField
                  {...field}
                  size="small"
                  label="Invite Message"
                  multiline
                  rows={10}
                  sx={{ width: "100%", mb: 2 }}
                />
              </>
            )}
          />
          {errors.inviteTemplates?.[0]?.body && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.body.message}
            </Box>
          )}
        </>
      )}
      {selectedTab === "preview" && (
        <>
          <Typography sx={{ mb: 1 }} variant="h6" fontWeight="bold">
            {previewTitle}
          </Typography>
          <Typography sx={{ mb: 4 }} color="primary">
            {previewLocation}
          </Typography>
          <Typography component="div">
            <TipTapPreview content={previewNotes} />
          </Typography>
        </>
      )}
      {openAIEnabled && (
        <SecondaryButton
          onClick={() => {
            setAIModalOpen(true);
          }}
        >
          Use AI to write your invite
        </SecondaryButton>
      )}
    </>
  );
};

const EmailTemplatesAccordion = () => {
  const [expanded, setExpanded] = useState("email-invite-0");

  const {
    control,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();
  const {
    fields: emailTemplates,
    append,
    remove,
  } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emailTemplates", // unique name for your Field Array
  });

  return (
    <>
      <Box sx={{ mb: 5 }}>
        {emailTemplates.map((emailItem, index) => (
          <Accordion
            key={emailItem.id}
            expanded={expanded === `email-invite-${index}`}
            onChange={() => {
              if (expanded === `email-invite-${index}`) {
                return setExpanded("");
              }
              setExpanded(`email-invite-${index}`);
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls={`email-invite-${index}-content`}
              id={`email-invite-${index}-header`}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {errors.emailTemplates?.[index] && (
                    <Error sx={{ color: "red" }} />
                  )}

                  <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      color: errors.emailTemplates?.[index] ? "red" : "inherit",
                    }}
                  >
                    Email Attempt #{index + 1}
                  </Typography>
                </Stack>

                {index > 0 && (
                  <IconButton sx={{ ml: "auto" }} onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <EmailTemplateInvite emailIndex={index} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {emailTemplates.length < 3 && (
        <>
          <SecondaryButton
            onClick={() =>
              append({
                body: "",
                order: emailTemplates.length + 1,
                title: "",
              })
            }
          >
            Add Email Template
          </SecondaryButton>
        </>
      )}
    </>
  );
};

type EmailTemplateInviteProps = {
  emailIndex: number;
};

const EmailTemplateInvite = (props: EmailTemplateInviteProps) => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const openAIEnabled = useOpenAiIntegration();

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.title`)
  );
  const previewBody = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.body`)
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <TextField
              {...register(`emailTemplates.${props.emailIndex}.title`, {
                required: {
                  value: true,
                  message: "Email title is required",
                },
              })}
              size="small"
              label="Email Title"
              sx={{ width: "100%", mb: 2 }}
            />
            {errors.emailTemplates?.[props.emailIndex]?.title && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.title?.message}
              </Box>
            )}

            <Controller
              control={control}
              name={`emailTemplates.${props.emailIndex}.body`}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Email Body is required",
              }}
              render={({ field }) => (
                <>
                  <TemplateAIModal
                    open={aiModalOpen}
                    onClose={() => {
                      setAIModalOpen(false);
                    }}
                    onAIResponse={(text: string) => {
                      const cleaned = cleanRichTextHTML(text);
                      field.onChange(cleaned);
                    }}
                  />

                  <Box sx={{ mb: 2 }}>
                    <EmailEditor
                      label="Email body"
                      value={field.value ?? ""}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      withMergeFields
                    />
                  </Box>
                </>
              )}
            />
            {errors.emailTemplates?.[props.emailIndex]?.body && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.body?.message}
              </Box>
            )}
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="normal">
              <Typography component="span" fontWeight="bold" fontSize="inherit">
                Subject:
              </Typography>{" "}
              {previewTitle}
            </Typography>
            {/* Only show this if "Kronologic Email" is selected */}
            {getValues("inviteStyle") === "custom" && (
              <Stack
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #E1E6EB",
                  flexDirection: "row",
                  mb: 3,
                }}
              >
                <Stack
                  sx={{
                    py: 9,
                    px: 4,
                    borderRight: "1px solid #E1E6EB",
                    backgroundColor: "#E1E6EB",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper elevation={1}>
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "5px 5px 0 0",
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        MONTH
                      </Typography>
                    </Box>
                    <Stack
                      sx={{
                        backgroundColor: "white",
                        py: 1,
                        px: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0 0 5px 5px",
                      }}
                    >
                      <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                        ##
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        00:00 AM PST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: theme.palette.primary.dark,
                        }}
                      >
                        Day
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
                <Box sx={{ py: 3, px: 4 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">
                      Calendar Invite Subject Line
                    </Typography>
                    <Typography>## Minutes</Typography>
                    <Typography>CST</Typography>
                  </Box>

                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6">
                      [DOW] [Month] [##] [YYYY] 00:00am CST{" "}
                    </Typography>
                    <Typography>
                      Location:
                      https://us02web.zoom.us/j/sdfalkjwerlkjwerlkjlkjlkj
                    </Typography>
                  </Box>

                  <Stack sx={{ mb: 1, flexDirection: "row", gap: 1 }}>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Accept
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Decline
                    </Box>
                  </Stack>

                  <Typography
                    component="span"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    See more available times
                  </Typography>
                </Box>
              </Stack>
            )}
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
        {openAIEnabled && (
          <SecondaryButton
            onClick={() => {
              setAIModalOpen(true);
            }}
          >
            Use AI to write your email
          </SecondaryButton>
        )}
      </Box>
    </>
  );
};

const DeclineEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.title")
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.body")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Decline Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.title"}
              rules={{
                required: "Decline Email Title is required",
                minLength: {
                  value: 5,
                  message: "Decline Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    size="small"
                    label="Decline Email Title"
                    sx={{ width: "100%", mb: 2 }}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.body"}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Decline Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailEditor
                      label="Decline Email Body"
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                      withMergeFields
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const ReminderEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.meetingReminder.title")
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.meetingReminder.body")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Meeting Reminder Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 1 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name="properties.meetingReminder.title"
              rules={{
                required: "Reminder Email Title is required",
                minLength: {
                  value: 5,
                  message:
                    "Reminder Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    size="small"
                    label="Reminder Email Title"
                    sx={{ width: "100%", mb: 2 }}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name="properties.meetingReminder.body"
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Reminder Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailEditor
                      label="Reminder Email Body"
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                      withMergeFields
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const ReminderOptions = () => {
  const { control } = useFormContext<MeetingDefinition>();

  const dayOptions: number[] = buildRange([0, 10, 1]);
  const hourOptions: number[] = buildRange([0, 23, 1]);
  const minuteOptions: number[] = buildRange([0, 45, 15]);

  return (
    <>
      <Typography
        sx={{
          marginBottom: 2,
        }}
      >
        Meeting reminders will not be sent if either the guest has accepted the
        meeting on day of the meeting or if the guest would have received a
        reminder email within 5 hours of accepting the meeting
      </Typography>

      <Stack direction="row" sx={{ width: "25%" }}>
        <Controller
          control={control}
          name="properties.meetingReminder.days"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-days-select">Days</InputLabel>
              <Select
                sx={{ marginRight: "5px", width: "100%" }}
                label="Days"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {dayOptions.map((day) => (
                  <MenuItem key={`day-${day}`} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.hours"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-hours-select">Hours</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Hours"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {hourOptions.map((hour) => (
                  <MenuItem key={`hour-${hour}`} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.minutes"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="reminder-minutes-select">Minutes</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Minutes"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {minuteOptions.map((minutes) => (
                  <MenuItem key={`minutes-${minutes}`} value={minutes}>
                    {minutes}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    </>
  );
};
