import { useMemo } from "react";
import { useConfigurationValue } from "./useConfigurationValue";

// Returns a given configuration value or the supplied default value if the
// configuration parameter is not defined or if the value is not a parsable
// integer.
export function useConfigurationIntValue(
  key: string,
  defaultValue: number
): number {
  const value = useConfigurationValue(key, defaultValue.toString());

  return useMemo(() => {
    return Number.parseInt(value) || defaultValue;
  }, [value, defaultValue]);
}
