import MenuIcon from "@mui/icons-material/Menu";
import { Link, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import HelpIcon from "@mui/icons-material/Help";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useQuickLaunch } from "../features";
import { Role } from "../types";
import QuickLauncher from "./QuickLaunch";
import UserMenu, { Option } from "./UserMenu";
import { useOrgAdminOnlyQuickLaunch } from "../features/useOrgAdminOnlyQuickLaunch";
import { ROLE_LEVELS, supersedes } from "../auth/roles";
import { KronologicIcon } from "src/icons/KronologicIcon";

export interface Page {
  name: string;
  icon: React.ReactNode;
  route: string;
}

function CollapsedMenu({ pages }: { pages: Page[] }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const history = useHistory();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { lg: "none", xs: "block" },
        }}
      >
        {pages.map((page) => (
          <MenuItem
            key={page.name}
            onClick={() => {
              history.push(page.route);
              handleCloseNavMenu();
            }}
            sx={{
              color: location.pathname.startsWith(page.route)
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          >
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
        {pages.length === 0 && (
          <MenuItem disabled>
            <Typography textAlign="center">None</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function ExpandedMenu({ pages }: { pages: Page[] }) {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  return (
    <>
      {pages.map((page) => (
        <Button
          size="small"
          startIcon={page.icon}
          key={page.name}
          onClick={() => history.push(page.route)}
          sx={{
            color: location.pathname.startsWith(page.route)
              ? theme.palette.primary.main
              : theme.palette.text.primary,

            mr: 1,
            my: 0,
            textTransform: "none",
          }}
        >
          {page.name}
        </Button>
      ))}
    </>
  );
}

export function GenericNavbar({
  pages,
  options,
  additionalOptions,
  user,
}: {
  pages: Page[];
  options: Option[];
  additionalOptions?: Option[];
  user: { email?: string; role?: Role } | null;
}) {
  const theme = useTheme();

  const quickLaunch = useQuickLaunch();
  const orgAdminOnlyQuickLaunch = useOrgAdminOnlyQuickLaunch();
  const showQuickLaunch =
    quickLaunch &&
    (orgAdminOnlyQuickLaunch
      ? supersedes({
          has: user?.role,
          required: ROLE_LEVELS.ORG_ADMIN,
        })
      : true);

  const [anchorElHelpNav, setAnchorElHelpNav] =
    React.useState<null | HTMLElement>(null);

  const handleOpenHelpNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHelpNav(event.currentTarget);
  };

  const handleCloseHelpNavMenu = () => {
    setAnchorElHelpNav(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ background: theme.palette.background.paper, zIndex: 10 }}
      elevation={1}
    >
      <Toolbar variant="dense">
        <Box sx={{ display: { lg: "flex", xs: "none" }, mr: 3 }}>
          <Link component={RouterLink} to="/">
            <KronologicIcon />
          </Link>
        </Box>
        <Box
          sx={{
            display: { lg: "none", xs: "flex" },
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <CollapsedMenu pages={pages} />
          <Link component={RouterLink} to="/">
            <KronologicIcon />
          </Link>
        </Box>
        <Box
          sx={{
            display: { lg: "none", xs: "flex" },
            flexGrow: 1,
            mr: 1,
          }}
        ></Box>
        <Box
          sx={{
            display: { lg: "flex", xs: "none" },
            flexGrow: 1,
          }}
        >
          <ExpandedMenu pages={pages} />
        </Box>

        {showQuickLaunch && <QuickLauncher />}

        <Box
          sx={{
            display: { lg: "flex", xs: "none" },
            ml: 2,
            mr: 2,
            flexGrow: 0,
          }}
        >
          <Button
            size="small"
            startIcon={<HelpIcon />}
            onClick={handleOpenHelpNavMenu}
            sx={{
              color: theme.palette.text.primary,
              textTransform: "none",
            }}
          >
            Help
          </Button>

          <Menu
            id="help-menu"
            anchorEl={anchorElHelpNav}
            open={Boolean(anchorElHelpNav)}
            onClose={handleCloseHelpNavMenu}
          >
            <MenuItem
              onClick={handleCloseHelpNavMenu}
              href="https://www.kronologic.com/how-to-guides"
              target="_blank"
              component="a"
            >
              <ListItemIcon>
                <OndemandVideoIcon />
              </ListItemIcon>
              <ListItemText>How To Videos</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleCloseHelpNavMenu}
              href="https://www.kronologic.com/submitaticket"
              target="_blank"
              component="a"
            >
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText>Support</ListItemText>
            </MenuItem>
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <UserMenu
            user={user}
            options={options}
            additionalOptions={additionalOptions}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
