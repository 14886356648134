import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Tag } from "../types";
import { buildQueryString } from "../utils";
import { errorHandler } from "src/hooks/errorHandler";

export function useTags(limit: number, offset: number, query?: string) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const q = buildQueryString({ limit, offset, qry: query });

  const { data, error } = useSWR(`/api/tags?${q}`, (url) =>
    service
      .get(url)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { total: number; data: Tag[] } | undefined,
    error,
    loading: !error && !data,
  };
}
