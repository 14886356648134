import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import googleMeetLogo from "src/assets/googleMeet.svg";
import teamsLogo from "src/assets/microsoft-teams.svg";
import webexLogo from "src/assets/webex.png";
import zoomLogo from "src/assets/zoom.svg";
import { Spinner } from "src/components/Spinner";
import { AccountsEventColorRadio } from "src/componentsV2/AccountsEventColorRadio";
import HighEmphasisButton from "src/componentsV2/buttons/HighEmphasisButton";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { determineIntegrationStatus } from "src/integrations/util";
import { Account } from "src/pages/settings/accounts";
import { useIsAuthorized } from "../../../../auth";
import { confirm } from "../../../../components/confirmModal/ConfirmModal";
import { useTimezones } from "../../../../components/timezone";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import { useUpdateOrgName } from "../../../../mutations/useUpdateOrgName";
import { useOrgName } from "../../../../queries/useOrgName";
import {
  useAppSettingsRepository,
  useIntegrationsRepository,
  useUsersRepository,
} from "../../../../repository";
import { EmailEditor } from "src/componentsV2/inputs";
import { UserSettings } from "src/types/UserSettings";
import { UserIntegration } from "src/types/UserIntegration";
import UserAvailability from "src/componentsV2/UserAvailability";

function KMeManageAccount() {
  const { addGeneralNotification, addError } = useGeneralNotifications();
  const { getUserSettingsById, updateUserSettings } =
    useAppSettingsRepository();
  const updateOrgName = useUpdateOrgName();
  const { data } = useOrgName();
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const { userId } = useParams<{ userId: string }>();
  const { getUserAccounts } = useUsersRepository();
  const { tmzData } = useTimezones();

  const isAuthorized = useIsAuthorized();

  const [microsoftTeams, setMicrosoftTeams] = useState<UserIntegration | null>(
    null
  );
  const [webex, setWebex] = useState<UserIntegration | null>(null);
  const [zoom, setZoom] = useState<UserIntegration | null>(null);
  const [googleMeet, setGoogleMeet] = useState<UserIntegration | null>(null);
  const [companyName, setCompanyName] = useState<string>(data || "");

  const {
    connectUserIntegration,
    disconnectUserIntegration,
    getUserIntegrations,
  } = useIntegrationsRepository();

  const { register, handleSubmit, control, setValue, getValues, reset } =
    useForm<UserSettings>();

  const onSubmit: SubmitHandler<UserSettings> = async (data) => {
    try {
      await updateUserSettings(data, selectedAccount?.userId);
      await updateOrgName(companyName);
    } catch {
      addError(`Settings update failed`);
    }
  };

  const fetchSettings = useCallback(async () => {
    try {
      const userAccountsData: Account[] = await getUserAccounts();
      const accountToSet = userAccountsData.find(
        (account) => account.userId.toString() === userId
      );
      setSelectedAccount(accountToSet);
      if (!accountToSet?.userId) {
        return;
      }

      const userSettingsData: UserSettings = await getUserSettingsById(
        accountToSet.userId
      );
      reset(userSettingsData);

      const userIntegrationData: UserIntegration[] = await getUserIntegrations(
        userId
      );
      if (userIntegrationData) {
        userIntegrationData.forEach((integration) => {
          switch (integration.name) {
            case "google":
              setGoogleMeet(integration);
              break;
            case "microsoft_teams":
              setMicrosoftTeams(integration);
              break;
            case "webex":
              setWebex(integration);
              break;
            case "zoom":
              setZoom(integration);
              break;
          }
        });
      }

      setIsLoading(false);
    } catch (error) {
      addError(`Settings fetch failed`);
    }
  }, [
    addError,
    getUserSettingsById,
    reset,
    userId,
    getUserAccounts,
    getUserIntegrations,
  ]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (data) {
      setCompanyName(data);
    }
  }, [data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!selectedAccount) {
    return <h1>Account not found</h1>;
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title={
          <Typography variant="h3">{`Manage ${selectedAccount.email}`}</Typography>
        }
      />
      <CardContent>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            my: 4,
          }}
        >
          <SecondaryButton onClick={() => history.push("/settings/accounts")}>
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={async () => {
              await handleSubmit(onSubmit)();
              addGeneralNotification("Settings updated");
            }}
          >
            Update
          </PrimaryButton>
        </Stack>
        {isAuthorized && (
          <section>
            <Stack sx={{ gap: 2, mb: 4 }}>
              <Typography variant="h5" fontWeight="bold">
                General
              </Typography>
              <Typography>Change general parts of the application.</Typography>
            </Stack>
            <Stack sx={{ gap: 3, mb: 10 }}>
              <TextField label="First name" {...register("firstName")} />
              <TextField label="Last name" {...register("lastName")} />
              <TextField label="Title" {...register("title")} />
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(event) => {
                  setCompanyName(event.target.value);
                }}
              />
              <Controller
                name="signature"
                control={control}
                render={({ field }) => (
                  <EmailEditor
                    label="Signature"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />

              <Box>
                <SecondaryButton
                  size="small"
                  onClick={async () => {
                    await confirm({
                      cancelLabel: "Cancel",
                      okLabel: "Confirm",
                      title: (
                        <div>Your signature will be overwritten. Proceed?</div>
                      ),
                    });

                    setValue(
                      "signature",
                      `<div>${getValues("firstName")} ${getValues(
                        "lastName"
                      )}</div>
                    ${
                      getValues("title")?.length > 0
                        ? `<div>${getValues("title")}</div>`
                        : ""
                    }
                    <div>${data || ""}</div>`
                    );
                  }}
                >
                  Use default signature?
                </SecondaryButton>
              </Box>
            </Stack>

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Stack
                sx={{ flexDirection: "row", gap: 1, alignItems: "center" }}
              >
                <Typography variant="h5" fontWeight="bold">
                  Meeting Link Providers
                </Typography>
              </Stack>

              {/* Google Meet integration */}

              {/* microsoft users should not be allowed to connect to google meet since it requires underlying google account */}
              {selectedAccount.tokenInfo.tokenType === "google" && (
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      width={50}
                      height={50}
                      src={googleMeetLogo}
                      alt={"Google Meet logo"}
                    />
                    <Stack>
                      <Typography>Google Meet</Typography>
                    </Stack>
                  </Stack>

                  <IntegrationActions
                    preferredMeetingLinkProvider={
                      googleMeet?.isPreferredLinkProvider
                    }
                    integrationStatus={
                      // google meet piggy backs off of google integration. there is no real connect / disconnect, so use isPreferredLinkProvider instead
                      googleMeet?.isPreferredLinkProvider
                        ? "Current"
                        : "Disabled"
                    }
                    onDisconnect={async () => {
                      await confirm({
                        title: `Are you sure you would like to remove your Google Meet connection?`,
                      });

                      try {
                        const response = await disconnectUserIntegration({
                          integrationId: googleMeet?.id,
                          userId: selectedAccount.userId,
                        });
                        if (response) {
                          setGoogleMeet(null);
                        }
                      } catch {
                        addError(`Google Meet disconnect failed`);
                      }
                    }}
                    onConnect={async () => {
                      try {
                        await handleSubmit(onSubmit)();
                        const response = await connectUserIntegration(
                          "google_meet",
                          googleMeet?.id || 0,
                          selectedAccount.userId,
                          window.location.href
                        );

                        if (response && response.url) {
                          window.location.href = response.url;
                        }
                      } catch (err) {
                        addError(`Failed to connect to Google Meet.`);
                        console.error("Failed to connect to Google Meet", err);
                      }
                    }}
                  />
                </Stack>
              )}

              {/* Microsoft Teams integration */}
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}
                >
                  <img
                    width={50}
                    height={50}
                    src={teamsLogo}
                    alt={"Microsoft Teams logo"}
                  />
                  <Stack>
                    <Typography>Microsoft Teams</Typography>
                  </Stack>
                </Stack>

                <IntegrationActions
                  preferredMeetingLinkProvider={
                    microsoftTeams?.isPreferredLinkProvider
                  }
                  integrationStatus={determineIntegrationStatus(microsoftTeams)}
                  onDisconnect={async () => {
                    await confirm({
                      title: `Are you sure you would like to remove your Microsoft Teams connection?`,
                    });

                    try {
                      const response = await disconnectUserIntegration({
                        integrationId: microsoftTeams?.id,
                        userId: selectedAccount.userId,
                      });
                      if (response) {
                        setMicrosoftTeams(null);
                      }
                    } catch {
                      addError(`Microsoft Teams disconnect failed`);
                    }
                  }}
                  onConnect={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "microsoft_teams",
                        microsoftTeams?.id || 0,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to Microsoft Teams.`);
                      console.error(
                        "Failed to connect to Microsoft Teams",
                        err
                      );
                    }
                  }}
                  onReauthorize={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "microsoft_teams",
                        microsoftTeams?.id,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to Microsoft Teams.`);
                      console.error(
                        "Failed to connect to Microsoft Teams",
                        err
                      );
                    }
                  }}
                />
              </Stack>

              {/* Webex integration */}
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}
                >
                  <img
                    width={50}
                    height={50}
                    src={webexLogo}
                    alt={"Webex logo"}
                  />
                  <Stack>
                    <Typography>Webex</Typography>
                  </Stack>
                </Stack>

                <IntegrationActions
                  preferredMeetingLinkProvider={webex?.isPreferredLinkProvider}
                  integrationStatus={determineIntegrationStatus(webex)}
                  onDisconnect={async () => {
                    await confirm({
                      title: `Are you sure you would like to remove your Webex connection?`,
                    });

                    try {
                      const response = await disconnectUserIntegration({
                        integrationId: webex?.id,
                        userId: selectedAccount.userId,
                      });
                      if (response) {
                        setWebex(null);
                      }
                    } catch {
                      addError(`Webex disconnect failed`);
                    }
                  }}
                  onConnect={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "webex",
                        webex?.id || 0,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to webex.`);
                      console.error("Failed to connect to webex", err);
                    }
                  }}
                  onReauthorize={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "webex",
                        webex?.id,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to webex.`);
                      console.error("Failed to connect to webex", err);
                    }
                  }}
                />
              </Stack>

              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <img
                    width={50}
                    height={50}
                    src={zoomLogo}
                    alt={"Zoom logo"}
                  />
                  <Stack>
                    <Typography>Zoom</Typography>
                  </Stack>
                </Stack>

                <IntegrationActions
                  preferredMeetingLinkProvider={zoom?.isPreferredLinkProvider}
                  integrationStatus={determineIntegrationStatus(zoom)}
                  onDisconnect={async () => {
                    await confirm({
                      title: `Are you sure you would like to remove your zoom connection?`,
                    });

                    try {
                      const response = await disconnectUserIntegration({
                        integrationId: zoom?.id,
                        userId: selectedAccount.userId,
                      });
                      if (response) {
                        setZoom(null);
                      }
                    } catch {
                      addError(`Zoom disconnect failed`);
                    }
                  }}
                  onConnect={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "zoom",
                        zoom?.id || 0,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to Zoom.`);
                      console.error("Failed to connect to Zoom", err);
                    }
                  }}
                  onReauthorize={async () => {
                    try {
                      await handleSubmit(onSubmit)();
                      const response = await connectUserIntegration(
                        "zoom",
                        zoom?.id,
                        selectedAccount.userId,
                        window.location.href
                      );

                      if (response && response.url) {
                        window.location.href = response.url;
                      }
                    } catch (err) {
                      addError(`Failed to connect to Zoom.`);
                      console.error("Failed to connect to Zoom", err);
                    }
                  }}
                />
              </Stack>

              <TextField
                label="Custom Meeting Link"
                {...register("meetingLink")}
              />
            </Stack>

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Typography variant="h5" fontWeight="bold">
                Timezone
              </Typography>
              <Controller
                name="timezone"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    value={tmzData.find((tmz) => tmz.value === field.value)}
                    onChange={(_, selectedTimezone) => {
                      field.onChange(selectedTimezone?.value);
                    }}
                    options={tmzData}
                    renderInput={(params) => (
                      <TextField {...params} label="Timezone" />
                    )}
                  />
                )}
              />
            </Stack>

            <Controller
              name="weekSchedule"
              control={control}
              render={({ field }) => (
                <UserAvailability
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Typography variant="h5" fontWeight="bold">
                Calendar Event Color
              </Typography>
              <Controller
                name="defaultEventColor"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    {getValues("eventColors")?.map((value) => (
                      <AccountsEventColorRadio key={value.name} value={value} />
                    ))}
                  </RadioGroup>
                )}
              />
            </Stack>

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Typography variant="h5" fontWeight="bold">
                User Calendars
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Calendar</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      Use for Availability
                    </TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      Make Public
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(getValues("calendars") || []).map((calendar, index) => {
                    return (
                      <TableRow
                        key={calendar.calendarId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {calendar.calendarType === "other" && calendar.name}
                          {calendar.calendarType === "scheduling" &&
                            "Hidden Calendar"}
                          {calendar.calendarType === "default" &&
                            "Default Calendar"}
                        </TableCell>
                        <TableCell sx={{ width: "140px" }} align="center">
                          <Controller
                            name={`calendars.${index}.isUsedForAvailability`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                disabled={["default", "scheduling"].includes(
                                  calendar.calendarType
                                )}
                                {...field}
                                checked={field.value}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell sx={{ width: "140px" }} align="center">
                          <Controller
                            name={`calendars.${index}.isPublic`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox {...field} checked={field.value} />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Stack>

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Typography variant="h4">Email Notification Settings</Typography>
              <Typography variant="h5" fontWeight="bold">
                Kronologic Invite
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Guest Status</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      Notification Enabled
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>Requires Host Intervention</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      <Controller
                        name={"notifications.kronologicInvite.rhi"}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>

            <Stack sx={{ gap: 3, mb: 10 }}>
              <Typography variant="h5" fontWeight="bold">
                Kronologic Email
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Guest Status</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      Notification Enabled
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>Accept</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      <Controller
                        name={"notifications.kronologicEmail.accept"}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Decline</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      <Controller
                        name={"notifications.kronologicEmail.decline"}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>Requires Host Intervention</TableCell>
                    <TableCell sx={{ width: "140px" }} align="center">
                      <Controller
                        name={"notifications.kronologicEmail.rhi"}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </section>
        )}
      </CardContent>
    </Card>
  );
}

export default KMeManageAccount;

type IntegrationActionsProps = {
  integrationStatus: string;
  preferredMeetingLinkProvider?: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
  onReauthorize?: () => void;
};
const IntegrationActions = (props: IntegrationActionsProps) => {
  // microsoft users should not be allowed to connect to google meet since it requires underlying google account
  return (
    <>
      {props.preferredMeetingLinkProvider &&
        props.integrationStatus === "Current" && (
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
            <Typography>Connected</Typography>
            <HighEmphasisButton
              onClick={() => props.onDisconnect()}
              size="small"
            >
              Disconnect
            </HighEmphasisButton>
          </Stack>
        )}
      {props.preferredMeetingLinkProvider &&
        props.integrationStatus === "Expired" &&
        props.onReauthorize && (
          <Stack sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
            <Typography color="error.main">Expired</Typography>
            <SecondaryButton
              onClick={() => props.onReauthorize?.()}
              size="small"
            >
              Reauthorize
            </SecondaryButton>
            <HighEmphasisButton
              onClick={() => props.onDisconnect()}
              size="small"
            >
              Disconnect
            </HighEmphasisButton>
          </Stack>
        )}
      {props.preferredMeetingLinkProvider &&
        props.integrationStatus === "Disabled" && (
          <SecondaryButton onClick={() => props.onConnect()}>
            Connect
          </SecondaryButton>
        )}
      {!props.preferredMeetingLinkProvider && (
        <SecondaryButton onClick={() => props.onConnect()}>
          Connect
        </SecondaryButton>
      )}
    </>
  );
};
