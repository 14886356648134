import React from "react";
import { Checkbox } from "@mui/material";

import { Table, Column, TableProps } from "./Table";

export function SelectTable<
  T extends {
    id: number | string;
  }
>({
  loading = false,
  minRows = 0,
  columns = [],
  data = [],
  sort,
  onSortChange = () => null,
  selected,
  onSelect,
}: {
  selected: (number | string)[];
  onSelect: (checked: boolean, selected: T[]) => void;
} & TableProps<T>) {
  const allSelected =
    data.length > 0 && data.every((c) => selected.includes(c.id));

  const indeterminate =
    !allSelected &&
    selected.length > 0 &&
    data.some((c) => selected.includes(c.id));

  return (
    <Table
      getKey={(i) => i.id}
      loading={loading}
      columns={[
        {
          component: (c) => (
            <Checkbox
              color="primary"
              checked={selected.includes(c.id)}
              onChange={() => onSelect(selected.includes(c.id), [c])}
            />
          ),
          id: "checkbox",
          label: (
            <Checkbox
              color="primary"
              indeterminate={indeterminate}
              checked={allSelected}
              onChange={() => {
                if (allSelected) {
                  onSelect(true, data);
                } else if (indeterminate) {
                  onSelect(
                    false,
                    data.filter((c) => !selected.includes(c.id))
                  );
                } else {
                  onSelect(false, data);
                }
              }}
            />
          ),
          width: 50,
        } as Column<T>,
      ].concat(columns)}
      data={data}
      minRows={minRows}
      sort={sort}
      onSortChange={onSortChange}
      onRowClick={(c) => onSelect(selected.includes(c.id), [c])}
    />
  );
}
