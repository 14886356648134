import React, { useCallback, useMemo, useState } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";

import { Select, useMeetingTypeOptions } from "../../inputs";
import { useSearch, useSearchUpdater } from "../Search";
import { NO_OPTIONS } from "../../utils/variables";
import { useFilterMenuContext } from "../context";
import { useDropdownTheme } from "./useDropdownTheme";
import style from "./style.module.scss";
import { useMeetingTemplates } from "src/queries";

export function MeetingTypeFilterControls({ menuPortalTarget }) {
  const [input, setInput] = useState("");

  const { data, loading } = useMeetingTemplates(100, 0, {
    templateName: input,
  });

  const meetingTypeOptions = useMeetingTypeOptions(data?.data || []);
  const { setFilterMenuClosed, setFilterMenuOpen } = useFilterMenuContext();
  const {
    filter: { meetingTypeFilter },
  } = useSearch();
  const searchUpdater = useSearchUpdater();
  const onMeetingTypeFilterChange = useCallback(
    (values) => {
      if (values === null) {
        searchUpdater.setMeetingTypeFilter([]);
        return;
      }
      const newMeetingTypeFilter = values.map((v) => v.value);
      searchUpdater.setMeetingTypeFilter(newMeetingTypeFilter);
    },
    [searchUpdater]
  );
  const meetingTypeValues = useMemo(() => {
    if (meetingTypeFilter.length === 0) {
      return null;
    }
    const meetingTypeFilterIds = meetingTypeFilter.map((mt) => mt.id);
    return meetingTypeOptions.filter((option) => {
      return meetingTypeFilterIds.includes(option.value.id);
    });
  }, [meetingTypeFilter, meetingTypeOptions]);
  const [components, theme] = useDropdownTheme();
  const placeholder = useMemo(() => {
    return (
      <div className={style.meetingType__filterPlaceHolder}>
        <div className={style.meetingType__filterPlaceHolderText}>
          Meeting Templates
        </div>
        <Icon
          className={style.meetingType__filterPlaceHolderIcon}
          path={mdiChevronDown}
          size={0.5}
        />
      </div>
    );
  }, []);
  return useMemo(() => {
    return (
      <Select
        isLoading={loading}
        input={input}
        onInputChange={(value) => {
          setInput(value);
        }}
        closeMenuOnScroll
        components={components}
        customTheme={theme}
        isMulti
        label="Meeting Templates"
        menuPortalTarget={menuPortalTarget}
        name="/instances/action_bar/meeting_types_dropdown"
        noOptionsMessage={() => NO_OPTIONS.meetingTypes}
        onChange={(values) => onMeetingTypeFilterChange(values)}
        onMenuOpen={setFilterMenuOpen}
        onMenuClose={setFilterMenuClosed}
        options={meetingTypeOptions}
        placeholder={placeholder}
        value={meetingTypeValues}
      />
    );
  }, [
    input,
    loading,
    components,
    meetingTypeOptions,
    meetingTypeValues,
    menuPortalTarget,
    onMeetingTypeFilterChange,
    placeholder,
    setFilterMenuClosed,
    setFilterMenuOpen,
    theme,
  ]);
}
