import React, { useCallback, useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";

import {
  DEFAULTS,
  FEATURE_DISPLAY_NAMES,
  FEATURE_LIST,
} from "../../../features";
import { useConfirmationDialog } from "../../../hooks";
import OrganizationSelectInput from "../../../componentsV2/inputs/OrganizationSelectInput";
import { useOrgFeatureFlags } from "../../../queries";
import { Loading } from "../../../componentsV2/Loading";
import { useUpdateOrgFeatureFlag } from "../../../mutations";
import { PageLayout } from "src/componentsV2/PageLayout";

export default function FeatureFlags() {
  const [selectedOrg, setSelectedOrg] = useState(null);

  const openConfirmation = useConfirmationDialog();

  const updateOrgFeatureFlag = useUpdateOrgFeatureFlag();

  const { data: featureFlags, loading } = useOrgFeatureFlags(
    selectedOrg?.schemaName || null
  );

  const onSwitch = useCallback(
    (feature, value) => {
      if (!value) {
        openConfirmation(
          "Feature Disable Confirmation",
          `You are about to turn off ${FEATURE_DISPLAY_NAMES[feature]} for ${selectedOrg.name}. Are you certain you would like to proceed?`,
          () => {
            updateOrgFeatureFlag(selectedOrg.schemaName, feature, value);
          }
        );
      } else {
        updateOrgFeatureFlag(selectedOrg.schemaName, feature, value);
      }
    },
    [openConfirmation, selectedOrg, updateOrgFeatureFlag]
  );

  return (
    <PageLayout title="Feature Flags">
      <Stack spacing={2}>
        <OrganizationSelectInput
          value={selectedOrg}
          onChange={setSelectedOrg}
        />
        {selectedOrg && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <Stack spacing={1}>
                {FEATURE_LIST.map((feature) => {
                  let isOn = DEFAULTS[feature];
                  if (feature in featureFlags) {
                    isOn = featureFlags[feature];
                  }
                  return (
                    <Stack
                      key={feature}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>{FEATURE_DISPLAY_NAMES[feature]}</Typography>

                      <Switch
                        checked={isOn}
                        onChange={(e, value) => {
                          onSwitch(feature, value);
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </>
        )}
      </Stack>
    </PageLayout>
  );
}
