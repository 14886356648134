// Using this instead of enums -> Detailed explanation: https://youtu.be/jjMbPt_H3RQ
export const INVITE_STYLE = {
  CALENDAR_FIRST: "calendar_first",
  CUSTOM_INVITE: "custom",
  WEBINAR: "webinar",
  LINK_FIRST: "link_first",
  // Don't forget the "as const" at the end!
} as const;

// We can extract both the keys and the values as types from the object above
type InviteStyleKeys = keyof typeof INVITE_STYLE;
type InviteStyleValues = typeof INVITE_STYLE[InviteStyleKeys];

// We only need the values from the INVITE_STYLE object as a type, so we assign it a clear name and export it.
export type InviteStyle = InviteStyleValues;

// FIXME: this is webinar feature flag related (on backend) but much more difficult to chop
// we will chop when we rejigger the meeting templates page
export function isWebinar(meetingInviteStyle?: InviteStyle | null) {
  return meetingInviteStyle === INVITE_STYLE.WEBINAR; // <- We can use either INVITE_STYLE.WEBINAR, or "webinar" here, both are TS valid.
}

export function isCalendarFirst(meetingInviteStyle?: InviteStyle | null) {
  return meetingInviteStyle === INVITE_STYLE.CALENDAR_FIRST;
}

export function isEmailFirst(meetingInviteStyle?: InviteStyle | null) {
  return meetingInviteStyle === INVITE_STYLE.CUSTOM_INVITE;
}

export function isLinkFirst(meetingInviteStyle?: InviteStyle | null) {
  return meetingInviteStyle === INVITE_STYLE.LINK_FIRST;
}
