import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { usePollingInterval } from "../configuration";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Meeting } from "../types";
import { errorHandler } from "src/hooks/errorHandler";

export function useMeetings(ids?: number[] | null) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const accessToken = getUserToken();

  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  let url: string | undefined;
  if (ids && ids.length > 0) {
    url = `/api/meetings/instances/other?ids=${ids.join(",")}`;
  }

  const interval = usePollingInterval();

  const { data, error } = useSWR(
    url,
    (u) =>
      service
        .get(u)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        // Sort the returned values so the reflect the order of the ids param.
        .then((meetings: Meeting[]) => {
          return meetings.sort((a, b) =>
            ids ? ids.indexOf(a.id) - ids.indexOf(b.id) : 0
          );
        })
        .catch(errorHandler),
    { refreshInterval: interval }
  );

  return {
    data: data as Meeting[] | undefined,
    error,
    loading: !error && !data,
  };
}
