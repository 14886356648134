import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { mdiCloudUploadOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { useUploadLogo } from "../mutations/useUploadLogo";
import { useGetLogoURL } from "../queries/useGetLogoURL";
import { useDeleteLogoRefKey } from "../mutations/useDeleteLogoRefKey";
import kronologicK from "../assets/K.png";
import PrimaryButton from "./buttons/PrimaryButton";
import SecondaryButton from "./buttons/SecondaryButton";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import { ConfirmationDialog } from "./dialogs";

// max size 5MB -- please keep consistent with BE code (/app/api/web/user/org_controller.go - `maxFileSize`)
export const MAX_FILE_SIZE_IN_MB = 5;

export const ALLOWED_EXTENSIONS = [".jpg", ".jpeg", ".png", ".svg", ".gif"];

function OrgSettingsLogoUpload() {
  const uploadLogo = useUploadLogo();
  const deleteLogoRefKey = useDeleteLogoRefKey();
  const { data } = useGetLogoURL();
  const { addError, addGeneralNotification } = useGeneralNotifications();
  const [file, setFile] = useState<File | null>(null);
  const [isRemoveLogoDialogOpen, setIsRemoveDialogOpen] =
    useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_EXTENSIONS,
    onDrop: (selectedFiles) => {
      if (selectedFiles?.length) {
        const selectedFile = selectedFiles[0];
        if (selectedFile.size > MAX_FILE_SIZE_IN_MB * 1024 ** 2) {
          addError(`File too large. Maximum ${MAX_FILE_SIZE_IN_MB}MB`);
          return;
        }

        setFile(selectedFile);
      }
    },
  });

  return (
    <>
      <ConfirmationDialog
        open={isRemoveLogoDialogOpen}
        onClose={() => {
          setIsRemoveDialogOpen(false);
        }}
        title="Confirmation"
        message="The organization logo will be removed. Proceed?"
        onConfirm={async () => {
          try {
            await deleteLogoRefKey();
            addGeneralNotification("Logo successfully deleted.");
          } catch {
            addError("Logo could not be deleted. Please try again later.");
          }

          setIsRemoveDialogOpen(false);
        }}
      />
      <Typography variant="h6">Logo Upload</Typography>
      <Stack
        direction="row"
        sx={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "25px",
        }}
      >
        <Stack
          direction="column"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Typography align="center">Organization Logo</Typography>
          <img
            src={data && data.length ? data : kronologicK}
            height="256px"
            width="256px"
            alt=""
            style={{
              objectFit: "contain",
            }}
          />
        </Stack>
      </Stack>
      {data && data.length > 0 && (
        <Stack
          direction="row"
          sx={{ justifyContent: "center", marginTop: "25px" }}
        >
          <Button
            onClick={() => {
              setIsRemoveDialogOpen(true);
            }}
            variant="outlined"
            sx={{ textTransform: "none" }}
          >
            Remove Logo?
          </Button>
        </Stack>
      )}
      <Stack alignItems="center" spacing={2}>
        <div
          {...getRootProps()}
          style={{
            minHeight: "150px",
            minWidth: "300px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <Stack alignItems="center">
            <Icon path={mdiCloudUploadOutline} size={5} />
            <Typography align="center">
              Drag &amp; drop or click here to select file (
              {ALLOWED_EXTENSIONS.join(", ")}).
              <br />
              Use a 250px by 250px image for best results,&nbsp;
              {MAX_FILE_SIZE_IN_MB} MB max file size.
            </Typography>
          </Stack>
        </div>

        {file && (
          <Stack direction="column">
            <Typography align="center" sx={{ marginTop: "15px" }}>
              File selected: {file.name}
            </Typography>
            <Stack
              direction="row"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "15px",
              }}
            >
              <SecondaryButton
                nowrap
                onClick={() => {
                  setFile(null);
                }}
              >
                Undo File Selection
              </SecondaryButton>
              <PrimaryButton
                sx={{
                  marginLeft: "10px",
                }}
                onClick={async () => {
                  try {
                    await uploadLogo(file);
                    setFile(null);
                    addGeneralNotification("Logo successfully updated.");
                  } catch (error: any) {
                    addError(
                      `Unable to update logo. Please try again later. (${error?.message})`
                    );
                  }
                }}
              >
                Upload
              </PrimaryButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default OrgSettingsLogoUpload;
