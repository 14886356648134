import { useCallback, useEffect, useRef, useState } from "react";

import { useAsync } from "../async";
import { useFetch } from "../fetch";

function usePaused(initialPauseValue) {
  const [isPaused, setIsPaused] = useState(initialPauseValue);
  return [isPaused, () => setIsPaused(true), () => setIsPaused(false)];
}

function useExecuteQuery(execute, isPaused) {
  return useCallback(() => {
    if (isPaused) {
      return;
    }
    execute();
  }, [execute, isPaused]);
}

function useJson(response, isSuccess) {
  const deferredFn = useCallback(async () => {
    return response.json();
  }, [response]);
  return useAsync(deferredFn, isSuccess);
}

export function useQuery(
  url,
  { headers = null, body = null, method = "GET", pollingInterval = null } = {}
) {
  const [isPaused, pause, resume] = usePaused(false);
  const {
    controller,
    error: fetchError,
    execute,
    isFufilled: isFetchFufilled,
    isLoading: isFetchLoading,
    isRejected: isFetchRejected,
    isSettled: isFetchSettled,
    value: response,
  } = useFetch(url, {
    body: body !== null ? JSON.stringify(body, null, 4) : null,
    headers,
    method,
  });
  const {
    error: jsonError,
    value: jsonData,
    isFufilled: isJsonFufilled,
    isLoading: isJsonLoading,
    isRejected: isJsonRejected,
    isSettled: isJsonSettled,
  } = useJson(response, isFetchFufilled);
  const executeQuery = useExecuteQuery(execute, isPaused);
  const interval = useRef(null);
  useEffect(() => {
    // TODO(Matt): Set error if polling interval is an invalid value i.e. not null or an integer
    if (!isPaused && Number.isInteger(pollingInterval) && pollingInterval > 0) {
      interval.current = setInterval(async () => {
        executeQuery();
      });
    }
    return () => {
      if (controller !== null) {
        controller.abort();
      }
      if (interval.current) {
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  }, [controller, isPaused, executeQuery, pollingInterval]);
  const query = {
    controller,
    data: jsonData,
    execute: executeQuery,
    isPaused,
    pause,
    response,
    resume,
  };
  delete query.status;
  delete query.value;
  if (fetchError !== null) {
    Object.assign(query, {
      error: fetchError,
      isFufilled: isFetchFufilled,
      isLoading: isFetchLoading,
      isRejected: isFetchRejected,
      isSettled: isFetchSettled,
    });
    return query;
  }
  return Object.assign(query, {
    error: jsonError,
    isFufilled: isFetchFufilled && isJsonFufilled,
    isLoading: isFetchLoading || isJsonLoading,
    isRejected: isFetchRejected || isJsonRejected,
    isSettled: isFetchSettled && isJsonSettled,
  });
}
