import { useCallback, useEffect, useState } from "react";
import { useCustomFetchFn } from "../components/fetch";

export default function useDoFetch(requests) {
  const { fetch } = useCustomFetchFn();
  const [isInitialized, setisInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([].fill(null, 0, requests.length));
  const [payloads, setPayloads] = useState([].fill(null, 0, requests.length));
  const refresh = useCallback(async () => {
    setIsLoading(true);
    return Promise.all(
      requests.map(async (request) => {
        try {
          const response = await fetch(request);
          const { status } = response;
          if (status !== 200) {
            throw new Error(await response.text());
          }
          return {
            data: await response.json(),
            error: null,
          };
        } catch (error) {
          return {
            data: null,
            error,
          };
        }
      })
    ).then((envelopes) => {
      const newPayloads = envelopes.map((e) => e.data || null);
      const newErrors = envelopes.map((e) => e.error || null);
      setPayloads(newPayloads);
      setErrors(newErrors);
      setisInitialized(true);
      setIsLoading(false);
    });
  }, [fetch, requests]);
  useEffect(() => {
    refresh();
  }, [refresh]);
  return {
    errors,
    isInitialized,
    isLoading,
    payloads,
    refresh,
    setPayloads,
  };
}
