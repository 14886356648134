import { useCallback, useState } from "react";

import { useCustomFetchFn } from "./useCustomFetchFn";
import { buildRequest } from "../../utils/fetchV2";
import { useAsync } from "../async";

export function useFetch(url, { headers = null, body = null, method = "GET" }) {
  const { fetch } = useCustomFetchFn();
  const [controller, setController] = useState(null);
  const deferredFn = useCallback(
    (newController) => {
      setController(newController);
      const options = {};
      if (newController) {
        options.signal = newController.signal;
      }
      if (body !== null) {
        options.body = body;
      }
      return fetch(
        buildRequest(url, {
          headers: {
            ...headers,
          },
          method,
          ...options,
        })
      );
    },
    [headers, fetch, url, body, method]
  );
  return {
    ...useAsync(deferredFn),
    controller,
  };
}
