import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import Flexbox from "../../components/flexbox";
import { useCustomFetchFn } from "../../components/fetch";
import { NO_OPTIONS } from "../../utils/variables";
import { P1 } from "../../components/typography";
import { buildMeetingTypeRequest } from "../../repository/meetingTypes";
import useMeetingTypeSelectTheme, {
  DropdownIndicator,
} from "./useMeetingTypeSelectTheme";
import {
  meetingTypesToOptions,
  AsyncSelect,
  meetingTypeToOption,
} from "../../inputs";
import style from "../multiGuestTable/style.module.scss";
import { useMeetingTypes } from "../../queries";
import { INVITE_STYLE } from "../../meetingTypes/invite/props";

const Placeholder = ({ color }) => (
  <Flexbox>
    {({ Row }) => (
      <Row>
        <Icon
          className={style.meetingType_dropdown__placeholder_icon}
          style={{ color }}
          path={mdiPlus}
          size={1}
        />

        <P1
          bold
          className={style.meetingType_dropdown__placeholder_text}
          style={{ color }}
        >
          Meeting Template
        </P1>
      </Row>
    )}
  </Flexbox>
);

const MeetingTypeSelect = ({
  disabled,
  meetingType,
  setMeetingType,
  color,
}) => {
  const [selectedMeetingType, setSelectedMeetingType] = useState(
    meetingTypeToOption(meetingType)
  );
  const selectTheme = useMeetingTypeSelectTheme(color);
  const { fetch } = useCustomFetchFn();

  const selectComponents = useMemo(() => {
    return { DropdownIndicator };
  }, []);

  // TODO(vovan): we don't support 'link-first' meeting types yet.
  const inviteStyleFilter = [
    INVITE_STYLE.CALENDAR_FIRST,
    INVITE_STYLE.CUSTOM_INVITE,
  ];

  // FIXME: switch this over to useMeetingTemplates
  const { data: meetingTypes, loading } = useMeetingTypes(
    100,
    0,
    null,
    inviteStyleFilter
  );

  const initialMeetingTypeOptions = useMemo(
    () => meetingTypesToOptions(meetingTypes?.data),
    [meetingTypes]
  );

  const noOptionsMessage = useCallback(() => NO_OPTIONS.meetingTypes, []);

  const searchMeetingTypes = useCallback(
    async (query) => {
      try {
        const response = await fetch(
          buildMeetingTypeRequest({ query, inviteStyleFilter })
        ).then((res) => res.json());

        return {
          data: response,
          error: null,
        };
      } catch (error) {
        return { data: null, error };
      }
    },
    [fetch]
  );

  const r = useRef();

  const onChangeMeetingType = useCallback(
    (mt) => {
      r.current.focus();
      setMeetingType(mt.value);
      setSelectedMeetingType(mt);
    },
    [setMeetingType, r]
  );

  useEffect(() => {
    setSelectedMeetingType(meetingTypeToOption(meetingType));
  }, [meetingType]);

  return (
    <>
      <input className={style.meetingType_hidden_input} ref={r} />
      <AsyncSelect
        name="/instances/action_bar/add_new_instance/meeting_type"
        disabled={disabled}
        components={selectComponents}
        customTheme={selectTheme}
        isLoading={loading}
        isMulti={false}
        load={searchMeetingTypes}
        noOptionsMessage={noOptionsMessage}
        onChange={onChangeMeetingType}
        defaultOptions={initialMeetingTypeOptions}
        placeholder={<Placeholder color={color} />}
        toOption={meetingTypesToOptions}
        value={selectedMeetingType}
      />
    </>
  );
};

export default MeetingTypeSelect;
