import React, { useEffect, useMemo, useRef } from "react";
import Flexbox from "../../../components/flexbox";
import Message from "../message";
import style from "./mesengerBody.module.scss";
import { SET_MESSAGE_SENT, useMessengerState } from "../state";

const MessengerBody = ({ messengerHeader }) => {
  const messagesEndRef = useRef(null);
  const {
    messengerDispatch,
    messenger: { messageSent, messages },
  } = useMessengerState();

  const messengerHeaderHeight = useMemo(() => {
    return messengerHeader?.getBoundingClientRect().height || 0;
  }, [messengerHeader]);

  useEffect(() => {
    if (messageSent) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      messengerDispatch({
        payload: { value: false },
        type: SET_MESSAGE_SENT,
      });
    }
  }, [messageSent, messengerDispatch]);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.messengerBody} justifyContent="unset">
          {/* Used to push messages below header for scrolling */}
          <Column
            className={style.messengerBody__header_spacer}
            style={{ minHeight: messengerHeaderHeight }}
          />

          {messages.map(
            ({
              sent_email_info: {
                email_status: emailStatus,
                open_count: openCount,
              } = { email_status: "", open_count: 0 },
              guest_status: guestStatus,
              occurrence,
              note_type: noteType,
              note,
            }) => (
              <Message
                key={`${noteType} - ${occurrence}`}
                guestStatus={guestStatus}
                occurrence={occurrence}
                message={note}
                responseType={noteType}
                emailStatus={emailStatus}
                openCount={openCount}
              />
            )
          )}

          {/* Used to scroll to bottom after message is sent */}
          <div ref={messagesEndRef} />
        </Column>
      )}
    </Flexbox>
  );
};

export default MessengerBody;
