import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Container, Stack, Typography } from "@mui/material";

import { useUnsubscribe } from "../../mutations";

import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { Loading } from "../../componentsV2/Loading";
import BorderBox from "../../componentsV2/BorderBox";
import { useUrlQuery } from "../../hooks";
import { useUnsubscribeContactData } from "../../queries";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import ErrorPage from "src/pages/ErrorPage";

export default function Page() {
  document.title = "Unsubscribe";

  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const { addError } = useGeneralNotifications();
  const unsubscribe = useUnsubscribe();

  const query = useUrlQuery();

  // Get the Org's UUID
  const org = query.o;
  // Get the Meeting's UUID
  const meeting = query.m;
  // Get the Contact's UUID
  const contact = query.c;

  const {
    data,
    error,
    loading: isContactLoading,
  } = useUnsubscribeContactData(org, meeting, contact);

  const handleUnsubscribeClick = () => {
    setLoading(true);

    unsubscribe(org, meeting, contact)
      .then(() => {
        setUnsubscribed(true);
      })
      .catch(() =>
        addError("There was an error while updating your contact preferences")
      )
      .finally(() => {
        setLoading(false);
      });
  };

  // If the necessary query parameters are not defined then redirect back to the
  // home page of the app.
  if (!org && !meeting && !contact) {
    return <Redirect to="/" />;
  }

  if (loading || isContactLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  return (
    <Container maxWidth="sm">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <BorderBox>
          {unsubscribed ? (
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h5">You have been Unsubscribed!</Typography>

              <Typography>
                You will not receive meeting requests from{" "}
                {data?.unsubscribeFrom} anymore.
                <br />
                <br />
                However, you may receive meeting cancellation emails from your
                email service provider or other forms of communication, which
                you will be able to customize to your desired preferences.
              </Typography>
            </Stack>
          ) : (
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h5">Unsubscribe</Typography>

              <Typography>
                Please confirm that you do not wish to receive meeting request
                from {data?.unsubscribeFrom}.
              </Typography>

              <PrimaryButton nowrap onClick={handleUnsubscribeClick}>
                Unsubscribe
              </PrimaryButton>
            </Stack>
          )}
        </BorderBox>
      </Stack>
    </Container>
  );
}
