import { useCallback, useRef } from "react";
import { useCustomFetchFn } from "../components/fetch";
import { HTTP_METHODS } from "../components/fetch/props";
import { API } from "../props";

import { buildRequest } from "../utils/fetchV2";

const buildValidateCSVRequest = ({ body }) =>
  buildRequest(API.importExport.import.validateCSV, {
    body,
    method: HTTP_METHODS.POST,
  });

const buildUploadContactCSVRequest = ({ body }) =>
  buildRequest(API.importExport.import.uploadContactCSV, {
    body,
    method: HTTP_METHODS.POST,
  });

const buildUploadUserCSVRequest = ({ body }) =>
  buildRequest(API.importExport.import.uploadUserCSV, {
    body,
    method: HTTP_METHODS.POST,
  });

const buildListCSVsRequest = () =>
  buildRequest(API.importExport.export.exports);

const buildCSVDownloadRequest = (id) =>
  buildRequest(API.importExport.export.exportByID(id));

const buildMeetingExportCSVRequest = (body) =>
  buildRequest(API.meetings.exportCSV, {
    body: JSON.stringify(body),
    method: HTTP_METHODS.POST,
  });

const buildUserExportCSVRequest = ({ body }) =>
  buildRequest(API.users.exportCSV, {
    body: JSON.stringify(body),
    method: HTTP_METHODS.POST,
  });

const buildContactExportCSVRequest = ({ body }) =>
  buildRequest(API.contacts.exportCSV, {
    body: JSON.stringify(body),
    method: HTTP_METHODS.POST,
  });

export default function useImportExportRepository() {
  const { fetch } = useCustomFetchFn();

  const validateCSV = useCallback(
    async (body) =>
      fetch(buildValidateCSVRequest({ body: JSON.stringify(body) })).then((r) =>
        r.json()
      ),
    [fetch]
  );

  const uploadContactCSV = useCallback(
    async (body) =>
      fetch(buildUploadContactCSVRequest({ body: JSON.stringify(body) })).then(
        (r) => r.json()
      ),
    [fetch]
  );

  const uploadUserCSV = useCallback(
    async (body) =>
      fetch(buildUploadUserCSVRequest({ body: JSON.stringify(body) })).then(
        (r) => r.json()
      ),
    [fetch]
  );

  const listExportCSVs = useCallback(
    () => fetch(buildListCSVsRequest()).then((r) => r.json()),
    [fetch]
  );

  const startMeetingCSVProcessing = useCallback(
    (body) => fetch(buildMeetingExportCSVRequest(body)),
    [fetch]
  );

  const startUserCSVProcessing = useCallback(
    (body) => fetch(buildUserExportCSVRequest({ body })),
    [fetch]
  );

  const startContactCSVProcessing = useCallback(
    (body) => fetch(buildContactExportCSVRequest({ body })),
    [fetch]
  );

  const requestCsvDownload = useCallback(
    (id) => fetch(buildCSVDownloadRequest(id)).then((r) => r.json()),
    [fetch]
  );

  const { current } = useRef({
    listExportCSVs,
    requestCsvDownload,
    startContactCSVProcessing,
    startMeetingCSVProcessing,
    startUserCSVProcessing,
    uploadContactCSV,
    uploadUserCSV,
    validateCSV,
  });

  return current;
}
