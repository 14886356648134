import React, { useEffect, useMemo, useRef } from "react";
import Flexbox from "../../../components/flexbox";
import Message from "../message";
import DirectMessengerHeader from "../directMessengerHeader";
import { SET_MESSAGE_SENT, useMessengerState } from "../state";
import style from "./directMessenger.module.scss";

const DirectMessenger = ({ messenger, messengerHeader, messengerInput }) => {
  const messagesEndRef = useRef(null);
  const {
    messenger: { messages, messageSent },
    messengerDispatch,
  } = useMessengerState();

  const directMessages = useMemo(
    () => messages.filter((noteType) => noteType !== "system_note"),
    [messages]
  );

  const height = useMemo(() => {
    const messengerHeight = messenger?.getBoundingClientRect().height;
    const headerHeight = messengerHeader?.getBoundingClientRect().height;
    const inputHeight = messengerInput?.getBoundingClientRect().height;
    return `${messengerHeight - headerHeight - inputHeight - 30 || 0}px`;
  }, [messenger, messengerHeader, messengerInput]);

  const bottomPosition = useMemo(() => {
    const inputHeight = messengerInput?.getBoundingClientRect().height;
    return `${inputHeight}px`;
  }, [messengerInput]);

  useEffect(() => {
    if (messageSent) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      messengerDispatch({
        payload: { value: false },
        type: SET_MESSAGE_SENT,
      });
    }
  }, [messageSent, messengerDispatch]);

  return (
    <Flexbox>
      {({ Column }) => (
        <Column
          className={style.directMessenger}
          width="70%"
          height={height}
          style={{ bottom: bottomPosition }}
        >
          <DirectMessengerHeader />

          <Column
            className={style.directMessenger__list}
            justifyContent="unset"
          >
            {directMessages.map(
              ({
                guest_status: guestStatus,
                occurrence,
                note_type: noteType,
                note,
              }) => (
                <Message
                  key={`${noteType} - ${occurrence}`}
                  guestStatus={guestStatus}
                  occurrence={occurrence}
                  message={note}
                  responseType={noteType}
                />
              )
            )}

            {/* Used to scroll to bottom after message is sent */}
            <div ref={messagesEndRef} />
          </Column>
        </Column>
      )}
    </Flexbox>
  );
};

export default DirectMessenger;
