import { useEffect, useState } from "react";
import { Stack, TextField, Typography } from "@mui/material";

import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import Dialog from "./Dialog";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { CalendarPicker, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

export function ProposeNewTimeDialog({
  open,
  currentTime,
  onSubmit,
  onClose,
}: {
  open: boolean;
  currentTime?: Dayjs | null;
  onSubmit: (value: Dayjs) => void;
  onClose: () => void;
}) {
  const [value, setValue] = useState<Dayjs | null>(
    currentTime || dayjs().add(3, "hours")
  );
  const [date, setDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    if (value?.isValid() || value === null) {
      setDate(value);
    }
  }, [value]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Propose New Meeting Time"
      actions={
        <>
          <PrimaryButton
            disabled={
              value?.isBefore(dayjs()) || value === null || !value.isValid()
            }
            onClick={() => {
              if (value) {
                onSubmit(value);
              }
            }}
          >
            Submit
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack>
        <Typography>
          <Typography fontWeight="bold" component="span">
            Current Time:
          </Typography>{" "}
          {currentTime?.format("M/D/YYYY [at] h:mm a z") || "Not yet scheduled"}
        </Typography>
        <Stack direction="row" spacing={2} sx={{ pt: 1 }}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CalendarPicker<Dayjs>
                  openTo="day"
                  date={date}
                  disablePast
                  onChange={setValue}
                />

                <Stack spacing={2}>
                  <TimePicker
                    disableOpenPicker
                    label="Time"
                    value={value}
                    onChange={setValue}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        error={value?.isBefore(dayjs())}
                        helperText={
                          value?.isBefore(dayjs()) &&
                          "Must be a time in the future"
                        }
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default ProposeNewTimeDialog;
