import useSWRImmutable from "swr/immutable";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useFeatureFlags() {
  const accessToken = getUserToken();
  const service = useUserService();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWRImmutable(`/api/org/setting/features`, (url) =>
    service
      .get(url)
      .set(headers)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { [index: string]: boolean } | undefined,
    error,
    loading: !error && !data,
  };
}

export default useFeatureFlags;
