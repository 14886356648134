import React, { useMemo } from "react";
import Card from "../../../../components/card/Card";
import Flexbox from "../../../../components/flexbox";
import useMeetingStatusFilterOnClick from "../useMeetingStatusFilterOnClick";
import { P1 } from "../../../../components/typography";
import { LoadingCard } from "../../../../components/genericView/GenericView";
import {
  NO_QUORUM_STAGE,
  CANCELLED_STAGE,
  INSTANCE_STAGE_TO_LABEL,
  COMPLETED_STAGE,
} from "../../../props";
import { ButtonContainer } from "../../../../components/button";
import style from "./instanceFinal.module.scss";

const InstanceFinal = ({
  isLoading,
  completed = 0,
  noQuorum = 0,
  cancelled = 0,
}) => {
  const handleOnCompletedClick = useMeetingStatusFilterOnClick([
    COMPLETED_STAGE,
  ]);

  const handleOnNoQuorumClick = useMeetingStatusFilterOnClick([
    NO_QUORUM_STAGE,
  ]);

  const handleOnCancelledClick = useMeetingStatusFilterOnClick([
    CANCELLED_STAGE,
  ]);

  const handleOnTotalClick = useMeetingStatusFilterOnClick([
    COMPLETED_STAGE,
    NO_QUORUM_STAGE,
    CANCELLED_STAGE,
  ]);

  const total = useMemo(
    () => completed + noQuorum + cancelled,
    [completed, noQuorum, cancelled]
  );

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <div className={style.instanceFinal}>
          {isLoading && (
            <LoadingCard
              className={style.instanceFinal__loadingCard}
              height={160}
            />
          )}

          {!isLoading && (
            <Card className={style.instanceFinal__card}>
              <Column className={style.instanceFinal__card_content}>
                <Row>
                  <P1
                    className={style.instanceFinal__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[COMPLETED_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceFinal__value}
                    name="/instances/dashboard_is/completed"
                    onClick={handleOnCompletedClick}
                    transparent
                  >
                    <P1 bold>{completed}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceFinal__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[NO_QUORUM_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceFinal__value}
                    name="/instances/dashboard_is/no_quorum"
                    onClick={handleOnNoQuorumClick}
                    transparent
                  >
                    <P1 bold>{noQuorum}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1
                    className={style.instanceFinal__text}
                    small
                    textAlign="start"
                  >
                    {INSTANCE_STAGE_TO_LABEL[CANCELLED_STAGE]}
                  </P1>

                  <ButtonContainer
                    className={style.instanceFinal__value}
                    name="/instances/dashboard_is/cancelled"
                    onClick={handleOnCancelledClick}
                    transparent
                  >
                    <P1 bold>{cancelled}</P1>
                  </ButtonContainer>
                </Row>

                <Row>
                  <P1 bold className={style.instanceFinal__text_total} small>
                    Total Meetings
                  </P1>

                  <ButtonContainer
                    className={style.instanceFinal__value}
                    name="/instances/dashboard_is/total_instances_completed_group"
                    onClick={handleOnTotalClick}
                    transparent
                  >
                    <P1 bold>{total}</P1>
                  </ButtonContainer>
                </Row>
              </Column>
            </Card>
          )}
        </div>
      )}
    </Flexbox>
  );
};

export default InstanceFinal;
