import { useProvisioningService } from "../../services";
import { useTokenRefreshHandler } from "../../hooks";
import { useActingAsOverrideHeader } from "../../auth";
import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

export function useDisableOrg() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useProvisioningService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (orgId: number): Promise<void> =>
    service
      .post(`/api/provisioning/org/${orgId}/disable`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: Response) => {
        // @ts-ignore - `keys` is not explicitly specified on type `cache`
        const keys: IterableIterator<string> = cache.keys();

        Array.from(keys)
          .filter(
            (route: string) =>
              route.includes("/api/provisioning") || route.includes("/api/org")
          )
          .forEach((route: string) => {
            mutate(route);
          });

        return res;
      })
      .catch(errorHandler);
}
