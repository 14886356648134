import { useRef, useState } from "react";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  List,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import FilterButton from "./FilterButton";

export function FilterDropdown<T extends string | number>(props: {
  label?: string;
  options?: { label: string; value: T }[];
  value?: T[];
  onChange?: (value: T[]) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const options = props.options || [];
  const selected = options.filter((o) => props.value?.includes(o.value));

  return (
    <div>
      <FilterButton
        ref={buttonRef}
        onClick={() => {
          setOpen(true);
        }}
        active={selected.length > 0}
        dropdown
      >
        <Typography sx={{ fontSize: "11px" }}>{props.label}</Typography>
        <Typography sx={{ fontSize: "11px" }}>
          {selected.length > 0 ? ` (${selected.length})` : ""}
        </Typography>
      </FilterButton>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ width: "300px" }}>
          {options.map((option) => {
            const checked = selected.some((o) => o.value === option.value);

            return (
              <ListItemButton
                key={option.value}
                onClick={() => {
                  if (props.onChange) {
                    if (checked) {
                      props.onChange(
                        selected
                          .map((option) => option.value)
                          .filter((o) => o !== option.value)
                      );
                    } else {
                      props.onChange(
                        selected
                          .map((option) => option.value)
                          .concat(option.value)
                      );
                    }
                  }
                }}
                sx={{ p: 0.5 }}
              >
                <Checkbox
                  size="small"
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={checked}
                />
                <Typography>{option.label}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </div>
  );
}

export default FilterDropdown;
