import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepButton,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { TemplateEditDistribution } from "src/componentsV2/TemplateEditDistribution";
import { TemplateEditInviteAndEmail } from "src/componentsV2/TemplateEditInviteAndEmail";
import { TemplateEditSettings } from "src/componentsV2/TemplateEditSettings";
import { TemplateEditTimeAttributes } from "src/componentsV2/TemplateEditTimeAttributes";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import useUrlQueryV2 from "src/hooks/useUrlQueryV2";
import { useActivateTemplate, useDeactivateTemplate } from "src/mutations";
import { useDeleteMeetingTemplate } from "src/mutations/useDeleteMeetingTemplate";
import {
  MeetingTemplateUpdateBody,
  useUpdateMeetingTemplate,
} from "src/mutations/useUpdateMeetingTemplate";
import { useUpdateRoutingLogicUser } from "src/mutations/useUpdateRoutingLogicUser";
import { useMeetingDefinition } from "src/queries";
import { RoutingLogic, useRoutingLogic } from "src/queries/useRoutingLogic";
import { formatDateTime } from "src/services/formatDateTime";
import { MeetingDefinition } from "src/types";
import { ROLE_LEVELS } from "../../../auth/roles";
// Datafetching imports
import { Delete, Error as ErrorIcon } from "@mui/icons-material";
import {
  useActingAs,
  useActingAsOverrideHeader,
  useIsDefaultUser,
} from "src/auth";
import { useTokenRefreshHandler } from "src/hooks";
import { useUserService } from "src/services";
import { getUserDetails, getUserToken } from "src/utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

export default function MeetingTemplateEditPage() {
  const [activeStep, setActiveStep] = useState(0);
  const { addGeneralNotification, addError } = useGeneralNotifications();

  const { id }: { id: string } = useParams();
  const { data: routingLogic } = useRoutingLogic(id);

  const query = useUrlQueryV2();
  const history = useHistory();
  const [disableSave, setDisableSave] = useState(false);
  const [hideMeetingHosts, setHideMeetingHosts] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const deleteMeetingTemplate = useDeleteMeetingTemplate();
  const activateTemplate = useActivateTemplate();
  const deactivateTemplate = useDeactivateTemplate();
  const updateRoutingLogic = useUpdateRoutingLogicUser();
  const isDefaultUser = useIsDefaultUser();

  const methods = useForm<MeetingDefinition>({
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const updateMeetingTemplate = useUpdateMeetingTemplate();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const { data: meetingDefinition, error } = useMeetingDefinition(id);

  const userDetails = getUserDetails();
  const actingAsDetails = useActingAs();

  // ---------------- Default routing logic fetching.
  // We have to do all of this here, because we need to invoke this API call on team change, and wait for the result.
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const fetchDefaultRoutingLogic = (teamId: number | string) =>
    service
      .get(`/api/meetings/definition/default_routing_logic/${teamId}`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((data?: RoutingLogic[]) => data)
      .catch(errorHandler);

  // ---------------- Default routing logic fetching END.

  useEffect(() => {
    setActiveStep(Number(query.get("step") || 0));
  }, [query]);

  useEffect(() => {
    if (!meetingDefinition) {
      return;
    }

    // If the user is the default user, and they haven't created the template, they shouldn't be able to edit it.
    let ableToEdit = true;

    if (actingAsDetails[0]?.role === ROLE_LEVELS.DEFAULT) {
      ableToEdit =
        actingAsDetails[0].id ===
        meetingDefinition?.creationData.creatorData.userId;
    } else if (userDetails?.role === ROLE_LEVELS.DEFAULT) {
      ableToEdit =
        userDetails?.id === meetingDefinition?.creationData.creatorData.userId;
    }

    if (!ableToEdit) {
      history.replace("/meeting-templates");
    }

    if (
      meetingDefinition.routing_logic?.metadata?.subtypeDetails.subtype ===
      "email"
    ) {
      meetingDefinition.routing = "email";
    }

    reset(meetingDefinition);
    setInitialDataLoaded(true);
  }, [meetingDefinition, reset]);

  if (error) {
    console.error(error);
    return <Redirect to="/404" />;
  }

  const createdByDefaultUser =
    meetingDefinition?.creationData.creatorData.userRole ===
    ROLE_LEVELS.DEFAULT;

  if (!initialDataLoaded) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="10vh" />
      </Stack>
    );
  }

  const formatRoutingLogic = (
    templateUpdateBody: MeetingTemplateUpdateBody,
    formData: MeetingDefinition,
    newRoutingLogic?: RoutingLogic[]
  ) => {
    // We have to keep the code below for now until we get a new endpoint for updating Meeting Templates.
    // If the team has changed, newRoutingLogic is passed in as a third param, and we need to use it to reset the sequential props, in the routing_logic prop.
    if (newRoutingLogic) {
      if (templateUpdateBody.routing_logic) {
        templateUpdateBody.routing_logic.sequential.order = newRoutingLogic.map(
          (routingLogicItem) => routingLogicItem.user_id
        );

        templateUpdateBody.routing_logic.custom = {};
        newRoutingLogic.forEach((routingLogicItem) => {
          if (templateUpdateBody.routing_logic) {
            templateUpdateBody.routing_logic.custom[routingLogicItem.user_id] =
              {
                enabled: routingLogicItem.enabled,
                mapping_logic: routingLogicItem.mapping_logic,
              };
          }
        });
      }

      delete templateUpdateBody.routing_logic?.metadata;

      // If newRoutingLogic isn't passed in, we need to populate the routing_logic properties with the data from the routing logic endpoint. We populate these differently, depending on if the user selected the "email" or "sequential" routing logic.
    } else if (formData.routing === "email") {
      if (templateUpdateBody.routing_logic) {
        const subtypeDetailsField =
          templateUpdateBody.routing_logic.metadata?.subtypeDetails.field ??
          "routing_field";

        templateUpdateBody.routing = "custom";
        templateUpdateBody.routing_logic.metadata = {
          subtypeDetails: {
            field: subtypeDetailsField,
            subtype: "email",
          },
        };

        routingLogic?.forEach((item) => {
          if (templateUpdateBody.routing_logic) {
            templateUpdateBody.routing_logic.custom[item.user_id] = {
              enabled: item.enabled,
              mapping_logic: [
                {
                  field: subtypeDetailsField,
                  operator: "is",
                  value: item.email,
                },
              ],
            };
          }
        });
      }
    } else if (formData.routing === "sequential") {
      delete templateUpdateBody.routing_logic?.metadata;

      // For both the sequential and custom routing logic we have to set the `enabled` flag.
      // We do not touch the sequential property, just this one in both cases.
      routingLogic?.forEach((item) => {
        if (templateUpdateBody.routing_logic) {
          templateUpdateBody.routing_logic.custom[item.user_id] = {
            enabled: item.enabled,
            mapping_logic: [],
          };
        }
      });
    }

    // We have to delete this because otherwise the patch fails.
    delete templateUpdateBody.routing_logic?.sequential.last_processed;

    return templateUpdateBody.routing_logic;
  };

  const onSubmit = async (
    data: MeetingDefinition,
    _: React.BaseSyntheticEvent<object, any, any> | undefined,
    newRoutingLogic?: RoutingLogic[]
  ) => {
    setDisableSave(true);
    try {
      const templateUpdateBody = {
        ...data,
        enabled: data.active,
        team: data.team.id,
        routingJustMe: data.routingJustMe,
        tags: data.tags.map((tag) => tag.id),
      };

      if (!(isDefaultUser || createdByDefaultUser)) {
        templateUpdateBody.routing_logic = formatRoutingLogic(
          templateUpdateBody,
          data,
          newRoutingLogic
        );
      } else {
        // Reset routing logic for personal meeting type.
        templateUpdateBody.routing = "sequential";
        templateUpdateBody.routing_logic = {
          sequential: {
            last_processed: 0,
            order: null,
          },
          custom: {},
        };
      }

      if (data.inviteStyle === "link_first") {
        // For "link_first" we set hardcoded 1-30 day range since this
        // meeting type does not allow day range configuration and
        // default value is not good.
        templateUpdateBody.properties.dayRange.from = 1;
        templateUpdateBody.properties.dayRange.to = 30;
      }

      await updateMeetingTemplate(data.id, templateUpdateBody);
      addGeneralNotification(
        `Template ${templateUpdateBody.name} updated successfully!`
      );
    } catch (error) {
      addError(`Template update failed: ${getErrorMessage(error)}`);

      console.error(error);
    } finally {
      setDisableSave(false);
    }
  };
  return (
    <Box sx={{ backgroundColor: "#f1f1f1", minHeight: "100%", pt: 4 }}>
      <Container maxWidth="xl" disableGutters sx={{ px: "30px", pb: "65px" }}>
        <Dialog
          open={deleteDialogVisible}
          onClose={() => setDeleteDialogVisible(false)}
        >
          <DialogTitle>
            <Typography
              sx={{ color: "primary.dark", fontWeight: "bold" }}
              variant="h5"
              component="span"
            >
              Delete Template
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the template?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start", px: 3 }}>
            <SecondaryButton
              disabled={actionInProgress}
              onClick={() => setDeleteDialogVisible(false)}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={actionInProgress}
              onClick={async () => {
                if (!meetingDefinition?.id) {
                  setDeleteDialogVisible(false);
                  return;
                }
                try {
                  setActionInProgress(true);
                  await deleteMeetingTemplate(meetingDefinition.id);

                  history.push("/meeting-templates");
                  addGeneralNotification(
                    `Succesfully deleted template ${meetingDefinition.name}`
                  );
                  setDeleteDialogVisible(false);
                } catch (error) {
                  addError(
                    `Failed to delete the template: ${getErrorMessage(error)}`
                  );
                  console.error(error);
                } finally {
                  setActionInProgress(false);
                }
              }}
            >
              Delete
            </PrimaryButton>
          </DialogActions>
        </Dialog>

        <FormProvider {...methods}>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Stack
                sx={{
                  mb: 3,
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <Stack>
                  <TextField
                    {...register("name", {
                      required: "Template name is required.",
                    })}
                    InputProps={{
                      sx: { fontWeight: "bold", fontSize: "22px" },
                    }}
                    sx={{ width: "400px" }}
                    variant="standard"
                    placeholder="New Template Name"
                    label="Template name"
                  />
                  {errors.name && (
                    <Typography sx={{ color: "red" }}>
                      {errors.name.message}
                    </Typography>
                  )}
                </Stack>
                <Box>
                  <Typography fontSize={"14px"}>
                    Created By:{" "}
                    {`${getValues(
                      "creationData.creatorData.userFirstName"
                    )} ${getValues("creationData.creatorData.userLastName")}`}
                  </Typography>
                  <Typography fontSize={"14px"}>
                    Created At:{" "}
                    {formatDateTime(getValues("creationData.createdAt"))}
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "auto",
                    gap: 2,
                  }}
                >
                  <IconButton
                    sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
                    aria-label="delete"
                    size="small"
                    onClick={() => setDeleteDialogVisible(true)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                  <Controller
                    control={control}
                    name="active"
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        disabled={disableSave}
                        onChange={async (event) => {
                          setDisableSave(true);

                          try {
                            field.onChange(event.target.checked);

                            if (event.target.checked) {
                              await activateTemplate(getValues("id"));
                              addGeneralNotification(
                                `Template ${getValues(
                                  "name"
                                )} successfully activated!`
                              );
                            } else {
                              await deactivateTemplate(getValues("id"));
                              addGeneralNotification(
                                `Template ${getValues(
                                  "name"
                                )} successfully deactivated!`
                              );
                            }
                          } catch (error) {
                            addError(
                              `Template activation failed: ${getErrorMessage(
                                error
                              )}`
                            );

                            field.onChange(!event.target.checked);
                          } finally {
                            setDisableSave(false);
                          }
                        }}
                      />
                    )}
                  />
                  <PrimaryButton
                    // Forms isValid flag is not working properly so had to do it like this.
                    disabled={Object.keys(errors).length > 0 || disableSave}
                    onClick={() => {
                      handleSubmit(onSubmit)();
                    }}
                  >
                    Save
                  </PrimaryButton>
                </Stack>
              </Stack>
              <Stepper nonLinear activeStep={activeStep}>
                <Step>
                  <StepButton
                    color="inherit"
                    onClick={() =>
                      history.push({
                        search: `?step=0`,
                      })
                    }
                  >
                    Template Settings
                  </StepButton>
                </Step>
                <Step>
                  <StepButton
                    icon={
                      (errors.emailTemplates ||
                        errors.inviteTemplates ||
                        errors.properties?.cleanDeclineRule ||
                        errors.properties?.meetingReminder) && (
                        <ErrorIcon sx={{ color: "red" }} />
                      )
                    }
                    color="inherit"
                    onClick={() =>
                      history.push({
                        search: `?step=1`,
                      })
                    }
                  >
                    Email & Invite
                  </StepButton>
                </Step>
                <Step>
                  <StepButton
                    color="inherit"
                    onClick={() =>
                      history.push({
                        search: `?step=2`,
                      })
                    }
                  >
                    Meeting Distribution
                  </StepButton>
                </Step>
                <Step>
                  <StepButton
                    color="inherit"
                    onClick={() =>
                      history.push({
                        search: `?step=3`,
                      })
                    }
                  >
                    Time Attributes
                  </StepButton>
                </Step>
              </Stepper>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              {/* We have to show/hide the steps through css, otherwise the validation doesn't work properly, because the elements are not present on the page */}
              <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
                <Box sx={{ mb: 5 }}>
                  <TemplateEditSettings />
                </Box>
                <PrimaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep + 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Next
                </PrimaryButton>
              </Box>
              <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
                <Box sx={{ mb: 5 }}>
                  <TemplateEditInviteAndEmail />
                </Box>
                <Stack sx={{ flexDirection: "row", gap: 2 }}>
                  <SecondaryButton
                    onClick={() => {
                      history.push({
                        search: `?step=${activeStep - 1}`,
                      });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Previous
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      history.push({
                        search: `?step=${activeStep + 1}`,
                      });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Next
                  </PrimaryButton>
                </Stack>
              </Box>
              <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
                <Box sx={{ mb: 5 }}>
                  <TemplateEditDistribution
                    hideMeetingHosts={hideMeetingHosts}
                    currentTeam={meetingDefinition?.team}
                    routingLogic={routingLogic}
                    createdByDefaultUser={createdByDefaultUser}
                    onTeamChange={async () => {
                      try {
                        setDisableSave(false);
                        setHideMeetingHosts(false);
                        const newRoutingLogic = await fetchDefaultRoutingLogic(
                          getValues("team.id")
                        );

                        // When changing teams, always default to the "sequential" routing logic first.
                        setValue("routing", "sequential");
                        handleSubmit((data, event) =>
                          onSubmit(data, event, newRoutingLogic)
                        )();
                      } catch (error: any) {
                        if (error?.status === 404 && error.message) {
                          addError(JSON.parse(error.message).message);
                          setDisableSave(true);
                          setHideMeetingHosts(true);
                        }
                      }
                    }}
                    // here - need to handle error here
                    onUpdateRoutingLogicUser={(routingLogic: RoutingLogic) =>
                      updateRoutingLogic(id, routingLogic.user_id, {
                        enabled: routingLogic.enabled,
                        mapping_logic: routingLogic.mapping_logic,
                      })
                    }
                  />
                </Box>
                <Stack sx={{ flexDirection: "row", gap: 2 }}>
                  <SecondaryButton
                    onClick={() => {
                      history.push({
                        search: `?step=${activeStep - 1}`,
                      });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Previous
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      history.push({
                        search: `?step=${activeStep + 1}`,
                      });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Next
                  </PrimaryButton>
                </Stack>
              </Box>
              <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
                <Box sx={{ mb: 5 }}>
                  <TemplateEditTimeAttributes
                    meetingDefinitionId={meetingDefinition?.id}
                  />
                </Box>
                <SecondaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep - 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Previous
                </SecondaryButton>
              </Box>
            </CardContent>
          </Card>
        </FormProvider>
      </Container>
    </Box>
  );
}

const getErrorMessage = (error: unknown) => {
  let errorMessage = "Unknown error";

  if (error instanceof Error && error.message) {
    errorMessage = error.message;
  }

  if (error instanceof Error && JSON.parse(error.message)?.message) {
    errorMessage = JSON.parse(error.message).message;
  }

  // Sometimes the BE returns the errors as an array, so we show the first one.
  if (error instanceof Error && JSON.parse(error.message)?.errors?.[0]) {
    errorMessage = JSON.parse(error.message).errors[0];
  }

  return errorMessage;
};
