import React, { useCallback } from "react";
import { useAddMeetingTags } from "../../mutations";
import { TagManager } from "../../components/tagManager";
import { useTagsRepository } from "../../repository";

const EMPTY_LIST = [];

function isSavable(selectedTags) {
  return selectedTags !== null && selectedTags.length > 0;
}

export function BulkAddTags({ close, instances }) {
  const addMeetingTags = useAddMeetingTags();

  const repository = useTagsRepository();

  const addTags = useCallback(
    async (selectedTags) => {
      await addMeetingTags(
        instances,
        selectedTags.map((t) => t.id)
      );
    },
    [addMeetingTags, instances]
  );
  return (
    <TagManager
      close={close}
      initialSelectedTags={EMPTY_LIST}
      isSavable={isSavable}
      onRemove={useCallback(() => [], [])}
      onSave={addTags}
      title="Bulk Add Tags"
      repository={repository}
    />
  );
}
