import { useCallback, useState } from "react";
import { useChannelsRepository } from "../../repository";

export default function useSampleImportData() {
  // for test running a channel
  const [sampleImportData, setSampleImportData] = useState(null);
  const [isRetrievingSampleData, setIsRetrievingSampleData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { runSampleImportChannel } = useChannelsRepository();

  const runSampleImportData = useCallback(
    async (chanObj, chanId = 0) => {
      setHasError(false);
      setSampleImportData(null);
      setIsRetrievingSampleData(true);

      try {
        let errOccurred = false;
        const response = await runSampleImportChannel(chanObj, chanId)
          .then((r) => r.json())
          .catch(() => {
            errOccurred = true;
          });

        if (errOccurred) {
          throw new Error();
        }

        setSampleImportData(response);
      } catch (err) {
        setSampleImportData(null);
        setHasError(true);
      }

      setIsRetrievingSampleData(false);
    },
    [runSampleImportChannel]
  );

  const clearSampleData = useCallback(() => {
    setHasError(false);
    setSampleImportData(null);
    setIsRetrievingSampleData(false);
  }, []);

  return {
    clearSampleData,
    hasError,
    isRetrievingSampleData,
    runSampleImportData,
    sampleImportData,
  };
}
