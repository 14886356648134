import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import Mustache from "mustache";

import { Intent, MerlinResponse, useMerlin } from "../../../queries";
import DateInput from "../../../componentsV2/DateInput";
import { Loading } from "../../../componentsV2/Loading";
import Logo from "../../../componentsV2/Logo";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useConfiguration } from "../../../configuration";
import { Availability } from "../../../componentsV2/AvailabilitySettings";
import { findSlot, TimeRange } from "../../../scheduling";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { TimezoneSelect } from "src/componentsV2/inputs/TimezoneSelect";
import AvailabilityInput from "src/componentsV2/inputs/AvailabilityInput";
import { PageLayout } from "src/componentsV2/PageLayout";

dayjs.extend(utc);
dayjs.extend(tz);

const MERLIN_TIME_FORMAT = "MM/DD/YYYY h:mm A";

function GuestIntent({ intent }: { intent: Intent }) {
  let text = "";

  switch (intent) {
    case "altdatetime":
      text =
        "Merlin detected that the guest has proposed an alternative time for the meeting";
      break;
    case "accept":
      text = "Merlin detected that the guest has accepted the meeting!";
      break;
    case "decline":
      text = "Merlin detected that the guest has declined the meeting";
      break;
    case "unsubscribe":
      text =
        "Merlin detected that the guest would like to unsubscribe from the outreach";
      break;
    default:
      text = "Merlin does not understand the guest's intentions";
  }

  return <Typography>{text}</Typography>;
}

function SuggestedResponsesInput({
  suggestions,
  onSelect,
}: {
  suggestions: { id: string | number; value: string }[];
  onSelect?: (selection: { id: string | number; value: string }) => void;
}) {
  const searchInput = useRef<HTMLInputElement>();

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [focused, setFocus] = useState(false);

  const setInputFocus = (state: boolean) => {
    if (searchInput.current?.value) {
      return setFocus(true);
    }

    setFocus(state);
    setInput("");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(_, value) => {
        setInput(value);
      }}
      onChange={(_, value) => {
        if (value && onSelect) {
          onSelect(value);
        }

        if (!searchInput?.current) {
          console.error("searchInput reference is undefined");
          return null;
        }

        searchInput.current.value = "";
        searchInput.current.blur();
      }}
      value={null}
      getOptionLabel={(option) => option.value}
      options={suggestions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<Typography>Suggested Responses</Typography>}
          InputProps={{
            ...params.InputProps,
            inputRef: searchInput,
            onBlur: () => setInputFocus(false),
            onFocus: () => setInputFocus(true),
          }}
          InputLabelProps={{ shrink: focused }}
        />
      )}
    />
  );
}

const availableEmailTemplate = `Hey {{guest_first_name}},
{{meeting_time}} on {{meeting_day_of_week}} {{meeting_month_day}}, works great for me.
I've updated the calendar invite.`;

const unavailableEmailTemplate = `Hey {{guest_first_name}},
I'm unavailable at that time.
I do have {{meeting_date_time}}, I've updated the invite if that works for you.
If not, would you mind suggesting a couple times that would?`;

function EmailResponse({
  available,
  name,
  time,
}: {
  available: boolean;
  name: string;
  time: dayjs.Dayjs;
}) {
  const vars = {
    guest_first_name: name,
    meeting_date_time: time.format("MMMM D YYYY, h:mm a z"),
    meeting_day_of_week: time.format("dddd"),
    meeting_month_day: time.format("MMMM D"),
    meeting_time: time.format("h:mm a"),
  };

  return (
    <TextField
      fullWidth
      label="Automated Merlin Response"
      minRows={5}
      multiline
      value={
        available
          ? Mustache.render(availableEmailTemplate, vars)
          : Mustache.render(unavailableEmailTemplate, vars)
      }
      disabled
    />
  );
}

function DataDogLogLink({
  label,
  requestId,
}: {
  label: string;
  requestId: string;
}) {
  const { ENVIRONMENT } = useConfiguration();
  // TODO(vovan): production app has 'us.app' injected as ENVIRONMENT
  //  variable. This is what DD RUM expect but DD logs
  //  view wants production ENVIRONMENT to be 'production'.
  const env = ENVIRONMENT === "us.app" ? "production" : ENVIRONMENT;
  const dataDogLogsUrl = new URL("https://app.datadoghq.com/logs");
  dataDogLogsUrl.search = new URLSearchParams({
    cols: "host,service",
    index: "",
    live: "true",
    query: `env:${env} service:merlin @request.id:${requestId}`,
    stream_sort: "time,desc",
  }).toString();

  return (
    <a href={dataDogLogsUrl.toString()} target="_blank" rel="noreferrer">
      {label}
    </a>
  );
}

function getHostAvailability(
  availability: Availability,
  timezone: string
): TimeRange[] {
  const today = dayjs().startOf("day");

  const a: TimeRange[] = [];

  for (let i = 0; i < 60; i += 1) {
    const t = timezone ? today.add(i, "day").tz(timezone) : today.add(i, "day");

    switch (t.day()) {
      default:
        break;
      case 0:
        if (availability.daysOfWeek.sunday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 1:
        if (availability.daysOfWeek.monday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 2:
        if (availability.daysOfWeek.tuesday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 3:
        if (availability.daysOfWeek.wednesday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 4:
        if (availability.daysOfWeek.thursday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 5:
        if (availability.daysOfWeek.friday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
      case 6:
        if (availability.daysOfWeek.saturday) {
          a.push({
            start: t
              .set("hour", Math.floor(availability.timeOfDay[0] / 60))
              .set("minute", availability.timeOfDay[0] % 60),
            end: t
              .set("hour", Math.floor(availability.timeOfDay[1] / 60))
              .set("minute", availability.timeOfDay[1] % 60),
          });
        }
        break;
    }
  }

  return a;
}

export default function Page() {
  const [loading, setLoading] = useState(false);
  const [guestName, setGuestName] = useState("Amy");
  const [input, setInput] = useState("Could you meet at 2pm instead?");
  const [hostTimezone, setHostTimezone] = useState(dayjs.tz.guess());
  const [guestTimezone, setGuestTimezone] = useState(dayjs.tz.guess());
  const [response, setResponse] = useState<MerlinResponse | undefined>(
    undefined
  );
  const [inviteDate, setInviteDate] = useState(
    dayjs().add(1, "day").set("hour", 10).set("minute", 30)
  );
  const [replyDate, setReplyDate] = useState(dayjs());
  const [availability, setAvailability] = useState<Availability>({
    daysOfWeek: {
      sunday: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
    },
    timeOfDay: [540, 1020],
  });

  const merlin = useMerlin();
  const { addError } = useGeneralNotifications();

  const formValid = !(input.length < 1);

  const handleSubmit = () => {
    setLoading(true);
    merlin(
      {
        email: "john@doe.com",
        first_name: "John",
        last_name: "Doe",
        timezone: guestTimezone,
      },
      input,
      inviteDate.tz(hostTimezone).toDate(),
      replyDate.tz(guestTimezone).toDate()
    )
      .then((res) => setResponse(res))
      .catch(() => {
        addError("Failed to send request to Merlin");
      })
      .finally(() => setLoading(false));
  };

  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(null);

  useEffect(() => {
    if (response) {
      const a = getHostAvailability(
        availability,
        hostTimezone || dayjs.tz.guess()
      );

      const proposedTimes = (response.data.proposed_time_ranges || [])
        .map((t) => ({
          end: dayjs(t.end),
          start: dayjs(t.start),
        }))
        .concat(
          (response.data.proposed_times || []).map((t) => ({
            start: dayjs(t),
            end: dayjs(t).add(30, "minutes"),
          }))
        );

      const s = findSlot(a, proposedTimes, 30);

      setSelectedTime(s?.start || null);
    }
  }, [availability, response, hostTimezone]);

  return (
    <PageLayout title="Merlin Chat">
      <Stack justifyContent="center" alignItems="center" spacing={3}>
        <Logo width={350} />
        <Typography align="center">
          Meet Merlin, Kronologic's Natural Language Processing AI assistant who
          can help negotiate a time for your next meeting. Why schedule the
          meeting yourself when Merlin can do it for you?
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <TimezoneSelect
                label="Host Timezone"
                value={hostTimezone}
                onChange={(v) => {
                  if (v) setHostTimezone(v);
                }}
              />
              <AvailabilityInput
                label="Host Availability"
                value={availability}
                onChange={setAvailability}
              />
              <DateInput
                label="Start Time"
                value={inviteDate}
                helperText="The original proposed start time for the meeting (in the host's timezone)"
                onChange={(value) =>
                  setInviteDate(
                    value ??
                      dayjs().add(1, "day").set("hour", 10).set("minute", 30)
                  )
                }
                inputFormat={MERLIN_TIME_FORMAT}
              />
              <TextField
                label="Guest First Name"
                fullWidth
                value={guestName}
                onChange={(event) => {
                  setGuestName(event.target.value);
                }}
              />
              <TimezoneSelect
                label="Guest Timezone"
                value={guestTimezone}
                onChange={(v) => {
                  if (v) setGuestTimezone(v);
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={3} sx={{ width: "100%" }}>
              <DateInput
                label="Reply Time"
                value={replyDate}
                helperText="Represents the time at which the guest sent the response below (in the guest's timezone)"
                onChange={(value) => setReplyDate(value ?? dayjs())}
                inputFormat={MERLIN_TIME_FORMAT}
              />
              <SuggestedResponsesInput
                suggestions={[
                  {
                    id: "Propose new time",
                    value: "Can we meet at 2pm instead?",
                  },
                  {
                    id: "Suggestion time in different timezone",
                    value: "Can we meet at 2pm PST?",
                  },
                  {
                    id: "Propose a time range",
                    value: "Are you available Thursday between 1 and 4?",
                  },
                  {
                    id: "Propose new window",
                    value: "Let's meet the following week instead.",
                  },
                  {
                    id: "Propose multiple alternatives",
                    value:
                      "Are you available Thursday between 1 and 4 or Friday at 11?",
                  },
                  {
                    id: "Accept",
                    value: "Looking forward to our meeting.",
                  },
                  {
                    id: "Unsubscribe",
                    value: "Please stop emailing me.",
                  },
                  {
                    id: "Decline",
                    value: "Thanks, but I am not interested.",
                  },
                ]}
                onSelect={(selection) => {
                  setInput(selection.value);
                }}
              />
              <TextField
                label="Response"
                minRows={5}
                multiline
                helperText="The email response from the meeting guest"
                value={input}
                onChange={(event) => setInput(event.target.value)}
              />

              <PrimaryButton onClick={handleSubmit} disabled={!formValid}>
                Submit
              </PrimaryButton>
            </Stack>
          </Grid>
        </Grid>
        {response && <Divider flexItem />}
        {loading ? (
          <Loading />
        ) : (
          response && (
            <Stack spacing={2} alignItems="center">
              <Box sx={{ m: "1em" }}>
                <GuestIntent intent={response.data.intent} />
              </Box>
              {(response.data.proposed_times ||
                response.data.proposed_time_ranges) && (
                <>
                  {selectedTime ? (
                    <>
                      <EmailResponse
                        available
                        name={guestName}
                        time={selectedTime.tz(guestTimezone)}
                      />
                      <Typography align="center" variant="caption">
                        *This demo does not take into account the meeting host's
                        availability. When used in production, Merlin would
                        either accept the proposed time or suggested a new time
                        in accordance with the host's calendar availability.
                      </Typography>
                    </>
                  ) : (
                    <Typography color="error">
                      No availability could be on the host's calendar so no
                      response was sent
                    </Typography>
                  )}
                </>
              )}

              <DataDogLogLink
                requestId={response.data.request_id}
                label="View Merlin Logs"
              />
            </Stack>
          )
        )}
      </Stack>

      <Box sx={{ height: "2em", minHeight: "2em" }} />
    </PageLayout>
  );
}
