import { Check, Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";

import { useActingAs } from "src/auth";
import { supersedes } from "src/auth/roles";
import { PrimaryButton } from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { useConfirmationDialog, useDebounce, useSnackbar } from "src/hooks";
import { useCreateTag, useDeleteTags, useUpdateTag } from "src/mutations";
import { useTags } from "src/queries";
import { OrgAdminRole, Tag } from "src/types";
import { getUserDetails } from "src/utils/jwtToken";
import { Loading } from "../Loading";

export function TagManagementDialog(props: {
  label: string;
  open: boolean;
  initialTags?: Tag[];
  onSubmit: (tags: Tag[]) => void;
  onClose: () => void;
}) {
  const [tags, setTags] = useState<Tag[]>(props.initialTags || []);

  const [openSnackbar] = useSnackbar();
  const updateTag = useUpdateTag();
  const deleteTag = useDeleteTags();
  const openConfirmationDialog = useConfirmationDialog();

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();

  // Determine if the user is allowed to edit or create tags.
  const editable = supersedes({
    has: actingAs?.role || loggedInUser?.role,
    required: OrgAdminRole,
  });

  return (
    <Dialog open={props.open} onClose={props.onClose} disablePortal fullWidth>
      <DialogTitle
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          pb: 0,
        }}
      >
        <Typography
          component="span"
          sx={{ fontSize: "22px", color: "primary.dark", fontWeight: "bold" }}
          variant="h5"
        >
          {props.label}
        </Typography>

        <IconButton onClick={props.onClose}>
          <Close sx={{ color: "text.primary" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Stack sx={{ gap: 2, pt: "20px" }}>
          <AddTagInput
            onSelect={(tag) => {
              if (!tags.map((t) => t.id).includes(tag.id)) {
                setTags(tags.concat([tag]));
              }
            }}
            onEdit={async (tag) => {
              try {
                await updateTag(tag.id, tag.name);
                setTags(tags.filter((t) => tag.id != t.id).concat([tag]));
              } catch {
                openSnackbar("Failed to update tag");
              }
            }}
            onDelete={(tag) => {
              openConfirmationDialog(
                "Delete confirmation",
                `Are you sure you want to delete the ${tag.name}`,
                async () => {
                  try {
                    await deleteTag([tag.id]);
                    setTags(tags.filter((t) => tag.id != t.id));
                  } catch {
                    openSnackbar("Failed to delete tag");
                  }
                }
              );
            }}
            editable={editable}
          />
          <Typography sx={{ fontWeight: "bold", color: "black" }}>
            Current Tags
          </Typography>
          {tags.length > 0 ? (
            <Stack sx={{ flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag.id}
                  sx={{
                    backgroundColor: "primary.light",
                    borderRadius: "5px",
                    "& .MuiChip-deleteIcon": {
                      color: "primary.dark",
                    },
                  }}
                  size="small"
                  label={tag.name}
                  onDelete={() => setTags(tags.filter((t) => tag.id != t.id))}
                  deleteIcon={<Close />}
                />
              ))}
            </Stack>
          ) : (
            <Typography>No tags selected</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ p: "30px 25px 25px 25px", justifyContent: "flex-start" }}
      >
        <SecondaryButton
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            props.onSubmit(tags);
          }}
        >
          Update Tags
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
}

function TagListItemEditMode(props: {
  value: Tag;
  onSave: (value: string) => void;
  onClose: () => void;
}) {
  const [input, setInput] = useState(props.value.name);

  const debouncedInput = useDebounce(input, 750);
  const { data, loading } = useTags(5, 0, debouncedInput);

  const loaded = debouncedInput === input && !loading;
  const empty = input.length < 1;
  const same = input.toLowerCase() === props.value.name.toLowerCase();
  const exists =
    data?.data.some((tag) => tag.name.toLowerCase() === input.toLowerCase()) &&
    !same;

  return (
    <ListItem
      secondaryAction={
        <Stack direction="row" spacing={1}>
          <IconButton
            size="small"
            disabled={!loaded || exists || empty || same}
            onClick={() => {
              props.onSave(input);
            }}
          >
            {!loaded ? (
              <CircularProgress size={20} />
            ) : (
              <Check fontSize="small" />
            )}
          </IconButton>

          <IconButton
            size="small"
            onClick={() => {
              props.onClose();
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      }
    >
      <TextField
        fullWidth
        autoFocus
        size="small"
        value={input}
        error={exists}
        helperText={exists ? "this tag already exists, please change name" : ""}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        sx={{
          mr: 6,
        }}
      />
    </ListItem>
  );
}

function TagListItemDisplayMode(props: {
  value: Tag;
  onSelect: () => void;
  onEdit: () => void;
  onDelete: () => void;
  editable?: boolean;
}) {
  return (
    <ListItem
      disablePadding
      secondaryAction={
        props.editable && (
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={props.onDelete}>
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        )
      }
    >
      <ListItemButton
        onClick={() => {
          props.onSelect();
        }}
      >
        <ListItemText
          primary={
            <Typography noWrap sx={{ mr: 6 }}>
              {props.value.name}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

function TagListItem(props: {
  value: Tag;
  onSelect: () => void;
  onEdit: (value: string) => void;
  onDelete: () => void;
  editable?: boolean;
}) {
  const [edit, setEdit] = useState(false);

  return edit ? (
    <TagListItemEditMode
      value={props.value}
      onSave={(name) => {
        props.onEdit(name);
        setEdit(false);
      }}
      onClose={() => setEdit(false)}
    />
  ) : (
    <TagListItemDisplayMode
      value={props.value}
      editable={props.editable}
      onSelect={props.onSelect}
      onEdit={() => setEdit(true)}
      onDelete={props.onDelete}
    />
  );
}

function AddTagInput(props: {
  editable?: boolean;
  onSelect: (value: Tag) => void;
  onEdit: (value: Tag) => void;
  onDelete: (value: Tag) => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>();

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [creatingTag, setCreatingTag] = useState(false);
  const createTag = useCreateTag();
  const [openSnackbar] = useSnackbar();

  const query = useDebounce(input, 750);

  const { data, loading } = useTags(5, 0, query);

  const options: Tag[] = data?.data || [];

  return (
    <Box>
      <TextField
        inputRef={inputRef}
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        variant="outlined"
        label="Search to add or create tags"
        onClick={() => setOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setOpen(false);
          }
          setOpen(true);
        }}
        autoComplete="off"
        fullWidth
      />
      <Popover
        open={open}
        disablePortal
        disableAutoFocus
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { minWidth: "300px", width: inputRef.current?.offsetWidth },
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <List>
            {options.length > 0 &&
              options.map((tag) => (
                <TagListItem
                  key={tag.id}
                  value={tag}
                  onSelect={() => {
                    setOpen(false);
                    setInput("");
                    props.onSelect(tag);
                  }}
                  onDelete={() => {
                    props.onDelete(tag);
                  }}
                  onEdit={(name) => {
                    props.onEdit({ id: tag.id, name });
                  }}
                  editable={props.editable}
                />
              ))}
            {input.trim() !== "" &&
              !options.some((tag) => tag.name.trim() === input.trim()) && (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      try {
                        setCreatingTag(true);
                        const tag = await createTag(input.trim());
                        props.onSelect(tag);
                      } catch {
                        openSnackbar("Failed to create tag");
                      } finally {
                        setCreatingTag(false);
                      }
                    }}
                  >
                    {creatingTag ? (
                      <Loading />
                    ) : (
                      <ListItemText
                        primary={
                          <Typography>Create '{input.trim()}'</Typography>
                        }
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              )}
          </List>
        )}
      </Popover>
    </Box>
  );
}

export default TagManagementDialog;
