import React, { useMemo, useCallback } from "react";
import cx from "classnames";
import { Select } from "../../../../inputs";
import { Input } from "../../../../components/input";
import { useConditionSelectTheme } from "./useConditionSelectTheme";
import LockedDropdown from "../../../../components/dropdown/LockedDropdown";
import {
  IMPORT_CONDITION_TYPES,
  INTERCEPT_CONDITION_TYPES,
} from "../cardProps";
import { CHANNEL_TYPES } from "../../../props";
import style from "../style.module.scss";

function FieldDropdown({
  showInvalidFields,
  hasError,
  channelType,
  isOrphaned,
  fields,
  localField,
  setValueOptions,
  setLocalField,
  setLocalCondition,
  setLocalValue,
}) {
  const [components, theme] = useConditionSelectTheme({ left: true });

  const fieldOptions = useMemo(() => {
    return fields.map((field) => ({
      label: field.label,
      value: field,
    }));
  }, [fields]);

  const showFieldIsInvalidStyling = useMemo(
    () =>
      showInvalidFields &&
      (localField.label === null || localField.label === ""),
    [localField.label, showInvalidFields]
  );

  const inputStyling = useMemo(
    () =>
      cx(style.conditions__card_condition_field_input, {
        [style.invalid_dropdown]: showFieldIsInvalidStyling,
      }),
    [showFieldIsInvalidStyling]
  );

  const canShowFieldDropdown = useMemo(
    () => !hasError && !isOrphaned,
    [hasError, isOrphaned]
  );

  const canShowLockedField = useMemo(
    () => (fields.length === 0 && hasError) || isOrphaned,
    [hasError, fields.length, isOrphaned]
  );

  const dropdownValue = useMemo(
    () => localField.label || localField.name || "",
    [localField]
  );

  const handleOnSelectField = useCallback(
    (item) => {
      if (item) {
        const { name, label, options } = item;

        setLocalField({
          label,
          name,
        });

        if (channelType === CHANNEL_TYPES.IMPORT) {
          setLocalCondition((prev) => prev || IMPORT_CONDITION_TYPES[0]);
        }

        if (channelType === CHANNEL_TYPES.INTERCEPT) {
          setLocalCondition((prev) => prev || INTERCEPT_CONDITION_TYPES[0]);
        }

        setValueOptions((prevValueOptions) => {
          if (options && options.length > 0) {
            setLocalValue(options[0].value);
          } else if (prevValueOptions !== null) {
            setLocalValue("");
          } else {
            setLocalValue((prev) => prev);
          }

          return options || null;
        });
      }
    },
    [
      channelType,
      setLocalCondition,
      setLocalField,
      setLocalValue,
      setValueOptions,
    ]
  );

  const handleOnChangeField = useCallback(
    (event) => {
      setLocalField({
        label: event.target.value,
        name: event.target.value,
      });
    },
    [setLocalField]
  );

  const dropdownVal = useMemo(() => {
    const val = fieldOptions.find((fo) => fo.label === localField.label);

    // if this option has a picklist of values, set them here
    if (val && val.value?.options?.length > 0) {
      setValueOptions(val.value.options);
    }

    return val;
  }, [fieldOptions, localField, setValueOptions]);

  return (
    <>
      {canShowFieldDropdown && (
        <div className={style.conditions__card_condition_field}>
          {fields?.length > 0 && (
            <Select
              components={components}
              customTheme={theme}
              name={`/channels/${channelType}/add_channel/select_field`}
              onChange={({ value }) => {
                handleOnSelectField(value);
              }}
              options={fieldOptions}
              placeholder={<div>Select A Field...</div>}
              value={dropdownVal}
            />
          )}

          {fields?.length === 0 && (
            <Input
              placeholder="Field..."
              type="text"
              name="localField"
              className={inputStyling}
              onChange={handleOnChangeField}
              value={dropdownValue}
            />
          )}
        </div>
      )}

      {canShowLockedField && (
        <LockedDropdown
          className={style.conditions__card_condition_locked_dropdown_field}
          value={dropdownValue}
        />
      )}
    </>
  );
}

export default FieldDropdown;
