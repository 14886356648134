import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { fetchWithErrorHandling } from "../../utils/fetchV2";
import { hasForbiddenErrors, hasUnauthorizedErrors } from "../../error";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";

export function useCustomFetchFn() {
  const { addError } = useGeneralNotifications();
  const history = useHistory();
  const doFetch = useCallback(
    async function (request, options) {
      try {
        return await fetchWithErrorHandling(request, options);
      } catch (error) {
        if (hasUnauthorizedErrors(error)) {
          history.push("/logout");
          return;
        }
        if (hasForbiddenErrors(error)) {
          addError(
            "Action Forbidden: You do not have the necessary permissions to perform this action"
          );
          return;
        }
        throw error;
      }
    },
    [addError, history]
  );

  const fetchJson = useCallback(
    // TODO(Matt): Create an error to throw here
    async function useFetchJson(request) {
      const response = await doFetch(request);
      return response.json();
    },
    [doFetch]
  );

  return {
    fetch: doFetch,
    fetchJson,
  };
}

function toKey(request) {
  return `${request.method}-${request.url}`;
}

export function useCachedFetch() {
  const { fetch } = useCustomFetchFn();
  const cache = useRef(new Map());
  const contains = useCallback(
    (request) => {
      const key = toKey(request);
      return cache.current.has(key);
    },
    [cache]
  );
  const get = useCallback(
    (request) => {
      const key = toKey(request);
      return cache.current.get(key);
    },
    [cache]
  );
  const set = useCallback(
    (request, value) => {
      const key = toKey(request);
      cache.current.set(key, value);
    },
    [cache]
  );
  return {
    contains,
    fetch,
    get,
    set,
  };
}
