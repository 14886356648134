import PropTypes from "prop-types";
import React, { useMemo } from "react";
import cx from "classnames";
import style from "./tag.module.scss";

const Tag = ({
  children,
  className,
  status,
  type,
  hasSwitch,
  onClick = null,
  ...rest
}) => {
  const onTagClick = useMemo(() => {
    if (onClick === null) {
      return null;
    }
    return () => {
      onClick(status);
    };
  }, [onClick, status]);
  return (
    <span
      tabIndex={0}
      role="button"
      onClick={onTagClick}
      onKeyPress={onTagClick}
      className={cx(
        className,
        style.tag,
        style[type],
        status ? style[status] : null,
        {
          [style["tag--clickable"]]: onTagClick !== null,
          [style.withSwitch]: hasSwitch,
        }
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

Tag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hasSwitch: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.oneOf(["default", "warning", "error", "info", "go", "light"]),
  value: PropTypes.string,
};

Tag.defaultProps = {
  children: null,
  className: [],
  hasSwitch: false,
  status: null,
  type: "default",
  value: null,
};

export default Tag;
