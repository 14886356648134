import { useSWRConfig } from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Meeting } from "../types";
import { MAX_PER_UPDATE, updateMeetingResponse } from "./useUpdateMeetings";
import { errorHandler } from "src/hooks/errorHandler";

type sendLaterRequest = {
  id: number;
  enabled: boolean;
  executeAt: Date;
};

export function useSendLater() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (
    meetings: sendLaterRequest[]
  ): Promise<updateMeetingResponse> => {
    // accumulate requests
    const batches: sendLaterRequest[][] = [];

    while (meetings.length) {
      // batch into 100 max
      batches.push(meetings.splice(0, MAX_PER_UPDATE));
    }

    const failures: any[] = [];
    const instances: Meeting[] = [];

    for (let i = 0; i < batches.length; i += 1) {
      // we want these to happen sequentially, so we disable the linter for the no-await-in-loop rule
      // the reason -- we want to limit update requests to a maximum of 100 per batch
      // we've found that >~300 will cause issues and potentially even time out
      // in order to reduce server load, we will force 100 per request and force requests to be sequential
      // docs: https://eslint.org/docs/latest/rules/no-await-in-loop
      // eslint-disable-next-line no-await-in-loop
      const response: updateMeetingResponse = await service
        .patch(`/api/meetings/instance/send_later`)
        .set(headers)
        .send(batches[i])
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .then((res: any) => {
          // @ts-ignore
          const keys = cache.keys();

          // Invalidate meeting queries and force refresh.
          Array.from(keys)
            .filter((r: any) => r.includes("/api/meetings/instances"))
            .forEach((k: any) => {
              mutate(k);
            });

          return res;
        })
        .catch(errorHandler);

      if (response.failures?.length > 0) {
        failures.push(...response.failures);
      }

      if (response.instances?.length > 0) {
        instances.push(...response.instances);
      }
    }

    return { failures, instances };
  };
}
