import React from "react";

import style from "./globallogs.module.scss";

function buildLogLine(calendarName, action, object) {
  return (
    <span>
      Calendar
      {` ${calendarName} `}
      {` ${action} `}
      <span className={style.logBuilder__userAttributeChange}>
        {` ${object} `}
      </span>
    </span>
  );
}

const buildUserCalendarSharingPropertiesChangedLog = function* (log) {
  const {
    structuredData: {
      userEmailsAdded,
      userEmailsRemoved,
      userTeamsAdded,
      userTeamsRemoved,
      calendarName,
    },
  } = log;

  if (userEmailsAdded?.length > 0) {
    yield buildLogLine(calendarName, "was added to user(s):", userEmailsAdded);
  }

  if (userEmailsRemoved?.length > 0) {
    yield buildLogLine(
      calendarName,
      "was removed from user(s):",
      userEmailsRemoved
    );
  }

  if (userTeamsAdded?.length > 0) {
    yield buildLogLine(calendarName, "was added to team(s):", userTeamsAdded);
  }

  if (userTeamsRemoved?.length > 0) {
    yield buildLogLine(
      calendarName,
      "was removed from team(s):",
      userTeamsRemoved
    );
  }
};

export { buildUserCalendarSharingPropertiesChangedLog as default };
