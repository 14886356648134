import React, { useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import { mdiCheckBold } from "@mdi/js";
import { Icon } from "@mdi/react";

import style from "./checkboxv3.module.scss";

function Checkbox({
  // TODO: Throw error if name not specified
  name = null,
  checked,
  disabled = false,
  dataCy = null,
  dataGtm = null,
  toggle,
  className,
  indeterminate,
  zIndex = 3,
  iconSize = 0.6,
  inputProps = {},
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  const ref = useRef(null);
  useEffect(() => {
    ref.current.indeterminate = indeterminate;
  }, [ref, indeterminate]);
  const onChangeStopPropagation = useCallback(
    (event) => {
      event.stopPropagation();
      toggle(event);
    },
    [toggle]
  );
  return (
    <div
      className={cx(className, style.checkbox, {
        [style["checkmark--disabled"]]: disabled,
        [style["checkmark--checked"]]: checked,
      })}
    >
      {checked && (
        <div className={style.checkbox__icon}>
          <Icon path={mdiCheckBold} size={iconSize} />
        </div>
      )}
      <input
        aria-label={name}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChangeStopPropagation}
        className={style.checkbox__input}
        ref={ref}
        type="checkbox"
        style={{
          zIndex,
        }}
        {...inputProps}
        {...dataProps}
      />
    </div>
  );
}

export default Checkbox;
