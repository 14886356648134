import React, {
  useMemo,
  useEffect,
  useCallback,
  useContext,
  useState,
} from "react";
import cx from "classnames";
import Select from "../../../inputs/Select";
import Checkbox from "../../../components/checkbox/CheckboxV3";
import { useIntegrationOptions } from "../../../inputs/util";
import LockedDropdown from "../../../components/dropdown/LockedDropdown";
import { GoToIntegrations } from "./linkButtons";
import { CHANNELS_TEXT } from "../../../utils/variables";
import {
  MAPPED_FIELD_OBJECT_TYPES,
  BLANK_FIELD,
  DEFAULT_STATE,
  SALESFORCE_FIELD_OBJECT_TYPES,
} from "./cardProps";
import { ChannelsStateContext } from "../../ChannelsState";
import { CHANNEL_TYPES } from "../../props";
import { useDefaultSelectTheme } from "../../../components/select";
import { INTEGRATION_TYPES } from "../../../integrations/props";
import { IntegrationObjectSelect } from "./IntegrationObjectSelect";
import { ButtonContainer } from "../../../components/button";
import style from "./style.module.scss";

export function IntegrationsCard({
  channelType,
  checkAssociatedObjects = false,
  fieldObjectType,
  fieldObjectsDropdownData,
  integrations,
  isOpen,
  selectedIntegration,
  setIntegrationsCardIsValid,
  setTempModalState,
  updateModal = false,
}) {
  const selectOptions = useMemo(() => {
    return fieldObjectsDropdownData.map((value) => ({
      label: value.label,
      value,
    }));
  }, [fieldObjectsDropdownData]);
  const integrationOptions = useIntegrationOptions(integrations);
  const [components, theme] = useDefaultSelectTheme();
  const [
    {
      [`${channelType}Fields`]: channelFields,
      [`${channelType}Error`]: channelErrorMap,
    },
  ] = useContext(ChannelsStateContext);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [hasFieldObject, setHasFieldObject] = useState(false);
  const [hoveringCheckbox, setHoveringCheckbox] = useState(false);

  const hasError = useMemo(
    () => channelErrorMap.get(selectedIntegration.name) !== null,
    [channelErrorMap, selectedIntegration.name]
  );

  const isOrphaned = useMemo(
    () => !!selectedIntegration.orphaned,
    [selectedIntegration]
  );

  const canShowOrphanedIntegrationText = useMemo(
    () => !!selectedIntegration.orphaned,
    [selectedIntegration]
  );

  const canShowGoToIntegrationsButton = useMemo(
    () => integrations.length === 0 && !selectedIntegration.orphaned,
    [integrations.length, selectedIntegration]
  );

  const canShowUneditableIntegrationName = useMemo(
    () =>
      integrations.length > 0 && updateModal && !selectedIntegration.orphaned,
    [integrations.length, updateModal, selectedIntegration]
  );

  const canShowIntegrationsDropdown = useMemo(
    () =>
      integrations.length > 0 && !updateModal && !selectedIntegration.orphaned,
    [integrations.length, updateModal, selectedIntegration]
  );

  const canShowFieldObjectDropdown = useMemo(
    () =>
      fieldObjectsDropdownData.length > 0 &&
      !hasError &&
      !isOrphaned &&
      selectedIntegration.id &&
      !updateModal,
    [
      fieldObjectsDropdownData.length,
      hasError,
      isOrphaned,
      selectedIntegration.id,
      updateModal,
    ]
  );

  const canShowLockedFieldObjectDropdown = useMemo(
    () =>
      (fieldObjectType.type !== null && !hasError === null && updateModal) ||
      isOrphaned,
    [fieldObjectType.type, hasError, updateModal, isOrphaned]
  );

  const showCheckAssociatedObjectsCheckbox = useMemo(() => {
    // currently, only import channels are supported for cross filtering sfdc objects
    if (channelType !== CHANNEL_TYPES.IMPORT) {
      return false;
    }

    if (!selectedIntegration || !selectedIntegration.id) {
      return false;
    }

    if (!fieldObjectType || !fieldObjectType.type) {
      return false;
    }

    // this is only relevent for salesforce/salesforce sandbox integrations
    if (
      selectedIntegration.name !== INTEGRATION_TYPES.SALESFORCE &&
      selectedIntegration.name !== INTEGRATION_TYPES.SALESFORCE_TEST
    ) {
      return false;
    }

    // this is only relevent for campaign members or opportunity contact role object types
    return (
      fieldObjectType.type === SALESFORCE_FIELD_OBJECT_TYPES.CAMPAIGN_MEMBER ||
      fieldObjectType.type ===
        SALESFORCE_FIELD_OBJECT_TYPES.OPPORTUNITY_CONTACT_ROLE
    );
  }, [channelType, fieldObjectType, selectedIntegration]);

  const integrationsTitleText = useMemo(() => {
    switch (channelType) {
      case CHANNEL_TYPES.IMPORT:
      case CHANNEL_TYPES.INTERCEPT:
        return "Integration";
      case CHANNEL_TYPES.UPDATE:
        return "Update Record in Integration";
      default:
        return "Integration";
    }
  }, [channelType]);

  const fieldObjectText = useMemo(() => {
    if (hasError) {
      return CHANNELS_TEXT.FAILED_TO_LOAD;
    }

    if (!hasError && integrations.length === 0) {
      return CHANNELS_TEXT.NO_INTERGRATION;
    }

    return CHANNELS_TEXT.LOADING;
  }, [hasError, integrations.length]);

  const onMouseEnterHover = useCallback(() => {
    setHoveringCheckbox(true);
  }, []);

  const onMouseLeaveHover = useCallback(() => {
    setHoveringCheckbox(false);
  }, []);

  const toggleCheckAssociatedObjects = useCallback(() => {
    setTempModalState((prevState) => ({
      ...prevState,
      checkAssociatedObjects: !prevState.checkAssociatedObjects,
    }));
  }, [setTempModalState]);

  const setSelectedIntegration = useCallback(
    (value) => {
      let v = value;
      // if user selects "select integration..." again
      if (!value) {
        v = { id: null };
      }

      if (v.name === INTEGRATION_TYPES.SALESFORCE_TEST) {
        v.name = INTEGRATION_TYPES.SALESFORCE;
      }

      setTempModalState((prevState) => ({
        ...prevState,
        conditions: [[{ ...BLANK_FIELD }]],
        // when changing selected integration, we need to reset field mapping dropdowns too
        fieldMappings: DEFAULT_STATE.fieldMappings,
        // when changing selected integration, we need to reset field object type too
        fieldObjectType: { type: null },
        selectedIntegration: v,
      }));
    },
    [setTempModalState]
  );

  const setFieldObjectsDropdownData = useCallback(
    (value) => {
      setTempModalState((prevState) => ({
        ...prevState,
        fieldObjectsDropdownData: value,
      }));
    },
    [setTempModalState]
  );

  const setFieldObjectType = useCallback(
    (value) => {
      let v = value;
      // if user selects "select object..." again
      if (!value) {
        v = { type: null };
      }

      setTempModalState((prevState) => ({
        ...prevState,
        conditions: [[{ ...BLANK_FIELD }]],
        // when changing object type, we need to reset field mapping dropdowns too
        fieldMappings: DEFAULT_STATE.fieldMappings,
        fieldObjectType: v,
      }));
    },
    [setTempModalState]
  );

  useEffect(() => {
    if (isOpen && selectedIntegration.id) {
      setFieldObjectsDropdownData(
        Object.keys(channelFields.get(selectedIntegration.name) || [])
          .sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          })
          .map((key) => ({
            label: MAPPED_FIELD_OBJECT_TYPES[key],
            type: key,
          }))
      );
    }
  }, [
    fieldObjectType.type,
    selectedIntegration,
    setFieldObjectsDropdownData,
    isOpen,
    setFieldObjectType,
    channelFields,
  ]);

  useEffect(() => {
    setHasIntegration(
      selectedIntegration.id !== null &&
        (selectedIntegration.name !== "" || selectedIntegration.name !== null)
    );
    setHasFieldObject(!!fieldObjectType.type);
  }, [selectedIntegration, fieldObjectType]);

  useEffect(() => {
    setIntegrationsCardIsValid(hasIntegration && hasFieldObject);
  }, [hasIntegration, hasFieldObject, setIntegrationsCardIsValid]);

  const selectedFieldObjectOption = useMemo(() => {
    return selectOptions.find((so) => fieldObjectType.type === so.value.type);
  }, [fieldObjectType.type, selectOptions]);

  const selectedIntegrationOption = useMemo(() => {
    return integrationOptions.find(
      (io) => selectedIntegration.id === io.value.id
    );
  }, [selectedIntegration.id, integrationOptions]);

  const integrationName = useMemo(() => {
    switch (selectedIntegration.name) {
      case INTEGRATION_TYPES.HUBSPOT:
        return "Hubspot";
      case INTEGRATION_TYPES.DYNAMICS:
        return "Dynamics";
      case INTEGRATION_TYPES.SALESFORCE:
        return "Salesforce";
      default:
        return selectedIntegration.name;
    }
  }, [selectedIntegration.name]);

  return (
    <div className={style.integrations__card}>
      <div className={style.integrations__card_integration__text}>
        {integrationsTitleText}
      </div>

      {canShowOrphanedIntegrationText &&
        `${integrationName} integration Disconnected. Please connect the integration and click Update`}
      {canShowGoToIntegrationsButton && <GoToIntegrations />}
      {canShowUneditableIntegrationName && (
        <LockedDropdown
          className={style.integrations__card_integration_title}
          value={selectedIntegrationOption.label}
          name="/integrationsCard/integrationNameLockedDropdown"
        />
      )}
      {canShowIntegrationsDropdown && (
        <div className={style.integrations__card__dropdown_wrapper}>
          <Select
            components={components}
            customTheme={theme}
            name={`/channels/${channelType}/add_channel/integration`}
            onChange={({ value }) => {
              if (value.id === selectedIntegration.id) {
                return;
              }
              setSelectedIntegration(value);
            }}
            options={integrationOptions}
            placeholder={<div>Select Integration</div>}
            value={selectedIntegrationOption}
          />
        </div>
      )}
      <div className={style.integrations__card_field_object}>
        {hasError && (
          <div className={cx(style.loadingSFDCText)}>{fieldObjectText}</div>
        )}
        {!hasError && (
          <IntegrationObjectSelect
            name={`/channels/${channelType}/add_channel/object_type`}
            locked={canShowLockedFieldObjectDropdown}
            disabled={!canShowFieldObjectDropdown}
            onChange={({ value }) => {
              setFieldObjectType(value);
            }}
            options={selectOptions}
            value={selectedFieldObjectOption}
          />
        )}
      </div>
      {showCheckAssociatedObjectsCheckbox && (
        <div
          className={style.checkbox_wrapper}
          onMouseEnter={onMouseEnterHover}
          onMouseLeave={onMouseLeaveHover}
        >
          <Checkbox
            className={style.check_associated_objects_checkbox}
            name={`/channels/${channelType}/add_channel/check_associated_objects`}
            checked={checkAssociatedObjects}
            toggle={toggleCheckAssociatedObjects}
          />
          <ButtonContainer
            transparent
            secondary
            className={style.check_text}
            onClick={toggleCheckAssociatedObjects}
          >
            Check associated objects?*
          </ButtonContainer>
          {hoveringCheckbox && (
            <div className={style.hover_box}>
              *If this option is selected, Kronologic will also watch the
              associated contact/lead for changes at Salesforce
            </div>
          )}
        </div>
      )}
    </div>
  );
}
