import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { ContactWithDetails } from "../types";
import { buildQueryString } from "../utils";
import { errorHandler } from "src/hooks/errorHandler";

interface Query {
  qry: string;
  active: string;
  attempts: number;
  sort: {
    field:
      | "firstName"
      | "lastName"
      | "email"
      | "account"
      | "logicField"
      | "routingField"
      | "lastMeetingActivity"
      | "unsubscribed";
    order: "asc" | "desc";
  };
  tags: number[];
  types: number[];
  subscriptionStatus: "any" | "subscribed" | "unsubscribed";
  createdAfter: Date;
}

export function useContacts(
  limit: number,
  offset: number,
  query?: Partial<Query>
) {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  let url = `/api/contact`;
  const params = buildQueryString({
    active: query?.active,
    attempts: query?.attempts,
    createdAfter: query?.createdAfter?.toISOString(),
    limit,
    offset,
    qry: query?.qry,
    sortBy: query?.sort ? `${query.sort.field}+${query.sort.order}` : undefined,
    subscriptionStatus: query?.subscriptionStatus,
    tags: query?.tags?.join(","),
    types: query?.types?.join(","),
  });

  if (params !== "") {
    url += `?${params}`;
  }

  const { data, error } = useSWR(url, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { total: number; data: ContactWithDetails[] } | undefined,
    error,
    loading: !error && !data,
  };
}
