import useSWR from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { getUserToken } from "../utils/jwtToken";
import { errorHandler } from "src/hooks/errorHandler";

export function useGetLogoURL() {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR("/api/org/setting/logo", (url: string) =>
    service
      .get(url)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as string,
    error,
    loading: !error && !data,
  };
}
