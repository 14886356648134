import React, { useCallback, useContext, useMemo, useState } from "react";
import { DateRangePicker } from "react-dates";
import { Select } from "../../../inputs";
import {
  MEETING_START_TIME,
  MEETING_SORT_TIME,
  MEETING_START_TIME_OPTIONS,
} from "../../props";
import {
  SearchCalendarFilterTypeContext,
  useSearch,
  useSearchUpdater,
} from "../../Search";
import { useDatePicker } from "../../../components/datepicker";
import { useDropdownTheme } from "../useDropdownTheme";
import { NO_OPTIONS } from "../../../utils/variables";
import style from "../menu.module.scss";

export function CalendarFilterControls({ showStartTimeFilter = true }) {
  const { getDateRangeWithKey, onDatesChange } = useDatePicker();
  const search = useSearch();
  const searchUpdater = useSearchUpdater();
  const [calendarFilterType, setCalendarFilterType] = useContext(
    SearchCalendarFilterTypeContext
  );
  const [focusedInput, setFocusedInput] = useState(null);

  const searchDateRange = useCallback(
    (end, start) => {
      searchUpdater.setDateFilter({
        end,
        start,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
      });
    },
    [searchUpdater]
  );

  const customArrowIcon = useMemo(
    () => (
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%",
          justifyContent: "center",
          textAlign: "center",
          width: 36,
        }}
      >
        <span>--</span>
      </div>
    ),
    []
  );

  const [components, theme] = useDropdownTheme();

  return useMemo(
    () => (
      <div className={style.instanceMenu__calendarFilterControls}>
        <div className={style.instanceMenu__lastActivityFilterSelect}>
          <Select
            closeMenuOnScroll
            components={components}
            customTheme={theme}
            label="Time type"
            name="/instances/action_bar/start_time_dropdown"
            noOptionsMessage={() => NO_OPTIONS.meetingTypes}
            onChange={({ value }) => {
              searchUpdater.setDateFilterBy(value);
            }}
            options={MEETING_SORT_TIME}
            value={MEETING_SORT_TIME.find((mst) => {
              return mst.value === search.filter.dateFilter.by;
            })}
          />
        </div>
        {showStartTimeFilter && (
          <div className={style.instanceMenu__startTimeFilterSelect}>
            <Select
              closeMenuOnScroll
              components={components}
              customTheme={theme}
              name="/instances/action_bar/all_time_dropdown"
              onChange={({ value }) => {
                setCalendarFilterType(value);
                if (value === "") {
                  return MEETING_START_TIME.find((m) => m.key === value);
                }
                const { start, end } = getDateRangeWithKey(value);
                searchDateRange(end, start);
              }}
              options={MEETING_START_TIME_OPTIONS}
              value={
                MEETING_START_TIME_OPTIONS.find(
                  (m) => m.value === calendarFilterType
                ) || MEETING_START_TIME_OPTIONS[0]
              }
            />
          </div>
        )}
        <DateRangePicker
          customArrowIcon={customArrowIcon}
          startDate={search.filter.dateFilter.start}
          startDateId="/instances/action_bar/start_date"
          endDate={search.filter.dateFilter.end}
          endDateId="/instances/action_bar/end_date"
          startDateAriaLabel="/instances/action_bar/start_date"
          endDateAriaLabel="/instances/action_bar/end_date"
          onDatesChange={({ startDate, endDate }) => {
            const { start, end } = onDatesChange(startDate, endDate);
            searchDateRange(end, start);
            if (calendarFilterType !== "custom") {
              setCalendarFilterType("custom");
            }
          }}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          isOutsideRange={() => false}
          hideKeyboardShortcutsPanel
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      calendarFilterType,
      focusedInput,
      getDateRangeWithKey,
      onDatesChange,
      searchDateRange,
      search.filter.dateFilter.end,
      search.filter.dateFilter.start,
      setCalendarFilterType,
      setFocusedInput,
    ]
  );
}
