import { useTokenRefreshHandler } from "src/hooks";
import { errorHandler } from "src/hooks/errorHandler";
import { useUserService } from "src/services";
import useSWR from "swr";

export function useKMeAuthUrl() {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const { data, error } = useSWR(`/api/user/sign_in/web/url`, (url: string) =>
    service
      .get(url)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { [index: string]: string } | undefined,
    error,
    loading: !error && !data,
  };
}
