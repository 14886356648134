import { Delete, Group, LocalOffer } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import isEqual from "lodash.isequal";

import { useActingAs } from "src/auth";
import { Loading } from "src/componentsV2/Loading";
import { PageLayout } from "src/componentsV2/PageLayout";
import { TagList } from "src/componentsV2/TagList";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import TeamManagementDialog from "src/componentsV2/dialogs/TeamManagementDialog";
import {
  AdditionalPermissionsInput,
  EmailEditor,
  RoleSelectInput,
} from "src/componentsV2/inputs";
import AddCalendarInput from "src/componentsV2/inputs/AddCalendarInput";
import { TimezoneSelect } from "src/componentsV2/inputs/TimezoneSelect";
import { useDeleteConfirmationDialog, useSnackbar } from "src/hooks";
import {
  useApplyCalendarToUsers,
  useDeleteUsers,
  useTagUsers,
  useUpdateSchedulers,
  useUpdateTimezone,
  useUpdateUser,
} from "src/mutations";
import ErrorPage from "src/pages/ErrorPage";
import { useUser } from "src/queries";
import {
  Calendar,
  DefaultUserRole,
  OrgAdminRole,
  Role,
  SuperAdminRole,
  User,
} from "src/types";
import { getUserDetails } from "src/utils/jwtToken";
import UserAvailability from "src/componentsV2/UserAvailability";
import { WeekSchedule } from "src/types/Availability";
import { useAppSettingsRepository } from "src/repository";

function UserDetails({ data }: { data: User }) {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();
  const user = actingAs || loggedInUser;
  const org = actingAs?.org || loggedInUser?.org.org_key || "";

  const openDeleteConfirmationDialog = useDeleteConfirmationDialog();
  const tagUsers = useTagUsers();
  const updateSchedulers = useUpdateSchedulers();
  const updateTimezone = useUpdateTimezone();
  const deleteUsers = useDeleteUsers();
  const { updateUserSettings } = useAppSettingsRepository();
  const applyCalendarToUsers = useApplyCalendarToUsers();
  const [showTeamManagementDialog, setShowTeamManagementDialog] =
    useState(false);

  const [openSnackbar] = useSnackbar();
  const updateUser = useUpdateUser();

  const {
    watch,
    reset,
    register,
    control,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
  } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    meetingLink: string;
    location: string;
    timezone: string;
    weekSchedule: WeekSchedule;
    permissions: number;
    role: Role;
    calendars: Calendar[];
    signature: string;
  }>({
    defaultValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      title: data.title,
      meetingLink: data.meetingLink,
      timezone: data.timezone,
      location: data.location,
      weekSchedule: data.weekSchedule,
      permissions: data.permissions,
      role: data.role,
      calendars: data.sharedUserCalendars || [],
      signature: data.signature,
    },
  });

  // on successful submission, we want `isDirty` to reset back to `false` in order to
  // disable the Save button and also allow the user to set the field's value back to what
  // it originally was and still allow them to save
  // method found here: https://github.com/react-hook-form/react-hook-form/issues/3097#issuecomment-1310537506
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit: SubmitHandler<{
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    meetingLink: string;
    location: string;
    timezone: string;
    weekSchedule: WeekSchedule;
    permissions: number;
    role: Role;
    calendars: Calendar[];
    signature: string;
  }> = async (updatedData) => {
    try {
      updateUser(id, {
        role: updatedData.role,
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        permissions: updatedData.permissions,
        title: updatedData.title,
        meetingLink: updatedData.meetingLink,
        location: updatedData.location,
      })
        .then(() => {
          openSnackbar("Successfully updated user");
        })
        .catch(() => {
          openSnackbar("Failed to update user");
        });

      if (data.timezone !== updatedData.timezone) {
        updateTimezone(watch("timezone"), [Number.parseInt(id)]).catch(() => {
          openSnackbar("Failed to update user timezone");
        });
      }

      if (!isEqual(data.weekSchedule, updatedData.weekSchedule)) {
        updateSchedulers(updatedData.weekSchedule, [Number.parseInt(id)]).catch(
          () => {
            openSnackbar("Failed to update user availability");
          }
        );
      }

      if (data.signature !== updatedData.signature) {
        updateUserSettings(
          { signature: updatedData.signature },
          Number.parseInt(id)
        ).catch(() => {
          openSnackbar("Failed to update user signature");
        });
      }

      applyCalendarToUsers(
        [data.id],
        updatedData.calendars.map((calendar) => calendar.calendarId)
      ).catch(() => {
        openSnackbar(
          "There was an error when attempting to apply calendar to users"
        );
      });
    } catch {
      openSnackbar("Failed to update user");
    }
  };

  return (
    <>
      {showTeamManagementDialog && (
        <TeamManagementDialog
          label="Update User Teams"
          open
          initialTeams={data?.teams || []}
          onClose={() => {
            setShowTeamManagementDialog(false);
          }}
          onSubmit={async (teams) => {
            const addToTeams = teams.filter(
              (team) => !data.teams?.some((t) => t.id === team.id)
            );
            const removeFromTeams = (data.teams || []).filter(
              (team) => !teams.some((t) => t.id === team.id)
            );

            try {
              updateUser(id, {
                remove_from_teams:
                  removeFromTeams.length > 0
                    ? removeFromTeams.map((team) => team.id)
                    : undefined,
                add_to_teams:
                  addToTeams.length > 0
                    ? addToTeams.map((team) => team.id)
                    : undefined,
              });
              openSnackbar("Successfully updated teams");
              setShowTeamManagementDialog(false);
            } catch {
              openSnackbar("Failed to update teams");
            }
          }}
        />
      )}
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Update User Tags"
          open
          initialTags={data.tags || []}
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await tagUsers(
                [data.id],
                tags.map((tag) => tag.id)
              );
              openSnackbar("Successfully updated tags");
              setShowTagManagementDialog(false);
            } catch {
              openSnackbar("Failed to update tags");
            }
          }}
        />
      )}
      <PageLayout
        title="User"
        breadcrumbs={[
          {
            name: "Users",
            route: "/users",
          },
          {
            name: id,
            route: `/user/${id}`,
          },
        ]}
        actions={
          <>
            <Tooltip title="Manage Tags" arrow>
              <IconButton
                disabled={!data}
                onClick={() => {
                  setShowTagManagementDialog(true);
                }}
              >
                <LocalOffer />
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage Teams" arrow>
              <IconButton
                disabled={!data}
                onClick={() => {
                  setShowTeamManagementDialog(true);
                }}
              >
                <Group />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete User" arrow>
              <IconButton
                disabled={!data}
                onClick={() => {
                  openDeleteConfirmationDialog(
                    `Are you sure that you want to delete ${data.email}?`,
                    () => {
                      deleteUsers([Number.parseInt(id)])
                        .then(() => {
                          openSnackbar(`Successfully deleted  user`);
                          history.push("/users");
                        })
                        .catch();
                    }
                  );
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        }
      >
        <Stack spacing={1}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ width: "fit-content" }}
                >
                  <Avatar
                    src=""
                    alt={`${data.firstName} ${data.lastName}`}
                    sx={{ width: "120px", height: "120px" }}
                  />
                  <Stack>
                    <Typography variant="h4">
                      {data.firstName} {data.lastName}
                    </Typography>
                    <Typography>{data.email}</Typography>{" "}
                    <Typography>{data.title}</Typography>
                  </Stack>
                  <SecondaryButton
                    onClick={() =>
                      history.push(
                        `/instances?query=${data.email}&categories=Host+Email`
                      )
                    }
                  >
                    Meetings
                  </SecondaryButton>
                </Stack>
                <Typography>
                  Created On: {dayjs(data.signUpDate).format("M/D/YYYY")}
                </Typography>
              </Stack>
            </Stack>
            {data.tags?.length && (
              <Stack sx={{ flexDirection: "row", gap: 1 }}>
                <Typography component="span" fontWeight="bold">
                  Tags:{" "}
                </Typography>
                <TagList tags={data.tags || []} />
              </Stack>
            )}
            <Stack
              sx={{ flexDirection: "row", alignItems: "flex-start", gap: 1 }}
            >
              <Typography component="span" fontWeight="bold">
                Teams:
              </Typography>
              <TagList tags={data?.teams || []} />
            </Stack>
            <Box>
              <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                <Tab label="Info" sx={{ textTransform: "none" }} />
                <Tab label="Calendars" sx={{ textTransform: "none" }} />
                <Tab label="Permissions" sx={{ textTransform: "none" }} />
                {/* if these two tabs below are grouped under the same conditional using a React fragment, the `onChange` above no longer works */}
                {data.status === "active" && (
                  <Tab label="Signature" sx={{ textTransform: "none" }} />
                )}
                {data.status === "active" && (
                  <Tab label="Settings" sx={{ textTransform: "none" }} />
                )}
              </Tabs>
              <Divider />
              <Box
                sx={{
                  pt: 2,
                  minHeight: "500px",
                }}
              >
                {tab === 0 && (
                  <Stack flexGrow={0} spacing={2}>
                    <TextField label="First Name" {...register("firstName")} />
                    <TextField label="Last Name" {...register("lastName")} />
                    <TextField disabled label="Email" {...register("email")} />
                    <TextField label="Title" {...register("title")} />
                    <TextField label="Location" {...register("location")} />
                  </Stack>
                )}
                {tab === 1 && (
                  <Controller
                    name="calendars"
                    control={control}
                    render={({ field }) => (
                      <Stack flexGrow={0} spacing={2}>
                        <AddCalendarInput
                          onSelect={(calendar) => {
                            if (
                              !watch("calendars")
                                .map((c) => c.calendarId)
                                .includes(calendar.calendarId)
                            ) {
                              field.onChange(
                                watch("calendars").concat([calendar])
                              );
                            }
                          }}
                        />
                        <List disablePadding>
                          {watch("calendars").map((t) => (
                            <ListItem
                              key={t.calendarId}
                              secondaryAction={
                                <IconButton
                                  onClick={() => {
                                    field.onChange(
                                      watch("calendars").filter(
                                        (tag) => tag.calendarId != t.calendarId
                                      )
                                    );
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              }
                            >
                              <ListItemText
                                primary={`${t.userFirstName} ${t.userLastName} - ${t.calendarName}`}
                                secondary={t.calendarType}
                              />
                            </ListItem>
                          ))}

                          {watch("calendars").length === 0 && (
                            <Typography align="center">None</Typography>
                          )}
                        </List>
                      </Stack>
                    )}
                  />
                )}
                {tab === 2 && (
                  <Stack flexGrow={0} spacing={2}>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => {
                        return (
                          <RoleSelectInput
                            disabled={
                              ![OrgAdminRole, SuperAdminRole].includes(
                                user?.role || DefaultUserRole
                              )
                            }
                            enableSuperAdmin={org === "kronologic"}
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="permissions"
                      control={control}
                      render={({ field }) => {
                        return (
                          <AdditionalPermissionsInput
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                  </Stack>
                )}
                {tab === 3 && (
                  <div>
                    <Controller
                      name="signature"
                      control={control}
                      render={({ field }) => (
                        <EmailEditor
                          label="Signature"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                )}
                {tab === 4 && (
                  <Stack spacing={2}>
                    <TextField
                      label="Meeting Link"
                      {...register("meetingLink")}
                    />
                    <Controller
                      name="timezone"
                      control={control}
                      render={({ field }) => {
                        return (
                          <TimezoneSelect
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="weekSchedule"
                      render={({ field }) => (
                        <UserAvailability
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Stack>
                )}
              </Box>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SecondaryButton
                disabled={!isDirty}
                onClick={() => {
                  reset();
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                disabled={!isDirty}
                onClick={async () => {
                  await handleSubmit(onSubmit)();
                }}
              >
                Save
              </PrimaryButton>
            </Stack>
          </Paper>
        </Stack>
      </PageLayout>
    </>
  );
}

export default function Page() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useUser(id);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return <UserDetails data={data} />;
}
