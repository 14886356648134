import { useCallback, useRef } from "react";
import { buildRequest, buildUrl } from "../utils/fetchV2";
import { API } from "../props";
import { DELETE, PATCH, useCustomFetchFn } from "../components/fetch";

const buildGetReminderRequest = (meetingId) =>
  buildRequest(buildUrl(API.reminder.default, { meetingId }));

const buildUpdateReminderRequest = (body) =>
  buildRequest(API.reminder.default, {
    body: JSON.stringify(body),
    method: PATCH,
  });

const buildRemoveReminderRequest = (body) =>
  buildRequest(API.reminder.default, {
    body: JSON.stringify(body),
    method: DELETE,
  });

export function useRemindersRepository() {
  const { fetchJson } = useCustomFetchFn();

  const getMeetingReminder = useCallback(
    ({ meetingId }) => fetchJson(buildGetReminderRequest(meetingId)),
    [fetchJson]
  );

  const updateMeetingReminder = useCallback(
    (body) => fetchJson(buildUpdateReminderRequest(body)),
    [fetchJson]
  );

  const removeMeetingReminder = useCallback(
    (body) => fetchJson(buildRemoveReminderRequest(body)),
    [fetchJson]
  );

  const { current } = useRef({
    getMeetingReminder,
    removeMeetingReminder,
    updateMeetingReminder,
  });

  return current;
}
