import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import { mdiChatPlus } from "@mdi/js";
import Flexbox from "../../../components/flexbox";
import { SET_DM_IS_OPEN, useMeeting, useMessengerState } from "../state";
import {
  HOST_INTERVENED_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  SCHEDULING_STAGE,
} from "../../props";
import { ButtonContainer, ButtonIcon } from "../../../components/button";
import { getInitials } from "../util/helpers";
import { WHITE } from "../../../components/colors";
import InitialsIcon from "../../../components/initials";
import style from "./addDM.module.scss";

const DM_STATUSES = [REQUIRES_HOST_INTERVENTION_STATUS, HOST_INTERVENED_STATUS];

const AddDM = () => {
  const [meeting] = useMeeting();
  const {
    messenger: { dmIsOpen },
    messengerDispatch,
  } = useMessengerState();

  const { guests = null } = meeting;
  const addDmDisabled = useMemo(
    () => !meeting.enabled || meeting.status !== SCHEDULING_STAGE,
    [meeting]
  );

  const showGuestDMIcon = useMemo(() => {
    if (guests !== null && guests.length !== 0) {
      const guest = guests[0];
      if (DM_STATUSES.includes(guest?.status)) {
        return true;
      }
    }
    return false;
  }, [guests]);

  const initials = useMemo(() => {
    if (guests !== null && guests.size !== 0) {
      return getInitials(guests[0]);
    }
    return "";
  }, [guests]);

  const handleOpenDm = useCallback(() => {
    messengerDispatch((prev) => ({
      payload: { value: !prev.dmIsOpen },
      type: SET_DM_IS_OPEN,
    }));
  }, [messengerDispatch]);

  return (
    <Flexbox>
      {({ Row }) => (
        <Row className={style.add_dm} width="fit-content" height="fit-content">
          <ButtonContainer
            className={style.add_dm__action}
            disabled={addDmDisabled}
            name="/instanceDetails/add_dm"
          >
            <ButtonIcon className={style.add_dm__icon} path={mdiChatPlus} />
          </ButtonContainer>

          {showGuestDMIcon && (
            <ButtonContainer
              className={style.add_dm__action}
              onClick={handleOpenDm}
            >
              <InitialsIcon
                className={cx(style.add_dm_guest_icon, {
                  [style["add_dm_guest_icon--active"]]: dmIsOpen,
                })}
                width="25px"
                height="25px"
                initials={initials}
                color={WHITE}
              />
            </ButtonContainer>
          )}
        </Row>
      )}
    </Flexbox>
  );
};

export default AddDM;
