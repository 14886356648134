/**
 * Generate a range array
 *
 * @private
 * @param {number} start
 * @param {number} end
 *
 * @returns {number[]} array of the range
 */
function getRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((v, i) => i + start);
}

/**
 * Generate an array of pages
 *
 * @private
 * @param {number} current
 * @param {number} delta
 * @param {Range} range
 * @param {number} length
 *
 * @returns {number[]} array of pages
 */
function getPages(current, delta, range, length) {
  return current > delta
    ? getRange(
        Math.min(range.start, length - delta),
        Math.min(range.end, length)
      )
    : getRange(1, Math.min(length, delta + 1));
}

/**
 * Generates paginated numbers to use in a pagination system.
 *
 * @private
 * @param {number} initialCurrent
 * @param {number} length
 * @param {number} delta
 *
 * @returns {number[]} array of pagination numbers
 */
export const usePageNumbers = (initialCurrent, length, delta = 4) => {
  if (length === 0) {
    return [1];
  }

  let current = initialCurrent;

  if (initialCurrent > length) {
    current = length;
  }

  /**
   * @typedef {Object} Range - a range object
   * @property {number} start - start of the range
   * @property {number} end - end of the range
   */
  const range = {
    end: Math.round(current + delta / 2),
    start: Math.round(current - delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === length) {
    range.start += 1;
    range.end += 1;
  }

  let pages = getPages(current, delta, range, length);

  // TODO: It might make sense to separate the addition of ellipsis into a separate function.
  const withDots = (value, pair) =>
    pages.length + 1 !== length ? pair : [value];

  if (pages[0] !== 1) {
    pages = withDots(1, [1, "..."]).concat(pages);
  }

  if (pages[pages.length - 1] < length) {
    pages = pages.concat(withDots(length, ["...", length]));
  }

  return pages;
};
