import React from "react";
import moment from "moment";
import cx from "classnames";
import { P1 } from "../../components/typography";
import {
  INSTANCE_STAGE_TO_LABEL,
  toInstanceStageLabel,
  toInstanceStatusColor,
} from "../../meetings/props";
import {
  LEGACY_STATUS_TO_LABEL,
  toLegacyStatusLabel,
  toLegacyStatusColor,
} from "./props";

import style from "./globallogs.module.scss";
import { formatWordForPossession } from "./util";

// -- INSTANCE STATUS CHANGE LOG -- //
// buildStatusData returns statuses as labels and their corresponding colors
const buildStatusData = (statusOld, statusNew) => {
  // check instance status label/color first
  const oldStatusLabel = toInstanceStageLabel(statusOld);
  const newStatusLabel = toInstanceStageLabel(statusNew);

  if (oldStatusLabel !== null && newStatusLabel !== null) {
    return {
      newStatusLabel,
      oldStatusLabel,
      statusNewColor: toInstanceStatusColor(statusNew),
      statusOldColor: toInstanceStatusColor(statusOld),
    };
  }

  // return legacy data if we didn't get data back above
  return {
    newStatusLabel: toLegacyStatusLabel(statusNew),
    oldStatusLabel: toLegacyStatusLabel(statusOld),
    statusNewColor: toLegacyStatusColor(statusNew),
    statusOldColor: toLegacyStatusColor(statusOld),
  };
};

// fallback pattern: user name --> contact email --> orgKey
const buildUserInfo = (structuredData, orgKey) => {
  const { userEmail, userFirstName, userLastName } = structuredData;

  if (userEmail?.length > 0) {
    return formatWordForPossession(userEmail);
  }

  if (userFirstName?.length > 0 && userLastName?.length > 0) {
    return `${userFirstName} ${formatWordForPossession(userLastName)}`;
  }

  if (orgKey?.length > 0) {
    return formatWordForPossession(orgKey);
  }

  return "[missing user/org information]";
};

const noEventStartTimePhase = (status) =>
  status === LEGACY_STATUS_TO_LABEL.initialized ||
  status === LEGACY_STATUS_TO_LABEL.initializing ||
  status === LEGACY_STATUS_TO_LABEL.init_fault ||
  status === LEGACY_STATUS_TO_LABEL.queued ||
  status === INSTANCE_STAGE_TO_LABEL.queued ||
  status === INSTANCE_STAGE_TO_LABEL.init_fault ||
  status === INSTANCE_STAGE_TO_LABEL.initialized ||
  status === INSTANCE_STAGE_TO_LABEL.initializing;

const shouldShowStartTime = (statusOld, statusNew) => {
  // if the instance is going into a status that will not have a meeting start time
  if (noEventStartTimePhase(statusNew)) {
    return false;
  }

  const isInstanceCancelled =
    statusNew === INSTANCE_STAGE_TO_LABEL.cancelled ||
    statusNew === LEGACY_STATUS_TO_LABEL.cancelled;

  // if the instance is coming out of a status that does not have a meeting start time but is going into cancelled
  return !(isInstanceCancelled && noEventStartTimePhase(statusOld));
};

const buildInstanceStatusChangeLog = function* (log) {
  // sample log structure:
  /* 
  XX/XX/XXXX XX:XX am/pm  Meeting stage for [host name]'s 
  meeting on XX/XX/XXXX at XX:XX am/pm changed from [old stage] to [new stage].
*/

  const { structuredData, orgKey } = log;
  const { meetingId, statusNew, statusOld } = structuredData;
  let { eventStart } = structuredData;

  const userInfo = buildUserInfo(structuredData, orgKey);

  const statusData = buildStatusData(statusOld, statusNew);
  const { oldStatusLabel, statusNewColor, statusOldColor } = statusData;
  let { newStatusLabel } = statusData;

  // abort log if we can't parse status
  if (oldStatusLabel === null || newStatusLabel === null) {
    return "";
  }

  // this prevents showing 12/31/2000 for meetings that do not have start times yet
  if (!shouldShowStartTime(oldStatusLabel, newStatusLabel)) {
    eventStart = null;
  }

  // per design
  if (
    newStatusLabel === INSTANCE_STAGE_TO_LABEL.scheduled ||
    newStatusLabel === LEGACY_STATUS_TO_LABEL.accepted
  ) {
    newStatusLabel = newStatusLabel.toUpperCase();
  }

  yield (
    <P1>
      <span
        className={cx(style.logBuilder__instanceId, style.logBuilder__italics)}
      >
        {`Meeting ID: ${meetingId} - `}
      </span>
      <span>
        Meeting stage for
        {` ${userInfo} meeting `}
        {eventStart &&
          `on ${moment(eventStart).format("MM/DD/YYYY")} at ${moment(
            eventStart
          ).format("hh:mma")} `}
        changed from
      </span>
      <span
        className={style.logBuilder__bold}
        style={{ color: statusOldColor }}
      >
        {` ${oldStatusLabel} `}
      </span>
      to
      <span
        className={style.logBuilder__bold}
        style={{ color: statusNewColor }}
      >
        {` ${newStatusLabel}`}
      </span>
    </P1>
  );
};
// -- END INSTANCE STATUS CHANGE LOG -- //

export default buildInstanceStatusChangeLog;
