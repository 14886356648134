import React, { useState } from "react";
import { MenuItem, Paper, Stack, Typography, Select, Box } from "@mui/material";
import { CancelOutlined, Check } from "@mui/icons-material";
import {
  DataGridPro,
  GridEditInputCell,
  GridRenderEditCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { UserUpload, UserRow } from "./UserUpload";
import { User } from "src/types";
import { validateEmailAddress } from "src/utils/helpers";
import ImportFooter from "../ImportFooter";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useOrganizationDomains } from "src/queries";
import { Loading } from "src/componentsV2/Loading";
import ErrorPage from "src/pages/ErrorPage";

function RoleSelectEditComponent(
  props: GridRenderEditCellParams & {
    domains: string[];
    onRowChange: (value: Row) => void;
  }
) {
  const apiRef = useGridApiContext();

  const [value, setValue] = useState(props.value);

  return (
    <Select
      value={value}
      onChange={(event) => {
        const row = props.row;

        row.role = event.target.value;
        row.error = !isValidUser(row, props.domains);

        props.onRowChange(row);

        apiRef.current.updateRows([row]);
        setValue(row.role);
      }}
      disableUnderline
      variant="standard"
      sx={{
        px: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        "& .MuiSelect-select": {
          "&.MuiInputBase-input": {
            "&.MuiInput-input": {
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
          },
        },
      }}
    >
      <MenuItem value={-1}>Select</MenuItem>
      <MenuItem value={0}>Default User</MenuItem>
      <MenuItem value={1}>Team Admin</MenuItem>
      <MenuItem value={2}>Org Admin</MenuItem>
    </Select>
  );
}

function isValidEmail(email: string, allowableDomains: string[]): boolean {
  const validEmail = validateEmailAddress(email);
  if (!validEmail) {
    return false;
  }

  const parts = email.split("@");
  const domain = parts?.[1] || "";

  if (!allowableDomains.includes(domain)) {
    return false;
  }

  return true;
}

function isValidUser(user: Partial<User>, allowableDomains: string[]): boolean {
  if (!user.email || user.role === undefined) {
    return false;
  }

  return (
    isValidEmail(user.email.trim(), allowableDomains) &&
    [0, 1, 2].includes(user.role)
  );
}

type Row = UserRow & { error: boolean };

function ValidationStep({
  users,
  domains,
  onPrev = () => null,
  onNext = () => null,
}: {
  users: UserUpload;
  domains: string[];
  onPrev: () => void;
  onNext: (user: UserUpload) => void;
}) {
  const apiRef = useGridApiRef();

  const [data] = useState<Row[]>(
    users.map((user, index) => ({
      ...user,
      firstName: user.firstName?.trim(),
      lastName: user.lastName?.trim(),
      email: user.email?.trim(),
      row: index,
      error: !isValidUser(user, domains || []),
    }))
  );

  const [errors, setErrors] = useState(
    data
      .filter((row) => row.error)
      .reduce((prev, current) => {
        prev[current.row] = current;
        return prev;
      }, {} as { [index: number]: UserUpload[0] })
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const numErrors = Object.entries(errors).length;

  const onHandleRowChange = (row: Row) => {
    if (row.error) {
      setErrors({
        ...errors,
        [row.row]: row,
      });
    } else {
      const err = { ...errors };
      delete err[row.row];
      setErrors(err);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack spacing={2}>
          {numErrors > 0 ? (
            <Stack spacing={1}>
              <Typography>
                It looks like there are a few issues with some of the data.
                Please fix the {numErrors} errors shown below.
              </Typography>
            </Stack>
          ) : (
            <Typography>Your upload looks ready to go!</Typography>
          )}

          <Paper elevation={0}>
            <DataGridPro
              apiRef={apiRef}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    row: true,
                    status: true,
                    firstName: true,
                    lastName: true,
                    email: true,
                    role: true,
                  },
                },
              }}
              sx={{ height: 54 * (pageSize + 2) }}
              rowHeight={54}
              pagination
              rowsPerPageOptions={[10, 25, 100]}
              editMode="row"
              experimentalFeatures={{
                newEditingApi: true,
                // preventCommitWhileValidating: false,
              }}
              page={page}
              onPageChange={setPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              columns={[
                {
                  field: "row",
                  valueGetter: ({ row }) => row.row + 1,
                  headerName: "Row",
                  editable: false,
                  minWidth: 20,
                  hideable: false,
                },
                {
                  field: "error",
                  headerName: "Valid",
                  editable: false,
                  minWidth: 40,
                  hideable: true,
                  renderCell: (params) =>
                    params.value ? (
                      <CancelOutlined color="error" />
                    ) : (
                      <Check color="success" />
                    ),
                },
                // {
                //   field: "firstName",
                //   headerName: "First Name",
                //   editable: true,
                //   minWidth: 150,
                //   hideable: false,
                // },
                // {
                //   field: "lastName",
                //   headerName: "Last Name",
                //   editable: true,
                //   minWidth: 150,
                //   hideable: false,
                // },
                {
                  field: "email",
                  headerName: "Email",
                  editable: true,
                  minWidth: 250,
                  hideable: false,
                  renderCell: (props) => {
                    const error = !isValidEmail(props.value, domains || []);
                    return (
                      <Typography color={error ? "error" : undefined}>
                        {props.value}
                      </Typography>
                    );
                  },
                  renderEditCell: (props) => {
                    return <GridEditInputCell {...props} />;
                  },
                },
                {
                  field: "role",
                  headerName: "Role",
                  editable: true,
                  minWidth: 160,
                  renderCell: (params: GridRenderCellParams) => {
                    return (
                      <RoleSelectEditComponent
                        {...params}
                        value={params.value}
                        domains={domains}
                        onRowChange={onHandleRowChange}
                      />
                    );
                  },
                  renderEditCell: (params: GridRenderEditCellParams) => {
                    return (
                      <RoleSelectEditComponent
                        {...params}
                        domains={domains}
                        onRowChange={onHandleRowChange}
                      />
                    );
                  },
                },
              ]}
              rows={data}
              getRowId={(row) => row.row}
              processRowUpdate={(row) => {
                row.error = !isValidUser(row, domains);
                onHandleRowChange(row);
                return row;
              }}
            />
          </Paper>
        </Stack>
      </Box>
      <ImportFooter
        onLeftButtonClick={onPrev}
        onRightButtonClick={() => {
          onNext(
            Object.values(apiRef.current.state.rows.idRowsLookup) as UserUpload
          );
        }}
        rightButtonDisabled={numErrors !== 0}
      />
    </>
  );
}

export function Validation({
  users,
  onPrev = () => null,
  onNext = () => null,
}: {
  users: UserUpload;
  onPrev: () => void;
  onNext: (user: UserUpload) => void;
}) {
  const { data, loading, error } = useOrganizationDomains();

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <ValidationStep
      users={users}
      domains={data}
      onNext={onNext}
      onPrev={onPrev}
    />
  );
}

export default Validation;
