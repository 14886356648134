import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import FilterButton from "src/componentsV2/filters/FilterButton";
import { useConfirmationDialog, useLocalStorage, useSnackbar } from "src/hooks";
import { GuestStatus } from "src/types";

import {
  ChevronLeft,
  ChevronRight,
  Delete,
  EventBusy,
  Label,
  ScheduleSend,
  Send,
} from "@mui/icons-material";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import { BaseInternalLink } from "src/componentsV2/BaseInternalLink";
import {
  MeetingQuery,
  MeetingsFilterBar,
} from "src/componentsV2/MeetingsFilterBar";
import { Pages } from "src/componentsV2/Pagination";
import { showQuickLaunchAtom } from "src/componentsV2/QuickLaunch";
import { TableSort } from "src/componentsV2/Table";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { TransferHostDialog } from "src/componentsV2/dialogs";
import ScheduleSendDialog from "src/componentsV2/dialogs/ScheduleSendDialog";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import { SelectTable } from "src/componentsV2/tables/SelectTable";
import { TableLoading } from "src/componentsV2/tables/Table";
import useUrlQueryV2 from "src/hooks/useUrlQueryV2";
import { FileDownloadIcon } from "src/icons/FileDownloadIcon";
import { FileUploadIcon } from "src/icons/FileUploadIcon";
import MeetingEmptyStateIcon from "src/icons/MeetingEmptyStateIcon";
import TransferHostIcon from "src/icons/TransferHostIcon";
import { GUEST_STATUS_TO_LABEL } from "src/meetings/props";
import {
  useAddMeetingTags,
  useDeleteMeetings,
  useRouteMeetings,
  useSendLater,
  useTransferHost,
  useUpdateMeetings,
} from "src/mutations";
import ErrorPage from "src/pages/ErrorPage";
import { useMeetingReportV2 } from "src/queries";
import { useMeetingSearchResultsNewPage } from "src/queries/useMeetingSearchResultsNewPage";
import { useMeetingsCount } from "src/queries/useMeetingsCount";
import RouteMeetingsIcon from "../../icons/RouteMeetingsIcon";
import {
  useChangeMeetingOwner,
  useDefaultUserDisableMeetingsDelete,
} from "../../features";
import { ROLE_LEVELS } from "../../auth/roles";
import { userCanChangeMeetingOwner } from "../../auth/permissions";
import { getUserDetails } from "../../utils/jwtToken";
import CoHostIndicator from "../../componentsV2/CoHostIndicator";
import { useDefaultUserDisableMeetingsCancel } from "../../features/useDefaultUserDisableMeetingsCancel";
import { isEnterpriseMode } from "../../configuration/getApplicationMode";

function GuestStatusIndicator(props: { status?: GuestStatus }) {
  if (!props.status) {
    return null;
  }

  let backgroundColor = "lightgray";

  switch (props.status) {
    case "accepted":
      backgroundColor = "#70AF80";
      break;
    case "accept_pending_quorum":
      backgroundColor = "#70AF80";
      break;
    case "negotiation_in_progress":
      backgroundColor = "#7fa2bd";
      break;
    case "waiting_for_first_response":
      backgroundColor = "#9bbdec";
      break;
    case "away":
      backgroundColor = "#d9d4c9";
      break;
    case "cancelled":
      backgroundColor = "#7e8295";
      break;
    case "declined":
      backgroundColor = "#7f92a6";
      break;
    case "host_intervened":
      backgroundColor = "#81a1d5";
      break;
    case "no_response":
      backgroundColor = "#959393";
      break;
    case "paused":
      backgroundColor = "#b4c6e2";
      break;
    case "requires_host_intervention":
      backgroundColor = "#7aa6ea";
      break;
    case "responded_unknown_intent":
      backgroundColor = "#61d1e3";
      break;
    case "staging":
      backgroundColor = "#c6d5ee";
      break;
    case "undeliverable":
      backgroundColor = "#7e8295";
      break;
  }

  return (
    <Box
      sx={{
        width: "110px",
        borderRadius: "4px",
        px: "2px",
        py: "8px",
        textAlign: "center",
        backgroundColor,
      }}
    >
      <Typography sx={{ fontSize: "12px", color: "white" }} component="span">
        {GUEST_STATUS_TO_LABEL[props.status]}
      </Typography>
    </Box>
  );
}

const defaultMeetingQuery: MeetingQuery = {
  meetingIdFilter: [],
  guestEmail: "",
  hostEmail: "",
  notes: "",
  meetingStatus: [],
  guestStatus: [],
  template: [],
  tags: [],
  date: {
    field: "lastActivity",
    range: [null, null],
  },
  sort: {
    field: "lastActivity",
    order: "desc",
  },
  tagFilterSearch: "",
  templateFilterSearch: "",
  coHostFilter: "allMeetings",
};

const defaultTableSort: TableSort = {
  columnId: "lastActivity",
  order: "desc",
};

dayjs.extend(relativeTime);

export function SearchBar(props: {
  filters?: { [index: string]: boolean };
  onFilterChange?: (value: { [index: string]: boolean }) => void;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>();
  const [open, setOpen] = useState(false);
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    "searchHistory",
    []
  );
  const filters = props.filters || {};
  const timer = useRef<NodeJS.Timeout>();
  const filterList = Object.entries(filters)
    .reduce((prev, [key, value]) => {
      if (value) {
        return prev.concat(key);
      } else {
        return prev;
      }
    }, [] as string[])
    .join(", ");
  const filterCount = Object.values(filters).reduce(
    (a, b) => (b ? a + 1 : a),
    0
  );

  return (
    <div style={{ width: "400px" }}>
      <TextField
        inputRef={inputRef}
        placeholder={`Search Meetings by ${filterList}`}
        value={props.value}
        onChange={(event) => {
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            let h = [event.target.value].concat(searchHistory);

            // Drop any empty values.
            h = h.filter((e) => e.length && e.length > 0);

            // Remove duplicates.
            h = h.filter((value, index, self) => self.indexOf(value) === index);

            // Keep only 5 items.
            if (h.length > 5) {
              h = h.slice(0, 5);
            }

            setSearchHistory(h);
          }, 750);

          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
        onClick={() => setOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setOpen(false);
          }
        }}
        autoComplete="off"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1}>
                {filterCount > 0 && (
                  <Typography>
                    {filterCount}
                    {" filter"}
                    {filterCount > 1 ? "s" : ""}
                  </Typography>
                )}
                <SearchIcon />
              </Stack>
            </InputAdornment>
          ),
        }}
      />

      <Popover
        open={open}
        disableAutoFocus
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { minWidth: "300px", width: inputRef.current?.offsetWidth },
        }}
      >
        <Stack>
          <Box sx={{ p: 1 }}>
            <Typography>Search By</Typography>
            <Grid container rowGap={1} columnGap={1} sx={{ py: 1 }}>
              {Object.keys(filters).map((filterKey) => (
                <Grid item key={filterKey}>
                  <FilterButton
                    onClick={() => {
                      const temp = { ...filters };
                      temp[filterKey] = !filters[filterKey];
                      if (props.onFilterChange) {
                        props.onFilterChange(temp);
                      }
                    }}
                    active={filters[filterKey]}
                  >
                    {filterKey}
                  </FilterButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Typography>Recent Searches</Typography>
            <List>
              {searchHistory.map((item) => (
                <ListItemButton
                  key={item}
                  dense
                  onClick={() => {
                    if (props.onChange) {
                      if (inputRef.current) {
                        inputRef.current.value = item;
                      }

                      props.onChange(item);
                    }
                  }}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Stack>
      </Popover>
    </div>
  );
}

function Reporting({ query }: { query: MeetingQuery }) {
  const { data } = useMeetingReportV2({
    filter: {
      guestEmail: query.guestEmail,
      hostEmail: query.hostEmail,
      notes: query.notes,
      guestStatusFilter: query.guestStatus,
      meetingTypeFilter: query.templateIds,
      meetingIdFilter: query.meetingIdFilter,
      tagFilter: query.tags.map((t) => t.id),
      coHostFilter: query.coHostFilter,
      ...toDateFilters(query),
    },
  });

  if (!data) {
    return (
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "40px",
        }}
      >
        <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={"77px"}
            width="100%"
          />
        </Card>
        <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={"77px"}
            width="100%"
          />
        </Card>
        <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={"77px"}
            width="100%"
          />
        </Card>
        <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={"77px"}
            width="100%"
          />
        </Card>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "40px",
      }}
    >
      <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
        <CardHeader
          title={<Typography variant="h6">Action Required</Typography>}
          sx={{ p: 0, m: 0 }}
        />
        <CardContent
          sx={{
            p: 0,
            m: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            {data.readyToSend.actionRequired + data.scheduling.hostActionNeeded}
          </Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
        <CardHeader
          title={<Typography variant="h6">Currently Scheduled</Typography>}
          sx={{ p: 0, m: 0 }}
        />
        <CardContent
          sx={{
            p: 0,
            m: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            {data.scheduled.total}
          </Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
        <CardHeader
          title={<Typography variant="h6">Acceptance Rate</Typography>}
          sx={{ p: 0, m: 0 }}
        />
        <CardContent
          sx={{
            p: 0,
            m: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            {(data.finalized.acceptanceRate * 100).toFixed(2)}%
          </Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%", py: "24px", px: "20px" }}>
        <CardHeader
          title={<Typography variant="h6">Total Completed</Typography>}
          sx={{ p: 0, m: 0 }}
        />
        <CardContent
          sx={{
            p: 0,
            m: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
            {data.finalized.completed}
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
}

type MeetingPageTabs =
  | "Host Missing"
  | "Ready to Send"
  | "Sent"
  | "Scheduled"
  | "No Quorum"
  | "Completed"
  | "All";

export default function MeetingsPage() {
  const history = useHistory();
  const urlQuery = useUrlQueryV2();
  const [templateFilter, setTemplateFilter] = useState<number[]>([]);

  useEffect(() => {
    const templateQuery = urlQuery.getAll("templateId");
    setTemplateFilter(
      templateQuery.map((templateQueryItem) => Number(templateQueryItem))
    );
  }, [urlQuery]);

  const [query, setQuery] = useState<MeetingQuery>(defaultMeetingQuery);
  const [selected, setSelected] = useState<number[]>([]);
  const [sort, setSort] = useState<TableSort>(defaultTableSort);

  const openConfirmationDialog = useConfirmationDialog();
  const sendLater = useSendLater();
  const deleteMeetings = useDeleteMeetings();
  const updateMeetings = useUpdateMeetings();
  const routeMeetings = useRouteMeetings();
  const addMeetingTags = useAddMeetingTags();
  const meetingsCount = useMeetingsCount({
    filter: {
      guestEmail: query.guestEmail,
      hostEmail: query.hostEmail,
      notes: query.notes,
      guestStatusFilter: query.guestStatus,
      meetingTypeFilter: templateFilter,
      tagFilter: query.tags.map((t) => t.id),
      meetingIdFilter: query.meetingIdFilter,
      coHostFilter: query.coHostFilter,
      ...toDateFilters(query),
    },
  });

  const [openSnackbar] = useSnackbar();
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);
  const [showScheduleSendDialog, setShowScheduleSendDialog] = useState(false);
  const [tab, setTab] = useState<MeetingPageTabs>("All");

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [, setShowQuickLaunch] = useAtom(showQuickLaunchAtom);

  const [showTransferHostDialog, setShowTransferHostDialog] = useState(false);
  const transferHost = useTransferHost();

  const meetingDeletionDisabledForDefaultUser =
    useDefaultUserDisableMeetingsDelete();

  const meetingCancellationDisabledForDefaultUser =
    useDefaultUserDisableMeetingsCancel();

  const changeMeetingOwnerEnabled = useChangeMeetingOwner();

  const user = getUserDetails();
  const userRole = user?.role || ROLE_LEVELS.DEFAULT;
  const canTransferMeetings =
    changeMeetingOwnerEnabled &&
    (userRole > ROLE_LEVELS.DEFAULT ||
      (user && userCanChangeMeetingOwner(user)));
  const canDeleteMeetings =
    userRole > ROLE_LEVELS.DEFAULT || !meetingDeletionDisabledForDefaultUser;
  const canCancelMeetings =
    userRole > ROLE_LEVELS.DEFAULT ||
    !meetingCancellationDisabledForDefaultUser;

  useEffect(() => {
    setPage(1);
  }, [query]);

  const { data, loading, error } = useMeetingSearchResultsNewPage({
    filter: {
      meetingIdFilter: query.meetingIdFilter,
      guestEmail: query.guestEmail,
      hostEmail: query.hostEmail,
      notes: query.notes,
      meetingStatusFilter: query.meetingStatus,
      guestStatusFilter: query.guestStatus,
      meetingTypeFilter: templateFilter,
      tagFilter: query.tags.map((t) => t.id),
      coHostFilter: query.coHostFilter,
      ...toDateFilters(query),
    },
    paging: {
      limit: pageSize,
      offset: page - 1,
    },
    sort: {
      field: sort.columnId as "lastActivity" | "meetingTime",
      order: sort.order,
    },
  });

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  const total = data?.total || 0;

  return (
    <>
      <TransferHostDialog
        open={showTransferHostDialog}
        meetingIds={selected}
        onClose={() => setShowTransferHostDialog(false)}
        onConfirm={async ({ host, meetingLinkOption, customMeetingLink }) => {
          try {
            // TODO(BE): Support passing an array of meeting IDs to this API call.
            const transferHostPromises = selected.map((meetingId) =>
              transferHost(
                meetingId,
                host.id,
                meetingLinkOption === "current",
                customMeetingLink
              )
            );
            Promise.all(transferHostPromises);

            openSnackbar(
              `Successfully transferred meetings to ${host.firstName} ${host.lastName}`
            );
            setSelected([]);
            setShowTransferHostDialog(false);
          } catch (error) {
            openSnackbar("There was a problem transferring the meetings");
            console.error(error);
          }
        }}
      />
      <ScheduleSendDialog
        open={showScheduleSendDialog}
        onSubmit={async (date) => {
          try {
            const { failures, instances } = await sendLater(
              selected.map((id) => ({
                id,
                enabled: true,
                executeAt: date.toDate(),
              }))
            );
            setShowScheduleSendDialog(false);
            if (failures.length === 0) {
              openSnackbar("Meetings successfully scheduled for send");
              setSelected([]);
            } else if (instances.length > 0) {
              openSnackbar(
                `Some meetings activated were scheduled successfully but there were errors with ${failures
                  .map((failure) => failure.meetingId)
                  .join(", ")}`
              );
            } else {
              openSnackbar("Failed to schedule meetings to be sent later");
            }
          } catch {
            openSnackbar("Failed to schedule meetings to be sent later");
          }
        }}
        onClose={() => {
          setShowScheduleSendDialog(false);
        }}
      />
      <TagManagementDialog
        label="Bulk Add Tags"
        open={showTagManagementDialog}
        onClose={() => {
          setShowTagManagementDialog(false);
        }}
        onSubmit={async (tags) => {
          if (tags.length === 0) {
            return;
          }
          try {
            await addMeetingTags(
              selected,
              tags.map((tag) => tag.id)
            );

            setShowTagManagementDialog(false);
            openSnackbar(
              `Added ${tags.length} tag${tags.length > 1 ? "s" : ""} to ${
                selected.length
              } meeting${selected.length > 1 ? "s" : ""}`
            );
            setSelected([]);
          } catch {
            openSnackbar(`Failed to add tags to meetings`);
          }
        }}
      />
      <Box sx={{ backgroundColor: "#f1f1f1", minHeight: "100%" }}>
        <Container maxWidth="xl" disableGutters sx={{ px: "30px", pb: "65px" }}>
          <Stack
            sx={{
              py: "35px",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{ fontSize: "40px", fontWeight: "bold" }}
            >
              Meetings
            </Typography>
            <Stack sx={{ flexDirection: "row", gap: "8px" }}>
              <PrimaryButton
                onClick={() => {
                  history.push("/reports/import/contacts");
                }}
              >
                <Stack
                  sx={{
                    gap: "4px",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FileUploadIcon />
                  <Box component="span">Upload Contacts</Box>
                </Stack>
              </PrimaryButton>

              <PrimaryButton
                onClick={() => {
                  history.push(
                    `/reports/export/meetings?guestEmail=${
                      query.guestEmail
                    }&hostEmail=${query.hostEmail}&notes=${
                      query.notes
                    }&filterBy=${query.date.field}&start=${
                      query.date.range?.[0]?.toISOString() || ""
                    }&end=${
                      query.date.range?.[1]?.toISOString() || ""
                    }&templates=${JSON.stringify(
                      query.template.map((m) => ({
                        id: m.id,
                        name: m.name,
                      }))
                    )}&tags=${JSON.stringify(
                      query.tags
                    )}&guestStatuses=${query.guestStatus.join(
                      ","
                    )}&meetingStatuses=${query.meetingStatus.join(",")}`
                  );
                }}
              >
                <Stack
                  sx={{
                    gap: "4px",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FileDownloadIcon />
                  <Box component="span">Export Meetings</Box>
                </Stack>
              </PrimaryButton>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Paper elevation={0} sx={{ p: 2 }}>
              <MeetingsFilterBar
                filters={query}
                sort={sort}
                templateFilter={templateFilter}
                onFilterUpdate={(newFilters) => setQuery(newFilters)}
                onSortUpdate={(newSort) => setSort(newSort)}
                onTemplateFilterUpdate={(templates) => {
                  urlQuery.delete("templateId");
                  templates.forEach((templateItem) =>
                    urlQuery.append("templateId", templateItem.id.toString())
                  );
                  history.push({
                    search: `?${urlQuery.toString()}`,
                  });
                }}
                onReset={() => {
                  setQuery(defaultMeetingQuery);
                  setSort(defaultTableSort);
                  history.push({
                    search: "",
                  });
                }}
              />
            </Paper>

            <Reporting
              query={{
                ...query,
                templateIds: templateFilter,
              }}
            />

            <Paper elevation={0}>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pt: "20px",
                  px: "20px",
                  mb: "10px",
                }}
              >
                <Typography>Selected: {selected.length}</Typography>
              </Stack>
              <Box>
                <Stack
                  sx={{
                    px: "20px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Tabs
                    value={tab}
                    sx={{
                      textDecoration: 0,
                      borderBottom: "1px solid #CDD6DD",
                    }}
                    TabIndicatorProps={{
                      style: { backgroundColor: "transparent" },
                    }}
                    onChange={(_, value: MeetingPageTabs) => {
                      switch (value) {
                        case "Host Missing":
                          setQuery({
                            ...query,
                            meetingStatus: ["init_fault"],
                          });
                          break;
                        case "Ready to Send":
                          setQuery({
                            ...query,
                            meetingStatus: ["initialized", "queued"],
                          });
                          break;
                        case "Sent":
                          setQuery({
                            ...query,
                            meetingStatus: ["scheduling"],
                          });
                          break;
                        case "Scheduled":
                          setQuery({
                            ...query,
                            meetingStatus: ["scheduled"],
                          });
                          break;
                        case "No Quorum":
                          setQuery({
                            ...query,
                            meetingStatus: ["no_quorum", "cancelled"],
                          });
                          break;
                        case "Completed":
                          setQuery({
                            ...query,
                            meetingStatus: ["completed"],
                          });
                          break;
                        case "All":
                          setQuery({
                            ...query,
                            meetingStatus: [],
                          });
                          break;
                      }
                      setSelected([]);
                      setTab(value);
                    }}
                  >
                    <Tab
                      label={`All (${meetingsCount.data?.total ?? 0})`}
                      value="All"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Host Missing (${
                        meetingsCount.data?.hostMissingTotal ?? 0
                      })`}
                      value="Host Missing"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Ready to Send (${
                        meetingsCount.data?.readyToSendTotal ?? 0
                      })`}
                      value="Ready to Send"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Sent (${meetingsCount.data?.sentTotal ?? 0})`}
                      value="Sent"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Scheduled (${
                        meetingsCount.data?.scheduledTotal ?? 0
                      })`}
                      value="Scheduled"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`No Quorum (${
                        meetingsCount.data?.noQuorumTotal ?? 0
                      })`}
                      value="No Quorum"
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={`Completed (${
                        meetingsCount.data?.completedTotal ?? 0
                      })`}
                      value="Completed"
                      sx={{ textTransform: "none" }}
                    />
                  </Tabs>
                  <Stack sx={{ flexDirection: "row" }}>
                    <IconButton
                      title="Send Now"
                      sx={{ color: "text.primary" }}
                      disabled={
                        selected.length < 1 ||
                        tab === "Completed" ||
                        tab === "Host Missing" ||
                        tab === "No Quorum" ||
                        tab === "Scheduled" ||
                        tab === "Sent"
                      }
                      onClick={async () => {
                        try {
                          const { failures, instances } = await updateMeetings(
                            selected.map((meetingId) => ({
                              enabled: true,
                              id: meetingId,
                            }))
                          );

                          if (failures.length === 0) {
                            openSnackbar("Meetings activated");
                            setSelected([]);
                          } else if (instances.length > 0) {
                            openSnackbar(
                              `Some meetings activated were activated successfully but there were errors with ${failures
                                .map((failure) => failure.meetingId)
                                .join(", ")}`
                            );
                          } else {
                            openSnackbar("Failed to activate meetings");
                          }
                        } catch {
                          openSnackbar("Failed to activate meetings");
                        }
                      }}
                    >
                      <Send />
                    </IconButton>
                    <IconButton
                      title="Send Later"
                      sx={{ color: "text.primary" }}
                      disabled={
                        selected.length < 1 ||
                        tab === "Completed" ||
                        tab === "Host Missing" ||
                        tab === "No Quorum" ||
                        tab === "Scheduled" ||
                        tab === "Sent"
                      }
                      onClick={() => {
                        setShowScheduleSendDialog(true);
                      }}
                    >
                      <ScheduleSend />
                    </IconButton>
                    <IconButton
                      title="Transfer Host"
                      sx={{ color: "text.primary" }}
                      disabled={
                        !canTransferMeetings ||
                        selected.length < 1 ||
                        tab === "Completed" ||
                        tab === "Host Missing" ||
                        tab === "No Quorum" ||
                        tab === "Ready to Send" ||
                        tab === "Sent"
                      }
                      onClick={() => {
                        setShowTransferHostDialog(true);
                      }}
                    >
                      <TransferHostIcon />
                    </IconButton>
                    <IconButton
                      title="Tag"
                      sx={{ color: "text.primary" }}
                      disabled={selected.length < 1}
                      onClick={() => {
                        setShowTagManagementDialog(true);
                      }}
                    >
                      <Label />
                    </IconButton>
                    <IconButton
                      title="Route Meetings"
                      sx={{ color: "text.primary" }}
                      disabled={
                        selected.length < 1 ||
                        tab === "Completed" ||
                        tab === "No Quorum" ||
                        tab === "Ready to Send" ||
                        tab === "Scheduled" ||
                        tab === "Sent"
                      }
                      onClick={async () => {
                        try {
                          const result = await routeMeetings(selected);
                          if (result.failures?.length > 0) {
                            openSnackbar(
                              `Failed to route selected meetings: ${result.failures.join(
                                ", "
                              )}`
                            );
                            console.error(result.failures);
                            return;
                          }
                          if (result.instances?.length > 0) {
                            openSnackbar(
                              `Successfully routed ${result.instances?.length} meetings`
                            );
                            setSelected([]);
                          }
                        } catch (error) {
                          openSnackbar("Failed to route selected meetings");
                          console.error(error);
                        }
                      }}
                    >
                      <RouteMeetingsIcon />
                    </IconButton>
                    <IconButton
                      title="Cancel Meetings"
                      sx={{ color: "text.primary" }}
                      disabled={
                        selected.length < 1 ||
                        !canCancelMeetings ||
                        tab === "Completed" ||
                        tab === "Host Missing" ||
                        tab === "No Quorum" ||
                        tab === "Ready to Send"
                      }
                      onClick={async () => {
                        try {
                          const result = await updateMeetings(
                            selected.map((meetingId) => ({
                              id: meetingId,
                              enabled: false,
                            }))
                          );
                          if (result.failures?.length > 0) {
                            openSnackbar("Failed to cancel selected meetings");
                            console.error(result.failures);
                            return;
                          }
                          openSnackbar(
                            "Successfully cancelled selected meetings"
                          );
                          setSelected([]);
                        } catch (error) {
                          openSnackbar("Failed to cancel selected meetings");
                          console.error(error);
                        }
                      }}
                    >
                      <EventBusy />
                    </IconButton>
                    <IconButton
                      title="Delete Meetings"
                      sx={{ color: "text.primary" }}
                      disabled={selected.length < 1 || !canDeleteMeetings}
                      onClick={() => {
                        openConfirmationDialog(
                          "Delete Confirmation",
                          `Are you sure you want to delete ${selected.length} meetings?`,
                          async () => {
                            try {
                              await deleteMeetings(selected);
                              openSnackbar("Meeting successfully deleted");
                              setSelected([]);
                            } catch (error) {
                              openSnackbar(
                                "Failed to delete selected meetings"
                              );
                              console.error(error);
                            }
                          }
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </Stack>

                {!loading && total === 0 && (
                  <Stack
                    sx={{
                      my: "140px",
                      mx: "auto",
                      maxWidth: "800px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      textAlign: "center",
                    }}
                  >
                    <MeetingEmptyStateIcon />
                    <Typography
                      color="primary.dark"
                      sx={{ fontSize: "30px", fontWeight: "bold" }}
                    >
                      No meetings found
                    </Typography>
                    <Box>
                      <Typography component="div">
                        Create new meetings using{" "}
                        <Typography
                          sx={{ cursor: "pointer" }}
                          component="span"
                          color="primary"
                          onClick={() => setShowQuickLaunch(true)}
                        >
                          Quick Launcher
                        </Typography>{" "}
                        or{" "}
                        <BaseInternalLink to="/reports/import/contacts">
                          Upload Contacts
                        </BaseInternalLink>
                        .
                      </Typography>
                      <Typography component="div">
                        If you think you should see meetings here, try removing
                        any applied filters.
                      </Typography>
                    </Box>
                  </Stack>
                )}

                {loading && <TableLoading />}

                {!loading && total > 0 && (
                  <SelectTable
                    loading={loading}
                    selected={selected}
                    onSelect={(checked, meetings) => {
                      if (!checked) {
                        setSelected(
                          selected.concat(meetings.map((meeting) => meeting.id))
                        );
                      } else {
                        setSelected(
                          selected.filter(
                            (s) =>
                              !meetings.map((meeting) => meeting.id).includes(s)
                          )
                        );
                      }
                    }}
                    columns={[
                      {
                        id: "id",
                        label: "Id",
                        component: (row) => (
                          <Link
                            component={RouterLink}
                            to={`/meetings/${row.id}`}
                          >
                            {row.id}
                          </Link>
                        ),
                      },
                      {
                        id: "meetingTime",
                        label: `Time (${dayjs().format("z")})`,
                        component: (row) => {
                          const startTime = row.startTime
                            ? dayjs(row.startTime)
                            : null;
                          return (
                            startTime && (
                              <Stack>
                                <Typography variant="caption">
                                  {startTime.format("ddd, MMM D")}
                                </Typography>
                                <Typography variant="caption">
                                  {startTime.format("h:mm A")}
                                </Typography>
                              </Stack>
                            )
                          );
                        },
                        width: "150px",
                      },
                      {
                        id: "template",
                        label: "Template",
                        component: (row) => (
                          <Stack sx={{ gap: "6px" }}>
                            <Typography fontSize="14px">
                              {row.meetingType?.name}
                            </Typography>
                            {row.attempts?.total > 1 && (
                              <Stack sx={{ flexDirection: "row", gap: "4px" }}>
                                {new Array(row.attempts.current)
                                  .fill(null)
                                  .map((_, index) => (
                                    <Box
                                      key={`attempt-${row.id}-${index}`}
                                      sx={{
                                        borderRadius: 999,
                                        height: "12px",
                                        width: "12px",
                                        backgroundColor: "primary.main",
                                      }}
                                    ></Box>
                                  ))}
                                {new Array(
                                  Math.max(
                                    row.attempts.total - row.attempts.current,
                                    0
                                  )
                                )
                                  .fill(null)
                                  .map((_, index) => (
                                    <Box
                                      key={`attempt-${row.id}-${index}`}
                                      sx={{
                                        borderRadius: 999,
                                        height: "12px",
                                        width: "12px",
                                        backgroundColor: "#CDD6DD",
                                      }}
                                    ></Box>
                                  ))}
                              </Stack>
                            )}
                          </Stack>
                        ),
                      },
                      {
                        id: "host",
                        label: "Host",
                        component: (row) => (
                          <Stack>
                            <Typography variant="caption">
                              {row.host?.firstName} {row.host?.lastName}
                            </Typography>
                            <Typography variant="caption">
                              {row.host?.email}
                            </Typography>
                          </Stack>
                        ),
                      },
                      ...(isEnterpriseMode
                        ? [
                            {
                              id: "cohost",
                              label: "Co-Host",
                              width: "100px",
                              component: (row: {
                                hasCoHosts: boolean | null;
                              }) => (
                                <Stack
                                  sx={{
                                    marginLeft: "20px",
                                  }}
                                >
                                  <CoHostIndicator enabled={row.hasCoHosts} />
                                </Stack>
                              ),
                            },
                          ]
                        : []),
                      {
                        id: "guest",
                        label: "Guest",
                        component: (row) => (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Stack>
                              <Typography variant="caption">
                                {row.guests?.[0]?.firstName}{" "}
                                {row.guests?.[0]?.lastName}
                              </Typography>
                              <Typography variant="caption">
                                {row.guests?.[0]?.email}
                              </Typography>
                            </Stack>
                          </Stack>
                        ),
                      },
                      {
                        id: "guestStatus",
                        label: "Guest Status",
                        component: (row) => (
                          <GuestStatusIndicator
                            status={row.guests?.[0]?.status}
                          />
                        ),
                      },
                    ]}
                    data={data?.data || []}
                    minRows={pageSize}
                  />
                )}
              </Box>
              {!loading && total > 0 && (
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Rows per page:</Typography>
                      <Select
                        variant="standard"
                        labelId="rows-per-page-label"
                        id="rows-per-page"
                        value={pageSize}
                        onChange={(event) =>
                          setPageSize(Number(event.target.value))
                        }
                        label="Rows per page"
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Stack>

                    <Stack sx={{ flexDirection: "row", gap: "10px" }}>
                      <PrimaryButton
                        disabled={page <= 1}
                        onClick={() => setPage(Math.max(page - 1, 1))}
                      >
                        <ChevronLeft />
                      </PrimaryButton>
                      <Pages
                        pages={Math.ceil(total / pageSize)}
                        currentPage={page}
                        onPageSelect={(newPage) => setPage(newPage)}
                      />

                      <PrimaryButton
                        disabled={page + 1 >= Math.ceil(total / pageSize)}
                        onClick={() =>
                          setPage(
                            Math.min(page + 1, Math.ceil(total / pageSize))
                          )
                        }
                      >
                        <ChevronRight />
                      </PrimaryButton>
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Paper>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

interface DateFilter {
  dateFilter?: {
    end?: string;
    start?: string;
    timezone?: string;
  };
  lastActivityFilter?: {
    end?: string;
    start?: string;
    timezone?: string;
  };
}

function toDateFilters(query: MeetingQuery): DateFilter {
  return {
    dateFilter:
      query.date.field === "startTime"
        ? {
            start: query.date.range[0]
              ? query.date.range[0].format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            end: query.date.range[1]
              ? query.date.range[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        : undefined,
    lastActivityFilter:
      query.date.field === "lastActivity"
        ? {
            start: query.date.range[0]
              ? query.date.range[0].format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            end: query.date.range[1]
              ? query.date.range[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss")
              : undefined,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }
        : undefined,
  };
}
