import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useOrgFeatureFlags(orgKey: string | null) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(
    orgKey ? `/api/org/setting/features/${orgKey}` : null,
    (url) =>
      service
        .get(url)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as { [index: string]: boolean } | undefined,
    error,
    loading: !error && !data,
  };
}
