import React, { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";

export const DropdownIndicator = () => <span />;

const useMeetingTypeSelectTheme = (valueColor) => {
  return useMemo(
    () => ({
      container: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? WHITE : "inherit",
        borderRadius: "3px",
        color: WHITE,
        cursor: "pointer",
        width: "100%",
      }),
      control: (base) => ({
        ...base,
        backgroundColor: "inherit",
        border: 0,
        borderRadius: "3px",
        boxShadow: 0,
        cursor: "pointer",
        flexDirection: "row-reverse",
        fontSize: "10px",
        height: "100%",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        padding: "0px",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: BLACK,
        fontSize: "14px",
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: WHITE,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: "0.5px solid #A8B1BD",
        borderRadius: 0,
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => ({
        ...base,
        backgroundColor: isFocused ? "#F1FAFF" : WHITE,
        border: "0.5px solid #A8B1BD",
        color: BLACK,
        cursor: "pointer",
        fontSize: "10px",
        paddingBottom: "7px",
        paddingTop: "7px",
      }),
      placeholder: (base, { isFocused }) => ({
        ...base,
        display: isFocused ? "none" : "flex",
        width: "100%",
      }),
      singleValue: (base) => ({
        ...base,
        color: valueColor || BLACK,
        fontSize: "1.2em",
        fontWeight: "bold",
        lineHeight: "16px",
        margin: 0,
        maxWidth: "unset",
        overflow: "unset",
        textAlign: "center",
        whiteSpace: "unset",
      }),
      valueContainer: (base) => ({
        ...base,
        cursor: "pointer",
        display: "flex",
        fontSize: "1.2em",
        justifyContent: "center",
        overflow: "visible",
      }),
    }),
    [valueColor]
  );
};

export default useMeetingTypeSelectTheme;
