import React, { useCallback, useMemo } from "react";

import Flexbox from "../../../components/flexbox";
import { PROVISIONING_STAGES } from "../../props";
import { P1 } from "../../../components/typography";
import { useMeeting, useMeetingTypeState } from "../state";
import MeetingTypeSelect from "../../meetingTypeSelect";
import { DARK_BLUE } from "../../../components/colors";

import style from "./meetingTypeDetailsHeaderSelect.module.scss";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useUpdateMeetings } from "../../../mutations";

function MeetingTypeDetailsHeaderSelect({ calendarInvite }) {
  const [meeting] = useMeeting();
  const { meetingType } = useMeetingTypeState();

  const { addError } = useGeneralNotifications();
  const updateMeetings = useUpdateMeetings();

  const location = useMemo(() => {
    if (calendarInvite !== null && calendarInvite.location !== "") {
      return calendarInvite.location;
    }

    if (meetingType.inviteTemplates.length !== 0) {
      return meetingType.inviteTemplates[0].location;
    }

    return "location";
  }, [calendarInvite, meetingType]);

  const canChangeMeetingType = useMemo(
    () => !meeting.enabled && PROVISIONING_STAGES.includes(meeting.status),
    [meeting.enabled, meeting.status]
  );

  const setMeetingType = useCallback(
    async (newMeetingType) => {
      updateMeetings([
        { id: meeting.id, meetingType: newMeetingType.id },
      ]).catch(() => {
        addError("Failed to update meeting type");
      });
    },
    [updateMeetings, addError, meeting]
  );

  return (
    <Flexbox>
      {({ Column }) => (
        <Column style={{ paddingTop: "10px" }}>
          {canChangeMeetingType && (
            <MeetingTypeSelect
              meetingType={meetingType}
              setMeetingType={setMeetingType}
              color={DARK_BLUE}
            />
          )}

          {!canChangeMeetingType && (
            <P1 className={style.meetingTypeDetailsHeaderInput__text} bold>
              {meetingType?.name}
            </P1>
          )}

          <P1 className={style.meetingTypeDetailsHeaderInput__location} small>
            {location}
          </P1>
        </Column>
      )}
    </Flexbox>
  );
}

export default MeetingTypeDetailsHeaderSelect;
