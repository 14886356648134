import useSWR from "swr";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useProvisioningService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

export function useOrgVersionInfo() {
  const service = useProvisioningService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(`/api/provisioning/orgs/versions`, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as
      | {
          orgVersions: {
            name: string;
            version: number;
          }[];
          globalVersion: number;
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}
