import React from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "./OutlinedInput";
import {
  addPermission,
  hasPermission,
  PermissionToChangeHost,
  removePermission,
} from "../../types";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

export function AdditionalPermissionsInput({
  value,
  onChange,
}: {
  value?: number;
  onChange?: (value: number) => void;
}) {
  const changeHost = hasPermission(value || 0, PermissionToChangeHost);

  return (
    <OutlinedInput label="Additional Permissions">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={changeHost}
              onChange={(_, checked) => {
                if (onChange) {
                  if (checked) {
                    onChange(addPermission(value || 0, PermissionToChangeHost));
                  } else {
                    onChange(
                      removePermission(value || 0, PermissionToChangeHost)
                    );
                  }
                }
              }}
              name="changeHost"
            />
          }
          label={
            <Stack direction="row" spacing={0.5}>
              <Typography>Change Meeting Host</Typography>
              <Tooltip title="Allow users to reassign an existing scheduled meeting to a new host of their choosing.">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Stack>
          }
        />
      </FormGroup>
    </OutlinedInput>
  );
}

export default AdditionalPermissionsInput;
