import { ResponseError } from "superagent";
import useSWRImmutable from "swr/immutable";
import { useUserService } from "../services";

export function useHostAvailability(
  orgUUID: string,
  meetingUUID: string,
  contactUUID: string
) {
  const service = useUserService();

  const body = {
    c: contactUUID,
    m: meetingUUID,
    o: orgUUID,
  };

  const { data, error } = useSWRImmutable(
    [`/api/meeting/host/availability`, body],
    (url: string) =>
      service
        .post(url)
        .send(body)
        .then((res: Response) => res.body)
  );

  return {
    data: data as { availableTimes: string[] } | undefined,
    error: error as ResponseError,
    loading: !error && !data,
  };
}
