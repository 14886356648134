import React, { useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { BLACK, WHITE } from "../../components/colors";
import Flexbox from "../../components/flexbox";
import { useTheme } from "../../components/theme";

function DropdownIndicator() {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column>
          <Icon path={mdiChevronDown} size={0.5} />
        </Column>
      )}
    </Flexbox>
  );
}

export function useDropdownTheme() {
  return useMemo(
    () => [
      { DropdownIndicator },
      {
        container: (base) => ({
          ...base,
          backgroundColor: "#e6e6e6",
          height: "38px",
          paddingLeft: "0px",
          paddingRight: "0px",
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: "inherit",
          border: 0,
          borderRadius: 0,
          boxShadow: 0,
          fontSize: "12px",
          fontWeight: 400,
          height: "100%",
          padding: "0px",
          width: "100%",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: "inherit",
          width: 0,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          padding: "0px",
        }),
        input: (base) => ({
          ...base,
          color: BLACK,
          fontSize: "12px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: WHITE,
          boxShadow: "none",
          left: "50%",
          marginTop: 2,
          transform: "translateX(-50%)",
          width: "200px",
        }),
        menuList: (base) => ({
          ...base,
          border: "0.5px solid #A8B1BD",
          borderRadius: 0,
          padding: "0px",
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: WHITE,
          borderRadius: "2px",
          color: BLACK,
          margin: "2px",
        }),
        option: (base, { isFocused }) => {
          return {
            ...base,
            backgroundColor: isFocused ? "#F1FAFF" : WHITE,
            border: "0.5px solid #A8B1BD",
            color: BLACK,
            cursor: "pointer",
            fontWeight: 400,
            padding: "7px",
          };
        },
        placeholder(base) {
          return {
            ...base,
            color: BLACK,
          };
        },
        singleValue: (base) => ({
          ...base,
          color: BLACK,
        }),
        valueContainer: (base) => ({
          ...base,
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          fontWeight: 400,
          height: "100%",
          justifyContent: "center",
          padding: "0px",
        }),
      },
    ],
    []
  );
}
