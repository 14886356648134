import {
  Chip,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

import Loading from "src/componentsV2/Loading";
import { useContactsV2 } from "src/queries";
import PrimaryButton from "../../../componentsV2/buttons/PrimaryButton";
import { useCreateContact } from "../../../mutations";
import { validateEmailAddress } from "../../../utils/helpers";
import { State } from "./State";

type AddGuestProps = {
  state: State;
  setState: (state: State) => void;
  next: () => void;
  previous: () => void;
};

function AddGuest({ state, setState, next, previous }: AddGuestProps) {
  const [email, setEmail] = useState(state.guest?.email || "");
  const [firstName, setFirstName] = useState(state.guest?.firstName || "");
  const [lastName, setLastName] = useState(state.guest?.lastName || "");

  const createContact = useCreateContact();

  const { data, loading, error } = useContactsV2(5, 0, {
    filter: {
      firstName,
      lastName,
      email,
    },
  });

  const { data: emailSearch } = useContactsV2(5, 0, {
    filter: {
      email,
    },
  });

  // Poor mans check to see if contact already exists.
  const unsubscribed = emailSearch?.data.some((contact) => {
    return (
      contact.email.trim().toLowerCase() === email.trim().toLowerCase() &&
      contact.unsubscribed
    );
  });

  return (
    <>
      <DialogContent>
        <Stack
          justifyContent="space-between"
          spacing={2}
          sx={{
            p: 2,
            height: "600px",
          }}
        >
          <Stack spacing={1}>
            <TextField
              autoFocus
              fullWidth
              label="Email"
              required
              spellCheck={false}
              value={email}
              error={
                (email !== "" && !validateEmailAddress(email)) || unsubscribed
              }
              helperText={
                email !== "" && !validateEmailAddress(email)
                  ? "must be a valid email"
                  : unsubscribed
                  ? "contact already exists and is unsubscribed"
                  : ""
              }
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <PrimaryButton
              disabled={!validateEmailAddress(email) || unsubscribed}
              onClick={() => {
                createContact({
                  email,
                  firstName,
                  lastName,
                  unsubscribed: false,
                }).then((g) => {
                  setState({
                    ...state,
                    guest: g,
                  });
                  next();
                });
              }}
            >
              Create New Contact
            </PrimaryButton>
            {error || (firstName === "" && lastName === "" && email === "") ? (
              <></>
            ) : loading ? (
              <Loading />
            ) : (
              <>
                <List sx={{ width: "100%" }}>
                  {data.data.map((contact) => (
                    <ListItem
                      key={contact.id}
                      secondaryAction={
                        <Stack direction="row" spacing={1} alignItems="center">
                          {contact.unsubscribed && (
                            <Chip size="small" label="Unsubcribed" />
                          )}
                          <PrimaryButton
                            disabled={contact.unsubscribed}
                            onClick={() => {
                              setState({
                                ...state,
                                guest: contact,
                              });
                              next();
                            }}
                          >
                            Select
                          </PrimaryButton>
                        </Stack>
                      }
                    >
                      <Stack>
                        <Typography>
                          {contact.firstName} {contact.lastName}
                        </Typography>
                        <Typography>{contact.email}</Typography>
                      </Stack>
                    </ListItem>
                  ))}
                  {data.data.length === 0 && (
                    <Typography align="center">No results</Typography>
                  )}
                </List>
                {data.data.length > 0 && (
                  <Typography align="right">
                    Showing {data.data.length} of {data.total}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          sx={{ pt: 2, width: "100%" }}
        >
          <PrimaryButton disabled={state.guest === null} onClick={() => next()}>
            Next
          </PrimaryButton>
          <PrimaryButton onClick={() => previous()}>Prev</PrimaryButton>
        </Stack>
      </DialogActions>
    </>
  );
}

export default AddGuest;
