import { MeetingDefinition } from "src/types";
import { getUserToken } from "src/utils/jwtToken";
import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { errorHandler } from "src/hooks/errorHandler";

// Type generated using quicktype: https://app.quicktype.io/
export interface MeetingTemplateUpdateBody
  extends Omit<MeetingDefinition, "active" | "team" | "tags"> {
  enabled?: boolean;
  team: number;
  tags: number[];
}

export function useUpdateMeetingTemplate() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return async (id: number, body: MeetingTemplateUpdateBody): Promise<void> => {
    const unsubscribeUpdatePromise = service
      .post(`/api/meetings/definition/${id}/unsubscribe`)
      .set(headers)
      .send(body.unsubscribeTemplate)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler);

    const dataUpdatePromise = service
      .patch(`/api/meetings/definition/${id}`)
      .set(headers)
      .send(body)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter(
            (r: any) =>
              r.includes(`/api/meetings/definition/list`) ||
              r.includes(`/api/meetings/definition/${id}`)
          )
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);

    const [dataUpdateResult, unsubscribeUpdateResult] = await Promise.all([
      unsubscribeUpdatePromise,
      dataUpdatePromise,
    ]);
    return dataUpdateResult;
  };
}
