export const MEETING_TYPES_FOR_REPS = "meetingTypesForReps";
export const QUICK_LAUNCH = "quickLaunch";
export const CHANGE_MEETING_OWNER = "changeMeetingOwner";
export const OPENAI_INTEGRATION = "openAiIntegration";
export const RESCHEDULING_FROM_REMINDER = "reschedulingFromReminder";
export const DEFAULT_USER_DISABLE_MEETINGS_DELETE =
  "defaultUserDisableMeetingsDelete";
export const DEFAULT_USER_DISABLE_MEETINGS_CANCEL =
  "defaultUserDisableMeetingsCancel";
export const NEW_MEETINGS_PAGE = "newMeetingsPage";
export const API_PAGE = "apiPage";
export const LINK_FIRST_INVITE = "linkFirstInvite";
export const ORG_ADMIN_ONLY_QUICK_LAUNCH = "orgAdminOnlyQuickLaunch";
export const NEW_CHANNELS_PAGE = "newChannelsPage";
export const REPORTING_PAGE = "reportingPage";
export const MAX_DAY_RANGE = "maxDayRange";
export const TEMPLATE_AVAILABILITY = "templateAvailability";

export const DEFAULTS = {
  [CHANGE_MEETING_OWNER]: false,
  [MEETING_TYPES_FOR_REPS]: true,
  [RESCHEDULING_FROM_REMINDER]: false,
  [OPENAI_INTEGRATION]: false,
  [DEFAULT_USER_DISABLE_MEETINGS_DELETE]: false,
  [DEFAULT_USER_DISABLE_MEETINGS_CANCEL]: false,
  [NEW_MEETINGS_PAGE]: false,
  [API_PAGE]: false,
  [LINK_FIRST_INVITE]: false,
  [ORG_ADMIN_ONLY_QUICK_LAUNCH]: false,
  [NEW_CHANNELS_PAGE]: false,
  [REPORTING_PAGE]: false,
  [MAX_DAY_RANGE]: false,
  [TEMPLATE_AVAILABILITY]: false,
};

export const FEATURE_DISPLAY_NAMES = {
  [CHANGE_MEETING_OWNER]: "Transfer meeting ownership to another host",
  [MEETING_TYPES_FOR_REPS]: "Meeting Templates For Reps",
  [QUICK_LAUNCH]: "Quick Launch",
  [RESCHEDULING_FROM_REMINDER]: "Allow Rescheduling From Meeting Reminder",
  [OPENAI_INTEGRATION]: "Open AI Integration",
  [DEFAULT_USER_DISABLE_MEETINGS_DELETE]:
    "Default User - Disable Meetings Delete",
  [DEFAULT_USER_DISABLE_MEETINGS_CANCEL]:
    "Default User - Disable Meetings Cancellation",
  [NEW_MEETINGS_PAGE]: "New meetings page",
  [API_PAGE]: "API Page",
  [LINK_FIRST_INVITE]: "Kronologic Link Meeting Type",
  [ORG_ADMIN_ONLY_QUICK_LAUNCH]: "Quick Launcher For Org Admin Only",
  [NEW_CHANNELS_PAGE]: "New Channels Page",
  [REPORTING_PAGE]: "Reporting Page",
  [MAX_DAY_RANGE]: "Max day range of 90 days",
  [TEMPLATE_AVAILABILITY]: "Custom Availability for Templates",
};

export const FEATURE_LIST = [
  MEETING_TYPES_FOR_REPS,
  CHANGE_MEETING_OWNER,
  RESCHEDULING_FROM_REMINDER,
  OPENAI_INTEGRATION,
  DEFAULT_USER_DISABLE_MEETINGS_DELETE,
  DEFAULT_USER_DISABLE_MEETINGS_CANCEL,
  QUICK_LAUNCH,
  NEW_MEETINGS_PAGE,
  API_PAGE,
  LINK_FIRST_INVITE,
  ORG_ADMIN_ONLY_QUICK_LAUNCH,
  NEW_CHANNELS_PAGE,
  REPORTING_PAGE,
  MAX_DAY_RANGE,
  TEMPLATE_AVAILABILITY,
];
