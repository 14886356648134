import React from "react";
import moment from "moment";
import cx from "classnames";
import { P1 } from "../../components/typography";
import {
  GUEST_STATUS_TO_LABEL,
  toGuestStatusLabel,
  toGuestStatusColor,
} from "../../meetings/props";
import style from "./globallogs.module.scss";
import { formatWordForPossession } from "./util";

// -- GUEST STATUS CHANGE LOG -- //
// buildStatusData reutrns statuses as lables and their corresponding colors
const buildStatusData = (statusOld, statusNew) => ({
  newStatusLabel: toGuestStatusLabel(statusNew),
  oldStatusLabel: toGuestStatusLabel(statusOld),
  statusNewColor: toGuestStatusColor(statusNew),
  statusOldColor: toGuestStatusColor(statusOld),
});

// fallback pattern: guest email --> guest name --> guest account
const buildGuestInfo = (structuredData) => {
  const { contactAccount, contactFirstName, contactLastName, contactEmail } =
    structuredData;

  if (contactEmail?.length > 0) {
    return contactEmail;
  }

  if (contactFirstName?.length > 0 && contactLastName?.length > 0) {
    return `${contactFirstName} ${contactLastName}`;
  }

  if (contactAccount?.length > 0) {
    return contactAccount;
  }

  return "[missing guest info]";
};

// fallback patten: host email --> host name --> orgKey
const buildHostInfo = (structuredData, orgKey) => {
  const { userEmail, userFirstName, userLastName } = structuredData;

  if (userEmail?.length > 0) {
    return formatWordForPossession(userEmail);
  }

  if (userFirstName?.length > 0 && userLastName?.length > 0) {
    return `${userFirstName} ${formatWordForPossession(userLastName)}`;
  }

  if (orgKey?.length > 0) {
    return formatWordForPossession(orgKey);
  }

  return "[missing host/org information]";
};

const noEventStartTimePhase = (status) =>
  status === GUEST_STATUS_TO_LABEL.staging;

const shouldShowStartTime = (statusNew) => {
  // if the guest status is going into a status that will not have a meeting start time
  return !noEventStartTimePhase(statusNew);
};

const buildGuestStatusChangeLog = function* (log) {
  // sample log structure:
  /* 
  XX/XX/XXXX XX:XX am/pm  Guest status for [host name]'s 
  meeting on XX/XX/XXXX at XX:XX am/pm with [guest name] changed from [old stage] to [new stage].
*/

  const { structuredData, orgKey } = log;
  const { meetingId, statusNew, statusOld } = structuredData;
  let { eventStart } = structuredData;

  const guestInfo = buildGuestInfo(structuredData);
  const hostInfo = buildHostInfo(structuredData, orgKey);

  const statusData = buildStatusData(statusOld, statusNew);
  const { oldStatusLabel, statusNewColor, statusOldColor } = statusData;
  let { newStatusLabel } = statusData;

  // abort log if we can't parse status
  if (oldStatusLabel === null || newStatusLabel === null) {
    return "";
  }

  if (!shouldShowStartTime(statusNew)) {
    eventStart = null;
  }

  if (newStatusLabel === GUEST_STATUS_TO_LABEL.accepted) {
    newStatusLabel = newStatusLabel.toUpperCase();
  }

  yield (
    <P1>
      <span
        className={cx(style.logBuilder__instanceId, style.logBuilder__italics)}
      >
        {`Meeting ID: ${meetingId} - `}
      </span>
      <span>
        Guest status for
        {` ${hostInfo} `}
        meeting
        {eventStart &&
          ` on ${moment(eventStart).format("MM/DD/YYYY")} at ${moment(
            eventStart
          ).format("hh:mma")} `}
        {` with ${guestInfo} `}
        changed from
      </span>
      <span
        className={style.logBuilder__bold}
        style={{ color: statusOldColor }}
      >
        {` ${oldStatusLabel} `}
      </span>
      to
      <span
        className={style.logBuilder__bold}
        style={{ color: statusNewColor }}
      >
        {` ${newStatusLabel}`}
      </span>
    </P1>
  );
};
// -- END GUEST STATUS CHANGE LOG -- //

export default buildGuestStatusChangeLog;
