import { Delete, LocalOffer } from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { PageLayout } from "src/componentsV2/PageLayout";
import { TagList } from "src/componentsV2/TagList";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import FilterSearchString from "src/componentsV2/filters/FilterSearchString";
import FilterSelect from "src/componentsV2/filters/FilterSelect";
import TagFilter from "src/componentsV2/filters/TagFilter";
import { SelectTable } from "src/componentsV2/tables/SelectTable";
import { Pagination } from "../../componentsV2/Pagination";
import { TableSort } from "../../componentsV2/tables/Table";
import { useDeleteConfirmationDialog, useSnackbar } from "../../hooks";
import { useDebounce } from "../../hooks/useDebounce";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import { useCreateChannel, useTagChannels } from "../../mutations";
import { useChannelsV2 } from "../../queries";
import { Channel, ChannelWithDetails, Tag } from "../../types";
import ErrorPage from "../ErrorPage";
import dayjs from "dayjs";
import { INTEGRATION_IMG_SRC } from "../../integrations/props";

export default function Page() {
  const [page, setPage] = useState(1);
  const history = useHistory();
  const tagChannels = useTagChannels();
  const [openSnackbar] = useSnackbar();

  const [selected, setSelected] = useState<number[]>([]);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const [channels, setChannels] = useState<ChannelWithDetails[]>([]);
  const [total, setTotal] = useState(0);

  const pageSize = 15;

  const { watch, setValue, reset, getValues } = useForm({
    defaultValues: {
      name: "",
      type: "",
      meetingTemplateID: "",
      integrationType: "",
      status: "",
    },
  });

  const filters = getValues();
  useEffect(() => {
    setPage(1);
  }, [
    filters.name,
    filters.type,
    filters.meetingTemplateID,
    filters.integrationType,
    filters.status,
  ]);

  const search = useMemo(
    () => ({
      filter: {
        name: filters.name.length > 0 ? filters.name : undefined,
        type: filters.type.length > 0 ? filters.type : undefined,
        meetingTemplateID:
          filters.meetingTemplateID.length > 0
            ? filters.meetingTemplateID
            : undefined,
        integrationType:
          filters.integrationType.length > 0
            ? filters.integrationType
            : undefined,
        status: filters.status.length > 0 ? filters.status : undefined,
      },
    }),
    [
      filters.name,
      filters.type,
      filters.meetingTemplateID,
      filters.integrationType,
      filters.status,
    ]
  );

  const searchQuery = useDebounce(search, 750);

  const { data, loading, error } = useChannelsV2(
    pageSize,
    (page - 1) * pageSize,
    searchQuery
  );
  useEffect(() => {
    if (data) {
      setTotal(data.total);
      setChannels(data.data);
    }
  }, [data]);

  const pages = data ? Math.ceil(total / pageSize) : 0;

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Bulk Add Tags"
          open
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await tagChannels(
                selected,
                tags.map((tag: Tag) => tag.id)
              );

              openSnackbar(
                `Successfully added ${tags.length} tag(s) to ${selected.length} channel(s)`
              );
              setShowTagManagementDialog(false);
            } catch {
              openSnackbar("Failed to apply tags to channels");
            }
          }}
        />
      )}

      <PageLayout
        title="Channels"
        actions={
          <>
            <Tooltip title="Add Channel" arrow>
              <IconButton
                onClick={() => {
                  alert("FIXME: wire up creating new channel");
                  // setShowAddNewContactModal(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Manage Tags" arrow>
              <IconButton
                disabled={selected.length < 2}
                onClick={() => {
                  setShowTagManagementDialog(true);
                }}
              >
                <LocalOffer />
              </IconButton>
            </Tooltip>
          </>
        }
      >
        <Stack spacing={2}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Stack direction="row" spacing={2}>
              <FilterSearchString
                label="Name"
                value={watch("name")}
                onChange={(value) => {
                  setValue("name", value);
                }}
              />
              <FilterSearchString
                label="Channel Type"
                value={watch("type")}
                onChange={(value) => {
                  setValue("type", value);
                }}
              />
              <FilterSearchString
                label="Meeting Template"
                value={watch("meetingTemplateID")}
                onChange={(value) => {
                  setValue("meetingTemplateID", value);
                }}
              />
              <FilterSearchString
                label="Integration Type"
                value={watch("integrationType")}
                onChange={(value) => {
                  setValue("integrationType", value);
                }}
              />
              <FilterSearchString
                label="Status"
                value={watch("status")}
                onChange={(value) => {
                  setValue("status", value);
                }}
              />

              <Divider orientation="vertical" flexItem />

              <Button
                onClick={() => {
                  reset();
                }}
              >
                Reset
              </Button>
            </Stack>
          </Paper>
          <Paper elevation={0} sx={{ width: "100%" }}>
            <SelectTable
              getKey={(channel) => channel.id}
              loading={loading}
              minRows={pageSize}
              columns={[
                {
                  align: "left",
                  component: (c) => (
                    <Link component={RouterLink} to={`/channels/${c.id}`}>
                      {c.id}
                    </Link>
                  ),
                  id: "id",
                  label: "Id",
                  width: 50,
                },
                {
                  component: (c) => (
                    <Stack>
                      <img
                        src={INTEGRATION_IMG_SRC[c.integration.name]}
                        alt={c.integration.name}
                        width={50}
                      />
                    </Stack>
                  ),
                  id: "integration",
                  label: "Integration",
                  width: 100,
                },
                {
                  component: (c) => (
                    <Stack>
                      <Typography>{c.type}</Typography>
                    </Stack>
                  ),
                  id: "type",
                  label: "Type",
                  width: 100,
                },
                {
                  component: (c) => (
                    <Stack>
                      <Typography>{c.name}</Typography>
                    </Stack>
                  ),
                  id: "name",
                  label: "Name",
                },
                {
                  component: (c) => <TagList tags={c.tags} />,
                  id: "tags",
                  label: "Tags",
                  visible: lg,
                },
                {
                  component: (c) => (
                    <Stack>
                      <Typography variant="caption">
                        {dayjs(c.createdAt).format("ddd, MMM D")}
                      </Typography>
                      <Typography variant="caption">
                        {dayjs(c.createdAt).format("h:mm A")}
                      </Typography>
                    </Stack>
                  ),
                  id: "createdAt",
                  label: `Created (${dayjs().format("z")})`,
                },
                {
                  component: (c) => (
                    <Chip
                      label={c.enabled ? "Enabled" : "Disabled"}
                      color={c.enabled ? "success" : "error"}
                      size="small"
                    />
                  ),
                  id: "status",
                  label: "Status",
                  width: 100,
                },
              ]}
              data={channels}
              selected={selected}
              onSelect={(checked, cs) => {
                if (!checked) {
                  setSelected(selected.concat(cs.map((c) => c.id)));
                } else {
                  setSelected(
                    selected.filter((s) => !cs.map((c) => c.id).includes(s))
                  );
                }
              }}
            />
            <Pagination
              pages={pages}
              currentPage={page}
              onPageChange={setPage}
            />
          </Paper>
        </Stack>
      </PageLayout>
    </>
  );
}
