import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import cx from "classnames";
import Icon from "@mdi/react";
import { mdiArrowDecisionOutline, mdiClose } from "@mdi/js";
import LockedDropdown from "../../../components/dropdown/LockedDropdown";
import Select from "../../../inputs/Select";
import Switch from "../../../components/switch/Switch";
import { GoToMeetingTypes } from "./linkButtons";
import { CONTACT_STATUS_TYPES } from "./cardProps";
import { CHANNEL_TYPES } from "../../props";
import { Input } from "../../../components/input";
import { useDefaultSelectTheme } from "../../../components/select";
import { useMeetingTypeOptions } from "../../../inputs/util";
import {
  ACCEPTED_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  COMPLETED_STAGE,
  DECLINED_STATUS,
  GUEST_STATUS_TO_LABEL,
  HOST_INTERVENED_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  INSTANCE_STAGE_TO_LABEL,
  NO_RESPONSE_STATUS,
  PAUSED_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  STAGING_STATUS,
} from "../../../meetings/props";
import style from "./style.module.scss";

// TODO: Delete if plausible
const CONTACT_STATUS_TYPE_OPTIONS = CONTACT_STATUS_TYPES.map(
  (contactStatusType) => {
    return {
      label: contactStatusType.status,
      value: contactStatusType,
    };
  }
);

const MEETING_STAGE_OPTIONS = Object.entries(INSTANCE_STAGE_TO_LABEL)
  .filter(([key]) => key !== COMPLETED_STAGE) // this one doesn't currently map to an old status
  .map(([key, value]) => ({
    label: value,
    value: key,
  }));

const GUEST_STATUS_OPTIONS = {
  cancelled: [{ label: "Any Status", value: "cancelled" }],
  init_fault: [
    {
      label: GUEST_STATUS_TO_LABEL[STAGING_STATUS],
      value: "init_fault",
    },
  ],
  initialized: [
    {
      label: GUEST_STATUS_TO_LABEL[STAGING_STATUS],
      value: "initialized",
    },
  ],
  initializing: [
    {
      label: GUEST_STATUS_TO_LABEL[STAGING_STATUS],
      value: "initializing",
    },
  ],
  no_quorum: [
    {
      label: GUEST_STATUS_TO_LABEL[DECLINED_STATUS],
      value: "declined",
    },
    {
      label: GUEST_STATUS_TO_LABEL[NO_RESPONSE_STATUS],
      value: "no_response",
    },
    {
      label: GUEST_STATUS_TO_LABEL[RESPONDED_UNKNOWN_INTENT_STATUS],
      value: "engaged_no_intent",
    },
  ],
  queued: [
    {
      label: GUEST_STATUS_TO_LABEL[PAUSED_STATUS],
      value: "queued",
    },
  ],
  scheduled: [
    {
      label: GUEST_STATUS_TO_LABEL[ACCEPTED_STATUS],
      value: "accepted",
    },
  ],
  scheduling: [
    {
      label: GUEST_STATUS_TO_LABEL[AWAITING_RESPONSE_STATUS],
      value: "waiting_for_first_response",
    },
    {
      label: GUEST_STATUS_TO_LABEL[AI_NEGOTIATING_STATUS],
      value: "negotiation_in_progress",
    },
    {
      label: GUEST_STATUS_TO_LABEL[REQUIRES_HOST_INTERVENTION_STATUS],
      value: "requires_user_intervention",
    },
    {
      label: GUEST_STATUS_TO_LABEL[HOST_INTERVENED_STATUS],
      value: "user_intervened",
    },
  ],
};

const MEETING_STAGE_BY_LEGACY_STATUS = Object.fromEntries(
  Object.entries(GUEST_STATUS_OPTIONS)
    .map(([meetingStage, guestOptions]) =>
      guestOptions.map((option) => [option.value, meetingStage])
    )
    .flat()
);

const GUEST_STATUS_BY_LEGACY_STATUS = Object.fromEntries(
  Object.entries(GUEST_STATUS_OPTIONS)
    .map(([, guestOptions]) =>
      guestOptions.map((option) => [option.value, option])
    )
    .flat()
);

function MeetingInstanceCard({
  active,
  canCreate,
  channelStatus,
  channelType,
  handleOnClose,
  isLoading,
  meetingDef,
  meetingStatus,
  meetingTypes,
  name,
  presetMeetingDef,
  selectedIntegration,
  setMeetingDef,
  setMeetingInstanceCardIsValid,
  setTempModalState,
  showInvalidFields,
}) {
  const [components, theme] = useDefaultSelectTheme();
  const meetingTypeOptions = useMeetingTypeOptions(meetingTypes);
  const [hasName, setHasName] = useState(false);
  const [hasMeetingInstance, setHasMeetingInstance] = useState(false);
  const [hasStatus, setHasStatus] = useState(false);
  const [localName, setLocalName] = useState(name);

  const isOrphaned = useMemo(
    () => !!selectedIntegration.orphaned,
    [selectedIntegration]
  );

  const canShowMeetingsIsLoading = useMemo(() => isLoading, [isLoading]);

  const canShowMeetingsDropdown = useMemo(
    () =>
      meetingTypes.length > 0 &&
      presetMeetingDef === null &&
      !isLoading &&
      !isOrphaned,
    [meetingTypes.length, presetMeetingDef, isLoading, isOrphaned]
  );

  const canShowLockedMeetingInstanceName = useMemo(
    () => (meetingTypes.length > 0 && presetMeetingDef !== null) || isOrphaned,
    [meetingTypes.length, presetMeetingDef, isOrphaned]
  );

  const canShowGoToMeetingsButton = useMemo(
    () => meetingTypes.length === 0 && presetMeetingDef === null && !isLoading,
    [meetingTypes.length, presetMeetingDef, isLoading]
  );

  const meetingInstanceTitleText = useMemo(() => {
    switch (channelType) {
      case CHANNEL_TYPES.INTERCEPT:
        return (
          <div
            className={
              style.meetingInstance__card_body_wrapper__text_wrapper__INTERCEPT
            }
          >
            Deactivate Meetings of Meeting Template
          </div>
        );
      case CHANNEL_TYPES.UPDATE:
        return (
          <div
            className={style.meetingInstance__card_body_wrapper__text_wrapper}
          >
            When Meeting Template
          </div>
        );
      default:
        return (
          <div
            className={style.meetingInstance__card_body_wrapper__text_wrapper}
          >
            Create Meetings of Meeting Template
          </div>
        );
    }
  }, [channelType]);

  const setName = useCallback((event) => setLocalName(event.target.value), []);

  const updateName = useMemo(() => {
    return debounce((value) => {
      setTempModalState((prevState) => ({
        ...prevState,
        name: value,
      }));
    }, 500);
  }, [setTempModalState]);

  const setChannelStatus = useCallback(
    (value) => {
      setTempModalState((prevState) => ({
        ...prevState,
        channelStatus: value,
      }));
    },
    [setTempModalState]
  );

  const setMeetingStatus = useCallback(
    (value) => {
      setTempModalState((prevState) => ({
        ...prevState,
        meetingStatus: value,
      }));
    },
    [setTempModalState]
  );

  const addMeetingStatus = useCallback(
    (index, value) => {
      const allStatus = [...meetingStatus];
      allStatus[index] = value;

      setMeetingStatus(allStatus);
    },
    [meetingStatus, setMeetingStatus]
  );

  const selectMeetingStage = useCallback(
    (index, value) => {
      const selected = GUEST_STATUS_OPTIONS[value][0].value;
      const allStatus = [...meetingStatus];
      allStatus[index] = selected;

      setMeetingStatus(allStatus);
    },
    [meetingStatus, setMeetingStatus]
  );

  const meetingStatusSegment = useCallback(
    (index) => (
      <div
        className={style.meetingInstance__card_meeting_statuses}
        key={`meeting-status-segment-${index}`}
      >
        <div className={style.meeting_status}>
          <div className={style.meeting_status__text}>
            {index > 0 && "or (optional)"}
          </div>

          <div className={style.meeting_status__dropdowns}>
            <Select
              components={components}
              customTheme={theme}
              disabled={meetingDef === null}
              name={`/channels/${channelType}/add_channel/instance_stage`}
              onChange={({ value }) => {
                selectMeetingStage(index, value);
              }}
              options={MEETING_STAGE_OPTIONS}
              placeholder={<div>Meeting Stage is</div>}
              value={MEETING_STAGE_OPTIONS.find(
                (o) =>
                  o.value ===
                  MEETING_STAGE_BY_LEGACY_STATUS[meetingStatus[index]]
              )}
            />
          </div>
          <div className={style.meeting_status__dropdowns}>
            <Select
              components={components}
              customTheme={theme}
              disabled={meetingDef === null}
              name={`/channels/${channelType}/add_channel/guest_status`}
              onChange={({ value }) => {
                addMeetingStatus(index, value);
              }}
              options={
                GUEST_STATUS_OPTIONS[
                  MEETING_STAGE_BY_LEGACY_STATUS[meetingStatus[index]]
                ]
              }
              placeholder={<div>Guest status is</div>}
              value={GUEST_STATUS_BY_LEGACY_STATUS[meetingStatus[index]]}
            />
          </div>
        </div>
      </div>
    ),
    [
      addMeetingStatus,
      components,
      channelType,
      meetingDef,
      meetingStatus,
      selectMeetingStage,
      theme,
    ]
  );

  const toggleActive = useCallback(() => {
    setTempModalState((prev) => ({
      ...prev,
      enabled: !prev.enabled,
    }));
  }, [setTempModalState]);

  useEffect(() => {
    setHasName(name !== "" && name !== null);
    setHasMeetingInstance(meetingDef.id !== null);
    setHasStatus(() => {
      if (channelType === CHANNEL_TYPES.IMPORT) {
        return channelStatus.value !== "" && channelStatus.value !== null;
      }

      if (channelType === CHANNEL_TYPES.INTERCEPT) {
        return true;
      }

      if (channelType === CHANNEL_TYPES.UPDATE) {
        return meetingStatus.length > 0;
      }
    });
  }, [channelStatus, channelType, meetingDef.id, meetingStatus, name]);

  useEffect(() => {
    if (name !== localName) {
      updateName(localName);
    }
  }, [localName, name, updateName]);

  useEffect(() => {
    setMeetingInstanceCardIsValid(hasName && hasStatus && hasMeetingInstance);
  }, [hasMeetingInstance, hasName, hasStatus, setMeetingInstanceCardIsValid]);

  return (
    <div className={style.meetingInstance__card}>
      <div className={style.meetingInstance__card_header}>
        <Icon
          className={style.meetingInstance__card_header_icon}
          path={mdiArrowDecisionOutline}
          size={2}
        />

        <span className={style.meetingInstance__card_header_text}>
          {channelType}
        </span>

        <div className={style.meetingInstance__card_header_input_wrapper}>
          {!isOrphaned && (
            <Input
              className={cx(
                {
                  [style.invalid_dropdown]: showInvalidFields && !hasName,
                },
                style.meetingInstance__card_header_input
              )}
              large
              placeholder="Name"
              type="text"
              name={`/channels/${channelType}/add_channel/name`}
              value={localName}
              onChange={setName}
            />
          )}

          {isOrphaned && (
            <LockedDropdown
              className={style.meetingInstance__card_header_input}
              value={localName}
              name="/meetingInstanceCard/headerLockedDropdown"
            />
          )}
        </div>

        <div className={style.meetingInstance__card_header_actions}>
          <span className={style.meetingInstance__card_header_actions_text}>
            <span>enable</span>
          </span>
          <span>
            <Switch
              size="small"
              isOn={isOrphaned ? false : active}
              handleToggle={toggleActive}
              disabled={!canCreate}
              name="/meetingInstanceCard/enableSwitch"
              // name={`/channels/${channelType}/add_channel/toggle_enable_disable`}
            />
          </span>
        </div>

        <Icon
          path={mdiClose}
          size={2}
          className={style.meetingInstance__card_close}
          name={`/channels/${channelType}/add_channel/close`}
          onClick={handleOnClose}
        />
      </div>

      <div className={style.meetingInstance__card_body_wrapper}>
        {meetingInstanceTitleText}
        <div
          className={
            channelType === CHANNEL_TYPES.INTERCEPT
              ? style.meetingInstance__card_body_meeting_instance__INTERCEPT
              : style.meetingInstance__card_body_meeting_instance
          }
        >
          {canShowMeetingsIsLoading && (
            <div className={style.title}>Loading...</div>
          )}
          {canShowLockedMeetingInstanceName && (
            <LockedDropdown
              value={presetMeetingDef?.name || meetingDef?.name}
            />
          )}

          {canShowMeetingsDropdown && (
            <Select
              components={components}
              customTheme={theme}
              name={`/channels/${channelType}/add_channel/meeting_type`}
              onChange={({ value }) => {
                setMeetingDef(value);
              }}
              options={meetingTypeOptions}
              placeholder={<div>Select Meeting Template</div>}
              value={meetingTypeOptions.find(
                (mt) => mt.value.id === meetingDef.id
              )}
            />
          )}
          {canShowGoToMeetingsButton && <GoToMeetingTypes />}
        </div>

        {channelType === CHANNEL_TYPES.IMPORT && (
          <div className={style.meetingInstance__card_body_status}>
            {!isOrphaned && (
              <Select
                components={components}
                customTheme={theme}
                name={`/channels/${channelType}/add_channel/set_instance_stage`}
                onChange={({ value }) => {
                  if (value === channelStatus) {
                    return;
                  }
                  setChannelStatus(value);
                }}
                options={CONTACT_STATUS_TYPE_OPTIONS}
                placeholder={<div>Set Meeting Stage To</div>}
                value={CONTACT_STATUS_TYPE_OPTIONS.find(
                  (cst) => cst.value.value === channelStatus.value
                )}
              />
            )}

            {isOrphaned && <LockedDropdown value={channelStatus.value} />}
          </div>
        )}
      </div>
      {channelType === CHANNEL_TYPES.UPDATE && meetingStatusSegment(0)}
      {channelType === CHANNEL_TYPES.UPDATE &&
        meetingStatus.length > 0 &&
        meetingStatus.map((_, i) => meetingStatusSegment(i + 1))}
    </div>
  );
}

export default MeetingInstanceCard;
