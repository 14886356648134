import React from "react";
import cx from "classnames";
import { mdiTagPlusOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import style from "./style.module.scss";

export function TagButton({
  className,
  onClick,
  name,
  size = 1,
  disabled = false,
}) {
  return (
    <button
      disabled={disabled}
      className={cx(
        style.tagIcon__button,
        { [style.tagIcon__button__disabled]: disabled },
        className
      )}
      name={name}
      aria-label={name}
      onClick={onClick}
      type="button"
    >
      <Icon
        className={style.tagIcon__icon}
        path={mdiTagPlusOutline}
        size={size}
      />
    </button>
  );
}
