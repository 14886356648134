import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import Flexbox from "../../../components/flexbox";
import MeetingTypeDetailsHeaderSelect from "../meetingTypeDetailsHeaderSelect";
import MeetingTypeDetailsCalendar from "./MeetingTypeDetailsCalendar";
import { ButtonContainer } from "../../../components/button";
import { useMeeting } from "../state";
import { useTimezones } from "../../../components/timezone";
import { NO_QUORUM_STAGE } from "../../props";
import { P1 } from "../../../components/typography";
import style from "./meetingTypeDetailsHeader.module.scss";

const MeetingTypeDetailsHeader = ({ calendarInvite }) => {
  const [meeting] = useMeeting();
  const { browserTimezone } = useTimezones();
  const [openCalendar, setOpenCalendar] = useState(false);

  const hoverText = useMemo(() => {
    if (meeting.startTimeOverridden) {
      return "The start time can be changed only once";
    }
    return "Start Time";
  }, [meeting.startTimeOverridden]);

  const editDateTimeDisabled = useMemo(
    () => meeting.status !== "scheduling" || meeting.startTimeOverridden,
    [meeting.status, meeting.startTimeOverridden]
  );

  const startDate = useMemo(() => {
    if (!meeting.startTime || meeting.status === NO_QUORUM_STAGE) {
      return null;
    }

    const todaysDate = moment().format("MM/DD/YYYY");
    const instanceStartDate = moment(meeting.startTime).format("MM/DD/YYYY");

    if (instanceStartDate === todaysDate) {
      return "Today";
    }
    return instanceStartDate;
  }, [meeting]);

  const startDateTime = useMemo(() => {
    if (!meeting.startTime || meeting.status === NO_QUORUM_STAGE) {
      return null;
    }
    return `${moment(meeting.startTime).format("h:mma")} ${browserTimezone}`;
  }, [browserTimezone, meeting]);

  const handleCloseCalendar = useCallback(() => {
    setOpenCalendar(false);
  }, [setOpenCalendar]);

  const handleCalendarOnClick = useCallback(() => {
    setOpenCalendar(true);
  }, [setOpenCalendar]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Row className={style.meetingTypeDetailsHeader} height="fit-content">
          <MeetingTypeDetailsHeaderSelect calendarInvite={calendarInvite} />

          <Column
            width="20%"
            alignItems="flex-end"
            justifyContent="space-evenly"
          >
            <ButtonContainer
              className={style.date_time_button}
              style={{ backgroundColor: "unset" }}
              small
              transparent
              disabled={editDateTimeDisabled}
              name="/instanceDetails/start_time"
              onClick={handleCalendarOnClick}
            >
              {startDate && startDateTime && (
                <span data-rh={hoverText} data-rh-at="bottom">
                  {startDate}
                  <br />
                  {startDateTime}
                </span>
              )}
              {(!startDate || !startDateTime) && <P1 small>&mdash;</P1>}
            </ButtonContainer>

            {openCalendar && (
              <MeetingTypeDetailsCalendar
                startTime={meeting.startTime}
                handleCloseCalendar={handleCloseCalendar}
              />
            )}
          </Column>
        </Row>
      )}
    </Flexbox>
  );
};

export default MeetingTypeDetailsHeader;
