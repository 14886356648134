import { createContext, useContext } from "react";

export const DEFAULT_MESSENGER = {
  dmIsOpen: false,
  messageSent: false,
  messages: [],
};

export const MessengerStateContext = createContext(DEFAULT_MESSENGER);

export const useMessengerState = () => useContext(MessengerStateContext);

const SET_MESSENGER = "SET_MESSENGER";
export const SET_MESSAGES = "SET_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_MESSAGE_SENT = "SET_MESSAGE_SENT";
export const SET_DM_IS_OPEN = "SET_DM_IS_OPEN";

export const messengerReducer = (state, action) => {
  const { type, payload } =
    typeof action === "function" ? action(state) : action;

  switch (type) {
    case SET_MESSENGER: {
      return { ...state, ...payload.messenger };
    }
    case SET_MESSAGES: {
      return { ...state, messages: payload.messages };
    }
    case ADD_MESSAGE: {
      return {
        ...state,
        messageSent: true,
        messages: [...state.messages, payload.message],
      };
    }
    case SET_MESSAGE_SENT: {
      return { ...state, messageSent: payload.value };
    }
    case SET_DM_IS_OPEN: {
      return { ...state, dmIsOpen: payload.value };
    }
    default: {
      throw new Error(
        `Dispatched type of ${type} does not exist in the messengerReducer`
      );
    }
  }
};
