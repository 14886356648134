import React, { useMemo, useCallback } from "react";
import cx from "classnames";

import { Select } from "../../../../inputs";
import LockedDropdown from "../../../../components/dropdown/LockedDropdown";
import { Input } from "../../../../components/input";
import { useConditionSelectTheme } from "./useConditionSelectTheme";
import { CHANNEL_TYPES } from "../../../props";
import style from "../style.module.scss";

function ValueDropdown({
  showInvalidFields,
  hasError,
  channelType,
  isOrphaned,
  localValue,
  valueOptions,
  setLocalValue,
  isOutreach,
}) {
  const [components, theme] = useConditionSelectTheme({
    right: true,
  });
  const showValueIsInvalidStyling = useMemo(
    () => showInvalidFields && localValue === "",
    [showInvalidFields, localValue]
  );

  const inputStyling = useMemo(
    () =>
      cx(style.conditions__card_condition_value_dropdown, {
        [style.invalid_dropdown]: showValueIsInvalidStyling,
      }),
    [showValueIsInvalidStyling]
  );

  const canShowValueDropdown = useMemo(
    () =>
      !hasError &&
      !isOrphaned &&
      !(isOutreach && channelType === CHANNEL_TYPES.UPDATE),
    [channelType, hasError, isOrphaned, isOutreach]
  );

  const canShowLockedValue = useMemo(
    () => hasError || isOrphaned,
    [hasError, isOrphaned]
  );

  const handleOnSelectValue = useCallback(
    (item) => {
      setLocalValue(item.value);
    },
    [setLocalValue]
  );

  const handleOnChangeValue = useCallback(
    (event) => {
      setLocalValue(event.target.value);
    },
    [setLocalValue]
  );

  const selectValueOptions = useMemo(() => {
    if (valueOptions === null) {
      return [];
    }
    return valueOptions.map((valueOption) => {
      return {
        label: valueOption.label,
        value: valueOption,
      };
    });
  }, [valueOptions]);

  return (
    <>
      {canShowValueDropdown && (
        <div className={style.conditions__card_condition_value}>
          {valueOptions !== null && (
            <Select
              components={components}
              customTheme={theme}
              onChange={({ value }) => {
                handleOnSelectValue(value);
              }}
              options={selectValueOptions}
              placeholder={<div>Select Object Type</div>}
              value={selectValueOptions.find(
                (svo) => localValue === svo.value.value
              )}
            />
          )}

          {valueOptions === null && (
            <Input
              large
              placeholder="Value..."
              type="text"
              name={`/channels/${channelType}/add_channel/value`}
              className={inputStyling}
              onChange={handleOnChangeValue}
              value={localValue}
            />
          )}
        </div>
      )}

      {canShowLockedValue && (
        <LockedDropdown
          className={style.conditions__card_condition_locked_dropdown_value}
          value={localValue}
        />
      )}
    </>
  );
}

export default ValueDropdown;
